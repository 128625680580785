import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

import { Container } from "style/layout";

import SubMainSection from "../components/SubMainSection";
import FirstSection from "../components/mri/FirstSection";
import SecondSection from "../components/mri/SecondSection";
import ThirdSection from "../components/mri/ThirdSection";
import FourthSection from "../components/mri/FourthSection";
import FifthSection from "../components/mri/FifthSection";
import Footer from "../Footer";
import ScrollBar from "../MenuBar";

import MobileMri from "mobile/page/MobileMri";

import $ from "jquery";
import "fullpage.js";
import "fullpage.js/dist/jquery.fullpage.min.css";

import {
	setCurrentPage,
	setCurrentSection,
	setOriginSection,
} from "../modules/page";

import { setHeaderVisiable } from "modules/style";
import { useMousePosition } from "hooks/CustomHooks";
import {isLandscape} from "../lib/function";

const Mri = () => {
	const dispatch = useDispatch();

	const { currentSection, device } = useSelector((state) => ({
		currentSection: state.page.currentSection,
		device: state.style.device,
	}));

	const mousePosition = useMousePosition();
	const [timeoutState, setTimeoutState] = useState(false);

	useEffect(() => {
		// Init fullpage when is not mobile
		if (!isMobile) {
			dispatch(setCurrentPage("mri"));

			$("#fullpage").fullpage({
				scrollBar: false,
				scrollingSpeed: 1000,
				autoScrolling: true,
				anchors: ["1", "2", "3", "4", "5", "6", "7"],
				touchSensitivity: 15,
				onLeave: function (origin, destination, direction) {
					dispatch(setCurrentSection(destination));
					dispatch(setOriginSection(origin));
					dispatch(setHeaderVisiable(true));
				},
			});

			// Resize event
			if (device === "mobile" || device === "tablet") {
				$.fn.fullpage.destroy("all");
			}
		}
	}, [device]);

	useEffect(() => {
		setTimeoutState(true);

		const headerTimer = setTimeout(() => {
			if (currentSection !== 1) {
				setTimeoutState(false);
				dispatch(setHeaderVisiable(false));
			}
		}, 3000);

		return () => {
			setTimeoutState(false);
			clearTimeout(headerTimer);
		};
	}, [currentSection]);

	useEffect(() => {
		if (!timeoutState) {
			if (mousePosition.y < 100) {
				dispatch(setHeaderVisiable(true));
			} else {
				dispatch(setHeaderVisiable(false));
			}
		}
	}, [mousePosition, timeoutState]);

	const handleTop = () => {
		$("#fullpage").fullpage.moveTo("1");
	};

	const handlePrev = () => {
		if (currentSection !== 1) {
			const prevPage = (currentSection - 1).toString();
			$("#fullpage").fullpage.moveTo(prevPage);
		}
	};

	const handleNext = () => {
		if (currentSection !== 7) {
			const nextPage = (currentSection + 1).toString();
			$("#fullpage").fullpage.moveTo(nextPage);
		}
	};

    const MriContents = () => {
		// Device check (isMobile -> mobile, tablet device)
		if (isMobile && !isLandscape() && device === "tablet") {
			return <MobileMri />;
		} else {
			// Resize event when desktop
			if (device === "mobile" || device === "tablet") {
				return <MobileMri />;
			} else {
				return (
					<>
						<div id="fullpage">
							<div className="section">
								<SubMainSection />
							</div>
							<div className="section">
								<FirstSection />
							</div>
							<div className="section">
								<SecondSection />
							</div>
							<div className="section">
								<ThirdSection />
							</div>
							<div className="section">
								<FourthSection />
							</div>
							<div className="section">
								<FifthSection />
							</div>
							<div className="section fp-auto-height">
								<Footer />
							</div>
						</div>
						<ScrollBar
							onHandleTop={handleTop}
							onHandlePrev={handlePrev}
							onHandleNext={handleNext}
						/>
					</>
				);
			}
		}
	};

	return (
		<>
			<Container>
				{ MriContents() }
			</Container>
			
		</>
	);
};

export default Mri;
