import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

import { Container } from "style/layout";
import CompanyMainSection from "../components/CompanyMainSection";
import FirstSection from "../components/company/FirstSection";
import SecondSection from "../components/company/SecondSection";
import Footer from "../Footer";
import ScrollBar from "../MenuBar";

import MobileCompany from "mobile/page/MobileCompany";

import {
	setCurrentPage,
	setCurrentSection,
	setOriginSection,
} from "../modules/page";

import $ from "jquery";
import "fullpage.js";
import "fullpage.js/dist/jquery.fullpage.min.css";

import { setHeaderVisiable } from "modules/style";
import { useMousePosition } from "hooks/CustomHooks";
import {isLandscape} from "../lib/function";

const Company = () => {
	const dispatch = useDispatch();

	const { currentSection, device } = useSelector((state) => ({
		currentSection: state.page.currentSection,
		device: state.style.device,
	}));

	const mousePosition = useMousePosition();
	const [timeoutState, setTimeoutState] = useState(false);

    useEffect(() => {
		// Init fullpage when is not mobile
		if (!isMobile) {
			dispatch(setCurrentPage("company"));

            $("#fullpage").fullpage({
                scrollBar: false,
                scrollingSpeed: 1000,
                autoScrolling: true,
                anchors: ['1', '2', '3'],
                touchSensitivity: 15,
                normalScrollElements: '.scrollable-content',
                onLeave: function (origin, destination, direction) {
                    dispatch(setCurrentSection(destination));
                    dispatch(setOriginSection(origin));
                    dispatch(setHeaderVisiable(true));
                }
            });

			// Resize event
			if (device === "mobile" || device === "tablet") {
				$.fn.fullpage.destroy("all");
			}
		}
	}, [device]);

	useEffect(() => {
		setTimeoutState(true);

		const headerTimer = setTimeout(() => {
			if (currentSection !== 1) {
				setTimeoutState(false);
				dispatch(setHeaderVisiable(false));
			}
		}, 3000);

		return () => {
			setTimeoutState(false);
			clearTimeout(headerTimer);
		};
	}, [currentSection]);

	useEffect(() => {
		if (!timeoutState) {
			if (mousePosition.y < 100) {
				dispatch(setHeaderVisiable(true));
			} else {
				dispatch(setHeaderVisiable(false));
			}
		}
	}, [mousePosition, timeoutState]);

	const handleTop = () => {
		$("#fullpage").fullpage.moveTo("1");
		// header.classList.remove("blur");
	};

	const handlePrev = () => {
		if (currentSection !== 1) {
			const prevPage = (currentSection - 1).toString();
			$("#fullpage").fullpage.moveTo(prevPage);
		}
	};

	const handleNext = () => {
		if (currentSection !== 7) {
			const nextPage = (currentSection + 1).toString();
			$("#fullpage").fullpage.moveTo(nextPage);
		}
	};

	const CompanyContents = () => {
		// Device check (isMobile -> mobile, tablet device)
		if (isMobile && !isLandscape() && device === "tablet") {
			return <MobileCompany />;
		} else {
			// Resize event when desktop
			if (device === "mobile" || device === "tablet") {
				return <MobileCompany />;
			} else {
				return (
					<>
						<div id="fullpage">
							<div className="section">
								<CompanyMainSection />
							</div>
							<div className="section">
								<FirstSection />
							</div>
							<div className="section">
								<SecondSection />
							</div>
							<div className="section fp-auto-height">
								<Footer />
							</div>
						</div>
						<ScrollBar
							onHandleTop={handleTop}
							onHandlePrev={handlePrev}
							onHandleNext={handleNext}
						/>
					</>
				);
			}
		}
	};

	return (
		<>
			<Container>{CompanyContents()}</Container>
		</>
	);
};

export default Company;
