import React from "react";
import styled from "styled-components";

import {
	PageWrapper,
	Wrapper,
	Width100,
	Width45,
	Width50,
	Width55,
	Height100,
} from "style/layout";

import Info from "components/Info";

import "../../layout.scss";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Slider from "react-slick";

const CustomSectionWrapper = styled.div`
    height: 100%;
`;

const CustomWrapper = styled(Wrapper)`
    border: 0.5px solid ${(props) => props.theme.colors.lbgBorderColor};
	border-width: 0 0.5px;
`;
const CustomWidth100 = styled(Width100)`
	height: 100%;
	box-sizing:border-box;
	.contents-wrapper{
		border-left: 0.5px solid rgba(90,90,119,0.08);
		justify-content: center;
	}
`;

const CustomWidth50 = styled(Width50)`
	align-items: center;
	flex-direction:column;
	padding-top:${props => props.theme.sizes.headerHeight};
	.scrollable-content{
		width:100%;
		${props => props.theme.device.tabletL} {
			height:40%;
		}
		
		@media (max-width: 1900px) {
			height:30vh;
			overflow:auto;
			width: 100%;
			box-sizing:border-box;
		}
		
		
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			align-items: stretch;
			width: 99.5%;
		}
		
	}
`;
const CustomDataList = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap:wrap;
	width: 100%;
	box-sizing: border-box;
	position: relative;
	justify-content: center;
	align-items: initial;
`;

const infoTextObj = {
	title:`(주)앤톡의 데이터바우처<br /> 판매 서비스`,
	content:`고객사의 세부 선호 기업군(특정 업력, 특허 보유, 투자유치,<br />
			 기술인증, 기업 유형 등)에 해당하는 기업군을 대상으로 <br />
			기업별 7대 영역(사업, 성과, 기술, 조직, 인증 등)의 <br />
			세부 데이터를 제공하며, 고객사는 선호하는 형태(API 혹은 파일)로 <br />
			데이터셋(Dataset)을 활용할 수 있습니다.`
};
const CustomList = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	min-height: ${(props) => props.theme.Rem(80)};
	padding: ${(props) => props.theme.Rem(26)} ${(props) => props.theme.Rem(14)} ${(props) => props.theme.Rem(20)} ${(props) => props.theme.Rem(20)};
	margin-top: ${(props) => props.theme.Rem(3)};
	word-break: keep-all;
	box-sizing: border-box;
	background-color:${props => props.theme.colors.contentBg};
	width:calc((100% / 3) - (0.8rem * 2));
	margin:0 0.8rem 0.8rem 0;
	border-radius:1.5rem;
	
	&:first-of-type{
		margin-left:0;
	}
	&:nth-of-type(3n){
		margin-right:0;
	}
	&:nth-of-type(n+2){
		margin-left:0;
	}
	img {
		width: ${(props) => props.theme.Rem(40)};
		margin-right: ${(props) => props.theme.Rem(20)};
	}
	${(props) => props.theme.device.tabletL} {
		min-height: ${(props) => props.theme.Rem(170)};
		padding: ${(props) => props.theme.Rem(10)}
			${(props) => props.theme.Rem(70)};
		margin-top: ${(props) => props.theme.Rem(0)};
	}
	${props => props.theme.device.desktopM}{
		padding: ${(props) => props.theme.Rem(25)} ${(props) => props.theme.Rem(20)};
		img {
			width: ${(props) => props.theme.Rem(30)};
			margin-right: ${(props) => props.theme.Rem(15)};
		}
	}
	${(props) => props.theme.device.desktopS} {
		padding: ${(props) => props.theme.Rem(13)} ${(props) => props.theme.Rem(20)};
		min-height:auto;
		img {
			width: ${(props) => props.theme.Rem(30)};
			margin-right: ${(props) => props.theme.Rem(15)};
		}
	}
	
	${(props) => props.theme.device.notebook} {
		margin-top: ${(props) => props.theme.Rem(0)};
	}
	
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		width:30%;
		flex-shrink:0;
		margin:0 0.5rem 0.5rem 0;
  	}
`;

const TextBox = styled.span`
	display: flex;
	flex-direction: column;
	width:100%;
`;

const TextBoxTitle = styled.b`
	br {
		display: none;
	}
	font-size: ${(props) => props.theme.Rem(16)};
	font-weight: 900;
	margin-bottom: ${(props) => props.theme.Rem(3)};
	text-align: left;
    width: auto;
    word-break: keep-all;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
	span {
		font-weight: 400;
		font-size: ${(props) => props.theme.Rem(14)};
		margin-left: ${(props) => props.theme.Rem(5)};
		
		${(props) => props.theme.device.desktopS} {
			font-size: ${(props) => props.theme.Rem(12)};	
		}
	}
	${(props) => props.theme.device.desktopS} {
		font-size: ${(props) => props.theme.Rem(14)};	
	}
	
`;

const TextBoxContents = styled.span`
	font-weight: 400;
	font-size: ${(props) => props.theme.Rem(14)};
	line-height: ${(props) => props.theme.Rem(20)};
	${(props) => props.theme.device.desktopS} {
		font-size: ${(props) => props.theme.Rem(12)};
		line-height: ${(props) => props.theme.Rem(16)};	
	}
`;

const ServiceContents = styled(Width100)`
	flex-direction:column;
	padding:0 10%;
	box-sizing:border-box;
	p{
		margin-bottom:${(props) => props.theme.Rem(35)};
		word-break:keep-all;
		font-size:1rem;
		line-height:1.4;
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			font-size:0.9rem;
  		}
	}
	${(props) => props.theme.device.tabletL} {
		padding:0 8%;	
	}
`
const DvInfoWrapper = styled.div`
	width:100%;
	height:auto;
	margin-bottom:${(props) => props.theme.Rem(40)};
	ul{
		display:flex;
		flex-direction:row;
		width:100%;
		img{
			max-height:120px;
			height:auto;
			width: 100%;
			margin-bottom:0;
			flex-shrink:0;
		}
		li{
			width:calc((100% / 5));
			margin-right:7%;
			display:flex;
			flex-direction:column;
			align-items:center;
			justify-content:flex-end;
			&:last-of-type{
				margin-right:0;
			}
			span{
				font-size:${(props) => props.theme.Rem(14)};
				color:${props => props.theme.colors.pointColor};
				font-weight:600;
				margin-bottom:0.5rem;
				margin-top:${(props) => props.theme.Rem(32)};
				${(props) => props.theme.device.desktopS} {
					font-size: ${(props) => props.theme.Rem(12)};	
				}
			}
			strong{
				font-size:${(props) => props.theme.Rem(22)};
				color:${props => props.theme.colors.pointColor};	
			}
		}
	}
`;

const SecondSection = () => {
	return (
		<>
			<CustomSectionWrapper>
				<PageWrapper>
					<CustomWrapper>
						<CustomWidth100>

							<Width50 className="page-info">
								<Info primary={false} texts = {infoTextObj}/>
							</Width50>

							<CustomWidth50 className="contents-wrapper border-left">
								<ServiceContents>
									<DvInfoWrapper>
										<ul>
											<li><img src="/img/dv_item01.png" alt="법인기업"/><span>100만개 이상</span><strong>법인기업</strong></li>
											<li><img src="/img/dv_item02.png" alt="원천데이터"/><span>기업별 300개 이상</span><strong>원천데이터</strong></li>
											<li><img src="/img/dv_item03.png" alt="AI 모형"/><span>30개 이상</span><strong>AI 모형</strong></li>
											<li><img src="/img/dv_item04.png" alt="AI 지표"/><span>2000개</span><strong>AI 지표</strong></li>
										</ul>
									</DvInfoWrapper>
									<p>
										㈜앤톡은 국내 법인기업의 재무 정보는 물론, 사업, 성과, 기술, 조직, 인증 등 기업별 7대 영역에 속하는
										비금융 대안데이터(Alternative Data)를 제공하여, 수요기업에 차별화된 기업 분석 인사이트를 지원합니다.
									</p>
								</ServiceContents>
								<div className="scrollable-content">
									<CustomDataList>
									<CustomList>
											<span>
												<img src="/img/reco_icon_b.png" alt="수상" />
											</span>
										<TextBox>
											<TextBoxTitle>
												수상<br />
												<span>Recognition</span>
											</TextBoxTitle>
											<TextBoxContents>
												정부 수상, 선정 지원 사업, 공공 및 민간 경진대회 입상 내역 등
											</TextBoxContents>
										</TextBox>
									</CustomList>
									<CustomList>
										<span>
											<img src="/img/org_icon_b.png" alt="조직" />
										</span>
										<TextBox>
											<TextBoxTitle>
												조직<br />
												<span>Organization</span>
											</TextBoxTitle>
											<TextBoxContents>
												고용 규모, 임금 수준, 입사 및 퇴사자 비율 등
											</TextBoxContents>
										</TextBox>
									</CustomList>
									<CustomList>
										<span>
											<img
												src="/img/sns_icon_b.png"
												alt="사회적 관계망"
											/>
										</span>
										<TextBox>
											<TextBoxTitle>
												사회적 관계망<br />
												<span>SNS</span>
											</TextBoxTitle>
											<TextBoxContents>
												SNS 채널 별 주소, 활동 현황, 업데이트 빈도 등
											</TextBoxContents>
										</TextBox>
									</CustomList>
									<CustomList>
										<span>
											<img src="/img/news_icon_b.png" alt="언론보도" />
										</span>
										<TextBox>
											<TextBoxTitle>
												언론보도<br />
												<span>News</span>
											</TextBoxTitle>
											<TextBoxContents>
												언론 보도 주기, 최신 보도 일자, 언론 노출 규모 등
											</TextBoxContents>
										</TextBox>
									</CustomList>
									<CustomList>
										<span>
											<img src="/img/web_icon_b.png" alt="조직" />
										</span>
										<TextBox>
											<TextBoxTitle>
												홈페이지<br />
												<span>Web</span>
											</TextBoxTitle>
											<TextBoxContents>
												사이트 주소, 가동여부, 서버 반응속도, 업데이트 이력 등
											</TextBoxContents>
										</TextBox>
									</CustomList>
									<CustomList>
										<span>
											<img src="/img/geol_icon_b.png" alt="지리" />
										</span>
										<TextBox>
											<TextBoxTitle>
												지리
												<br />
												<span>Geolocation</span>
											</TextBoxTitle>
											<TextBoxContents>
												회사 소재, 공시 지가, 주소 변경 이력 및 추이 등
											</TextBoxContents>
										</TextBox>
									</CustomList>

									<CustomList>
										<span>
											<img src="/img/tran_icon_b.png" alt="계약" />
										</span>
										<TextBox>
											<TextBoxTitle>
												계약
												<br />
												<span>Transaction</span>
											</TextBoxTitle>
											<TextBoxContents>
												정부 / 공공 프로젝트 수주 현황, 입찰 상세 내역 등
											</TextBoxContents>
										</TextBox>
									</CustomList>
									<CustomList>
										<span>
											<img src="/img/pro_icon_b.png" alt="상품" />
										</span>
										<TextBox>
											<TextBoxTitle>
												상품
												<br />
												<span>Product</span>
											</TextBoxTitle>
											<TextBoxContents>
												기업 소개, 사업 영역, 서비스 및 핵심 상품 설명, 상품 이미지 등
											</TextBoxContents>
										</TextBox>
									</CustomList>
									<CustomList>
										<span>
											<img src="/img/soci_icon_b.png" alt="사회 가치" />
										</span>
										<TextBox>
											<TextBoxTitle>
												사회 가치<br />
												<span>Social Value</span>
											</TextBoxTitle>
											<TextBoxContents>
												추구 가치, 소셜벤처 추정 점수, ESG 보유 기술 등
											</TextBoxContents>
										</TextBox>
									</CustomList>
									<CustomList>
										<span>
											<img src="/img/ip_icon_b.png" alt="지적재산권" />
										</span>
										<TextBox>
											<TextBoxTitle>
												지적재산권
												<br />
												<span>IP</span>
											</TextBoxTitle>
											<TextBoxContents>
												특허, 실용신안, 상표, 저작권, 국가 R&D 추진 내역
											</TextBoxContents>
										</TextBox>
									</CustomList>
									<CustomList>
										<span>
											<img src="/img/cert_icon_b.png" alt="인증" />
										</span>
										<TextBox>
											<TextBoxTitle>
												인증
												<br />
												<span>Certification</span>
											</TextBoxTitle>
											<TextBoxContents>
												정부 및 민간 기관에서 발급한 기업, 상품, 기술 관련 인증
											</TextBoxContents>
										</TextBox>
									</CustomList>
									<CustomList>
										<span>
											<img
												src="/img/search_icon_b.png"
												alt="검색기록"
											/>
										</span>
										<TextBox>
											<TextBoxTitle>
												검색기록
												<br />
												<span>Search Log</span>
											</TextBoxTitle>
											<TextBoxContents>
												검색 규모, 검색 순위, 시장 관심도 등
											</TextBoxContents>
										</TextBox>
									</CustomList>
								</CustomDataList>
								</div>
							</CustomWidth50>
						</CustomWidth100>
					</CustomWrapper>
				</PageWrapper>
			</CustomSectionWrapper>
		</>
	);
};

export default SecondSection;