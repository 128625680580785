import React from "react";
import styled, {css} from "styled-components";

import {
	Height100,
	Height50, MriPageLogo,
	PageLogo,
	PageWrapper, textBoxContents,
	textBoxTitle,
	Width100,
	Width33,
	Width45,
	Width55,
	Wrapper
} from "style/layout";
import Info from "../Info";
import Contents from "../landing/fourth/Contents";

const CustomSectionWrapper = styled.div``;

const CustomWidth100 = styled(Width100)`
	height: 100%;
`;
const PageInfoWidth45 = styled(Width45)`
	height: 100%;
	box-sizing: border-box;
	flex-wrap: nowrap;
	align-items: flex-start;
	justify-content:center;
	margin-top: -#{${(props) => props.theme.sizes.pageMarginTop}};
    flex-direction: column;
    border-right:1px solid ${props => props.theme.colors.lbgBorderColor};
`;

const CustomHeight50 = styled(Height50)`
	box-sizing: border-box;
	padding-top:${props => props.theme.Rem(50)};
	padding-bottom:${props => props.theme.Rem(50)};
	padding-right:${props => props.theme.Rem(15)};
	padding-left:${props => props.theme.Rem(22)};
	&:first-of-type{
		justify-content: flex-end;
		color:$point-color;
		padding:0;	
	}
	&:last-of-type{
		justify-content: flex-start;
		color:${props => props.theme.colors.pointColor};
	}
	.text-box{
		.title{
			${textBoxTitle}
			margin-bottom:${(props) => props.theme.Rem(30)};
			font-size:${(props) => props.theme.Rem(30)};
			word-break: keep-all;
		}
		.contents{
			${textBoxContents}
			word-break: keep-all;
			line-height:${(props) => props.theme.Rem(24)};
			font-size:${(props) => props.theme.Rem(16)};
		}
	}
`;

const CustomWidth33 = styled(Width33)`
	${(props) => {
	let styles=``;
	for (let i = 1; i <= 4 ; i += 1){
		styles +=`
		&:first-of-type{
			border:none;
		}
		&:nth-of-type(${i}){
			border-left:1px solid ${props.theme.colors.lbgBorderColor};
			box-sizing:border-box;
			
		}
	`;
	}
	return css`${styles}`;
}
}
`;

const ImageBox = styled.div`
    height: 100%;
    display: flex;
    overflow: hidden;
    margin-bottom: 2.5rem;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    img{
        max-width: 100%;
		box-shadow: 1px 1px 1px rgb(255 255 255 / 40%);
		flex-shrink: 0;
		width: 100%;
    }
`;
const infoTextObj = {
	title:`Success Case`,
	content:`AI 기반의 기업 데이터 수집, 분석, 검증을<br />
			수행하여 전 프로세스 상에서 차별적 경쟁 우위를<br />    
			달성합니다.`
}
const FifthSection = () => {
	return (
		<CustomSectionWrapper>
			<PageWrapper>
				<Wrapper>
					<CustomWidth100>
						<PageInfoWidth45>
							<Info texts = {infoTextObj}/>
							<MriPageLogo src="/img/antock_mri_b.png" alt="antock mri"/>
						</PageInfoWidth45>
						<Width55>
							<Width100>
								<Contents/>
							</Width100>
						</Width55>
					</CustomWidth100>
				</Wrapper>
			</PageWrapper>
		</CustomSectionWrapper>
	);
};

export default FifthSection;
