import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Container } from "style/layout";
import AiMainSection from "components/AiMainSection";
import FirstSection from "components/aiprocess/FirstSection";
import SecondSection from "components/aiprocess/SecondSection";
import ThirdSection from "components/aiprocess/ThirdSection";
import Footer from "Footer";
import ScrollBar from "MenuBar";

import $ from "jquery";
import "fullpage.js";
import "fullpage.js/dist/jquery.fullpage.min.css";

import {
	setCurrentPage,
	setCurrentSection,
	setOriginSection,
} from "modules/page";

import { setHeaderVisiable } from "modules/style";
import { useMousePosition } from "hooks/CustomHooks";

const Ai = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

	const { currentPage, currentSection, device } = useSelector((state) => ({
        currentPage: state.page.currentPage,
		currentSection: state.page.currentSection,
		device: state.style.device,
	}));

    const mousePosition = useMousePosition();
	const [timeoutState, setTimeoutState] = useState(false);

	useEffect(() => {
        
		if (device === "mobile" || device === "tablet") {
			$.fn.fullpage.destroy("all");
		} else {
			dispatch(setCurrentPage("dvai"));
           
			$("#fullpage").fullpage({
				scrollBar: false,
				scrollingSpeed: 1000,
				autoScrolling: true,
				anchors: ["1", "2", "3", "4", "5"],
				touchSensitivity: 15,
				normalScrollElements: '.scrollable-content',
				onLeave: function (origin, destination, direction) {
					dispatch(setCurrentSection(destination));
					dispatch(setOriginSection(origin));
                    dispatch(setHeaderVisiable(true));
				},
			});
		}
	}, [device]);

    useEffect(() => {
		setTimeoutState(true);

		const headerTimer = setTimeout(() => {
			if (currentSection !== 1) {
				setTimeoutState(false);
				dispatch(setHeaderVisiable(false));
			}
		}, 3000);

		return () => {
			setTimeoutState(false);
			clearTimeout(headerTimer);
		};
	}, [currentSection]);

	useEffect(() => {
		if (!timeoutState) {
			if (mousePosition.y < 100) {
				dispatch(setHeaderVisiable(true));
			} else {
				dispatch(setHeaderVisiable(false));
			}
		}
	}, [mousePosition, timeoutState]);

	const handleTop = () => {
		$("#fullpage").fullpage.moveTo("1");
	};

	const handlePrev = () => {
		if (currentSection !== 1) {
			const prevPage = (currentSection - 1).toString();
			$("#fullpage").fullpage.moveTo(prevPage);
		}
	};

	const handleNext = () => {
		if (currentSection !== 5) {
			const nextPage = (currentSection + 1).toString();
			$("#fullpage").fullpage.moveTo(nextPage);
		}
	};

    const handleLinkClick = (name) => {
        if(name === currentPage){
            $("#fullpage").fullpage.moveTo("2");
        } else {
            navigate("/dv/data", {replace: true});
            window.location.reload(false);
        };
    };

	return (
		<>
			<Container>
				<div id="fullpage">
					<div className="section">
						<AiMainSection onHandleLinkClick={handleLinkClick} />
					</div>
					<div className="section">
						<FirstSection />
					</div>
					<div className="section">
						<SecondSection />
					</div>
					<div className="section">
						<ThirdSection />
					</div>
					<div className="section fp-auto-height">
						<Footer />
					</div>
				</div>
			</Container>
			{device !== "mobile" ? (
				<ScrollBar
					onHandleTop={handleTop}
					onHandlePrev={handlePrev}
					onHandleNext={handleNext}
				/>
			) : null}
		</>
	);
};

export default Ai;
