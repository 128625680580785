import React, { useState, useEffect } from "react";
import styled, {css} from "styled-components";
import {
    PageWrapper,
    Wrapper,
    Width100,
    Width45,
    Width55,
    PageLogo,
    Width50,
    textBoxTitle,
    textBoxExplain, textBoxContents
} from "style/layout";


import '../layout.scss';
import { useDispatch, useSelector } from "react-redux";

const CustomSectionWrapper = styled.div`
	background-image: url("/img/company_bg.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background-attachment: auto;
	height: 100%;
`;

const CustomWrapper = styled(Wrapper)`
	// border:0.5px solid $border-color;
	// border-width:0 0.5px;
	border-width: 0 0.5px;
    position: relative;
`;

const CustomWidth100 = styled(Width100)`
	height: 100%;
`;

const CustomTitle = styled.h3`
	${textBoxTitle}
	display:inline;
	font-weight: 900;
	font-size: ${(props) => props.theme.Rem(20)};
	margin-bottom: ${(props) => props.theme.Rem(16)};
    color:#fff;
`;

const CustomExplain = styled.span`
	${textBoxExplain}
	font-weight:200;
	font-size: 1rem;
	padding-left:0.4rem;
	color:${props => props.theme.colors.dFontColor};
	font-size:${(props) => props.theme.Rem(16)};
`;

const CustomContents = styled.p`
	${textBoxContents}
	font-weight:400;
	line-height: ${(props) => props.theme.Rem(26)};
	margin-top: ${(props) => props.theme.Rem(20)};
	color:${props => props.theme.colors.dFontColor};
	font-size:${(props) => props.theme.Rem(16)};
`;

const MainInfoList = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    width:100%;
    height:100%;
    position:relative;
`;
const MainInfoItem = styled.div`
    position:absolute;
    overflow:visible;
    height:calc(100% - ${props => props.theme.sizes.headerHeight} - 25.6%);
    bottom:0;
    left:0;
    &:nth-of-type(2){
        left:calc(100% / 7);
        height:calc(100% - ${props => props.theme.sizes.headerHeight} - 25.6% - 20%);
    }
    &:nth-of-type(3){
        margin-left:calc((100% / 7) * 2);
        height:calc(100% - ${props => props.theme.sizes.headerHeight} - 25.6% - 40%);
    }
    
    ${props => props.theme.device.tabletL}{
        height:calc(100% - ${props => props.theme.sizes.headerHeight} - 15.6%);
        bottom:0;
        left:0;
        &:nth-of-type(2){
            left:calc(100% / 7);
            height:calc(100% - ${props => props.theme.sizes.headerHeight} - 15.6% - 20%);
        }
        &:nth-of-type(3){
            margin-left:calc((100% / 7) * 2);
            height:calc(100% - ${props => props.theme.sizes.headerHeight} - 15.6% - 40%);
        }
    }
`;

const MainTitle = styled.div`
    h2{
        font-size: ${(props) => props.theme.Rem(28)};
        font-weight:600;
        color:#fff;
        margin-top: calc(${(props) => props.theme.sizes.headerHeight} + 10%);
        margin-bottom:7%;
    }
    img{
        width:60%;
    }
`;

const CompanyMainSection = () => {
    const dispatch = useDispatch();

    const { currentSection, currentPage } = useSelector((state) => ({
        currentSection: state.page.currentSection,
        currentPage: state.page.currentPage,
    }));

    return (
        <CustomSectionWrapper>
            <PageWrapper>
                <CustomWrapper>
                    <CustomWidth100>
                        <Width50>
                            <MainTitle>
                                <h2>금융을 새로운 관점으로</h2>
                                <img src="/img/company_main_img.png" alt="Antock New Perspectives on Financial Analytics"/>
                            </MainTitle>
                        </Width50>
                        <Width50 className="border-left">
                            <MainInfoList>
                                <MainInfoItem>
                                    <CustomTitle>대안 데이터 수집</CustomTitle>
                                    <CustomExplain>Alternative Data</CustomExplain>
                                    <CustomContents>
                                        모든 분석은 데이터에 근거합니다. <br />
                                        기업과 금융 분석의 토대를 대안 데이터로 확장하는 것이<br />
                                        차별적 인사이트 창출의 시발점입니다.
                                    </CustomContents>
                                </MainInfoItem>
                                <MainInfoItem className="border-left">
                                    <CustomTitle>고유 인공지능 알고리즘</CustomTitle>
                                    <CustomExplain>Original Artificial Intelligence</CustomExplain>
                                    <CustomContents>
                                        인공지능 기술에 고유 진단 방법론을 접목하였습니다.<br />
                                        분석 목적에 특화 된 자체 개발 알고리즘과 데이터 조합을 추천하여<br />
                                        새로운 관점에서 정교하고 실질적인 분석 성과를 도출할 수 있습니다.
                                    </CustomContents>
                                </MainInfoItem>
                                <MainInfoItem className="border-left">
                                    <CustomTitle>통합 빅 데이터</CustomTitle>
                                    <CustomExplain>Integrated Big Data</CustomExplain>
                                    <CustomContents>
                                        데이터의 양이 분석의 질을 담보하지 않습니다.<br />
                                        데이터에 대한 단순 수집을 넘어 가공, 검수, 통합까지<br />
                                        전방위적 자동화를 통해 분석 활동에 유의미한 정보만을 공급합니다.
                                    </CustomContents>
                                </MainInfoItem>
                            </MainInfoList>
                        </Width50>
                    </CustomWidth100>
                </CustomWrapper>
            </PageWrapper>
        </CustomSectionWrapper>
    );
};

export default CompanyMainSection;
