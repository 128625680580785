import React, {  useRef } from "react";
import { animated, useSpring } from "react-spring";

const FirstText = ({ color, init, data }) => {
	const shapeRef = useRef(null);

	const animationFillStyle = useSpring({
		from: { fillColor: "#b8b8c6" },
		to: { fillColor: color },
		config: { duration: 1000 },
		delay: 0,
		reset: init,
	});

	return (
		<>
			<animated.text
				ref={shapeRef}
				fill={animationFillStyle.fillColor}
                id={data.id}
				transform={data.transform}
				font-size="14"
				font-family="MalgunGothic, Malgun Gothic"
			>
				<tspan x="0" y="0">
					{data.id}
				</tspan>
			</animated.text>
		</>
	);
};

export default React.memo(FirstText);
