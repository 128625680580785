import React from "react";
import styled from "styled-components";


const MenuInfoWrapper = styled.div`
	display: none;
	width: 130%;
	position: absolute;
	left: calc(100% - 120px - 24px);
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	top: 0;
	bottom: 0;
	margin: 0 auto;
	img {
		width: ${(props) => props.theme.Rem(120)};
		margin-right: ${(props) => props.theme.Rem(24)};
	}
	p {
		color: #fff;
		font-size: ${(props) => props.theme.Rem(18)};
		font-weight: 300;
		line-height: ${(props) => props.theme.Rem(35)};
		margin-bottom: auto;
	}
`;

const CustomNav = styled.ul`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
`;

const CustomList = styled.li`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	position: relative;
	width: 100%;
	margin-bottom: 1rem;
	a {
		font-size: 50px;
		font-weight: 100;
		color: ${(props) => props.theme.colors.dFontColor};
		border-bottom: 1px solid transparent;
		box-sizing: border-box;
		overflow: visible;

		& + ${MenuInfoWrapper} {
			display: flex;

			img {
				opacity: 0;
				transition: all 0.4s ease-in-out;
				transition-delay: 0.2s;
				transform: translate(${(props) => props.theme.Rem(-15)}, 0);
			}
			p {
				opacity: 0;
				transition: all 0.4s ease-in-out;
				transition-delay: 0.3s;
			}
		}

		&::after {
			position: absolute;
			content: "";
			width: ${(props) => props.theme.Rem(3)};
			left: ${(props) => props.theme.Rem(-1)};
			//left:rem(-3px);
			height: 98%;
			background-color: #ff000052;
			transition: all 0.3s ease-in-out;
		}
		&:hover,
		&.hover {
			color: #fff;
			border-bottom: 1px solid #fff;
			& + ${MenuInfoWrapper} {
				img {
					opacity: 1;
					transform: translate(0, 0);
				}
				p {
					opacity: 1;
				}
			}

			&::after {
				background-color: red;
				left: calc(0px - (1rem + 10px));
				//left: rem(-10px);
				width: ${(props) => props.theme.Rem(10)};
			}
		}
		${props => props.theme.device.tabletL}{
			font-size: 2rem;
			a{
				& + ${MenuInfoWrapper} {
					p {
						font-size:1.2rem;
					}
				}
			}
		}
	}
`;



const Nav = () => {
	return (
		<CustomNav>
			<CustomList>
				<a href="/hubble">Hubble Database</a>
				<MenuInfoWrapper>
					<img src="/img/main_arrow.png" alt="detail" />
					<p>
						고유 빅데이터 기술을 활용하여 파편화된 기업 데이터를통합 수집하고,<br />
						시스템 기반의 자동 가공 및 전처리 작업을 수행하여<br />
						AI 학습을 위한 기업 데이터 구축을 진행합니다.
					</p>
				</MenuInfoWrapper>
			</CustomList>
			<CustomList>
				<a href="/mri">antock MRI</a>
				<MenuInfoWrapper>
					<img src="/img/main_arrow.png" alt="detail" />
					<p>
						인공지능 분석을 통해 기업 현황에 대한 다각적인 경영진단을<br />
						수행하고 미래 사업 성과에 대한 창의적인 예측을 지원합니다.
					</p>
				</MenuInfoWrapper>
			</CustomList>
			<CustomList>
				<a href="/company">Company</a>
			</CustomList>
			<CustomList>
				<a href="/pr">PR</a>
			</CustomList>
		</CustomNav>
	);
};

export default Nav;
