import React from "react";
import styled from "styled-components";

import {
    PageWrapper,
    Wrapper,
    Width100,
    Width60,
    Width40,
    PageLogo,
    Height100,
} from "style/layout";

const CustomSectionWrapper = styled.div`
  position: relative;
`;
const CustomPageWrapper = styled(PageWrapper)`
   height:100%;
`;
const CustomWrapper = styled(Wrapper)`
  border-color: ${(props) => props.theme.colors.lbgBorderColor};
  padding-top: ${(props) => props.theme.sizes.headerHeight};
  box-sizing: border-box;
  padding-bottom: ${(props) => props.theme.Rem(170)};
`;

const CustomWidth100 = styled(Width100)`
  height: 100%;
`;

const CustomHeight100 = styled(Height100)`
  width: 100%;
`;

const ErrorInfoWrapper = styled.div`
    width:100%;
    background-color:transparent;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    height:100%;
    margin-bottom:auto;
    h2{
        font-size:4rem;
        margin-bottom:2rem;
        color:${props => props.theme.colors.pointColor};
        font-weight:800;
    }
    p{
        font-size:1.2rem;
        line-height:1.5;
        font-weight:500;
    }
`;
const ErrorMessage = styled.div`

`;
const Error = () => {
    return (
        <CustomSectionWrapper>
            <CustomPageWrapper>
                <CustomWrapper>
                    <CustomWidth100>
                        <CustomHeight100>
                            <ErrorInfoWrapper>
                                <h2>ERROR!</h2>
                                {/*<ErrorMessage>*/}
                                {/*  /!*Error Message*!/*/}
                                {/*  Each child in a list should have a unique "key" prop*/}
                                {/*</ErrorMessage>*/}
                                <p> 해당 페이지에 문제가 생겼습니다.</p>
                                <p> 자세한 사항은 관리자에게 문의주세요.</p>
                            </ErrorInfoWrapper>
                        </CustomHeight100>
                    </CustomWidth100>
                </CustomWrapper>
            </CustomPageWrapper>
        </CustomSectionWrapper>
    );
};

export default Error;
