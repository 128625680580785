import React from "react";
import styled from "styled-components";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

import { Container } from "style/layout";

import FirstSection from "components/pr/FirstSection";
import Footer from "Footer";

import MobilePr from "mobile/page/MobilePr";

import { setCurrentPage } from "modules/page";

import "simplebar/dist/simplebar.min.css";
import {isLandscape} from "../lib/function";

const CustomContainer = styled(Container)`
	background-color: #ebebeb;
`;

const Pr = () => {
	const { device } = useSelector((state) => ({
		device: state.style.device,
	}));

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setCurrentPage("pr"));
	}, []);

	const PrContents = () => {
		// Device check (isMobile -> mobile, tablet device)
		if (isMobile && !isLandscape() && device === "tablet") {
			return <MobilePr />;
		} else {
			// Resize event when desktop
			if (device === "mobile" || device === "tablet") {
				return <MobilePr />;
			} else {
				return (
					<>
						<div className="section">
							<FirstSection />
						</div>
						<Footer />
					</>
				);
			}
		}
	};

	return (
		<>
			<CustomContainer>{PrContents()}</CustomContainer>
		</>
	);
};

export default Pr;
