import React from "react";
import styled from "styled-components";

import {
	Height100,
	PageLogo,
	pageLogo,
	PageWrapper,
	Width100,
	Width40,
	Width60,
	Wrapper,
	textBoxTitle,
	textBoxExplain,
	textBoxContents,
} from "style/layout";
import Info from "../Info";

import SliderContents from "./second/SliderContents";
import { useHover } from "hooks/CustomHooks";

const CustomSectionWrapper = styled.div``;
const CustomWidth100 = styled(Width100)`
	height: 100%;
`;
const PageInfoWidth40 = styled(Width40)`
	height: 100%;
	box-sizing: border-box;
	flex-wrap: nowrap;
	align-items: flex-start;
	justify-content: center;
	margin-top: -#{${(props) => props.theme.sizes.pageMarginTop}};
	flex-direction: column;
	border-right: 1px solid ${(props) => props.theme.colors.lbgBorderColor};
	position: relative;
`;
const CustomWidth40 = styled(Width40)`
	background-color:${(props) => props.theme.colors.pointColor};
	color:#fff;
	box-sizing:border-box;
	padding-top:3vh;
	padding-bottom:3vh;
	.slider-wrapper{
		padding-top:${(props) => props.theme.sizes.headerHeight}};
		box-sizing: border-box;
		overflow: hidden;
		.prev{
			width:100%;

		}
	}
	.data-list{
		display:flex;
		flex-direction:column;
		align-items: flex-start;
		width:100%;
		box-sizing:border-box;
		padding-right:10%;
		padding-left:20%;
		position:relative;
		&::before{
			display:block !important;
			position:absolute;
			bottom:0;
			left:0;
			width:100%;
		}
		li{
			display:flex;
			flex-direction:row;
			flex-wrap: nowrap;
			width: 100%;
			margin-bottom:${(props) => props.theme.Rem(45)};
			.text-box{
				display:flex;
				flex-direction: column;
				flex-wrap:nowrap;
				width: 100%;
				.title{
					br{
						display:none;
					}
					font-size:${(props) => props.theme.Rem(16)};
					font-weight:900;
					margin-bottom:${(props) => props.theme.Rem(12)};
					text-align:left;
					span{
						font-weight:400;
						font-size:${(props) => props.theme.Rem(14)};
						margin-left:${(props) => props.theme.Rem(5)};
					}
				}
				.contents{
					font-weight:400;
					word-break:keep-all;
					font-size:${(props) => props.theme.Rem(14)};
					line-height:${(props) => props.theme.Rem(20)};
				}
			}
			img{
				width:${(props) => props.theme.Rem(40)};
				margin-right:${(props) => props.theme.Rem(24)};
			}
			b{

			}
		}
	}
}
`;

const CustomWidth60 = styled(Width60)`
	flex-direction: column;
	justify-content: center;
	.text-box {
		box-sizing: border-box;
		padding-left: ${(props) => props.theme.Rem(24)};
		padding-right: 20%;
		.title {
			font-size: ${(props) => props.theme.Rem(32)};
			margin-bottom: ${(props) => props.theme.Rem(48)};
			font-weight: 900;
		}
		.contents {
			font-size: ${(props) => props.theme.Rem(16)};
			line-height: ${(props) => props.theme.Rem(30)};
			margin-bottom: ${(props) => props.theme.Rem(40)};
			word-break: keep-all;
		}
	}
`;

const CustomHeight100 = styled(Height100)`
	width: 100%;
`;

const CustomTextBox = styled.div`
	box-sizing: border-box;
	padding-left: ${(props) => props.theme.Rem(24)};
	padding-right: 20%;
`;

const CustomTitle = styled.h3`
	font-size: ${(props) => props.theme.Rem(32)};
	margin-bottom: ${(props) => props.theme.Rem(48)};
`;

const CustomContents = styled.p`
	font-size: ${(props) => props.theme.Rem(16)};
	line-height: ${(props) => props.theme.Rem(30)};
	margin-bottom: ${(props) => props.theme.Rem(40)};
	word-break: keep-all;
`;
const infoTextObj = {
	title:`Non-Financial<br />Alternative Data`,
	content:`방대한 비금융 대안 데이터를 통해<br />
			기업을 보다 다채롭고 창의적인 형태로<br />    
			진단할 수 있는 분석의 토대`
}
const SecondSection = () => {
	return (
		<>
			<CustomSectionWrapper>
				<PageWrapper>
					<Wrapper>
						<CustomWidth100>
							<PageInfoWidth40>
								<Info texts = {infoTextObj}/>
								<PageLogo
									src="/img/hubble_h_b.png"
									alt="Hubble Database"
								/>
							</PageInfoWidth40>
							<Width60>
								<Width100>
									<CustomWidth40>
										<CustomHeight100>
											<SliderContents />
										</CustomHeight100>
									</CustomWidth40>

									<CustomWidth60>
										<CustomTextBox>
											<CustomTitle>
												대안 데이터 라이브러리
											</CustomTitle>
											<CustomContents>
												대안 데이터란 전통적인 금융
												지표와 기업 공시에서 탈피하여
												기업과 사업을 보다 정확하고
												적시적으로 분석할 수 있게 해주는
												비 재무적 데이터 일체를
												지칭합니다.
											</CustomContents>
											<CustomContents>
												조직, 인증, 홈페이지,
												지적재산권, 언론보도 등 다양한
												대안 정보 기반 개별 기업에 대한
												입체적인 파악이 가능하고 기업
												수행 사업의 성과를 면밀하게
												관찰할 수 있습니다.
											</CustomContents>
											<CustomContents>
												대안 데이터는 보다 창의적인
												방식으로 기업과 금융 분석을
												지원하며 이를 통해 보다 효과적인
												투자 기회 발굴은 물론 선제적인
												리스크 관리를 수행할 수
												있습니다.
											</CustomContents>
										</CustomTextBox>
									</CustomWidth60>
								</Width100>
							</Width60>
						</CustomWidth100>
					</Wrapper>
				</PageWrapper>
			</CustomSectionWrapper>
		</>
	);
};

export default SecondSection;
