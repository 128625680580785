import React, { useState, useRef, useEffect } from "react";
import { animated, useSpring } from "react-spring";


const FirstLine = ({ data, init }) => {

    const lineRef = useRef(null);
    const [length, setLength] = useState(null);

    useEffect(() => {
        setLength(lineRef.current.getTotalLength());
    }, [data]);

    const animatedStyle = useSpring({
        to: { length: 0 },
        from: { length },
        delay: 1000,
        config: { duration: 1000 },
        reset: init,
    });

    


    return (
        <>
            {/* <path d="M27.564C27.5 64 44.5 92.4991 83.5 92.4994C122.5 92.4997 211.5 92.4993 246.5 92.4994C281.5 92.4995 294 121 294 121" stroke="#B8B8C6" stroke-width="0.5" /> */}

            {/* <path d="M27.5 120.5  C27.5 120.5 41.9475 92.1144 81 92.1045C120.052 92.0946 211.759 92.1132 246.806 92.1045C281.853 92.0958 294.5 63.5002 294.5 63.5002" stroke="#B8B8C6" stroke-width="0.5" /> */}
            {/* <line x1="27.25" y1="64" x2="27.25" y2="121" stroke="#B8B8C6" stroke-width="0.5" /> */}
            {/* <path d="M27.5 64C27.5 64 46.5 92.5 78 93.0001C109.5 93.5001 116.643 120.295 116.643 120.295" stroke="#B8B8C6" stroke-width="0.5" /> */}
            {/* <path d="M27.4998 119.499C27.4998 119.499 46.8567 92.2947 78.3567 91.7946C109.857 91.2945 117 64.5 117 64.5" stroke="#B8B8C6" stroke-width="0.5" /> */}


            {/* <path d="M117 65   C117 65 120.209 89.7154 147 92C173.791 94.2846 242.155 90.9866 261.374 94.6044C280.594 98.2222 293.5 120 293.5 120" stroke="#B8B8C6" stroke-width="0.5" /> */}
            {/* <path d="M205 64  C205 64 201.319 89.7154 174.528 92C147.738 94.2846 79.3736 90.9866 60.1541 94.6044C40.9346 98.2222 28.0285 120 28.0285 120" stroke="#B8B8C6" stroke-width="0.5" /> */}
            {/* <path d="M294.5 63.4998  C294.5 63.4998 280.5 90.0767 252 92.5001C223.5 94.9235 172.373 88.853 153 92.5001C133.627 96.1472 116.802 120.5 116.802 120.5" stroke="#B8B8C6" stroke-width="0.5" /> */}

            {/* <line x1="294.25" y1="64" x2="294.25" y2="121" stroke="#B8B8C6" stroke-width="0.5" /> */}
            {/* <line x1="205.25" y1="64" x2="205.25" y2="121" stroke="#B8B8C6" stroke-width="0.5" /> */}

            {/* <path d="M117 66.0002C117 66.0002 122.5 92.3082 166.093 93.0004C209.686 93.6926 204.737 120.295 204.737 120.295" stroke="#B8B8C6" stroke-width="0.5" /> */}
            {/* <path d="M294 64C294 64 287.549 92.3079 243.956 93.0001C200.362 93.6924 205.312 120.295 205.312 120.295" stroke="#B8B8C6" stroke-width="0.5" /> */}
            {/* <path d="M117 120.5C117 120.5 135.361 92.2947 166.861 91.7947C198.361 91.2946 205.504 64.5001 205.504 64.5001" stroke="#B8B8C6" stroke-width="0.5" /> */}
            {/* <path d="M294.089 120.5C294.089 120.5 291.228 92.5002 249.5 92.5C207.772 92.4998 205 63.9995 205 63.9995" stroke="#B8B8C6" stroke-width="0.5" /> */}

            {/* <line x1="117" y1="64" x2="117" y2="121" stroke="#B8B8C6" stroke-width="0.5" /> */}

            {/* <path d="M116 189.988C116 189.988 116.709 215.703 143.5 217.988C170.291 220.272 242.155 214.371 261.374 217.989C280.594 221.607 295.5 247.002 295.5 247.002" stroke="#B8B8C6" stroke-width="0.5" /> */}


            {/* <path d="M27.5 120.5  C27.5 120.5 41.9475 92.1144 81 92.1045C120.052 92.0946 211.759 92.1132 246.806 92.1045C281.853 92.0958 294.5 63.5002 294.5 63.5002" 
            
            stroke="#B8B8C6" 
            stroke-width="0.5" /> */}

            <animated.path
                ref={lineRef}
                d={data}
                stroke-width="0.5"
                fill="transparent"
                stroke="#1210c7"
                strokeDashoffset={animatedStyle.length}
				strokeDasharray={length}
            />


        </>
    );
};

export default React.memo(FirstLine);