import React from "react";
import styled from "styled-components";
import {
	PageWrapper,
	Wrapper,
	Width100,
	Width50,
	PageInfoTitle,
	PageInfoExplain,
	Height50
} from "style/layout";

import { useSelector } from "react-redux";


const CustomSectionWrapper = styled.div`
    background-image: url("/img/ai_bg_1.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
`;

const CustomWrapper = styled(Wrapper)`
	border: 0.5px solid ${(props) => props.theme.colors.borderColor};
	border-width: 0 0.5px;
`;

const CustomWidth100 = styled(Width100)`
	height: 100%;
	h2{
		font-weight:700;
		font-size:${props => props.theme.Rem(48)};
	}
	span{
		font-weight:200;
	}
	.page-info{
		display:flex;
		flex-direction:column;
		height:100%;
		justify-content:center;
		align-items:flex-start;
		font-size:${props => props.theme.Rem(16)};
	}
`;

const AiLinkWrapper = styled.div`
	width:100%;
	height:100%;
	display:flex;
	flex-direction:column;
	.link-btn-wrapper{
		width:100%;
		height:100%;
		display:flex;
		flex-direction:column;
		align-items:center;
		justify-content:center;
		color:#fff;
		cursor:pointer;
		backdrop-filter: blur(0.25rem);
		a{
			display:flex;
			flex-direction:column;
			align-items:center;
			justify-content:center;
			width:100%;
			height:100%;
			strong{
				font-size:${props => props.theme.Rem(32)};
			}
			span{
				font-size:${props => props.theme.Rem(20)};
				margin-top:0.5rem;
			}
		}
	}
`;

const AiLinkBtn = styled(Height50)`
    img{
		width:${props => props.theme.Rem(45)};
		margin-bottom:${props => props.theme.Rem(20)};
	}
    &:hover {
        ${props => {
            if(props.page === props.name){
                if(props.name === "dvdata"){
                    return `background:linear-gradient(360deg,rgba(32,14,208,0.6),rgba(137,0,255,0.6), rgba(255, 255, 255, 0.15)); `;
                } else {
                    return `background:linear-gradient(180deg,rgba(32,14,208,0.6),rgba(137,0,255,0.6), rgba(255, 255, 255, 0.15)); `;
                }
            }else{
                return `background: rgba(255, 255, 255, 0.07); `;
            }
        }}
    }
	${props => {
		if(props.page === props.name){
			return `background:linear-gradient(90deg,rgba(32,14,208,0.6),rgba(137,0,255,0.6))`;
		}else{
			return `background:rgba(0,0,0,0.4)`;
		}
	} }
`;

const AiMainSection = (props) => {
	const { currentPage, device } = useSelector((state) => ({
		currentPage: state.page.currentPage,
		device: state.style.device,
	}));

    const handleLinkClick = (e, name) => {
        props.onHandleLinkClick(name);
    };

	return (
		<>
			<CustomSectionWrapper>
				<PageWrapper>
					<CustomWrapper>
						<CustomWidth100>
							<Width50 className="page-info">
								<div>
									<PageInfoTitle primary={true}>
										데이터바우처 <span>지원사업</span>
									</PageInfoTitle>
									<PageInfoExplain primary={true}>
										㈜앤톡은 빅데이터 및 AI 기술력을 기반으로<br />
										국내 100만 개 기업의 기업 정보 제공, 기업 분석,<br />
										심화 통계, AI 모델링 등 다각적 분석 인사이트를 제공합니다.
									</PageInfoExplain>
								</div>
							</Width50>

							<Width50 className="border-left">
								<AiLinkWrapper>
									<AiLinkBtn className="link-btn-wrapper" page={currentPage} name={"dvai"} onClick={(e) => handleLinkClick(e, "dvai")}>
                                        <a>
											<img src="/img/aiprocess.png" alt="데이터 가공"/>
											<strong>데이터 가공</strong>
											<span>일반 / AI</span>
										</a>
									</AiLinkBtn>
									<AiLinkBtn className="link-btn-wrapper" page={currentPage} name={"dvdata"} onClick={(e) => handleLinkClick(e, "dvdata")}>
                                        <a>
											<img src="/img/aisale.png" alt="데이터 가공"/>
											<strong>데이터 판매</strong>
										</a>
									</AiLinkBtn>
								</AiLinkWrapper>
							</Width50>
						</CustomWidth100>
					</CustomWrapper>
				</PageWrapper>
			</CustomSectionWrapper>
		</>
	);
};

export default AiMainSection;
