import React from "react";
import styled from "styled-components";
import Info from "components/Info";
import ServiceLink from "./first/ServiceLink";

import {
	PageWrapper,
	Wrapper,
	Width100,
	Width45,
	Width50,
	Width55,
} from "style/layout";

import "../../layout.scss";
import LeftTextBox from "./first/LeftTextBox";
import RightTextBox from "./first/RightTextBox";

const CustomSectionWrapper = styled.div`
	position: relative;
	&::after {
		content: "";
		width: calc(45.5% + 2px);
		height: 100%;
		display: block;
		background-image: url("/img/landing01_bg.png");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		${props => props.theme.device.tablet}{
        	display:none;
		}
	}
	${props => props.theme.device.tablet}{
        height:100%;
        .page-info{
			background-image: url("/img/landing01_bg.png");
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			position:relative;
			border: 1px solid rgba(90,90,119,0.08);
			border-width:0 1px;
			&::after,&::before{
				content:'';
				display:block;
				position:absolute;
				top:0;
				left:-8%;
				width:8%;
				height:100%;
				background-image: url("/img/landing01_bg.png");
				background-size: cover;
				background-repeat: no-repeat;
				background-position: left center;
				z-index:-1;
			}
			&::before{
				background-position:right center;
				left:auto;
				right:-8%;
			}
        }
	}
`;

const CustomWidth45 = styled(Width45)`
	height: 100%;
	box-sizing: border-box;
	flex-wrap: nowrap;
	// align-items: flex-start;
	justify-content: center;
	margin-top: -#{${(props) => props.theme.sizes.pageMarginTop}};
	// flex-direction: column;
	border-right: 1px solid ${(props) => props.theme.colors.lbgBorderColor};
	position: relative;
`;

const CustomWrapper = styled(Wrapper)`
	border-left-color: ${(props) => props.theme.colors.borderColor};
`;

const CustomWidth100 = styled(Width100)`
	height: 100%;
	// ${props => props.theme.device.tablet}{
    //     flex-direction:column;
	// }
`;

const CustomWidth50 = styled(Width50)`
	align-items: center;
	${props => props.theme.device.tablet}{
        width:100%;
        border:1px solid rgba(90,90,119,0.08);
	}
`;
const infoTextObj = {
	title:`Integrated AI<br />Financial Analytics`,
	content:`금융 인공지능 분석을 위한<br />
			기업 데이터 수집–가공-진단 기술이<br />    
			통합적으로 구현되었습니다.`
}
const FirstSection = () => {
	return (
		<CustomSectionWrapper>
			<PageWrapper>
				<CustomWrapper>
					<CustomWidth100>
						<CustomWidth45 className="page-info">
							<Info primary={true} texts = {infoTextObj}/>
							<ServiceLink />
						</CustomWidth45>
						<Width55 className="contents-wrapper border-left">
							<Width100>
								<Width50>
									<LeftTextBox />
								</Width50>
								<CustomWidth50 className="border-left">
									<RightTextBox />
								</CustomWidth50>
							</Width100>
						</Width55>

					</CustomWidth100>
				</CustomWrapper>
			</PageWrapper>
		</CustomSectionWrapper>
	);
};

export default FirstSection;
