import React, {useEffect} from "react";
import {
	ContentWrapper,
	InfoGraphicImg,
	PageInfoTitle,
	PageWrapper, SectionInfoWrapper,
	Width100, Width33,
	Width50,
	Wrapper
} from "../../style/layout";
import styled from "styled-components";
import Title from "../../components/landing/main/Title";
import LadderBox from "components/landing/second/LadderBox";
import MobileLadderBox from "mobile/components/MobileLadderBox";
import { useInView } from 'react-intersection-observer';
import {useDispatch, useSelector} from "react-redux";
import {setObserver} from "../../modules/style";
import FifthSection from "../../components/mri/FifthSection";
import MobileMri, {CaseSlideStyle, mriSlideObj} from "./MobileMri";
import {EffectCreative, Navigation} from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

const CustomWrapper = styled.div`
	background-image: url("/img/landing-main.png");
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center center;
	display:flex;
	width:100%;
	min-height:100vh;
	height:100%;
	.border-light{
		align-items:center;
		justify-content:center;
		display: flex;
		h2{
			width:auto;
			height:100%;
			align-items:flex-start;
			justify-content:center;
			position:relative;
			div{
				width:100%;
				position:absolute;
				bottom:20vh;
			}
			img{
				height:6vw;
			}
			p{
				width:100%;
				text-align:center;
				&:first-of-type{
					margin-top:0;
				}
				${props => props.theme.device.tabletL}{
					font-size:2rem;
				}
				${props => props.theme.device.tablet}{
					font-size:1.8rem;
				}
				${props => props.theme.device.mobile}{
					font-size:1.1rem;
				}
			}
		}
	}
`;

const BgInfoWrapper = styled.div`
	background-image: url(/img/landing01_bg.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	h2{
		color:#fff;
		margin-bottom:${props => props.theme.sizes.mHeaderHeight};
	}
	.border-light{
		padding-bottom:0;
	}
`

const ServiceLinkWrapper = styled(Width100)`
	border-top:1px solid ${props=>props.theme.colors.borderColor};
`;

const ServiceLink50 = styled(Width50)`
	cursor:pointer;
	padding:22px 17px;
	align-items:center;
	
	a{
		display:flex;
		width:100%;
		height:100%;
	}
	img{
		max-width: 45%;
	}
	&:last-of-type{
		border-left:1px solid ${props=>props.theme.colors.borderColor};
	}
`;

const ContentsListWrapper = styled(Width100)`
	flex-direction:column;
	&:nth-of-type(n+2){
		margin-top:${props => props.theme.sizes.mHeaderHeight};
	}
	h3{
		color:${props => props.theme.colors.pointColor};
		font-size:${props => props.theme.Rem(23)};
		margin-bottom:calc( ${props => props.theme.sizes.mHeaderHeight} / 2);
		font-weight:800;
	}
	
	ul{
		display:flex;
		flex-direction:column;
		width:100%;
		height:100%;
		box-sizing:border-box;
		padding-right:0.7rem;
		li{
			word-break:keep-all;
			display:flex;
			flex-direction:row;
			align-items:flex-start;
			justify-content:flex-start;
			font-size:${props => props.theme.Rem(19)};
			margin-bottom:calc( ${props => props.theme.sizes.mHeaderHeight} / 2);
			&:last-of-type{
				margin-bottom:0;
			}
			&::before{
				color:transparent;
				content:"ㆍ";
				width:0.6rem;
				height:0.6rem;
				display:block;
				border-radius:0.6rem;
				margin-right:1rem;
				margin-top:0.3rem;
				background-color:${props => props.theme.colors.fontColor};	
			}
		}
	}
	@media (min-width: ${props => props.theme.deviceSizes.tablet}){
		width:50%;
		flex-direction: row;
		flex-wrap: wrap;
		display: flex;
		&:nth-of-type(n+2){
			margin-top:0;
		}
	}
`;

const StepListWrapper = styled.ol`
	display:flex;
	flex-direction:column;
	justify-content:space-between;
	box-sizing:border-box;
	padding-bottom:${props => props.theme.sizes.mHeaderHeight};
	li{
		display:flex;
		flex-direction:column;
		text-align:left;
		&:nth-of-type(odd){
			text-align:right;
			    align-items: flex-end;
		}
		& > span{
			display:flex;
			flex-direction:column;
			font-size:0.9rem;
			font-weight:600;
			word-break:keep-all;
			color:${props => props.theme.colors.pointColor};
			margin-bottom:calc( ${props => props.theme.sizes.mHeaderHeight} / 2);
			b{
				font-size:2rem;
				font-weight:900;
			}
		}
		& > h4{
			font-size:1.7rem;
			font-weight:900;
			& + p{
				font-size:1.3rem;
				margin-bottom:calc( ${props => props.theme.sizes.mHeaderHeight} / 2);
				margin-top:0.3rem;
			}
		}
		.txt{
			font-size:1.15rem;
			line-height:2rem;
			width: 85%;
		}
	}
`;

const ThirdSectionWrapper = styled.ul`
	display:flex;
	flex-direction:column;
	justify-content:space-between;
	box-sizing:border-box;
	padding-bottom:${props => props.theme.sizes.mHeaderHeight};
	li{
		display:flex;
		flex-direction:column;
		text-align:left;
		padding-bottom:${props => props.theme.sizes.mHeaderHeight};
		&:last-of-type{
			padding:0;
		}
		& > span{
			font-size:1.4rem;
			font-weight:600;
			word-break:keep-all;
			color:${props => props.theme.colors.pointColor};
			margin:calc( ${props => props.theme.sizes.mHeaderHeight} / 2) 0;
			display:flex;
			flex-direction:row;
			text-align:right;
			align-items:center;
			justify-content:flex-end;
			&::after{
				content:"";
				width:25vw;
				height:1px;
				border-top:2px dotted ${props => props.theme.colors.fontColor};
				margin-left:1rem;
				display:block;
			}
			b{
				font-size:1.4rem
				font-weight:900;
			}
		}
		& > h4{
			font-size:1.7rem;
			font-weight:900;
			& + p{
				font-size:1.3rem;
				margin-bottom:calc( ${props => props.theme.sizes.mHeaderHeight} / 2);
				margin-top:0.3rem;
			}
		}
		.txt{
			font-size:1.15rem;
			line-height:2rem;
			width: 80%;
			word-break:keep-all;
		}
	}
`;
const CustomTitle = styled.li`
	//display: flex;
	width: ${(props) => props.theme.Rem(160)};
	//flex-direction:column;
	//justify-content: flex-start;
	//align-items: flex-start;
	b {
		font-size: 1rem;
		margin-bottom: 1rem;
		font-weight: 700;
	}
	p {
		font-size: rem(18px);
		font-weight: 300;
	}
`;

const CustomWidth33 = styled(Width33)`
	flex-direction:column;
	justify-content:flex-start;
	padding-bottom:calc( ${props => props.theme.sizes.mHeaderHeight} / 2);
	&:nth-of-type(2) {
		padding-top:10%;
	}
	&:nth-of-type(3) {
		padding-top:20%;
	}
`;

const CustomCustomerList = styled.ul`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	//width:75%;
	
	li {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: ${(props) => props.theme.Rem(20)};
		
		&:first-child {
			margin-bottom: ${(props) => props.theme.Rem(30)};
		}
		img {
			transform-origin: left;
			// transform:scale(0.40);
			width:auto;
			max-width:70%;
			margin-bottom:2rem;
			&.fixed-height{
				max-width:100%;
				height:${(props) => props.theme.Rem(28)};
			}
			
			@media (max-width: ${props => props.theme.deviceSizes.mobile}){
				width:auto;
				
				&.fixed-height{
					max-width:100%;
					height:${(props) => props.theme.Rem(18)};
				}
			}
		}
	}
`;

const ContentWrapper100 = styled(ContentWrapper)`
	flex-direction:row;
	@media (min-width: ${props => props.theme.deviceSizes.tablet}){
		flex-direction: row;
		flex-wrap: wrap;
		display: flex;
	}
`;

const MobileLanding = () => {
	const dispatch = useDispatch();
	const { device,observer } = useSelector((state) => ({
		device : state.style.device,
		observer: state.style.observer,

	}));
	const { ref, inView } = useInView({});

	useEffect(()=>{
		dispatch(setObserver(inView));
	},[inView]);

	return (
		<>
			{/*Main*/}
			<CustomWrapper ref={ref}>
				<PageWrapper>
					<Wrapper className="border-light">
						<Title mobile={true}/>
					</Wrapper>
				</PageWrapper>
			</CustomWrapper>
			{/*Main End*/}

			{/* First */}
			<PageWrapper>
				<BgInfoWrapper>
					<Wrapper className="border-light info-wrapper">
						<PageInfoTitle>
							Integrated AI <br /> Financial Analytics
						</PageInfoTitle>
						<ServiceLinkWrapper>
							<ServiceLink50>
								<a href="https://hubble.co.kr/" target="_blank">
									<img src="/img/hubble_w.png" alt="hubble database" />
								</a>
							</ServiceLink50>
							<ServiceLink50>
								<img src="/img/antock_mri_w.png" alt="antock mri" />
							</ServiceLink50>
						</ServiceLinkWrapper>
					</Wrapper>
				</BgInfoWrapper>

				<ContentWrapper100 className="border-dark">
					<ContentsListWrapper>
						<h3>Big Data</h3>
						<ul>
							<li>국내 모든 법인 기업 (100만 개 이상) 자동으로 식별하고 표본 저장</li>
							<li>기업 별 최대 300가지 이상의 비 금융 대안 데이터 실시간 수집</li>
							<li>AI(인공지능) 분석을 위한 데이터 자동 전처리, 가공, 통합 실시</li>
						</ul>
					</ContentsListWrapper>
					<ContentsListWrapper>
						<h3>Artificial Intelligence</h3>
						<ul>
							<li>AI 기술력 접목된 약 100개 이상의 기업 분석 모형 라이브러리 구축</li>
							<li>기업경쟁력, 성장잠재성, 기술혁신성, 경영위험도, 사회적가치 등 예측</li>
							<li>투자 매력도, 리스크 수준 등에 따른 AI 큐레이션 / 포트폴리오 제안</li>
						</ul>
					</ContentsListWrapper>
				</ContentWrapper100>
			</PageWrapper>

			<PageWrapper>
				<ContentWrapper>
					<StepListWrapper>
						<li>
							<span>STEP<b>01</b></span>
							<h4>대안 데이터 수집</h4>
							<p>Alternative Data</p>
							<p className="txt">모든 기업에 대한 사업, 성과, 조직, 인증, 기술 등 기업 별 비 재무 정보 자동 유입</p>
						</li>
						<li>
							<span>STEP<b>02</b></span>
							<h4>빅 데이터 전처리 통합</h4>
							<p>AI Processing</p>
							<p className="txt">AI 진단 및 통계분석을 위한 정형 / 비정형 데이터 자동 전처리 및 표준화 수행</p>
						</li>
						<li>
							<span>STEP<b>03</b></span>
							<h4>인공지능 분석 평가</h4>
							<p>AI Robo-Advisor</p>
							<p className="txt">고객 활용 목적에 따른 기업 별 AI 분석 모형 맞춤화 및 자동화 진단 수행</p>
						</li>
					</StepListWrapper>
				</ContentWrapper>
			</PageWrapper>
			{/* First End*/}

			{/* Second Start */}
			<PageWrapper>
				<ContentWrapper className="info-wrapper">
					<PageInfoTitle>
						Customized<br />AI Application
					</PageInfoTitle>
				</ContentWrapper>
				<ContentWrapper>
					{
						device === "tablet" ?
							// <InfoGraphicImg src="/img/landing02_01.png" alt="도표" />
							<LadderBox />
							:
							// <InfoGraphicImg src="/img/landing_m_02.png" alt="Customized AI Application 인포그래픽"/>
                            <Width100>
                                <MobileLadderBox />
                            </Width100>

					}
				</ContentWrapper>
				<ContentWrapper>
					<SectionInfoWrapper>
						인공지능을 통해 분석 목적과 평가
						기업 유형에 따른 맞춤화 된 데이터 공급과
						분석 방법론을 적용합니다.
					</SectionInfoWrapper>
				</ContentWrapper>
			</PageWrapper>
			{/* Second End */}
			{/*Third Start*/}
			<PageWrapper>
				<ContentWrapper className="info-wrapper">
					<PageInfoTitle>
						Powerful<br />Analytics Service
					</PageInfoTitle>
				</ContentWrapper>
				<ContentWrapper>
					<ThirdSectionWrapper>
						<li>
							<h4>Integrated</h4>
							<p>통합적 분석</p>
							<p className="txt">비 금융 대안 데이터를 AI 분석에 집중 활용함으로써, 기존 재무 정보로는 가능하지 않았던 기업 속성, 상품, 기술, 가치, 조직 등의 영역에서 창의적 분석을 지원합니다.</p>
							<span><b>차별성</b></span>
						</li>
						<li>
							<h4>Targeted</h4>
							<p>맞춤화 진단</p>
							<p className="txt">고객의 기업 분석 목표와 속성을 체계적으로 파악하여, AI 기반으로 최적의 분석 모형을 제안하고 필요 데이터를 공급함으로써,정교한 결과 산출을 유도합니다.</p>
							<span><b>정교성</b></span>
						</li>
						<li>
							<h4>Automated</h4>
							<p>자동화 연산</p>
							<p className="txt">데이터 수집, AI 학습용 데이터 가공, AI 분석과 관련된 모든 일련의 프로세스가 시스템 기반의 자동화로 진행되어 인적 개입으로 인한 오염을 최소화하고 작업 시간을 혁신적으로 단축합니다.</p>
							<span><b>효율성</b></span>
						</li>
					</ThirdSectionWrapper>
				</ContentWrapper>
			</PageWrapper>
			{/*Third End*/}

			<PageWrapper>
				<ContentWrapper className="info-wrapper border-t-dark" style={{borderWidth:"1px 1px 0 1px"}}>
					<PageInfoTitle>AI Application Real Case Studies</PageInfoTitle>
				</ContentWrapper>

				<ContentWrapper style={{padding:"0",paddingBottom:"50px"}} className="case-swiper">
					<Swiper
						modules={[Navigation,EffectCreative]}
						navigation={true}
						spaceBetween={0}
						slidesPerView={1}
						// onSlideChange={() => console.log('slide change')}
						// onSwiper={(swiper) => console.log(swiper)}
						effect={"creative"}
						creativeEffect={{
							prev: {
								translate: [0, 0, -400],
								opacity:0,
							},
							next: {
								translate: ["100%", 0, 0],
								opacity:1,
							},
						}}
					>
						{mriSlideObj ? Object.keys(mriSlideObj).map((caseNum,idx)=>{
								return <SwiperSlide>
									<CaseSlideStyle>
										<div className="case-style">
											<span>case</span>
											<b>{caseNum}</b>
										</div>
										<div className="text-wrapper">
											<img src={mriSlideObj[caseNum]["logoURL"]} alt="logo" className="logo"/>
											<h4>{mriSlideObj[caseNum]["title"]}</h4>
											<p>{mriSlideObj[caseNum]["subTitle"]}</p>
											<div>
												<ol>
													{
														mriSlideObj[caseNum]["list"].map((listItem)=>{
															return <li>{listItem}</li>
														})
													}
												</ol>
											</div>
											<div className="sub-list">
												<ul>
													<li>
														<b>가공 표본</b>
														<p><b>{mriSlideObj[caseNum]["subList"][0]}</b> {idx === 0 ? " 차주" : "기업"}</p>
													</li>
													<li>
														<b>가공 객체</b>
														<p><b>{mriSlideObj[caseNum]["subList"][1]}</b> 항목</p>
													</li>
													<li>
														<b>개발실적</b>
														<p><b>{mriSlideObj[caseNum]["subList"][2]}</b> {idx === 3 || idx === 1 ? " / 시험인증 통과" : null}</p>
													</li>
												</ul>
											</div>
											<img src={mriSlideObj[caseNum]["thumbnail"]} alt="thumbnail"/>
										</div>
									</CaseSlideStyle>
								</SwiperSlide>
							})
							:null}
					</Swiper>
				</ContentWrapper>
			</PageWrapper>
			{/*Fourth Start*/}
			<PageWrapper>
				<ContentWrapper className="info-wrapper border-tb-dark" style={{borderWidth:"1px"}}>
					<PageInfoTitle>
						Fintech<br />Reference
					</PageInfoTitle>
				</ContentWrapper>
				<ContentWrapper style={{padding:"0"}}>
					<Width100>
						<CustomWidth33 className="border-left">
							<CustomCustomerList>
								<CustomTitle>
									<b>핵심 고객</b>
								</CustomTitle>
								<li>
									<img src="/img/customer/kbfg.png" alt="kb금융그룹" />
								</li>
								<li>
									<img src="/img/customer/woorigroup.png" alt="우리금융그룹"/>
								</li>
								<li>
									<img src="/img/customer/ibk_bank2.jpeg" alt="기업은행" />
								</li>
								<li>
									<img src="/img/customer/kised.png" alt="창업진흥원"/>
								</li>
								<li>
									<img src="/img/customer/moneytoday.png" alt="머니투데이" />
								</li>
								<li>
									<img src="/img/customer/cses.png" alt="사회적가치연구원"/>
								</li>
							</CustomCustomerList>
						</CustomWidth33>
						<CustomWidth33 className="border-left">
							<CustomCustomerList>
								<CustomTitle>
									<b>후원 기관</b>
								</CustomTitle>
								<li>
									<img src="/img/customer/dinnolab2.jpeg" alt="dinnolab" />
								</li>
								<li>
									<img src="/img/customer/kb_innovation_hub.png" alt="kb innovation hub" />
								</li>
								<li>
									<img src="/img/customer/ibank_lab.jpeg" alt="ibk 퍼스트 랩"/>
								</li>
								<li>
									<img src="/img/customer/kosme.png" alt="중소벤처기업진흥공단" />
								</li>
								<li>
									<img src="/img/customer/koreafintech.png" alt="한국핀테크지원센터"/>
								</li>
								<li>
									<img src="/img/customer/nia.png" alt="한국지능정보사회진흥원 nia"/>
								</li>
							</CustomCustomerList>
						</CustomWidth33>
						<CustomWidth33 className="border-left">
							<CustomCustomerList>
								<CustomTitle>
									<b>수상 기관</b>
								</CustomTitle>
								<li>
									<img src="/img/customer/finece.png" className={"fixed-height"} alt="금융위원회"/>
								</li>
								<li>
									<img src="/img/customer/tech.png" className={"fixed-height"} alt="과학기술정보통신부" />
								</li>
								<li>
									<img src="/img/customer/mois.png" className={"fixed-height"} alt="행정안전부"/>
								</li>
								<li>
									<img src="/img/customer/kipris.png" className={"fixed-height"} alt="특허청"/>
								</li>
								<li>
									<img src="/img/customer/koscom2.jpeg" className={"h-logo"} alt="koscom" />
								</li>
								<li>
									<img src="/img/customer/nia2.png" className={"h-logo"} alt="nia"
									/>
								</li>
							</CustomCustomerList>
						</CustomWidth33>
					</Width100>
				</ContentWrapper>
			</PageWrapper>
			{/*Fourth End*/}
		</>
	);
};

export default MobileLanding;
