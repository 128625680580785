import React from 'react';
import { animated, useSpring } from "react-spring";

const transform = ["translate(30 174)", "translate(117 174)", "translate(207 174)", "translate(297 174)"];

const SecondText = ({ index, textData, init }) => {

	const animationFillStyle = useSpring({
		from: { fillColor: "#b8b8c6" },
		to: { fillColor: "#1816c9" },
		config: { duration: 1000 },
		delay: 2000,
		reset: init,
	});


    return (
        <>
        
			<animated.text
				id="가치_적정성"
				data-name="가치적정성"
				transform={transform[index]}
				font-size="14"
				font-family="MalgunGothic, Malgun Gothic"
                fill={animationFillStyle.fillColor}
			>
				<tspan x="-14" y="0">
					{textData.substring(0, 2)}
				</tspan>
				<tspan y="0" font-family="SegoeUI, Segoe UI"></tspan>
				<tspan x="-21" y="16">
				{textData.substring(2, 5)}
				</tspan>
			</animated.text>

        </>
    );
};

export default SecondText;