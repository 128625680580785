import React, { useEffect, useState } from "react";
import { useInView } from 'react-intersection-observer';

import Category from "./shapes/Category";

import ObjectTextBackground from "./shapes/ObjectText/ObjectTextBackground";
import ObjectText from "./shapes/ObjectText/ObjectText";

import HoneyCombBackground from "./shapes/HoneyComb/HoneyCombBackground";
import HoneyComb from "./shapes/HoneyComb/HoneyComb";

import FirstLine from "./shapes/FirstLines/FirstLine";
import FirstLineArrow from "./shapes/FirstLines/FirstLineArrow";

import MethodText from "./shapes/MethodText/MethodText";

import FirstGroup from "./shapes/SecondLines/FirstGroup/FirstGroup";
import SecondGroup from "./shapes/SecondLines/SecondGroup/SecondGroup";
import ThirdGroup from "./shapes/SecondLines/ThirdGroup/ThirdGroup";

import FirstLibrary from "./shapes/LibraryText/FirstGroup/FirstLibrary";
import SecondLibrary from "./shapes/LibraryText/SecondGroup/SecondLibrary";
import ThirdLibrary from "./shapes/LibraryText/ThirdGroup/ThirdLibrary";

import ProgressBarBackground from "./shapes/Explanatory/ProgressBarBackground";

import ChampionText from "./shapes/Explanatory/ChampionText";
import Badge from "./shapes/Explanatory/Badge";

const AnimationBox = () => {

	const [honeyCombInfo, setHoneyCombInfo] = useState();
	const [rankInfo, setRankInfo] = useState();

	const [init, setInit] = useState(false);
	const [rest, setRest] = useState(false);
	const [visable, setVisable] = useState(true);

	const { ref, inView } = useInView({});

	useEffect(() => {
		inView ? setVisable(true) : setVisable(false);
	}, [inView]);


	useEffect(() => {
		if (rest && !init) {
			setTimeout(animationStart, 1500);
		}
	}, [rest, init]);

	const handleRest = () => {
		setTimeout(animationStop, 1500);
	};

	const handleHoneyCombInfo = (honeyCombInfoData) => {
		setHoneyCombInfo(honeyCombInfoData);
	};

	const handleRankInfo = (rankInfoData) => {
		setRankInfo(rankInfoData);
	};

	const animationStart = () => {
		setVisable(true);
		setRest(false);
		setInit(true);
	};

	const animationStop = () => {
		setVisable(false);
		setRest(true);
		setInit(false);
	};


	return (
		<>
			<svg
				ref={ref}
				xmlns="http://www.w3.org/2000/svg"
				width="939.396"
				height="677.402"
				viewBox="0 0 939.396 677.402"
			>
				<g
					id="그룹_936"
					data-name="그룹 936"
					transform="translate(-828.5 -3375.5)"
				>
					{visable ? <>
						<Category />
						<HoneyCombBackground onHandleHoneyCombInfo={handleHoneyCombInfo} rest={rest} />
						<ProgressBarBackground onHandleRankInfo={handleRankInfo} rest={rest} />
					</>
						: null}

					{visable && !rest ? (
						<>
							{/* Objective .. */}
							<ObjectTextBackground />
							<ObjectText init={init} />

							{/* AI Data Set */}
							<HoneyComb honeyCombInfo={honeyCombInfo} init={init} />

							{/* First Lines */}
							<FirstLine init={init} />
							<FirstLineArrow init={init} />

							{/* AI Supervision Method */}
							<MethodText init={init} />

							{/* Second Lines */}
							<FirstGroup init={init} />
							<SecondGroup init={init} />
							<ThirdGroup init={init} />

							{/* AI Algorithm Libarary Group -> First, Second, Third */}
							<FirstLibrary rankInfo={rankInfo} init={init} />
							<SecondLibrary rankInfo={rankInfo} init={init} />
							<ThirdLibrary rankInfo={rankInfo} init={init} />

							{/* AI Explanatory Power */}
							<ChampionText rankInfo={rankInfo} init={init} />
							<Badge rankInfo={rankInfo} init={init} onHandleRest={handleRest} />
						</>
					) : null}
				</g>
			</svg>
		</>
	);
};

export default React.memo(AnimationBox);
