import React, { useState, useRef, useEffect } from "react";
import { animated, useSpring } from "react-spring";

const ThirdLines = ({ data, init }) => {

    const lineRef = useRef(null);
    const [length, setLength] = useState(null);

    useEffect(() => {
        setLength(lineRef.current.getTotalLength());
    }, [data]);

    const animatedStyle = useSpring({
        to: { length: 0 },
        from: { length },
        delay: 4000,
        config: { duration: 1000 },
        reset: init,
    });

    return (
        <>
            {/* <path d="M27.5 311.815   C27.5 311.815 44.5 340.122 83.5 340.123C122.5 340.123 211.5 340.122 246.5 340.123C281.5 340.123 294 368.432 294 368.432" stroke="#B8B8C6" stroke-width="0.5" /> */}
            {/* <path d="M117 312.808  C117 312.808 120.209 337.357 147 339.627C173.791 341.896 242.155 338.62 261.374 342.214C280.594 345.807 293.5 367.439 293.5 367.439" stroke="#B8B8C6" stroke-width="0.5" /> */}
            {/* <path d="M205 311.814  C205 311.814 201.319 337.357 174.528 339.627C147.738 341.896 79.3736 338.62 60.1541 342.213C40.9346 345.807 28.0285 367.439 28.0285 367.439" stroke="#B8B8C6" stroke-width="0.5" /> */}
            {/* <path d="M294.5 311.318  C294.5 311.318 280.5 337.716 252 340.123C223.5 342.53 172.373 336.501 153 340.123C133.627 343.746 116.802 367.935 116.802 367.935" stroke="#B8B8C6" stroke-width="0.5" /> */}
            {/* <path d="M27.5 367.935  C27.5 367.935 41.9475 339.74 81 339.73C120.052 339.721 211.759 339.739 246.806 339.73C281.853 339.722 294.5 311.318 294.5 311.318" stroke="#B8B8C6" stroke-width="0.5" /> */}

            {/* <line x1="27.25" y1="312" x2="27.25" y2="368" stroke="#B8B8C6" stroke-width="0.5" /> */}
            {/* <line x1="294.25" y1="312" x2="294.25" y2="368" stroke="#B8B8C6" stroke-width="0.5" /> */}
            {/* <line x1="205.25" y1="312" x2="205.25" y2="368" stroke="#B8B8C6" stroke-width="0.5" /> */}

            {/* <path d="M27.5 311.815C27.5 311.815 46.5 340.123 78 340.62C109.5 341.117 116.643 367.731 116.643 367.731" stroke="#B8B8C6" stroke-width="0.5" /> */}
            {/* <path d="M117 313.801C117 313.801 122.5 339.933 166.093 340.62C209.686 341.308 204.737 367.731 204.737 367.731" stroke="#B8B8C6" stroke-width="0.5" /> */}
            {/* <path d="M294 311.815C294 311.815 287.549 339.932 243.956 340.62C200.362 341.308 205.312 367.731 205.312 367.731" stroke="#B8B8C6" stroke-width="0.5" /> */}
            {/* <path d="M27.4998 366.941C27.4998 366.941 46.8567 339.919 78.3566 339.423C109.857 338.926 117 312.311 117 312.311" stroke="#B8B8C6" stroke-width="0.5" /> */}
            {/* <path d="M117 367.935C117 367.935 135.361 339.919 166.861 339.423C198.361 338.926 205.504 312.311 205.504 312.311" stroke="#B8B8C6" stroke-width="0.5" /> */}
            {/* <path d="M294.089 367.935C294.089 367.935 291.228 340.123 249.5 340.123C207.772 340.123 205 311.814 205 311.814" stroke="#B8B8C6" stroke-width="0.5" /> */}

            {/* <line x1="116.25" y1="312" x2="116.25" y2="368" stroke="#B8B8C6" stroke-width="0.5" /> */}


            {/* <path d="M116.5 312C116.5 312 116.709 337.052 143.5 339.487C170.291 341.921 242.155 335.632 261.374 339.488C280.594 343.344 295.5 370.41 295.5 370.41" stroke="#B8B8C6" stroke-width="0.5" /> */}

            <animated.path
                ref={lineRef}
                d={data}
                stroke-width="0.5"
                fill="transparent"
                stroke="#1210c7"
                strokeDashoffset={animatedStyle.length}
                strokeDasharray={length} />

        </>
    );
};

export default ThirdLines;