import React from "react";
import styled from "styled-components";
import Info from "../Info";
import { PageWrapper, Wrapper, Width100, Width60, Width40 } from "style/layout";

import { useQuery } from "react-query";
import { getHistory } from "api/api";

import "../../layout.scss";

import History from "./first/History";

const CustomSectionWrapper = styled.div`
	position: relative;
`;

const CustomWrapper = styled(Wrapper)`
	border-color: ${(props) => props.theme.colors.lbgBorderColor};
`;

const CustomWidth100 = styled(Width100)`
	height: 100%;
`;

const PageInfoWidth40 = styled(Width40)`
	margin-top: 0;
	height: 100%;
	box-sizing: border-box;
	flex-wrap: nowrap;
	align-items: flex-start;
	justify-content: center;
	margin-top: -#{${(props) => props.theme.sizes.pageMarginTop}};
	flex-direction: column;
	position: relative;
`;

const CustomWidth60 = styled(Width60)`
	box-sizing: border-box;
	flex-direction: column;
	position: relative;
	overflow: hidden;
	//padding-top: 5.7%;
	box-sizing: border-box;
	flex-direction: row;
	background-position: 0% bottom;
	background-size: cover;
	background-repeat: no-repeat;
	overflow: visible;
	position: relative;
	// &::after,&::before{
	//     content:"";
	//     position:absolute;
	//     z-index:1;
	//     width:100%;
	//     height:17%;
	//     display:block;
	//     top:0;
	//     left:0;
	//     background:linear-gradient(#ffffff,transparent);
	// }
	// &::after{

	// }
	// &::before{
	// top:auto;
	//     bottom:0;
	//     background:linear-gradient(transparent,#ffffff);
	// }
`;

const infoTextObj = {
	title: `사업연혁`,
	content: `AI 분석 알고리즘을 토대로<br />
        전통적인 기업 분석과 미래 전망에 대한<br />
        예측을 다각적으로 진행할 수 있습니다.`,
};

const backUpHistoryObj = {
	2015: [
		{
			title: "주식회사 앤톡 법인 설립",
			historyDate: "2015-10-26",
		},
		{
			title: "2015년 사회적기업진흥원 사회적 기업가 육성사업 최종 선정",
			historyDate: "2015-04-09",
		},
	],
	2016: [
		{
			title: "한국경제TV 장외주식 분석 솔루션 개발 MOU 체결",
			historyDate: "2016-10-24",
		},
		{
			title: "코스콤 Open-API Platform 핀테크 공모전 수상",
			historyDate: "2016-09-28",
		},
		{
			title: "소프트웨어대전 K-ICT 스타트업 경진대회 우수상 수상",
			historyDate: "2016-09-08",
		},
		{
			title: "코스콤 Fin-tech Open Platform 연계 사업 관련 MOU 체결",
			historyDate: "2016-09-07",
		},
		{
			title: "우리은행 Wibee Fin-tech 기업 육성사업 1기 최종 선정",
			historyDate: "2016-08-10",
		},
		{
			title: "Antock MRI Beta 서비스 및 Application 출시",
			historyDate: "2016-07-01",
		},
		{
			title: "한국정보화진흥원 공공데이터 활용 기업 육성사업 최종 선정",
			historyDate: "2016-03-28",
		},
	],
	2017: [
		{
			title: "중소기업청 마포비즈플라자 입주기업 최종 선정",
			historyDate: "2017-11-30",
		},
		{
			title: "골든브릿지투자증권 크라우드펀딩 플랫폼 개발 계약 체결",
			historyDate: "2017-09-11",
		},
		{
			title: "한국경제TV 장외주식 분석 솔루션 개발 계약 체결",
			historyDate: "2017-06-02",
		},
		{
			title: "창업진흥원 마케팅 지원사업 최종 선정",
			historyDate: "2017-05-16",
		},
		{
			title: "Start-Up Accelerator 기관에 대한 분석 솔루션 개발 계약 체결",
			historyDate: "2017-05-15",
		},
		{
			title: "장외주식 분석 솔루션 개발 비용 투자 유치",
			historyDate: "2017-03-01",
		},
		{
			title: "중소기업진흥공단 청년창업사관학교 7기 최종 선정",
			historyDate: "2017-02-24",
		},
	],
	2018: [
		{
			title: "VietSoftware International Inc, 베트남 IT기업 MOU 체결",
			historyDate: "2018-11-29",
		},
		{
			title: "2018년 창업성장기술개발사업 디딤돌창업과제 최종 선정",
			historyDate: "2018-11-28",
		},
		{
			title: "마이리서치하우스 상장 종목 진단 서비스 공급 계약 체결",
			historyDate: "2018-11-05",
		},
		{
			title: "중소기업진흥공단 청년창업사관학교 8기 최종 선정",
			historyDate: "2018-08-27",
		},
		{
			title: "정보화진흥원 공공데이터 기업 관리 시스템 개발 계약 체결",
			historyDate: "2018-07-09",
		},
		{
			title: "한국공인회계사회(KICPA) 대상 데이터 공급 계약 체결",
			historyDate: "2018-07-05",
		},
		{
			title: "2018년 핀테크 API개발 지원사업 우선협상 대상 기업 선정",
			historyDate: "2018-06-25",
		},
		{
			title: "행안부, 혁신 서비스 우수사례, 금융부문 앤톡 선정",
			historyDate: "2018-05-10",
		},
		{
			title: "우리종합금융 위비크라우드 플랫폼 개발 계약 체결",
			historyDate: "2018-04-14",
		},
		{
			title: "장외주식 분석 솔루션(WOW스타파인더) 출시",
			historyDate: "2018-04-09",
		},
		{
			title: "(주)골든브릿지투자증권 크라우드펀딩 솔루션 납품",
			historyDate: "2018-03-26",
		},
		{
			title: "한국인터넷진흥원(KISA) 핀테크 기술지원 사업 최종 선정",
			historyDate: "2018-03-16",
		},
		{
			title: "창업진흥원 스마트세계로누림터 2기 입주기업 최종 선정",
			historyDate: "2018-01-02",
		},
	],
	2019: [
		{
			title: "서울특별시 소셜벤처허브센터 1기 소셜벤처 입주기업 최종 선정",
			historyDate: "2019-08-12",
		},
		{
			title: "H-온드림 사회적기업 창업오디션 펠로 8기 최종 선정",
			historyDate: "2019-07-23",
		},
		{
			title: "중소벤처기업진흥공단 기술평가대출기업 벤처기업 인증",
			historyDate: "2019-07-11",
		},
		{
			title: "중소벤처기업진흥공단 청년창업사관학교 우수창업기업 이사장상 수상",
			historyDate: "2019-06-25",
		},
		{
			title: "2019년 대한민국창업대상 소상공인시장진흥공단 이사장상 수상",
			historyDate: "2019-06-18",
		},
		{
			title: "한국인터넷진흥원 핀테크기술지원센터 스타트업 데이터 관리 솔루션 개발 계약 체결",
			historyDate: "2019-05-17",
		},
		{
			title: "MYSC 엠와이소셜컴퍼니 소셜벤처 데이터베이스 관리 솔루션 계약 체결",
			historyDate: "2019-04-17",
		},
		{
			title: '핀테크 기반 장외주식 분석 커뮤니티 "IPO런웨이" 공식 출시',
			historyDate: "2019-02-01",
		},
		{
			title: "(주)앤톡, '2018 공공 빅데이터 성과공유대회' 행정안전부 장관상 수상",
			historyDate: "2019-01-29",
		},
	],
	2020: [
		{
			title: "한국디자인진흥원(KIDP) 데이터비즈 인포그래픽 공모전 (주)앤톡 금상 수상",
			historyDate: "2020-12-18",
		},
		{
			title: "(주)앤톡, 제28회 중소벤처기업진흥공단 혁신아이디어 공모전 최우수상 수상",
			historyDate: "2020-10-29",
		},
		{
			title: "2020 디자인 주도 사회적기업 혁신역량강화사업(BM 개발) 최종 선정",
			historyDate: "2020-10-01",
		},
		{
			title: "창업진흥원 「비대면서비스바우처 지원사업」 공급기업 최종 선정",
			historyDate: "2020-09-23",
		},
		{
			title: "한국핀테크지원센터 2020년 금융 클라우드 지원사업 최종 선정",
			historyDate: "2020-09-03",
		},
		{
			title: "정보통신산업진흥원(NIPA) AI 바우처 수요기업 최종 선정",
			historyDate: "2020-09-01",
		},
		{
			title: "한국데이터산업진흥원 2020년 데이터바우처 공급기업 협약 완료",
			historyDate: "2020-07-09",
		},
		{
			title: "2020년 중소기업기술개발사업 전략형창업과제(4IR) 최종 선정",
			historyDate: "2020-07-08",
		},
		{
			title: '엘피알 x 앤톡 주식퀴즈 리워드 앱 "스퀴즈(SQIZ)" 공동 사업 추진을 위한 계약 체결',
			historyDate: "2020-06-23",
		},
		{
			title: "한국인터넷진흥원(KISA) 2020년 핀테크 API개발 지원사업 최종 선정",
			historyDate: "2020-04-24",
		},
	],
	2021: [
		{
			title: "창업진흥원, 육성기업 조회 및 포트폴리오 관리, 데이터 리포팅 서비스 계약 체결",
			historyDate: "2021-11-29",
		},
		{
			title: "한국언론진흥재단 2021 미디어 스타트업 지원사업 - 장려상 수상",
			historyDate: "2021-10-25",
		},
		{
			title: "서울대학교 캠퍼스타운 사업단, Hubble Database 시스템 이용 계약 체결",
			historyDate: "2021-10-08",
		},
		{
			title: "한국핀테크지원센터 2021년 금융 클라우드 지원사업 최종 선정",
			historyDate: "2021-08-25",
		},
		{
			title: "2021 서울대학교 캠퍼스타운 입주기업 최종 선정",
			historyDate: "2021-08-20",
		},
		{
			title: "한국사회투자, Hubble Database 시스템 이용 계약 체결",
			historyDate: "2021-08-05",
		},
		{
			title: "앤톡 · 한국사회투자, 사회적경제 및 소셜벤처육성 생태계 강화를 위한 MOU 체결",
			historyDate: "2021-08-04",
		},
		{
			title: "Inclusion Plus Solution Lab 3.0 사회혁신 투자육성 프로그램 최종 선정",
			historyDate: "2021-07-14",
		},
		{
			title: "한국특허정보원 2021 지식재산 정보 활용 창업 경진대회 - 특허청장상(우수상) 수상",
			historyDate: "2021-06-18",
		},
		{
			title: "경상북도경제진흥원, Hubble Database 시스템 이용 계약 체결",
			historyDate: "2021-06-11",
		},
		{
			title: "한국데이터산업진흥원 2021년 데이터바우처 공급기업 가공 부문 협약 완료",
			historyDate: "2021-06-01",
		},
		{
			title: "한국데이터산업진흥원 2021년 데이터바우처 공급기업 AI가공 부문 협약 완료",
			historyDate: "2021-06-01",
		},
		{
			title: "한국콘텐츠진흥원 2021 창업도약 프로그램 최종 선정",
			historyDate: "2021-05-12",
		},
		{
			title: "코리아 핀테크 위크 2021 스케일업관 참여기업 선정",
			historyDate: "2021-04-29",
		},
		{
			title: "한국언론진흥재단 주관 미디어 스타트업 2021 공모 최종 선정",
			historyDate: "2021-04-09",
		},
		{
			title: "우리금융지주 Hubble Database 시스템 이용 계약 체결",
			historyDate: "2021-03-31",
		},
		{
			title: "앤톡 · 소셜혁신연구소, 소셜벤처 협의체 구성과 생태계 조성을 위한 MOU 체결",
			historyDate: "2021-03-04",
		},
		{
			title: "정보통신산업진흥원(NIPA) AI 바우처 지원사업 공급기업 최종 선정",
			historyDate: "2021-02-22",
		},
		{
			title: "Hubble Database - 국내 최대 규모의 스타트업 빅데이터 큐레이션 플랫폼 출시",
			historyDate: "2021-02-01",
		},
		{
			title: "앤톡 · 에이젠글로벌, 기업평가 AI 모델을 통한 전략적 사업 제휴(MOU) 체결",
			historyDate: "2021-01-29",
		},
	],
	2022: [
		{
			title: "IBK 1st Lab 3기 참여기업 최종 선정",
			historyDate: "2022-01-05",
		},
	],
};

const FirstSection = () => {

	const {
		isError,
		isLoading,
		isFetching,
		data: historyData,
	} = useQuery("getHistory", getHistory);

	const HistoryContents = () => {
		if (isError) return <History historyData={backUpHistoryObj} />;
		if (historyData) return <History historyData={historyData} />;
	};

	return (
		<CustomSectionWrapper>
			<PageWrapper>
				<CustomWrapper>
					<CustomWidth100>
						<PageInfoWidth40 className="page-info">
							<Info texts={infoTextObj} />
						</PageInfoWidth40>
						<CustomWidth60>{HistoryContents()}</CustomWidth60>
					</CustomWidth100>
				</CustomWrapper>
			</PageWrapper>
		</CustomSectionWrapper>
	);
};

export default FirstSection;
