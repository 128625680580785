import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import Background from './ladderboxshapes/Background';

import Rectangle from './ladderboxshapes/Rectangle';
import Circle from './ladderboxshapes/Circle';
import QuadCircle from './ladderboxshapes/QuadCircle';
import TripleRectangle from './ladderboxshapes/TripleRectangle';

import FirstLine from './ladderboxshapes/FirstLine';
import SecondLine from './ladderboxshapes/SecondLine';
import ThirdLine from './ladderboxshapes/ThirdLine';

import FirstText from './ladderboxshapes/FirstText';
import SecondText from './ladderboxshapes/SecondText';
import ThirdText from './ladderboxshapes/ThirdText';
import FourthText from './ladderboxshapes/FourthText';

const textInitData = {
    first: ["상장기업", "중소기업", "강소기업", "스타트업"],
    second: ["성장잠재성", "경영리스크", "기술혁신성", "사업수익성"],
    thrid: ["02", "08", "12", "25"],
    fourth: ["05", "09", "10", "21"],
};

const companyList = ["상장기업", "중소기업", "강소기업", "스타트업", "공모기업"]
const purposeList = ["사업경쟁력", "경영안정성", "경영리스크", "성장잠재성", "기술혁신성", "가치적정성", "지속가능성", "조직생산성", "사업수익성"];

const infoInitObj = {
    0: {
        shapeIndex: [3, 0, 3, 1],
        pathInfo: [
            "M294.5,63.5002 C294.5,63.5002 281.853,92.0958 246.806,92.1045 C211.759,92.1132 120.052,92.0946 81,92.1045 C41.9475,92.1144 27.5,120.5 27.5,120.5",
            "M27.5 192.023 C27.5 192.023 44.5 218.584 83.5 218.584C122.5 218.584 211.5 218.584 246.5 218.584C281.5 218.584 294 245.146 294 245.146",
            "M294.5 311.318  C294.5 311.318 280.5 337.716 252 340.123C223.5 342.53 172.373 336.501 153 340.123C133.627 343.746 116.802 367.935 116.802 367.935",
        ]
    },

    1: {
        shapeIndex: [3, 2, 1, 2],
        pathInfo: [
            "M294 64C294 64 287.549 92.3079 243.956 93.0001C200.362 93.6924 205.312 120.295 205.312 120.295",
            "M205.504,192.49 C205.504,192.49 198.361,217.461 166.861,217.927 C135.361,218.393 117,244.68 117,244.68",
            "M117 313.801C117 313.801 122.5 339.933 166.093 340.62C209.686 341.308 204.737 367.731 204.737 367.731",
        ]
    },
    2: {
        shapeIndex: [0, 1, 2, 0],
        pathInfo: [
            "M27.5 64C27.5 64 46.5 92.5 78 93.0001C109.5 93.5001 116.643 120.295 116.643 120.295",
            "M117 193.888C117 193.888 122.5 218.406 166.093 219.051C209.686 219.696 204.737 244.489 204.737 244.489",
            "M205 311.814C205 311.814 201.319 337.357 174.528 339.627C147.738 341.896 79.3736 338.62 60.1541 342.213C40.9346 345.807 28.0285 367.439 28.0285 367.439",
        ]
    },
    3: {
        shapeIndex: [1, 3, 2, 0],
        pathInfo: [
            "M117 65C117 65 120.209 89.7154 147 92C173.791 94.2846 242.155 90.9866 261.374 94.6044C280.594 98.2222 293.5 120 293.5 120",
            "M294 192.024C294 192.024 287.549 218.406 243.956 219.051C200.362 219.696 205.312 244.489 205.312 244.489",
            "M205 311.814C205 311.814 201.319 337.357 174.528 339.627C147.738 341.896 79.3736 338.62 60.1541 342.213C40.9346 345.807 28.0285 367.439 28.0285 367.439",
        ]
    },
    4: {
        shapeIndex: [3, 1, 3, 2],
        pathInfo: [
            "M294.5 63.4998C294.5 63.4998 280.5 90.0767 252 92.5001C223.5 94.9235 172.373 88.853 153 92.5001C133.627 96.1472 116.802 120.5 116.802 120.5",
            "M117 192.956C117 192.956 120.209 215.99 147 218.119C173.791 220.248 242.155 217.174 261.374 220.546C280.594 223.918 293.5 244.214 293.5 244.214",
            "M294 311.815C294 311.815 287.549 339.932 243.956 340.62C200.362 341.308 205.312 367.731 205.312 367.731",
        ]
    },

};

const getCombinations = function (arr, selectNumber) {
    const results = [];
    if (selectNumber === 1) return arr.map((el) => [el]);
    arr.forEach((fixed, index, origin) => {
        const rest = origin.slice(index + 1);
        const combinations = getCombinations(rest, selectNumber - 1);
        const attached = combinations.map((el) => [fixed, ...el]);
        results.push(...attached);

    });

    return results;
}

const rand = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

const getRandomNumberString = () => {

    const num = rand(0, 50);
    let text = num.toString();
    if (num < 10) text = "0" + num;

    return text;

}

const makeRandomTextData = () => {

    const targetCompanyList = getCombinations(companyList, 4)[rand(0, 4)];
    const targetPurposeList = getCombinations(purposeList, 4)[rand(0, 4)];
    const targetModelList = [];
    const targetDataList = [];

    for (let i = 0; i < 4; i++) {
        targetModelList.push(getRandomNumberString());
    }

    for (let i = 0; i < 4; i++) {
        targetDataList.push(getRandomNumberString());
    }

    const textDataObj = {
        first: targetCompanyList,
        second: targetPurposeList,
        thrid: targetModelList,
        fourth: targetDataList,
    }

    return textDataObj;

};


const Map5 = () => {


    const [init, setInit] = useState(false);
    const [rest, setRest] = useState(false);
    const [visable, setVisable] = useState(true);

    // line select -> shape index select
    const [infoObj, setInfoObj] = useState(infoInitObj);

    // 랜덤 생성
    const [textData, setTextData] = useState(textInitData);

    useEffect(() => {
        let timer;
        if (rest && !init) {
            timer = setTimeout(animationStart, 1500);
        }
    }, [rest, init]);

    const { ref, inView } = useInView({});

    useEffect(() => {
    	inView ? setVisable(true) : setVisable(false);
    }, [inView]);


    const handleRest = () => {
        setTimeout(animationStop, 1500);
    };

    const animationStart = () => {
        setVisable(true);
        setRest(false);
        setInit(true);
    };

    const animationStop = () => {
        setTextData(makeRandomTextData());
        setLayoutData();
        setVisable(false);
        setRest(true);
        setInit(false);
    };

    const setLayoutData = () => {
        const ranNum = rand(0, 4);
        setShapeIndex(infoObj[ranNum].shapeIndex);
        setPathInfo(infoObj[ranNum].pathInfo);
    }

    const [shapeIndex, setShapeIndex] = useState(infoObj[4].shapeIndex);
    const [pathInfo, setPathInfo] = useState(infoObj[4].pathInfo);

    return (
        <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width="100%" height="444" viewBox="0 0 321 444" fill="none">

            {textData ? <Background textData={textData} /> : null}  
            {visable && !rest ?
                <>
                    <Rectangle index={shapeIndex[0]} init={init} />
                    <FirstText index={shapeIndex[0]} textData={textData.first[shapeIndex[0]]} init={init} />
                    <FirstLine data={pathInfo[0]} init={init} />

                    <Circle index={shapeIndex[1]} init={init} />
                    <SecondText index={shapeIndex[1]} textData={textData.second[shapeIndex[1]]} init={init} />
                    <SecondLine data={pathInfo[1]} init={init} />

                    <QuadCircle index={shapeIndex[2]} init={init} />
                    <ThirdText index={shapeIndex[2]} textData={textData.thrid[shapeIndex[2]]} init={init} />
                    <ThirdLine data={pathInfo[2]} init={init} />

                    <TripleRectangle index={shapeIndex[3]} init={init} />
                    <FourthText index={shapeIndex[3]} textData={textData.fourth[shapeIndex[3]]} init={init} onHandleRest={handleRest} />

                </>
                : null}



        </svg>
    );
};

export default Map5;