import React from "react";
import styled from "styled-components";
import { Height100, Height50 } from "style/layout";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const ServiceLinkWrapperHeight100 = styled(Height100)`
	width: ${(props) => props.theme.Rem(140)};
	margin-left: auto;
	border-left: 1px solid ${(props) => props.theme.colors.borderColor};
	box-sizing: border-box;
	margin-top: calc(${(props) => props.theme.sizes.pageMarginTop} * 2);
    
`;

const ServiceLinkHeight50 = styled(Height50)`
    box-sizing: border-box;
	padding: ${(props) => props.theme.Rem(50)} ${(props) => props.theme.Rem(20)}
		${(props) => props.theme.Rem(50)} ${(props) => props.theme.Rem(20)};
	img {
		width: 100%;
		margin-bottom: ${(props) => props.theme.Rem(85)};
	}
	&:first-of-type {
		justify-content: flex-end;
		border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
		box-sizing: border-box;
	}
	&:last-of-type {
		justify-content: flex-start;
	}
	&:hover{
		a{
			font-weight:800;
			color:#fff;
		}
	}
	a {
		font-size:1rem;
		color: ${(props) => props.theme.colors.dFontColor};
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		transition:all 0.3s ease-in-out;
		span {
			margin-bottom: -0.2rem;
		}
	}
	${props => props.theme.device.tabletL}{
		padding: ${(props) => props.theme.Rem(50)} ${(props) => props.theme.Rem(12)}
		${(props) => props.theme.Rem(50)} ${(props) => props.theme.Rem(12)};
	}
`;

const ServiceLink = () => {
	return (
		<ServiceLinkWrapperHeight100>
			<ServiceLinkHeight50>
				<div className="service-link">
					<img src="/img/hubble_w.png" alt="hubble database" />
					<a href="https://hubble.co.kr/" target="_blank">
						서비스
						<br />
						바로가기
						{/* <span class="material-icons">chevron_right</span> */}
                        <ChevronRightIcon/>
					</a>
				</div>
			</ServiceLinkHeight50>
			<ServiceLinkHeight50>
				<div className="service-link">
					<img src="/img/antock_mri_w.png" alt="antock mri" />
				</div>
			</ServiceLinkHeight50>
		</ServiceLinkWrapperHeight100>
	);
};

export default ServiceLink;
