import styled, { css } from "styled-components";

export const Container = styled.div`
	width: 100%;
	height: auto;
	position: relative;
	${props => {
		if(props.page === "pr" || props.page === "newsdetail"){
			return css`
				background-color:#EBEBEB;
			`;
		}
}}
`;

export const PageWrapper = styled.div`
	width: 100%;
	height: 100vh;
	min-height: 100vh;
	box-sizing: border-box;
	overflow: hidden;
	${props => props.theme.device.tabletL}{
		height:auto;		
		min-height: 0;
	}
`;

// export const MobilePageWrapper = styled.div`
// 	width: 100%;
// 	height: 100% !important;
// 	box-sizing: border-box;
// `;

export const Wrapper = styled.div`
	max-width: 88%;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	position: relative;
	box-sizing: border-box;
	border: 1px solid ${(props) => props.theme.colors.lbgBorderColor};
	border-width: 0 1px;
`;

export const FullpageWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

export const Logo = styled.h1`
	background-image: url("/img/all_menu_logo.png");
	background-repeat: no-repeat;
	text-indent: -9999px;
	background-position: center center;
	background-size: contain;
	width: 120px;
	cursor: pointer;
	a {
		display: block;
		width: 100%;
		height: 100%;
	}
`;

export const ScrollButton = styled.button`
	max-width: 20px;
	width: ${(props) => props.theme.Rem("11")};
	height: ${(props) => props.theme.Rem("65")};
	position: fixed;
	bottom: ${(props) => props.theme.Rem("66")};
	right: calc(3% - 5.5px);
	z-index: 1;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	opacity: 1;

	img {
		width: 100%;
		height: 100%;
	}

	&.hide {
		opacity: 0;
		z-index: 0;
	}
`;

export const PageInfo = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	box-sizing: border-box;
	flex-wrap: nowrap;
	align-items: center;
	margin-top: -#{${(props) => props.theme.sizes.pageMarginTop}};
`;

export const PageInfoTitle = styled.h2`
	font-size: ${(props) => props.theme.Rem(48)};
	font-weight: 900;
	margin-bottom: ${(props) => props.theme.Rem(56)};
	color: ${(props) => props.theme.colors.pointColor};
	word-break:keep-all;
	${(props) =>
		props.primary &&
		css`
			color: #fff;
		`}
`;

export const PageInfoExplain = styled.p`
	font-size: ${(props) => props.theme.Rem(20)};
	font-weight: 400;
	line-height: ${(props) => props.theme.Rem(35)};
	color: ${(props) => props.theme.colors.fontColor};
	${(props) =>
		props.primary &&
		css`
			color: #fff;
		`}
`;

export const textBoxTitle = css`
	font-weight: 900;
	font-size: ${(props) => props.theme.Rem(36.8)};
`;

export const textBoxSub = css`
	font-size: ${(props) => props.theme.Rem(20)};
	font-weight: 400;
`;

export const textBoxExplain = css`
	font-size: ${(props) => props.theme.Rem(14)};
	font-weight: 400;
	word-break: keep-all;
`;

export const textBoxContents = css`
	${props => {
		return css`
			font-size:${props.theme.Rem(18)};
			font-weight: 400;
			word-break: keep-all;
			${props.theme.device.desktopS}{
				font-size:0.95rem;
			}
		`;	
	}}
	
`;

export const PageLogo = styled.img`
	display: block;
    width: 7.5rem;
    margin-bottom: 3.875rem;
    position: absolute;
    left: 0;
    bottom: 0;
`;

export const MriPageLogo = styled.img`
	width:${props => props.theme.Rem(100)};
	margin-bottom: 3.875rem;
    position: absolute;
    left: 0;
    bottom: 0;
    display:block;
`;

export const Row = styled.div`
	flex-direction: row;
`;

export const Width33 = styled.div`
	width: 33.33%;
	display: flex;
	flex-direction: row;
`;

export const Width30 = styled.div`
	width: 30%;
	display: flex;
	flex-direction: row;
`;

export const Width70 = styled.div`
	width: 70%;
	display: flex;
	flex-direction: row;
`;
export const Width45 = styled.div`
	width: 45%;
	display: flex;
	flex-direction: row;
`;

export const Width50 = styled.div`
	width: 50%;
	display: flex;
	flex-direction: row;
`;

export const Width55 = styled.div`
	width: 55%;
	display: flex;
	flex-direction: row;
`;
export const Width60 = styled.div`
	width: 60%;
	display: flex;
	flex-direction: row;
`;
export const Width40 = styled.div`
	width: 40%;
	display: flex;
	flex-direction: row;
`;
export const Width67 = styled.div`
	width: 66.66%;
	display: flex;
	flex-direction: row;
`;
export const Width100 = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
`;

export const Height33 = styled.div`
	height: 33.33%;
	display: flex;
	flex-direction: column;
`;

export const Height45 = styled.div`
	height: 45%;
	display: flex;
	flex-direction: column;
`;

export const Height50 = styled.div`
	height: 50%;
	display: flex;
	flex-direction: column;
`;


export const Height55 = styled.div`
	height: 50%;
	display: flex;
	flex-direction: column;
`;

export const Height67 = styled.div`
	height: 67%;
	display: flex;
	flex-direction: column;
`;

export const Height100 = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
`;

/*Mobile*/

export const ContentWrapper = styled(Wrapper)`
	padding-top:50px;
`;

export const InfoGraphicImg = styled.img`
	width:100%;
	min-height:1px;
	height:auto;
	max-width: 700px;
    margin: 0 auto;
    display: block;
`;

export const SectionInfoWrapper = styled.p`
	box-sizing:border-box;
	font-size:1.3rem;
	line-height:2rem;
	width:92%;
	margin:0 auto;
	text-align:center;
	word-break:keep-all;
	padding-bottom:50px;
	font-weight:300;
	`;

export const MobileContentsList = styled.ul`
	display: flex;
	flex-direction:column;
	width:100%;
	text-align:right;
	li{
		margin-bottom:${props => props.theme.sizes.mHeaderHeight};
		display:flex;
		flex-direction:column;
		align-items:flex-end;
		span{
			color:${props => props.theme.colors.pointColor};
			font-size:${props => props.theme.Rem(18)};
			font-weight:500;
		}
		h3{
			font-size:${props => props.theme.Rem(36)};
			font-weight:900;
			margin-top:0.3rem;
		}
		p{
			font-size:${props => props.theme.Rem(16)};
			width:70%;
			word-break:keep-all;
			line-height:1.8rem;
			margin-top:0.5rem;		
		}
	}
`;
// @for $p from 1 to 11 {
//     $w : ($p * 10) + '%';
//     .w-#{$p * 10}{
//         width:#{$w};
//         display:flex;
//         flex-direction:row;
//     }
// }
