import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

const ItemStyle = styled.div`
	display: flex;
	flex-direction: column;
	// margin-left: 4rem;
	// width: 28%;
	width: 23%;
    margin-right: 2.6%;
	box-sizing:border-box;
	padding:;
	cursor: pointer;
	margin-bottom:${(props) => props.theme.Rem(85)};
	&:nth-of-type(4n){
		margin-right:0;
	}
	div {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: ${(props) => props.theme.Rem(22)} 0;
		font-weight: 700;
		color: #808091;
		span {
			&:last-of-type {
				font-weight: 400;
			}
		}
	}
	img {
		flex-shrink: 0;
		height: auto;
		margin-bottom: 0;
		transition: margin 0.3s ease-in-out;
	}
	h4 {
		font-size: ${(props) => props.theme.Rem(22)};
		line-height: 1.5;
		word-break: keep-all;
		transition: color 0.3s ease-in-out;
	}
	&:hover {
		h4 {
			color: ${(props) => props.theme.colors.pointColor};
		}
		// img{
		// 	margin-top: -1rem;
		// }
	}
`;

const ImgWrapper = styled.div`
	width: 100%;
	height: ${(props) => props.theme.Rem(216)};
	
	img {
		// width: 100%;
		height: 100%;
		display: block;
		width:auto;
		margin:0 auto;
		max-width: 100%;
	}
	
	${props => props.theme.device.desktopM}{
		height: ${(props) => props.theme.Rem(170)};
	}
`;

export const textLengthOverCut = (txt, len, lastTxt) => {
	if (len == "" || len == null) {
		// 기본값
		len = 20;
	}
	if (lastTxt == "" || lastTxt == null) {
		// 기본값
		lastTxt = "...";
	}
	if (txt.length > len) {
		txt = txt.substr(0, len) + lastTxt;
	}
	return txt;
};


const Items = (props) => {
	let date = "";
    const { currentItems, itemOffset } = props;

	const handleItemClick = (no) => {
		props.onHandleItemClick(no);
	};

	const CategoryFiltering = (string) => {
		switch(string){
			case "MEDIA":
				return "언론보도";

			case "FEED":
				return "회사소식";

			case "NOTICE":
				return "공지사항";

			default:
				return "전체";
		}
	}
	const DeviceCheckTextCut = (device,text) =>{
		switch(device){
			case "tablet":
				return textLengthOverCut(text,22,"...");
			case "desktop":
				return textLengthOverCut(text,25,"...");
			case "desktopM":
				return textLengthOverCut(text,40,"...");
			case "desktopL":
				return textLengthOverCut(text,60,"...");
			default:
				return textLengthOverCut(text,22,"...");
		}
	}
	const Item = ({ item }) => {
		const { device } = useSelector(
			(state) => ({
				device: state.style.device,
			})
		);
	    return (
	        <ItemStyle onClick={() => handleItemClick(item.no)}>
	            <ImgWrapper>
					<img
						src={item.thumbUrl ? item.thumbUrl : `/img/pr_no_thumbnail.png`}
						alt={item.title}
					/>
	            </ImgWrapper>
	            <div>
	                <span>{CategoryFiltering(item.category)}</span>
	                <span>
						{
							item.displayDate.split("-").map(( item, idx )=>{
								switch( idx ){
									case 0:
									date = item + "년 ";
									break;
									case 1:
									date += item + "월 ";
									break;
									case 2:
									date += item + "일 ";
									return <span key={date}>{date}</span>;
								}
							})
						}
					</span>
	            </div>
	            <h4>
					{DeviceCheckTextCut(device,item.title)}
				</h4>
	        </ItemStyle>
	    );
	};

	return (
		<>
			{currentItems &&
				currentItems.map((item, index) => (
					<Item key={index} item={item}/>
				))}
		</>
	);
};

export default Items;
