import React from "react";
import styled, {css} from "styled-components";

import {
	Height100,
	Height50,
	PageLogo,
	PageWrapper, textBoxContents,
	textBoxTitle,
	Width100,
	Width45,
	Width55,
	Wrapper
} from "style/layout";
import Info from "../Info";

const CustomSectionWrapper = styled.div``;

const CustomWidth100 = styled(Width100)`
	height: 100%;
`;
const PageInfoWidth45 = styled(Width45)`
	height: 100%;
	box-sizing: border-box;
	flex-wrap: nowrap;
	align-items: flex-start;
	justify-content:center;
	margin-top: -#{${(props) => props.theme.sizes.pageMarginTop}};
    flex-direction: column;
    border-right:1px solid ${props => props.theme.colors.lbgBorderColor};
`;

const CustomHeight50 = styled(Height50)`
	box-sizing: border-box;
	padding-top:${props => props.theme.Rem(50)};
	padding-bottom:${props => props.theme.Rem(50)};
	padding-right:${props => props.theme.Rem(15)};
	padding-left:${props => props.theme.Rem(22)};
	&:first-of-type{
		justify-content: flex-end;
		color:$point-color;
	}
	&:last-of-type{
		justify-content: flex-start;
		background-color:${props => props.theme.colors.pointColor};
		color:#fff;
	}
	.text-box{
		.title{
			${textBoxTitle}
			margin-bottom:${(props) => props.theme.Rem(30)};
			font-size:${(props) => props.theme.Rem(30)};
			word-break: keep-all;
		}
		.contents{
			${textBoxContents}
			word-break: keep-all;
			line-height:${(props) => props.theme.Rem(24)};
			font-size:${(props) => props.theme.Rem(16)};
		}
	}
`;

const CustomWidth33 = styled.div`
	${(props) => {
		let styles=``;
		for (let i = 1; i <= 4 ; i += 1){
			styles +=`
				width:33.33333%;
				box-sizing:border-box;
				&:first-of-type{
					border:none;
				}
				&:nth-of-type(${i}){
					border-left:1px solid ${props.theme.colors.lbgBorderColor};
					box-sizing:border-box;
					>div{
						& > div:first-of-type{
							background-image:url("/img/hubble04_${i}.png");
                            background-repeat:no-repeat;
                            background-size:cover;
                            background-position:top left;
                            color:${props.theme.colors.pointColor};
						}
					}
				}
			`;
		}
		return css`${styles}`;
		}
	}
`;
const infoTextObj = {
	title:`Next Level<br />Financial AI Data`,
	content:`허블 데이터베이스를 활용하여<br />
			기업 및 금융 데이터 활용 효과를<br />    
			혁신적으로 제고할 수 있습니다.`
}
const FourthSection = () => {
	return (
		<>
			<CustomSectionWrapper>
				<PageWrapper>
					<Wrapper>
						<CustomWidth100>
							<PageInfoWidth45>
								<Info texts = {infoTextObj}/>
								<PageLogo src="/img/hubble_h_b.png" alt="Hubble Database"/>
							</PageInfoWidth45>
							<Width55>
								<Width100>
									<CustomWidth33>
										<Height100>
											<CustomHeight50>
												<div className="text-box">
													<h3 className="title">All in One<br/>통합 시스템</h3>
													<p className="contents">
														다양한 공공 / 개방 데이터와
														유기적으로 연계하여 일원화된
														AI 기업 데이터 구축
													</p>
												</div>
											</CustomHeight50>
											<CustomHeight50>
												<div className="text-box">
													<h3 className="title">새로운 관점</h3>
													<p className="contents">
														다채로운 비 금융 데이터를
														활용하여 창의적인 관점에서
														기업에 대한 이해 제고
													</p>
												</div>
											</CustomHeight50>
										</Height100>
									</CustomWidth33>
									<CustomWidth33>
										<Height100>
											<CustomHeight50>
												<div className="text-box">
													<h3 className="title">데이터 AI<br/>전처리 프로그램</h3>
													<p className="contents">
														데이터 항목 <small>(단위, 스타일, 양식 등)</small>
														표준화 및 비정형 텍스트 데이터
														정량 변환 자동화
													</p>
												</div>
											</CustomHeight50>
											<CustomHeight50>
												<div className="text-box">
													<h3 className="title">효율적 작업</h3>
													<p className="contents">
														AI 분석 및 응용을 위한
														데이터 가공 및 클렌징 작업
														공수와 시간을 혁신적으로 단축
													</p>
												</div>
											</CustomHeight50>
										</Height100>
									</CustomWidth33>
									<CustomWidth33>
										<Height100>
											<CustomHeight50>
												<div className="text-box">
													<h3 className="title">자동 업데이트<br/> 알고리즘</h3>
													<p className="contents">
														전체 기업 표본과 기업 별
														세부 정보를 월 1회 갱신하여
														추가 / 변동 사항 자동 반영
													</p>
												</div>
											</CustomHeight50>
											<CustomHeight50>
												<div className="text-box">
													<h3 className="title">적시적 분석</h3>
													<p className="contents">
														최신화 된 기업 데이터를
														토대로 보다 적시적인
														기업 분석과 연구 진행
													</p>
												</div>
											</CustomHeight50>
										</Height100>
									</CustomWidth33>
								</Width100>
							</Width55>
						</CustomWidth100>
					</Wrapper>
				</PageWrapper>
			</CustomSectionWrapper>
		</>
	);
};

export default FourthSection;
