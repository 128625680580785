import React from "react";
import styled, {css} from "styled-components";

import {PageWrapper, Width100, Width30, Width70, Wrapper} from "style/layout";
import Info from "../Info";
import {useQuery} from "react-query";
import {getAwards} from "api/api";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

const CustomSectionWrapper = styled.div`
  background-color: #f8f8f8;
`;
const CustomWidth100 = styled(Width100)`
  height: 100%;
`;
const PageInfoWidth30 = styled(Width30)`
  height: 100%;
  box-sizing: border-box;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: ${(props) => props.theme.sizes.headerHeight};
  margin-top: ${(props) => props.theme.sizes.headerHeight};
  flex-direction: column;
  position: relative;
  ${(props) => props.theme.device.tabletL} {
    margin-top: 0;
  }
`;

const CustomWidth70 = styled(Width70)`
  height: 100%;
  margin-top: calc(${(props) => props.theme.sizes.headerHeight} * 2);
  ${(props) => props.theme.device.tabletL} {
    margin-top: ${(props) => props.theme.sizes.headerHeight};
  }
`;
const AwardList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: calc(100% - (${(props) => props.theme.sizes.headerHeight} * 2));
  box-sizing: border-box;
  padding: 0 0 4% 0;
  overflow: auto;
  ${(props) => props.theme.device.tabletL} {
    height: calc(100% - (${(props) => props.theme.sizes.headerHeight}));
  }
`;
const AwardItem = styled.div`
  background-color: #fff;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  width: 47%;
  position: relative;
  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
  }

  ${(props) => {
    return css`
      padding: ${props.theme.Rem(42)} ${props.theme.Rem(48)};
      margin-bottom: ${props.theme.Rem(40)};
      line-height: 1.7rem;
      color: #393939;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        font-size: ${props.theme.Rem(20)};
        font-weight: 300;
        word-break: keep-all;
      }
      h4 {
        font-size: ${props.theme.Rem(20)};
        font-weight: 700;
        word-break: keep-all;
        margin-bottom: ${props.theme.Rem(50)};
      }
      .host-logo {
        max-height: ${props.theme.Rem(50)};
        width: auto;
        flex-shrink: 0;
        height: auto;
        max-width: 80%;
        margin-top: auto;
      }
    `;
}};
  ${(props) => props.theme.device.tabletL} {
    padding: ${(props) => props.theme.Rem(36)} ${(props) => props.theme.Rem(28)};
    p {
      font-size: ${(props) => props.theme.Rem(14)};
      font-weight: 300;
    }
    h4 {
      font-size: ${(props) => props.theme.Rem(16)};
    }
  }
`;
const infoTextObj = {
    title: "수상인증",
    content: `기업 빅데이터에 대한 기술력을 입증 받아<br />
			대외적 성과를 창출하고 있습니다.`,
};
const AwardIcon = styled.img`
  flex-shrink: 0;
  align-self: flex-start;

  ${(props) => {
    return css`
      width: ${props.theme.Rem(26)};
    `;
}}
`;

const AwardImg = styled.img`
  width: 200px;
  height: 200px;
`

export const awardListArr = [
    {
        "no": 21,
        "title": "기술혁신형 중소기업 (이노비즈) 확인 (2023)",
        "registDate": "2022-12-29T15:42:11",
        "logoUrl": "/img/awards/innobiz.jpg",
    },
    {
        "no": 20,
        "title": "금융위원회 D-테스트베드 대상 (금융위원장상)",
        "registDate": "2022-12-29T15:42:11",
        "logoUrl": "/img/awards/finece.png",
    },
    {
        "no": 19,
        "title": "KOTRA Innovation Makers 평가회 최우수상",
        "registDate": "2022-12-20T15:42:11",
        "logoUrl": "/img/awards/kotra_logo.jpg",
    },
    {
        "no": 18,
        "title": "제 10회 NTIS 정보활용경진대회 대상 (과학기술정보통신부 장관상)",
        "registDate": "2022-12-08T15:42:11",
        "logoUrl": "/img/awards/tech.png",
    },
    {
        "no": 17,
        "title": "벤처창업 혁신조달상품 지정 (2022)",
        "registDate": "2022-12-29T15:42:11",
        "logoUrl": "/img/awards/service.png",
    },
    {
        "no": 16,
        "title": "2022 기술 & ESG 우수기업상",
        "registDate": "2022-11-28T15:42:11",
        "logoUrl": "/img/awards/korea.png",
    },
    {
        "no": 15,
        "title": "KB 스타터스 스케일업 우수 기업상",
        "registDate": "2022-11-21T15:42:11",
        "logoUrl": "/img/awards/kb_innovation_hub.png",
    },{
        "no": 14,
        "title": "투자용 기술신용평가 ‘대안신용평가 PULSE 시스템‘ 최상위 등급(TI2) 획득",
        "registDate": "2022-07-25T15:42:11",
        "logoUrl": "/img/awards/credible.png",
    },
    {
        "no": 13,
        "title": "벤처기업(연구개발유형) 인정 (2021)",
        "registDate": "2022-12-29T15:42:11",
        "logoUrl": "/img/awards/startup.jpg",
    },
    {
        "no": 12,
        "title": "2021 미디어 스타트업 지원사업 장려상",
        "registDate": "2021-10-25T15:42:11",
        "logoUrl": "/img/awards/korea_media.png",
    },
    {
        "no": 11,
        "title": "2021 지식재산 정보 활용 창업경진대회 우수상",
        "registDate": "2021-06-25T10:21:29",
        "logoUrl": "/img/awards/kipris.png",
    },
    {
        "no": 10,
        "title": "기술보증기금 소셜벤처기업 인정 (2020)",
        "registDate": "2022-12-29T15:42:11",
        "logoUrl": "/img/awards/kibo.jpg",
    },
    {
        "no": 9,
        "title": "2020 데이터비즈 인포그래픽 공모전 금상",
        "registDate": "2020-12-09T13:38:52",
        "logoUrl": "/img/awards/kidp.png",
    },
    {
        "no": 8,
        "title": "혁신아이디어 공모전 최우수상",
        "registDate": "2020-11-26T19:05:22",
        "logoUrl": "/img/awards/kosme.png",
    },
    {
        "no": 7,
        "title": "기업부설연구소 인정서",
        "registDate": "2020-07-20T19:36:06",
        "logoUrl": "/img/awards/koita.png",
    },
    {
        "no": 6,
        "title": "기술평가대출기업 벤처기업인증",
        "registDate": "2019-07-24T16:38:09",
        "logoUrl": "/img/awards/koscom.png",
    },
    {
        "no": 5,
        "title": "중소기업벤처진흥공단 이사장상",
        "registDate": "2019-07-24T16:36:53",
        "logoUrl": "/img/awards/kosme.png",
    },
    {
        "no": 4,
        "title": "행정안전부 장관 표창장 수상",
        "registDate": "2019-07-24T16:36:08",
        "logoUrl": "/img/awards/mois.png",
    },
    {
        "no": 3,
        "title": "2019 대한민국 창업대상 소상공인시장진흥공단 이사장상",
        "registDate": "2019-07-24T16:35:10",
        "logoUrl": "/img/awards/semas.png",
    },
    {
        "no": 2,
        "title": "KOSCOM API 공모전 수상",
        "registDate": "2019-07-24T16:34:20",
        "logoUrl": "/img/awards/koscom.png",
    },
    {
        "no": 1,
        "title": "K-ICT 본투글로벌센터장 우수상",
        "registDate": "2019-07-24T16:33:18",
        "logoUrl": "/img/awards/born2global.png",
    }
];

const AwardItemWrapper = styled.div`
  display: flex;
  flex-directions: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 4%;
`;

const SecondSection = () => {
    const {
        isLoading: awardsLoading,
        isError: awardsError,
        data: awardsData,
        isFetching: awardsFetching,
    } = useQuery("getAwards", getAwards);

    return (
        <>
            <CustomSectionWrapper>
                <PageWrapper>
                    <Wrapper>
                        <CustomWidth100>
                            <PageInfoWidth30>
                                <Info texts={infoTextObj}/>
                            </PageInfoWidth30>
                            <CustomWidth70>
                                <AwardList className="scrollable-content">
                                    <SimpleBar
                                        style={{
                                            overflow: "auto",
                                            overscrollBehavior: "contain",
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        <AwardItemWrapper>
                                            {awardListArr
                                                ? awardListArr.map((item, idx) => {
                                                    return (
                                                        <AwardItem>
                                                            <div>
                                                                <div>
                                                                    {/* <p>{item.contest}</p>
                                    <h4>{item.award}</h4> */}
                                                                    <h4>{item.title}</h4>
                                                                </div>

                                                                <AwardIcon
                                                                    // src={item.filename}
                                                                    src={"/img/award_icon.png"}
                                                                    alt="수상내역"
                                                                />

                                                            </div>
                                                            <AwardImg
                                                                src={item.logoUrl}
                                                                //   alt={item.host}
                                                                className="host-logo"
                                                            />
                                                        </AwardItem>
                                                    );
                                                })
                                                : null}
                                        </AwardItemWrapper>
                                    </SimpleBar>
                                </AwardList>
                            </CustomWidth70>
                        </CustomWidth100>
                    </Wrapper>
                </PageWrapper>
            </CustomSectionWrapper>
        </>
    );
};

export default SecondSection;
