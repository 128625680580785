import { useState, useEffect, useRef } from "react";
import debounce from "lodash/debounce";

export const usePreventRightClick = () => {

    useEffect(() => {
        document.addEventListener("contextmenu", e => {
            // e.target.matches("img") && e.preventDefault()
            e.preventDefault()
        });
        return () => document.addEventListener("contextmenu", e => {
            e.preventDefault()
        });
    } , []);
};

export const useMousePosition = () => {
    const [position, setPosition] = useState({ x: 0, y: 0});

    const delay = 50;

    useEffect(() => {

        const setFromEvent = (e) => setPosition({ x: e.clientX, y: e.clientY });
        window.addEventListener("mousemove",  debounce(setFromEvent, delay));
        return () => {
            window.removeEventListener("mousemove", setFromEvent);
        }

    }, []); 

    return position;
};


export const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});

	const delay = 10;

	useEffect(() => {
		const handleResize = () => {
			setWindowSize({
				// width: window.innerWidth,
				// height: window.innerHeight,
				width: window.outerWidth,
				height: window.outerHeight,
			});
		};
		//   window.addEventListener("resize", handleResize);
		window.addEventListener("resize", debounce(handleResize, delay));
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return windowSize;
};

export const useScroll = () => {
	const [scrollY, setScrollY] = useState(0);
	const onScroll = () => {
		setScrollY(window.pageYOffset);
	};

	const delay = 30;

	useEffect(() => {
		window.addEventListener("scroll", debounce(onScroll, delay));

		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	return { scrollY };
};

export const useSimpleBarScroll = (element) => {
	const [scrollY, setScrollY] = useState(0);
	const onScroll = () => {
		setScrollY(element.current.getScrollElement().scrollTop);
	};

	const delay = 30;

	useEffect(() => {
		if (element && element.current) {
			element.current
				.getScrollElement()
				.addEventListener("scroll", debounce(onScroll, delay));
		}

		return () =>
			element.current
				.getScrollElement()
				.removeEventListener("scroll", onScroll);
	}, [element]);

	return { scrollY };
};

export const useCaseSlider = (sliderIndex, sliderRef) => {
	useEffect(() => {
		const sliders = sliderRef.current;

		const slidersLength = sliders.children.length;
		let prevIndex;

		for (let i = 0; i < slidersLength; i++) {
			if (sliders.children[i].classList.contains("active")) {
				prevIndex = i;
			}
		}

		if (typeof prevIndex === "undefined") {
			prevIndex = 0;
		}

		if (prevIndex !== sliderIndex) {
			sliders.children[prevIndex].classList.toggle("active-out");
			sliders.children[prevIndex].addEventListener(
				"transitionend",
				function () {
					sliders.children[prevIndex].classList.remove("active-out");
				}
			);
		}

		sliders.children[prevIndex].classList.remove("active");
		sliders.children[sliderIndex].classList.toggle("active");
	}, [sliderIndex]);
};

export const useInterval = (callback, delay) => {
	const savedCallback = useRef();

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			let id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
};
