import React from "react";
import {
    ContentWrapper,
    InfoGraphicImg,
    PageInfoTitle,
    PageWrapper, SectionInfoWrapper,
    Width100,
    Width50,
    Wrapper
} from "../style/layout";
import styled from "styled-components";

const MFooterWrapper = styled.div`
    background-image: url("/img/landing-main.png");
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center center;
	box-sizing:border-box;
`;

const CustomWidth100 = styled(Width100)`
	height: 100%;
`;

const CustomFooterInfoList = styled.ul`
	display: flex;
	flex-direction: row;
	width: 100%;
    flex-direction:column;
    height:auto;
    box-sizing:border-box;
    padding-top:1rem;
    position:relative;
		>li{
			width:100%;
			padding: 1rem 0;
			flex-wrap:wrap;
			font-size:0.9rem;
			&:first-of-type {
				width: 100%;
				display:flex;
				flex-direction:row;
				justify-content:space-between;
				span{
				    display:flex;
				    flex-direction:column;
				}
			}
			&:last-of-type{
			    span{
			        flex-direction:column;
			        p{
				        width: 92%;
			        }
			    }
			}
			img {
                width: ${(props) => props.theme.Rem(140)};
                padding-bottom: ${(props) => props.theme.Rem(10)};
                & + p {
                    font-size: ${(props) => props.theme.Rem(13)};
                    color: ${(props) => props.theme.colors.footerUntilColor};
                }
            }
			&:not(:first-of-type){
			    flex-direction:row;
			    flex-wrap:wrap;
			    border: none;
				p{
					width:70%;
					line-height: ${(props) => props.theme.Rem(24)};
                    color: rgba(255, 255, 255, 0.7);
                    font-weight:800;
                    display: inline-block;
                    word-break:keep-all;
					&:nth-of-type(odd){
						width:auto;
                        padding-right:1rem;
                        box-sizing:border-box;
					}
					b {
                        display: block;
                        font-weight:400;
                        box-sizing: border-box;
                        padding-bottom: ${(props) => props.theme.Rem(10)};
                        color: rgba(255, 255, 255, 1);
                    }
				}
			}
		}
	}
`;

const CustomSnsWrapper = styled.ul`
	display: flex;
	flex-direction: row;
    justify-content: space-between;
	li {
		width: auto;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 0;
		&:first-of-type {
			img {
				width: ${(props) => props.theme.Rem(28)};
			}
		}
		img {
			width: ${(props) => props.theme.Rem(20)};
			margin-right: ${(props) => props.theme.Rem(18)};
		}
	}
`;
const MobileFooter = () =>{

    return (
        <MFooterWrapper>
            <Wrapper className="border-light">
                <CustomWidth100>
                    <CustomFooterInfoList>
                        <li>
                            <span>
                                <img src="/img/all_menu_logo.png" alt="antock" />
                                <p>© 2022 Antock Co., Ltd.</p>
                            </span>
                            <CustomSnsWrapper>
                                <li>
                                    <a
                                        href="https://www.youtube.com/channel/UCcXuO1UjoIdWv5nbIlVfwkQ"
                                        target="_blank"
                                    >
                                        <img
                                            src="/img/youtube.png"
                                            alt="youtube"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://blog.naver.com/NBlogTop.naver?isHttpsRedirect=true&blogId=antock"
                                        target="_blank"
                                    >
                                        <img
                                            src="/img/naver.png"
                                            alt="naver blog"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.instagram.com/antock_official/"
                                        target="_blank"
                                    >
                                        <img
                                            src="/img/instagram.png"
                                            alt="instagram"
                                        />
                                    </a>
                                </li>
                            </CustomSnsWrapper>
                        </li>
                        <li>
                            <ul>
                                <li>
                                    <p>대표자</p>
                                    <p>
                                        <b>박재준</b>
                                    </p>
                                </li>
                                <li>
                                    <p>회사명</p>
                                    <p>
                                        <b>주식회사 앤톡 Antock Co., Ltd.</b>
                                    </p>
                                </li>
                                <li>
                                    <p>사업자등록번호</p>
                                    <p>
                                        <b>527-88-00181</b>
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li>
                                    <p>T</p>
                                    <p>
                                        <b>02-6263-1026</b>
                                    </p>
                                </li>
                                <li>
                                    <p>F</p>
                                    <p>
                                        <b>050-8090-1026</b>
                                    </p>
                                </li>
                                <li>
                                    <p>E</p>
                                    <p>
                                        <b>contact@antock.com</b>
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <Width100 as="li">
                            <Width50 as="span">
                                <p>본사 주소</p>
                                <p>
                                    <b>
                                        서울특별시 성동구 뚝섬로17가길 48, 성수에이원 지식산업센터 906호 (04785)
                                    </b>
                                </p>
                            </Width50>
                            <Width50 as="span">
                                <p>기술연구소 R&D 주소</p>
                                <p>
                                    <b>
                                        서울특별시 영등포구 의사당대로83, 오투타워 서울핀테크랩 6층 108호 (07325)
                                    </b>
                                </p>
                            </Width50>
                        </Width100>
                    </CustomFooterInfoList>
                </CustomWidth100>
            </Wrapper>
        </MFooterWrapper>
    );
}

export default MobileFooter;