import React from "react";
import styled from "styled-components";
import { Width100 } from "style/layout";

const CustomWidth100 = styled(Width100)`
	align-items: center;
	justify-content: flex-start;
	img {
		width: 90%;
		margin: 0 5%;
	}
`;

const Contents = () => {
	return (
		<CustomWidth100>
			<img src="/img/landing02_01.png" alt="도표" />
		</CustomWidth100>
	);
};

export default Contents;
