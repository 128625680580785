import React from 'react';
import { animated, useSpring } from "react-spring";

const cx = ["27", "116", "205", "294"];

const Circle = ({ index, init }) => {

    const animationFillStyle = useSpring({
        from: { fillColor: "#b8b8c6" },
        to: { fillColor: "#1816c9" },
        config: { duration: 1000 },
        delay: 2000,
        reset: init,
    });


    return (
        <>
            <animated.circle cx={cx[index]} cy="140" r="20" fill={animationFillStyle.fillColor} />
        </>
    );
};

export default Circle;