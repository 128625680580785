import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { Wrapper, Logo } from "style/layout";
import { useScroll } from "hooks/CustomHooks";

import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { setCategory, setSearchKeyword, setOffset } from "./modules/news";

import { useNavigate } from "react-router-dom";

const CustomHeader = styled.header`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 75px;
  z-index: 20;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  color: #fff;
  ${(props) => {
    if(props.device === "mobile"){
        switch (props.page) {
            case "pr":
            case "company":
            case "landing":
            case "hubble":
            case "mri":
            case "newsdetail":
            case "dvai":
            case "dvdata":
                return css`
                    backdrop-filter: blur(0.25rem);
                    background-color: rgba(255, 255, 255, 0.45);
                    h1 {
                      background-image: url("/img/antock_logo_b.png");
                    }
                    li {
                      a {
                        color: ${(props) => props.theme.colors.pointColor};
                      }
                    }
                  `;
        }
    }else{
        switch (props.page) {
            case "dvai":
            case "dvdata":
                switch (props.section) {
                    case 1:
                        return css`
                            li {
                              a {
                                &::before {
                                  background-color: #fff;
                                }
                              }
                            }
                          `;
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        return css`
                          backdrop-filter: blur(0.25rem);
                          background-color: rgba(255, 255, 255, 0.7);
                          h1 {
                            background-image: url("/img/antock_logo_b.png");
                          }
                          li {
                            a {
                              color: ${(props) => props.theme.colors.pointColor};
                            }
                          }
                    `;
                }
                break;
            case "pr":
                if (props.isOffset) {
                    return css`
            backdrop-filter: blur(0.25rem);
            background-color: rgba(255, 255, 255, 0.7);
            h1 {
              background-image: url("/img/antock_logo_b.png");
            }
            li {
              a {
                color: ${(props) => props.theme.colors.pointColor};
              }
            }
          `;
                } else {
                    return css`
            h1 {
              background-image: url("/img/antock_logo_b.png");
            }
            li {
              a {
                color: ${(props) => props.theme.colors.pointColor};
              }
            }
          `;
                }
                break;
            case "newsdetail":
                return css`
          backdrop-filter: blur(0.25rem);
          background-color: rgba(255, 255, 255, 0.7);
          h1 {
            background-image: url("/img/antock_logo_b.png");
          }
          li {
            a {
              color: ${(props) => props.theme.colors.pointColor};
            }
          }
        `;
                break;
            case "company":
                switch (props.section) {
                    case 1:
                        return css`
              li {
                a {
                  &::before {
                    background-color: #fff;
                  }
                }
              }
            `;
                    case 2:
                    case 3:
                    case 4:
                        return css`
              backdrop-filter: blur(0.05rem);
              background-color: rgba(255, 255, 255, 0.25);
              h1 {
                background-image: url("/img/antock_logo_b.png");
              }
              li {
                a {
                  color: ${(props) => props.theme.colors.pointColor};
                }
              }
            `;
                }
                break;
            case "landing":
                switch (props.section) {
                    case 1:
                        return null;
                    case 2:
                        return css`
              backdrop-filter: blur(0.25rem);
              background-color: rgba(27, 26, 163, 0.35);
            `;
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        return css`
              backdrop-filter: blur(0.25rem);
              background-color: rgba(255, 255, 255, 0.7);
              h1 {
                background-image: url("/img/antock_logo_b.png");
              }
              li {
                a {
                  color: ${(props) => props.theme.colors.pointColor};
                }
              }
            `;
                }
                break;
            case "hubble":
                switch (props.section) {
                    case 1:
                        return css`
              li {
                a {
                  &::before {
                    background-color: #fff;
                  }
                }
              }
            `;
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        return css`
              backdrop-filter: blur(0.25rem);
              background-color: rgba(255, 255, 255, 0.7);
              h1 {
                background-image: url("/img/antock_logo_b.png");
              }
              li {
                a {
                  color: ${(props) => props.theme.colors.pointColor};
                }
              }
            `;
                }
                break;
            case "mri":
                switch (props.section) {
                    case 1:
                        return css`
                          li {
                            a {
                              &::before {
                                background-color: #fff;
                              }
                            }
                          }
                        `;
                    case 2:
                    case 3:
                    case 4:
                        return css`
                          backdrop-filter: blur(0.25rem);
                          background-color: rgba(255, 255, 255, 0.7);
                          h1 {
                            background-image: url("/img/antock_logo_b.png");
                          }
                          li {
                            a {
                              color: ${(props) => props.theme.colors.pointColor};
                            }
                          }
                    `;
                    case 5:
                        return css`
                          backdrop-filter: blur(0.05rem);
                          background-color: rgba(27, 26, 163, 0.15);
                          li {
                            a {
                              &::before {
                                background-color: #fff;
                              }
                            }
                          }
                        `;
                    case 6:
                        return css`
                          backdrop-filter: blur(0.25rem);
                          background-color: rgba(255, 255, 255, 0.7);
                          h1 {
                            background-image: url("/img/antock_logo_b.png");
                          }
                          li {
                            a {
                              color: ${(props) => props.theme.colors.pointColor};
                            }
                          }
                        `;
                }
                break;
        }
        // 마지막 페이지일때 블러처리
        if (props.section === 7) {
            return css`
        backdrop-filter: blur(0.25rem);
        background-color: rgba(255, 255, 255, 0.7);
        h1 {
          background-image: url("/img/antock_logo_b.png");
        }
        li {
          a {
            color: ${(props) => props.theme.colors.pointColor};
          }
        }
      `;
        }
    }

  }};

  ${props => {
    if(!props.headerVisiable){
        return `
            backdrop-filter: unset;
            background-color: unset;
            background-color: transparent;
        `;
    }
  }};



`;

const HeaderWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: row;
  border: none;
  position:relative;
  .ask-btn{
    position:absolute;
    height:${props => props.theme.sizes.headerHeight};
    right: calc((6% + 0.9rem) * -1);
    width: calc(6% + 0.95rem);
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    cursor: pointer;
    ${props => {
        if(props.page === "dvdata" || props.page === "dvai"){
            return `
                background:linear-gradient(90deg, rgba(32, 14, 208, 0.8), rgba(137, 0, 255, 0.8));
                background-position:0% 0%;
                background-size: 400% 400%;
                animation: AnimationName 7s ease infinite;
            `;
        }
    }}
    &::before{
        content:"문의하기";
        position:absolute;
        color:${props=>props.theme.colors.pointColor};
        background-image:url("/img/speach.png");
        background-size:100% 100%;
        background-position:center center;
        background-repeat:no-repeat;
        font-weight:600;
        top: 40%;
        left: -5rem;
        width:7rem;
        height:2.7rem;
        text-align:center;
        opacity:0;
        vertical-align: middle;
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        box-sizing: border-box;
        padding-right: 0.8rem;
        padding-bottom: 0.2rem;
        transition: all 0.3s ease-in-out ;
    }
    img{
        width:30px;
        ${props => props.theme.device.tabletL}{
            width: 20px;
            height: 20px;
        }
    }
    
        &:hover{
            &::before{
                opacity:1;
                display:flex;
            }
        }
  }
  
  @keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
`;

const HeaderNav = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  margin-left: ${(props) => props.theme.Rem("35")};
  width: 100%;
  li {
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
    font-weight: 300;
    flex-direction: column;
    position: relative;
    a {
      display: flex;
      padding: 0 ${(props) => props.theme.Rem("35")};
      height: 100%;
      vertical-align: middle;
      align-items: center;
      font-size: 1rem;
    }
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    justify-content: flex-start;
  }
  ${props => {
    if(!props.headerVisiable){
        return `
            visibility: hidden;
        `;
    }
  }};

`;

const PrSubMenuWrapper = styled.div`
  display: block;
  height: 2.5rem;
  width: 100%;
  background-color: ${(props) => props.theme.colors.pointColor};
  transition: all 0.3s ease-in-out;
  ${(props) => {
    if (
        (props.page === "pr" && props.isOffset) ||
        props.page === "newsdetail"
    ) {
        return css`
        display: block;
      `;
    } else if (props.device !== "desktop" || props.device !== "tablet") {
        return css`
        display: none;
      `;
    } else if (!props.isOffset) {
        return css`
        display: none;
      `;
    }
}}
`;

const HeaderNavItem = styled.li`
  ${(props) => {
    if (
        props.value === props.page ||
        (props.page === "dvdata" && props.value === "dvai") ||
        (props.page === "newsdetail" && props.value === "pr")
    ) {
        return css`
        a {
          font-weight: 800;
          position: relative;
          &::before {
            content: "";
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 0.5rem;
            background-color: ${(props) => props.theme.colors.pointColor};
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: 0.9rem;
          }
        }
        
      `;
    }
}}
    a{
       transition:all 0.3s ease-in-out;
       font-weight:300;
       box-sizing:border-box;
    }
    &:hover{
        a{
            font-weight:800;
            box-sizing:border-box;
        }
    }
    &.dv{
        margin-left:auto;   
    }
`;
const PrSubMenuList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 80vw;
  position: absolute;
  top: ${(props) => props.theme.sizes.headerHeight};
  left: 0;
  align-items: center;
  justify-contents: center;
  height: 2.5rem;
  li {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 1rem;
    input {
      background-color: rgba(255, 255, 255, 0.25);
      height: 40px;
      color: #fff;
      font-weight: 600;
    }
    button {
      // padding-left:1rem;
      height: 40px;
      width: 40px;
      background-color: rgba(255, 255, 255, 0.25);
      box-sizing: border-box;
      svg {
        vertical-align: middle;
      }
    }
  }
  ${props => props.theme.device.notebook} {
    left: auto;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    justify-contents: flex-start;
    left:-100%;
  }
`;

const PrSubMenuItem = styled.li`
  ${(props) => {
    if (props.category === props.value) {
        return css`
        font-weight: 900 !important;
      `;
    }
}}
`;

const Header = () => {

    const scroll = useScroll();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const inputRef = useRef(null);

    const [isOffset, setIsOffset] = useState(false);

    const [search, setSearch] = useState();
    const [inputFocus, setInputFocus] = useState(false);

    useEffect(() => {
        const offset = scroll.scrollY;
        offset > 0 ? setIsOffset(true) : setIsOffset(false);
    }, [scroll]);

    const { currentSection, currentPage, category, searchKeyword, device, headerVisiable } = useSelector(
        (state) => ({
            currentSection: state.page.currentSection,
            currentPage: state.page.currentPage,
            category: state.news.category,
            searchKeyword: state.news.searchKeyword,
            device: state.style.device,
            headerVisiable: state.style.headerVisiable,
        })
    );

    useEffect(() => {
        const SDK_KEY = process.env.REACT_APP_KAKAO_SDK_KEY;
        
        if (!window.Kakao.isInitialized()){
            window.Kakao.init(SDK_KEY);
        }
    } ,[]);


    useEffect(() => {
        if(searchKeyword && inputRef.current){
            inputRef.current.value = searchKeyword;
        }
    } ,[currentPage, isOffset]);


    const handleNavClick = (newCategory) => {
        inputRef.current.value = '';
        setSearch(null);
        dispatch(setSearchKeyword(null));
        dispatch(setCategory(newCategory));
        dispatch(setOffset(0));
        moveToPrPageWithParams(newCategory, "");
    };

    const handleKeywordChange = (e) => {
        setSearch(e.target.value);
        setInputFocus(true);
        if (e.target.value.length > 0) setInputFocus(true);
    };

    const handleSearchSubmit = () => {
        if (search) {
            dispatch(setSearchKeyword(search));
            moveToPrPageWithParams(category, search);
        } else {
            alert("검색어를 입력해주세요");
        }
    };

    const handleKeyPress = (e) => {
        if (e.key == "Enter") {
            if (search) {
                dispatch(setSearchKeyword(search));
                moveToPrPageWithParams(category, search);
            } else {
                alert("검색어를 입력해주세요");
            }
        }
    };

    const handleOnBlur = () => {
        inputRef.current.value.length > 0
            ? setInputFocus(true)
            : setInputFocus(false);
    };

    const moveToPrPageWithParams = (category, search) => {
        navigate("/pr", {
            state: {
                category: category,
                search: search,
            },
        });
    };

    const handleMoveToPR = () => {
        dispatch(setOffset(0));
        navigate("/pr", {
            state: {
                category: '',
                search: null,
            },
        });
    }

    const PrNavContents = () => {
        return (
            <>
                <PrSubMenuList>
                    <PrSubMenuItem
                        category={category}
                        value={""}
                        onClick={() => handleNavClick("")}
                    >
                        전체보기
                    </PrSubMenuItem>
                    <PrSubMenuItem
                        category={category}
                        value={"NOTICE"}
                        onClick={() => handleNavClick("NOTICE")}
                    >
                        공지사항
                    </PrSubMenuItem>
                    <PrSubMenuItem
                        category={category}
                        value={"FEED"}
                        onClick={() => handleNavClick("FEED")}
                    >
                        회사소식
                    </PrSubMenuItem>
                    <PrSubMenuItem
                        category={category}
                        value={"MEDIA"}
                        onClick={() => handleNavClick("MEDIA")}
                    >
                        언론보도
                    </PrSubMenuItem>
                    <li>
            <span>
              <input
                  ref={inputRef}
                  type="text"
                  onChange={handleKeywordChange}
                  onKeyPress={handleKeyPress}
                  placeholder={"검색어를 입력해주세요."}
                  onFocus={() => {
                      setInputFocus(true);
                  }}
                  onBlur={handleOnBlur}
              />
              <button type={"submit"} onClick={handleSearchSubmit}>
                <SearchIcon />
              </button>
            </span>
                    </li>
                </PrSubMenuList>
            </>
        );
    };

    const handleKakaoButton = () => {
        window.Kakao.Channel.chat({
            channelPublicId: '_wxcprb'
        });
    };

    const DesktopButtonContent = () => {
        let blueBtnDom = <a href="https://forms.gle/QbgWKviYSLZyVhwj8" className="ask-btn" target="_blank"><img src="/img/ask.png" alt="문의하기"/></a>;
        let whiteBtnDom = <a href="https://forms.gle/QbgWKviYSLZyVhwj8" className="ask-btn" target="_blank"><img src="/img/ask_w.png" alt="문의하기"/></a>;
        let kakaoBlueBtnDom = <a href onClick={handleKakaoButton} className="ask-btn" target="_blank"><img src="/img/ask.png" alt="문의하기"/></a>;
        let kakaoWhiteBtnDom = <a href onClick={handleKakaoButton} className="ask-btn" target="_blank"><img src="/img/ask_w.png" alt="문의하기"/></a>;
        if (currentPage === "dvdata" || currentPage === "dvai") {
            if (currentSection > 1) {
                //return blueBtnDom;
                return whiteBtnDom;
            }else{
                return whiteBtnDom;
            };
        } else if (currentPage === "pr"){
            return kakaoBlueBtnDom;
        }else if (currentPage === "landing"){
            if (currentSection <= 2) {
                return kakaoWhiteBtnDom;
            }else{
                return kakaoBlueBtnDom;
            }
        }else if (currentPage === "mri") {
            if (currentSection === 5 || currentSection === 1) {
                return kakaoWhiteBtnDom;
            }else{
                return kakaoBlueBtnDom;
            }
        }else {
            if (currentSection > 1) {
                return kakaoBlueBtnDom;
            }else{
                return kakaoWhiteBtnDom;
            };
        };
    };

    const MobileButtonContent = () => {
        if (currentPage === "dvdata" || currentPage === "dvai") {
            return <a href="https://forms.gle/QbgWKviYSLZyVhwj8" className="ask-btn" target="_blank"><img src="/img/ask.png" alt="문의하기"/></a>;
        } else {
            return <a href onClick={handleKakaoButton} className="ask-btn" target="_blank"><img src="/img/ask.png" alt="문의하기"/></a>;
        };
    };

    return (
        <CustomHeader
            page={currentPage}
            section={currentSection}
            isOffset={isOffset}
            device={device}
            headerVisiable={headerVisiable}
        >
            <HeaderWrapper page={currentPage}>
                <Logo>
                    <a href="/">Antock</a>
                </Logo>
                <HeaderNav headerVisiable={headerVisiable}>
                    <HeaderNavItem page={currentPage} value="hubble">
                        <a href="/hubble">Hubble Database</a>
                    </HeaderNavItem>
                    <HeaderNavItem page={currentPage} value="mri">
                        <a href="/mri">antock MRI</a>
                    </HeaderNavItem>
                    <HeaderNavItem page={currentPage} value="company">
                        <a href="/company">Company</a>
                    </HeaderNavItem>
                    <HeaderNavItem page={currentPage} value="pr">
                        <a href="/pr" onClick={handleMoveToPR}>PR</a>
                        {(currentPage === "pr" && isOffset) || currentPage === "newsdetail"
                            ? PrNavContents()
                            : null}
                    </HeaderNavItem>
                    <HeaderNavItem page={currentPage} value="dvai" className="dv">
                        <a href="/dv/ai">데이터바우처 지원사업</a>
                    </HeaderNavItem>
                </HeaderNav>
                {device === "mobile" ? MobileButtonContent() : DesktopButtonContent()}
            </HeaderWrapper>

            <PrSubMenuWrapper
                isOffset={isOffset}
                page={currentPage}
                device={device}
            />
        </CustomHeader>
    );
};

export default Header;
