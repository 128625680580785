import React from "react";
import styled from "styled-components";

import { PageWrapper, Wrapper, Width100, Width45, Width55 } from "style/layout";
import Nav from "./landing/main/Nav";
import Title from "./landing/main/Title";

import '../layout.scss';

const CustomSectionWrapper = styled.div`
	background-image: url("/img/landing-main.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background-attachment: fixed;
	height: 100%;
`;

const CustomWrapper = styled(Wrapper)`
	// border:0.5px solid $border-color;
	// border-width:0 0.5px;
	border: 0.5px solid ${(props) => props.theme.colors.borderColor};
	border-width: 0 0.5px;
	
	${props => props.theme.device.tabletL} {
		
	}
`;

const CustomWidth100 = styled(Width100)`
	height: 100%;
	// ${props => props.theme.device.tabletL} {
    // 	.page-info{
    // 		display:none;
    // 	}
    // 	.border-left{
    // 		width:100%;
	// 		height: 100%;
    // 		flex-direction:column;
    // 		justify-content:center;
    // 		align-items:center;
    // 		border-right:1px solid ${props=>props.theme.colors.borderColor};
    // 	}
  	// }
  	
	// ${props => props.theme.device.tablet} {
	// 	.border-left{
	// 		min-height:100vh;
	// 	}	
	// }
`;

const MainSection = () => {
	return (
		<CustomSectionWrapper>
			<PageWrapper>
				<CustomWrapper>
					<CustomWidth100>
						<Width45 className="page-info">
							<Nav/>
						</Width45>

						<Width55 className="border-left">
							<Title/>
						</Width55>

					</CustomWidth100>
				</CustomWrapper>
			</PageWrapper>
		</CustomSectionWrapper>
	);
};

export default MainSection;
