
import React, { useEffect } from 'react';
import styled from "styled-components";

const PrItemWrapper = styled.div`
    box-sizing:border-box;
    background-color:#fff;
    width:90%;
    margin:5vw auto;
    border-radius:1.5rem;
    display:flex;
    flex-direction:column;
    overflow:hidden;
    box-shadow:0px 5px 15px rgba(0,0,0,0.2);
    isolation: isolate;
    img{
        width:100%;
        height: 12rem;
        ${props => props.theme.device.mobile}{
            height: 14rem;
        }
    }
    .contents{
       box-sizing:border-box;
       display:flex;
       flex-direction:column;
       padding:1.5rem;
       p{
        font-size:0.9rem;
        display:flex;
        flex-direction:row;
        flex-wrap:no-wrap;
        justify-content:space-between;
        margin-bottom:0.9rem;
        span{
             font-weight:400;
            &:first-of-type{
                font-weight:800; 
            }
        }
       }
       b{
        font-weight:800;
        font-size:1.15rem;
        line-height:1.5rem;
        word-break:keep-all;
       }
    }
    @media (min-width: ${props => props.theme.deviceSizes.tablet}){
        width:calc(57.5% - (5% * 2));
        margin:0 5% 5vw 0;
        &:nth-of-type(2n){
            margin:0 0 5vw 0;
        }
    }
`;
const PrListWrapper = styled.div`
    @media (min-width: ${props => props.theme.deviceSizes.tablet}){
        width:90%;
        display:flex;
        flex-wrap:wrap;
        flex-direction:row;
        justify-content:flex-start;
        margin:0 auto;
    }
`;
const CategoryFiltering = (string) => {
    switch (string) {
        case "MEDIA":
            return "언론보도";

        case "FEED":
            return "회사소식";

        case "NOTICE":
            return "공지사항";

        default:
            return "전체";
    }
}

const MobileNewsItems = ({ currentItems, onHandleItemClick }) => {

    const handleItemClick = (no) => {
        onHandleItemClick(no);
    }
    let date = "";
    return (
        <PrListWrapper>
            {currentItems && currentItems.map((item, index) => (
                <PrItemWrapper key={index} onClick={() => handleItemClick(item.no)}>
                    <img src={item.thumbUrl} alt="썸네일" />
                    <div className="contents">
                        <p>
                            <span>{CategoryFiltering(item.category)}</span>
                            <span>
                                {
                                    item.displayDate && item.displayDate.split("-").map(( item, idx )=>{
                                        switch( idx ){
                                            case 0:
                                            date = item + "년 ";
                                            break;
                                            case 1:
                                            date += item + "월 ";
                                            break;
                                            case 2:
                                            date += item + "일 ";
                                            return <span key={date}>{date}</span>;
                                        }
                                    })
                                }
                            </span>
                        </p>
                        <b>{item.title}</b>
                    </div>
                </PrItemWrapper>
            ))}
        </PrListWrapper>
    );
};

export default MobileNewsItems;