import React from 'react';
import { animated, useSpring } from "react-spring";

const transform = ["translate(6 60)", "translate(94 60)", "translate(183 60)", "translate(272 60)"];

const FirstText = ({ index, textData, init }) => {


	const animationFillStyle = useSpring({
		from: { fillColor: "#b8b8c6" },
		to: { fillColor: "#1816c9" },
		config: { duration: 1000 },
		delay: 0,
		reset: init,
	});

    return (
        <>
        
			<animated.text
				id="상장기업"
				transform={transform[index]}
				font-size="14"
				font-family="MalgunGothic, Malgun Gothic"
                fill={animationFillStyle.fillColor}
			>
				<tspan x="0" y="0">
					{textData}
				</tspan>
			</animated.text>
        </>
    );
};

export default FirstText;