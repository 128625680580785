export const isExplorer = () => {
	const browse = navigator.userAgent.toLowerCase();

	if (
		(navigator.appName == "Netscape" && browse.indexOf("trident") != -1) ||
		browse.indexOf("msie") != -1
	) {
		return true;
	} else {
		return false;
	}
};

export const  isLandscape = () => {
	if (window.matchMedia("(orientation: landscape)").matches) {
		return true;
	}else{
		return false;
	}
}

export const percentValue = () => {
	const company = process.env.REACT_APP_COMPANY_COUNT;
	const corporate = process.env.REACT_APP_COPERATION_COUNT;

	const isCalc = !!company && !!corporate && company > 0 && corporate > 0;
	const val = company / (corporate * 0.01);
	if(isCalc){
		return val.toFixed(0);
	}else{
		return;
	}
}

export const addNumberComma = (number) =>{
	return Number(number).toLocaleString();
}