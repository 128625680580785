const SET_CATEGORY = "news/SET_CATEGORY";
const SET_SEARCH_KEYWORD = "news/SET_SEARCH_KEYWORD";
const SET_OFFSET = "news/SET_OFFSET";

export const setCategory = (category) => ({ type: SET_CATEGORY, category });
export const setSearchKeyword = (searchKeyword) => ({ type: SET_SEARCH_KEYWORD, searchKeyword });
export const setOffset = (offSet) => ({ type: SET_OFFSET, offSet });

const initialState = {
  category: "",
  searchKeyword: null,
  offSet: 0,
};

export default function news(state = initialState, action) {
  switch (action.type) {
    case SET_CATEGORY:
      return {
        ...state,
        category: action.category,
      };
    case SET_SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: action.searchKeyword,
      };
    case SET_OFFSET:
      return {
        ...state,
        offSet: action.offSet,
      };
    default:
      return state;
  }
}
