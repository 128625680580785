

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setOffset } from "modules/news";

import Items from "components/pr/Items";
// import MobileNewsItems from "./MobileNewsItems";
import MobileNewsItems from "./MobileNewsItems";

const ItemList = styled.div`
  display: flex;
  margin: 0 auto;
  width: 90%;
  height: auto;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const PagenationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const MyPaginate = styled(ReactPaginate).attrs({
    // You can redifine classes here, if you want.
    activeClassName: "active", // default to "disabled"
})`
  margin-top: 2rem 0 2rem 0;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  justify-content: center;
  list-style-type: none;
  padding: 0 5rem;
  li {
    margin: 0 0.3rem 0 0.3rem;
  }
  li a {
    border-radius: 7px;
    padding: 0.1rem 0.3rem;
    // border: gray 1px solid;
    cursor: pointer;
    color: #808091;
    height: 100%;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: ${(props) => props.theme.Rem(18)};
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.previous a {
    svg {
      transform: rotate(-180deg);
    }
  }
  li.active a {
    border-color: transparent;
    color: #1210c9;
    font-weight: 900;
  }
  li.disabled a {
    // color: #808091;
    // font-weight: 800;
    // display: hidden;
    visibility: hidden
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

const CategoryFiltering = (string) => {
    switch (string) {
        case "MEDIA":
            return "언론보도";

        case "FEED":
            return "회사소식";

        case "NOTICE":
            return "공지사항";

        default:
            return "전체";
    }
};

const SearchKeywordStyle = styled.div`
  width: 90%;
  margin: 0 auto;
  font-size: ${(props) => props.theme.Rem(28)};
  margin-top: ${(props) => props.theme.Rem(60)};
  margin-bottom: ${(props) => props.theme.Rem(30)};
  strong {
    color: ${(props) => props.theme.colors.pointColor};
    font-weight: 800;
  }
`;


const MobileNewsList = ({ itemsPerPage, newsListData }) => {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const pagenationRef = useRef();

    const { searchKeyword, category, offSet } = useSelector((state) => ({
        searchKeyword: state.news.searchKeyword,
        category: state.news.category,
        offSet: state.news.offSet,
    }));

    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [selectNo, setSelectNo] = useState();

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(newsListData.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(newsListData.length / itemsPerPage));
    }, [newsListData, itemOffset, itemsPerPage]);

    // 0일때, 맨끝?

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        window.scrollTo(0, 0);
        const newOffset = (event.selected * itemsPerPage) % newsListData.length;
        setItemOffset(newOffset);

        dispatch(setOffset(newOffset));
    };

    useEffect(() => {
        if (offSet) {
            setItemOffset(offSet);
            if (pagenationRef && currentItems) {
                pagenationRef.current.state.selected = offSet / itemsPerPage;
            }

        }
    }, [offSet, currentItems]);

    const handleItemClick = (no) => {
        setSelectNo(no);
    };

    useEffect(() => {
        if (selectNo) {
            navigate("/pr/" + selectNo, { state: { selectNo: selectNo } });
        }
    }, [selectNo]);

    const SearchKeyword = () => {
        return (
            <SearchKeywordStyle>
                <b>"{CategoryFiltering(category)}"</b>에서 <b>"{searchKeyword}"</b>의<br/>
                검색결과는 <strong>{newsListData.length}</strong>건 입니다.
            </SearchKeywordStyle>
        );
    };

    const NoNewsList = () => {
        return <div>해당 조건에 맞는 뉴스가 없습니다.</div>;
    };

    return (
        <>
            {searchKeyword ? SearchKeyword() : null}

            {newsListData && newsListData.length === 0 ? (
                NoNewsList()
            ) : (
                <>
                    <MobileNewsItems currentItems={currentItems} onHandleItemClick={handleItemClick} />
                </>
            )}

            {currentItems ? (
                <PagenationWrapper>
                    <MyPaginate
                        ref={pagenationRef}
                        breakLabel="..."
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={1}
                        pageCount={pageCount}
                        previousLabel={<ArrowForwardIosIcon />}
                        nextLabel={<ArrowForwardIosIcon />}
                    />
                </PagenationWrapper>
            ) : null}

        </>
    );
};

export default MobileNewsList;