import axios from "axios";
import { encode } from "lib/function";
import qs from "qs";

export const getHistory = async () => {
  const { data } = await axios.get(
    "https://home-api.antock.com/api/company/history",
  );

  return data.item;
};

export const getAwards = async () => {
  const { data } = await axios.get(
    "https://home-api.antock.com/api/company/awards",
  );

  return data.items;
};



export const getNewsList = async (params) => {
  const { data } = await axios.get(
    "https://home-api.antock.com/api/pr", {
        params: {
            category: params.category,
            search: encodeURIComponent(params.search),
        },
        paramsSerializer: (params) => qs.stringify(params, { encode: false })
    }
  );

  return data.items;
};

export const getNewsDetail = async (no) => {
  const { data } = await axios.get(
    "https://home-api.antock.com/api/pr/" + no,
     
  );

  return data.item;
};

