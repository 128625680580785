import React from "react";
import { useQuery } from "react-query";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";


import { getNewsDetail } from "api/api";
import { Container } from "style/layout";

import Footer from "Footer";
import PrNav from "components/pr/PrNav";
import Detail from "components/prdetail/Detail";
import Error from "components/Error";

import { setCurrentPage } from "modules/page";
import DetailLoading from "../components/prdetail/DetailLoading";

const PrDetail = () => {
    
    const dispatch = useDispatch(); 
    const param = useParams();

    const { device, currentPage } = useSelector((state) => ({
        currentPage: state.page.currentPage,
        device: state.style.device,
    }));

    const [selectNo, setSelectNo] = useState();
    const [detailData, setDetailData] = useState();

    const {
        isLoading,
        isError,
        data,
        isFetching,
        refetch: fetchNewsDetail,
    } = useQuery(["getNewsDetail", selectNo], () => getNewsDetail(selectNo), {
        enabled: false,
    });
    
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setCurrentPage("newsdetail"));
    }, []);

    useEffect(() => {
        if(param){
            setSelectNo(param.id);
        }
    }, [param]);

    useEffect(() => {
        if(selectNo){
            fetchNewsDetail();
        }
    } ,[selectNo]);

    useEffect(() => {
        if(data){
            setDetailData(data);
        }
    }, [data]);

    const Contents = () => {
        if (isError) return <Error/>;
        if (isLoading || isFetching) return <DetailLoading/>;
        if (detailData)
            return <Detail detailData={detailData} />;
    };

    return (
        <>
            <Container page={currentPage}>
                <PrNav />
                {Contents()}
                { device === "tablet" || device === "mobile" ? null : <Footer /> }
            </Container>
        </>
    );
};

export default React.memo(PrDetail);
