import React from "react";
import styled from "styled-components";
import {
	Width100,
	Width33,
	textBoxTitle,
	textBoxExplain,
	textBoxContents,
} from "style/layout";

import "../../../layout.scss";

const CustomTextBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding-top: 17vh;
	box-sizing: border-box;
	height: calc(100% - 25%);
	width: 100%;
	strong {
		color: ${(props) => props.theme.colors.pointColor};
		font-size: ${(props) => props.theme.Rem(24)};
		font-weight: 900;
		display: flex;
		flex-direction: column;
		&::before {
			content: "";
			display: block;
			width: 3px;
			height: 3.75rem;
			background-image: linear-gradient(
				#1210c9 33%,
				rgba(255, 255, 255, 0) 0%
			);
			background-position: right;
			background-size: 3px 8px;
			background-repeat: repeat-y;
			margin-left: 35%;
			margin-bottom: ${(props) => props.theme.Rem(20)};
		}
	}
	${props => props.theme.device.desktopS}{
		padding-top: 50%;
	}
`;

const CustomTitle = styled.h3`
	${textBoxTitle}
`;

const CustomExplain = styled.p`
	${textBoxExplain}
	font-weight:400;
	font-size: ${(props) => props.theme.Rem(20)};
	margin-top: ${(props) => props.theme.Rem(16)};
	margin-bottom: ${(props) => props.theme.Rem(78)};
`;

const CustomContents = styled.p`
	${textBoxContents}
	width: 100%;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	margin-bottom: ${(props) => props.theme.Rem(25)};
	padding-right: 0.5rem;
	box-sizing: border-box;
`;

const Contents = () => {
	return (
		<Width100>
			<Width33 className="border-left">
				<CustomTextBox>
					<CustomTitle>Integrated</CustomTitle>
					<CustomExplain>통합적 분석</CustomExplain>
					<CustomContents>
						비 금융 대안 데이터를 AI 분석에 집중 활용함으로써, 기존
						재무 정보로는 가능하지 않았던 기업 속성, 상품, 기술,
						가치, 조직 등의 영역에서 창의적 분석을 지원합니다.
					</CustomContents>
					<strong>차별성</strong>
				</CustomTextBox>
			</Width33>
			<Width33 className="border-left">
				<CustomTextBox>
					<CustomTitle>Targeted</CustomTitle>
					<CustomExplain>맞춤화 진단</CustomExplain>
					<CustomContents>
						고객의 기업 분석 목표와 속성을 체계적으로 파악하여, AI
						기반으로 최적의 분석 모형을 제안하고 필요 데이터를
						공급함으로써,정교한 결과 산출을 유도합니다.
					</CustomContents>
					<strong>정교성</strong>
				</CustomTextBox>
			</Width33>
			<Width33 className="border-left">
				<CustomTextBox>
					<CustomTitle>Automated</CustomTitle>
					<CustomExplain>자동화 연산</CustomExplain>
					<CustomContents>
						데이터 수집, AI 학습용 데이터 가공, AI 분석과 관련된
						모든 일련의 프로세스가 시스템 기반의 자동화로 진행되어
						인적 개입으로 인한 오염을 최소화하고 작업 시간을
						혁신적으로 단축합니다.
					</CustomContents>
					<strong>효율성</strong>
				</CustomTextBox>
			</Width33>
		</Width100>
	);
};

export default Contents;
