import React, { useEffect } from "react";
import styled from "styled-components";

import { PageWrapper, Wrapper, Width100, Width45, Width55 } from "style/layout";

import Info from "components/Info";
import Contents from "./second/Contents";
import LadderBox from "./second/LadderBox";
import PlainBackground from "./second/shapes/PlainBackground";

import { useSelector } from "react-redux";

import "../../layout.scss";

const CustomSectionWrapper = styled.div`
	background-color: #e6e6e6;
`;

const CustomWidth100 = styled(Width100)`
	height: 100%;
`;

const CustomWidth55 = styled(Width55)`
	border-left-color: ${(props) => props.theme.colors.lbgBorderColor};
`;
const infoTextObj = {
	title: `Customized<br />AI Application`,
	content: `인공지능을 통해 분석 목적과 평가 기업 유형에 따른<br />
			맞춤화 된 데이터 공급과 분석 방법론을 적용합니다.`,
};
const SecondSection = () => {

	const { currentSection, isExplorer, device } = useSelector((state) => ({
		currentSection: state.page.currentSection,
        isExplorer: state.style.isExplorer,
        device: state.style.device,
	}));


    const LadderContents = () => {
        if (isExplorer){
            return <Contents />;
        } else {

            if(device === "mobile"){

                return <LadderBox />;

            } else {

                if (currentSection === 3){
                    return <LadderBox />;
                } else {
                    return <PlainBackground />;
                }
            }

        }
    };

	return (
		<>
			<CustomSectionWrapper>
				<PageWrapper>
					<Wrapper>
						<CustomWidth100>

							<Width45 className="page-info">
								<Info primary={false} texts={infoTextObj} />
							</Width45>

							<CustomWidth55 className="border-left">
								{LadderContents()}
							</CustomWidth55>

						</CustomWidth100>
					</Wrapper>
				</PageWrapper>
			</CustomSectionWrapper>
		</>
	);
};

export default React.memo(SecondSection);
