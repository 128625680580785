// import React from "react";
import styled from "styled-components";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import "../../../layout.scss";

import { useState, useEffect, useRef } from "react";

import { Container } from "style/layout";
import { useInterval } from "hooks/CustomHooks";
import { useSelector } from "react-redux";

const CarouselContainer = styled(Container)`
  overflow: hidden;
`;

const CircleCarousel = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    left:0;
    top:0;
  }
`;

const SlidesInfoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SlidesStyle = styled.div`
  height: 33.33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 20vw;
  box-sizing: border-box;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width:100%;
    padding:0;
    position:relative;
  }
  @media not all and (min-resolution:.001dpcm)
  { @supports (-webkit-appearance:none) {
    padding-left: 0;
  }}
`;

const Slide = styled.div`
  position: absolute;
  padding-left: ${(props) => props.theme.Rem(118)};
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 0;
  display: none;
  width: 70%;
  margin-left: 36%;
  transition: all 2s ease-in-out;
  height:100%;
  
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      // display:flex;
      margin-left:0;
      height:auto;
      top:0;
      bottom:0;
      margin:auto;
      overflow:visible;
      padding-left:${(props) => props.theme.Rem(50)};
  }
  &.prev_active_02 {
    display: flex;
    transform: translate(
      ${(props) => props.theme.Rem(-200)},
      ${(props) => props.theme.Rem(-640)}
    );
    filter: blur(0.19rem);
    opacity: 0.5;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      transform: translate(-12.5rem, -40rem);
    }
  }
  &.prev_active_01 {
    display: flex;
    transform: translate(
      ${(props) => props.theme.Rem(-150)},
      ${(props) => props.theme.Rem(-440)}
    );
    ${props => props.theme.device.tabletL}{
        transform: translate(
            ${(props) => props.theme.Rem(-150)},
            ${(props) => props.theme.Rem(-370)}
            );
    }
    filter: blur(0.15rem);
    opacity: 0.8;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      transform : translate(-9.375rem, -27.5rem);
    }
  }
  &.prev_active_00 {
    display: flex;
    transform: translate(
      ${(props) => props.theme.Rem(-50)},
      ${(props) => props.theme.Rem(-250)}
    );
    ${props => props.theme.device.tabletL}{
        transform: translate(
            ${(props) => props.theme.Rem(-30)},
            ${(props) => props.theme.Rem(-190)}
            );
    }
    filter: blur(0.09rem);
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      transform : translate(-3.125rem, -15.625rem);
    }
  }
  &.active {
    z-index: 1;
    display: flex;
  }
  &.next_active_00 {
    display: flex;
    transform: translate(
      ${(props) => props.theme.Rem(-50)},
      ${(props) => props.theme.Rem(250)}
    );
    ${props => props.theme.device.tabletL}{
        transform: translate(
            ${(props) => props.theme.Rem(-30)},
            ${(props) => props.theme.Rem(190)}
            );
    }
    filter: blur(0.09rem);
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      transform : translate(-3.125rem, 15.625rem);
    }
  }
  &.next_active_01 {
    display: flex;
    transform: translate(
      ${(props) => props.theme.Rem(-150)},
      ${(props) => props.theme.Rem(440)}
    );
    ${props => props.theme.device.tabletL}{
        transform: translate(
            ${(props) => props.theme.Rem(-150)},
            ${(props) => props.theme.Rem(370)}
            );
    }
    filter: blur(0.15rem);
    opacity: 0.8;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      transform : translate(-9.375rem, 27.5rem);
    }
  }
  &.next_active_02 {
    display: flex;
    transform: translate(
      ${(props) => props.theme.Rem(-200)},
      ${(props) => props.theme.Rem(640)}
    );
    filter: blur(0.19rem);
    opacity: 0.5;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      transform: translate(-12.5rem, 40rem);
    }
  }
  h4 {
    font-size: ${(props) => props.theme.Rem(30)};
    font-weight: 800;
    margin-bottom: ${(props) => props.theme.Rem(20)};
  }
  p {
    font-size: ${(props) => props.theme.Rem(16)};
    font-weight: 400;
    line-height: ${(props) => props.theme.Rem(26)};
    ${props => props.theme.device.tabletL}{
        font-size: ${(props) => props.theme.Rem(14)};
        line-height: ${(props) => props.theme.Rem(24)};
    }
  }
`;

const Pagination = styled.div`
  position: absolute;
  top: 50%;
  left: -10%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  transition-property: transform;
  transition-timing-function: ease-out;
  pointer-events: none;
  user-select: none;
`;

const Dot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => props.theme.Rem(200)};
  height: ${(props) => props.theme.Rem(200)};
  ${props => props.theme.device.tabletL}{
    width: ${(props) => props.theme.Rem(165)};
    height: ${(props) => props.theme.Rem(165)};
  }
  border-radius: 50%;
  pointer-events: auto;
  transition: 0.8s;
  background-color: ${(props) => props.theme.colors.pointColor};
  filter: blur(1rem);
  opacity: 0.6;
  &:hover {
    transform: translate(-50%, -50%) scale(1.05);
    cursor: pointer;
    filter: none;
  }
`;

const Item = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;

  &.active {
    ${Dot} {
      color: black;
      filter: none;
      opacity: 1;
    }
  }
  &.active_00 {
    ${Dot} {
      color: black;
      filter: blur(0.5rem);
      opacity: 0.8;
    }
  }
`;

const Prev = styled.button`
  z-index: 2;
  padding-left: calc(14vw + 22.3%);
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: 5%;
  span {
    font-size: ${(props) => props.theme.Rem(50)};
  }
  align-items: flex-end;
  &:hover {
    cursor: pointer;
  }
`;

const Next = styled.button`
  z-index: 2;
  padding-left: calc(14vw + 22.3%);
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: 5%;
  span {
    font-size: ${(props) => props.theme.Rem(50)};
  }
  align-items: flex-start;
  &:hover {
    cursor: pointer;
  }
  transform: rotate(-180deg);
`;

const Carousel = () => {
  const { currentSection, device } = useSelector((state) => ({
    currentSection: state.page.currentSection,
    device: state.style.device,
  }));

  const slidesRef = useRef(null);
  const paginationRef = useRef(null);
  const carouselRef = useRef(null);

  const pagTransf = "translate(-50%,-50%)";
  const speed = 2000;

  const PagenationMap = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  const [isInit, setIsInit] = useState(false);
  const [count, setCount] = useState(0);

  const [slidesN, setSlidesN] = useState();
  const [pagination, setPagination] = useState();
  const [dots, setDots] = useState();
  const [dotsN, setDotsN] = useState();
  const [step, setStep] = useState(350);
  const [angle, setAngle] = useState(90);
  const [autoplay, setAutoplay] = useState(true);

  const [activeN, setActiveN] = useState(0);
  const [prevN, setPrevN] = useState();

  useInterval(() => {
    if (autoplay) {
      setSlide(activeN + 1);
    }
  }, 3000);

  useEffect(() => {
    setSlidesN(slidesRef.current.children.length);
    setPagination(paginationRef.current);
    setDots(paginationRef.current.children);
    setDotsN(paginationRef.current.children.length);

    setStep(-360 / paginationRef.current.children.length);
    setAutoplay(false);

    setIsInit(true);

    window.addEventListener(
      "blur",
      function () {
        setAutoplay(false);
      },
      false
    );

    return () =>
      window.removeEventListener(
        "blur",
        function () {
          setAutoplay(false);
        },
        false
      );
  }, []);

  useEffect(() => {
    if (device !== "mobile") {
      currentSection === 3 ? setAutoplay(true) : setAutoplay(false);
    } else {
      setAutoplay(true);
    }
  }, [device, currentSection]);

  useEffect(() => {
    if (currentSection === 3) {
      setAutoplay(true);
    } else {
      setAutoplay(false);
    }
  }, [currentSection]);

  useEffect(() => {
    if (isInit) {
      arrangeDots();
      pagination.style.transitionDuration = speed + "ms";
      pagination.style.transform = pagTransf + " rotate(" + angle + "deg)";

      setSlide(0);
    }
  }, [isInit]);

  // prevN이 바뀐다 -> 새로운 setSlide 실행
  useEffect(() => {
    rotate();
  }, [prevN]);

  // When rotate() finished
  useEffect(() => {
    setTimeout(() => {
      setCount((count) => count + 1);
    }, 5000);
  }, [angle]);

  const setSlide = (targetIndex) => {
    // 상태값 update 차이에 따른 초기값 예외처리
    if (targetIndex === 1 && angle === 90 && autoplay) {
      pagination.style.transform = pagTransf + "rotate(54deg)";
      setAngle(54);
    }

    let prevIndex;
    let sndPrevIndex;
    let thrdPrevIndex;

    let nextIndex;
    let sndNextIndex;
    let thrdNextIndex;

    if (activeN === 0) {
      prevIndex = slidesN - 1;
    } else {
      prevIndex = activeN - 1;
    }

    sndPrevIndex = prevIndex - 1;
    if (sndPrevIndex < 0) {
      sndPrevIndex = slidesN - 1;
    }

    thrdPrevIndex = sndPrevIndex - 1;
    if (thrdPrevIndex < 0) {
      thrdPrevIndex = slidesN - 1;
    }

    if (activeN === slidesN - 1) {
      nextIndex = 0;
    } else {
      nextIndex = activeN + 1;
    }

    sndNextIndex = nextIndex + 1;
    if (sndNextIndex === slidesN) {
      sndNextIndex = 0;
    }

    thrdNextIndex = sndNextIndex + 1;
    if (thrdNextIndex === slidesN) {
      thrdNextIndex = 0;
    }

    // 슬라이더, 닷츠 클래스 초기화
    slidesRef.current.children[thrdPrevIndex].classList.remove(
      "prev_active_02"
    );
    slidesRef.current.children[sndPrevIndex].classList.remove("prev_active_01");
    slidesRef.current.children[prevIndex].classList.remove("prev_active_00");
    slidesRef.current.children[activeN].classList.remove("active");
    slidesRef.current.children[nextIndex].classList.remove("next_active_00");
    slidesRef.current.children[sndNextIndex].classList.remove("next_active_01");
    slidesRef.current.children[thrdNextIndex].classList.remove(
      "next_active_02"
    );

    if (
      paginationRef.current.children[prevIndex] ||
      paginationRef.current.children[nextIndex]
    ) {
      paginationRef.current.children[prevIndex].classList.remove("active_00");
      paginationRef.current.children[nextIndex].classList.remove("active_00");
    }
    if (paginationRef.current.children[activeN])
      paginationRef.current.children[activeN].classList.remove("active");

    // targetIndex 기준으로 앞, 뒤 index 설정
    let activeIndex = targetIndex;
    if (targetIndex < 0) activeIndex = slidesN - 1;
    else if (targetIndex >= slidesN) activeIndex = 0;

    // 인덱싱 관련 상태값 set
    setActiveN(activeIndex);
    setPrevN(activeN);

    if (activeIndex === 0) {
      prevIndex = slidesN - 1;
    } else {
      prevIndex = activeIndex - 1;
    }

    sndPrevIndex = prevIndex - 1;
    if (sndPrevIndex < 0) {
      sndPrevIndex = slidesN - 1;
    }

    thrdPrevIndex = sndPrevIndex - 1;
    if (thrdPrevIndex < 0) {
      thrdPrevIndex = slidesN - 1;
    }

    if (activeIndex === slidesN - 1) {
      nextIndex = 0;
    } else {
      nextIndex = activeIndex + 1;
    }

    sndNextIndex = nextIndex + 1;
    if (sndNextIndex === slidesN) {
      sndNextIndex = 0;
    }

    thrdNextIndex = sndNextIndex + 1;
    if (thrdNextIndex === slidesN) {
      thrdNextIndex = 0;
    }

    // 새로 설정된 index 값 기준으로 슬라이더, 닷츠 클래스 입력
    slidesRef.current.children[thrdPrevIndex].classList.toggle(
      "prev_active_02"
    );
    slidesRef.current.children[sndPrevIndex].classList.toggle("prev_active_01");
    slidesRef.current.children[prevIndex].classList.toggle("prev_active_00");
    slidesRef.current.children[activeIndex].classList.toggle("active");
    slidesRef.current.children[nextIndex].classList.toggle("next_active_00");
    slidesRef.current.children[sndNextIndex].classList.toggle("next_active_01");
    slidesRef.current.children[thrdNextIndex].classList.toggle(
      "next_active_02"
    );

    if (
      paginationRef.current.children[prevIndex] ||
      paginationRef.current.children[nextIndex]
    ) {
      paginationRef.current.children[prevIndex].classList.toggle("active_00");
      paginationRef.current.children[nextIndex].classList.toggle("active_00");
    }
    if (paginationRef.current.children[activeIndex])
      paginationRef.current.children[activeIndex].classList.toggle("active");
  };

  const rotate = () => {
    if (activeN < dotsN) {
      const changeAngle = (function (dots, next, prev, step) {
        let inc,
          half = dots / 2;
        if (prev > dots) prev = dots - 1;
        if (Math.abs((inc = next - prev)) <= half) return step * inc;
        if (Math.abs((inc = next - prev + dots)) <= half) return step * inc;
        if (Math.abs((inc = next - prev - dots)) <= half) return step * inc;
      })(dotsN, activeN, prevN, step);

      const adjustAngle = angle + changeAngle;
      pagination.style.transform =
        pagTransf + " rotate(" + adjustAngle + "deg)";

      setAngle((angle) => angle + changeAngle);
    }
  };

  const arrangeDots = () => {
    for (let i = 0; i < dotsN; i++) {
      dots[i].style.transform = "rotate(" + (360 / dotsN) * i + "deg)";
    }
  };

  const handlePageClick = (index) => {
    if (count > 1) {
      setAutoplay(false);
      setSlide(index);
    }
  };

  const handleMouseEnter = () => {
    if (count > 1) {
      setAutoplay(false);
    }
  };

  const handleMouseLeave = () => {
    if (count > 1) {
      setTimeout(() => {
        setAutoplay(true);
      }, 1000);
    }
  };

  const handlePrevClick = () => {
    if (count > 1) {
      setAutoplay(false);
      setSlide(activeN - 1);
    }
  };

  const handleNextClick = () => {
    if (count > 1) {
      setAutoplay(false);
      setSlide(activeN + 1);
    }
  };

  // const handleCarouselBlur = () => {
  //   console.log('bluh');
  //   setAutoplay(false);
  // }

  return (
    <CarouselContainer
      className="border-left"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      // onBlur={handleCarouselBlur}
    >
      <CircleCarousel>
        <SlidesInfoWrapper>
          <Prev onClick={handlePrevClick}>
            <ExpandLessIcon />
          </Prev>

          <SlidesStyle ref={slidesRef}>
            <Slide>
              <h4>P4P</h4>
              <p>
                대안 데이터에 대한 AI 분석을 통해
                <br />
                기업 경쟁력을 산업 맥락과 유사 기업군에 대한
                <br />
                백분율 관점에서 산출하는 알고리즘
              </p>
            </Slide>

            <Slide>
              <h4>Doppleganger</h4>
              <p>
                AI Classification 기반으로 유사 기업군을
                <br />
                자동 도출하고, 가치 배수 (Multiple)
                <br />역 적용을 통해 기업 가치를 산정
              </p>
            </Slide>
            <Slide>
              <h4>Judgement Day</h4>
              <p>
                과거 상장 신청 기업들에 대한
                <br />
                AI 기반의 속성 분석을 통해
                <br />
                진단 기업의 향후 상장 가능성을 정량화
              </p>
            </Slide>
            <Slide>
              <h4>Impact Count-Up</h4>
              <p>
                기업의 온라인 활동 로그를 자동 조사하여
                <br />
                사회적 가치 (SV) 및 기술 혁신성 (TI) 부문을
                <br />
                점수화하여 도출
              </p>
            </Slide>
            <Slide>
              <h4>Reference Model</h4>
              <p>
                산업 생태계에서 특정 기업의 활동 기록
                <br />
                (수상, 제휴, 선정, 보도, 투자 등) 정보를
                <br />
                데이터화하여 시장 내 영향력 및 관심을 정량화
              </p>
            </Slide>
            <Slide>
              <h4>Victory Road</h4>
              <p>
                성공 기업을 (매출, 투자, 시가총액 등) 기준 별로
                <br />
                유형화하고, 해당 기업 과거 데이터에 대한
                <br />
                AI 분석 통해 현재 유사 초기기업 선별
              </p>
            </Slide>
            <Slide>
              <h4>Tech Scanner</h4>
              <p>
                키워드 라이브러리 (Keyword Library) 및<br />
                기업 텍스트 데이터 교차 연산을 통해
                <br />
                관련 기업 발굴하고 AI 기반 우선순위화
              </p>
            </Slide>
            <Slide>
              <h4>ESG Breakdown</h4>
              <p>
                ESG (Environment, Social, Governance)
                <br />
                혹은 사회적 가치를 부문 별로 유형화하고
                <br />
                개별 영역에 대한 AI 관점의 정량적인 가치 책정
              </p>
            </Slide>
            <Slide>
              <h4>PULSE</h4>
              <p>
                뉴스 데이터에 대한 AI RFM
                <br />
                (Recency, Frequency, Magnitude) 모형을
                <br />
                적용하여 리스크 (부도 / 연체 / 자본잠식) 수준 측정
              </p>
            </Slide>
            <Slide>
              <h4>Screener Tech</h4>
              <p>
                투자자 혹은 투자기관의 온라인 검색 기록을
                <br />
                활용하여 베타 계수 (β) 단위 기준으로
                <br />
                투자 위험 회피 (Risk Aversion) 성향 산출
              </p>
            </Slide>
          </SlidesStyle>

          <Next onClick={handleNextClick}>
            <ExpandLessIcon />
          </Next>
        </SlidesInfoWrapper>

        <Pagination ref={paginationRef}>
          {PagenationMap.map((item, index) => (
            // <Item key={index} onClick={(e) => handlePageClick(index)}>
            <Item key={index}>
              <Dot />
            </Item>
          ))}
        </Pagination>
      </CircleCarousel>
    </CarouselContainer>
  );
};

export default Carousel;
