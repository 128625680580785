import React, { useState, useRef, useEffect } from "react";
import { animated, useSpring } from "react-spring";

const SecondLines = ({ data, init }) => {

    const lineRef = useRef(null);
    const [length, setLength] = useState(null);

    useEffect(() => {
        setLength(lineRef.current.getTotalLength());
    }, [data]);

    const animatedStyle = useSpring({
        to: { length: 0 },
        from: { length },
        delay: 2500, 
        config: { duration: 1000 },
        reset: init,
    });


    return (
        <>
        {/* <path d="M27.5 192.023 C27.5 192.023 44.5 218.584 83.5 218.584C122.5 218.584 211.5 218.584 246.5 218.584C281.5 218.584 294 245.146 294 245.146" stroke="#B8B8C6" stroke-width="0.5" /> */}
        {/* <path d="M117 192.956  C117 192.956 120.209 215.99 147 218.119C173.791 220.248 242.155 217.174 261.374 220.546C280.594 223.918 293.5 244.214 293.5 244.214" stroke="#B8B8C6" stroke-width="0.5" /> */}
        {/* <path d="M205 192.023  C205 192.023 201.319 215.99 174.528 218.119C147.738 220.248 79.3736 217.174 60.1541 220.546C40.9346 223.918 28.0285 244.214 28.0285 244.214" stroke="#B8B8C6" stroke-width="0.5" /> */}
        {/* <path d="M294.5 191.557  C294.5 191.557 280.5 216.326 252 218.585C223.5 220.843 172.373 215.186 153 218.585C133.627 221.984 116.802 244.68 116.802 244.68" stroke="#B8B8C6" stroke-width="0.5" /> */}
        {/* <path d="M27.5 244.68  C27.5 244.68 41.9475 218.225 81 218.216C120.052 218.207 211.759 218.224 246.806 218.216C281.853 218.208 294.5 191.558 294.5 191.558" stroke="#B8B8C6" stroke-width="0.5" /> */}
        {/* <line x1="27.25" y1="192" x2="27.25" y2="245" stroke="#B8B8C6" stroke-width="0.5" /> */}
        {/* <line x1="294.25" y1="192" x2="294.25" y2="245" stroke="#B8B8C6" stroke-width="0.5" /> */}
        {/* <line x1="205.25" y1="192" x2="205.25" y2="245" stroke="#B8B8C6" stroke-width="0.5" /> */}

        {/* <path d="M27.5 192.023C27.5 192.023 46.5 218.585 78 219.051C109.5 219.517 116.643 244.489 116.643 244.489" stroke="#B8B8C6" stroke-width="0.5" /> */}
        {/* <path d="M117 193.888C117 193.888 122.5 218.406 166.093 219.051C209.686 219.696 204.737 244.489 204.737 244.489" stroke="#B8B8C6" stroke-width="0.5" /> */}
        {/* <path d="M294 192.024C294 192.024 287.549 218.406 243.956 219.051C200.362 219.696 205.312 244.489 205.312 244.489" stroke="#B8B8C6" stroke-width="0.5" /> */}
        {/* <path d="M27.4998 243.747C27.4998 243.747 46.8567 218.393 78.3567 217.927C109.857 217.461 117 192.489 117 192.489" stroke="#B8B8C6" stroke-width="0.5" /> */}
        {/* <path d="M117 244.68C117 244.68 135.361 218.393 166.861 217.927C198.361 217.461 205.504 192.49 205.504 192.49" stroke="#B8B8C6" stroke-width="0.5" /> */}
        {/* <path d="M294.089 244.68C294.089 244.68 291.228 218.585 249.5 218.585C207.772 218.585 205 192.023 205 192.023" stroke="#B8B8C6" stroke-width="0.5" /> */}
        {/* <line x1="116.25" y1="192" x2="116.25" y2="245" stroke="#B8B8C6" stroke-width="0.5" /> */}

        <animated.path
                ref={lineRef}
                d={data}
                stroke-width="0.5"
                fill="transparent"
                stroke="#1210c7"
                strokeDashoffset={animatedStyle.length}
				strokeDasharray={length}
        />

        </>
    );
};

export default SecondLines;