import React from "react";
import styled from "styled-components";
import Info from "../Info";

import {
    PageWrapper,
    Wrapper,
    Width100,
    Width60,
    Width40,
    MriPageLogo
} from "style/layout";

import '../../layout.scss';

const CustomSectionWrapper = styled.div`
	position: relative;
`;

const CustomWrapper = styled(Wrapper)`
	border-color: ${props => props.theme.colors.lbgBorderColor};
`;

const CustomWidth100 = styled(Width100)`
	height: 100%;
`;

const PageInfoWidth40 = styled(Width40)`
margin-top:0;
	height: 100%;
	box-sizing: border-box;
	flex-wrap: nowrap;
	align-items: flex-start;
	justify-content:center;
	margin-top: -#{${(props) => props.theme.sizes.pageMarginTop}};
    flex-direction: column;
    border-right:1px solid ${props => props.theme.colors.lbgBorderColor};
    position:relative;
`;

const CustomWidth60 = styled(Width60)`
	padding-top:8.7%;
	box-sizing:border-box;
	flex-direction:column;
	position: relative;
	overflow: hidden;
	padding-top: 8.7%;
    box-sizing: border-box;
    flex-direction: column;
    background-image: url(/img/mri01.png);
    background-position: right bottom;
    background-size: cover;
    background-repeat: no-repeat;
`;

const TitleTextBox = styled.div`
	.title{
		font-size:${(props) => props.theme.Rem(48)};
		font-weight:800;
		margin-bottom:${(props) => props.theme.Rem(36)};
	}
	.contents{
		font-size:${(props) => props.theme.Rem(16)};
		font-weight:400;
		line-height:${(props) => props.theme.Rem(30)};
	}
`;

const IntroduceWrapper = styled.div`
	margin-top:${(props) => props.theme.Rem(84)};
	.text-box{
		&:nth-of-type(2),&:nth-of-type(3){
			box-sizing:border-box;
			padding-top:${(props) => props.theme.Rem(46)};
		}
		.title{
			font-size:${(props) => props.theme.Rem(20)};
			font-weight:800;
			line-height:${(props) => props.theme.Rem(24)};
			margin-bottom:${(props) => props.theme.Rem(25)};
		}
		.contents{
			font-size:${(props) => props.theme.Rem(14)};
			font-weight:400;
			line-height:${(props) => props.theme.Rem(24)};
		}
	}
	${props => props.theme.device.desktopS}{
		margin-top:${(props) => props.theme.Rem(40)};
		.text-box{
			&:nth-of-type(2),&:nth-of-type(3){
				padding-top:${(props) => props.theme.Rem(26)};
			}
			.title{
				margin-bottom:${(props) => props.theme.Rem(15)};
			}
		}
	}
`;
const infoTextObj = {
    title:`Versatile AI<br />Evaluation`,
    content:`AI 분석 알고리즘을 토대로<br />
			전통적인 기업 분석과 미래 전망에 대한<br />    
			예측을 다각적으로 진행할 수 있습니다.`
}
const FirstSection = () => {
    return (
        <CustomSectionWrapper>
            <PageWrapper>
                <CustomWrapper>
                    <CustomWidth100>
                        <PageInfoWidth40 className="page-info">
                            <Info texts = {infoTextObj}/>
                            <MriPageLogo src="/img/antock_mri_b.png" alt="antock mri"/>
                        </PageInfoWidth40>

                        <CustomWidth60>
                            <TitleTextBox>
                                <h3 className="title">한 번의 클릭으로<br />2,000가지 이상의 진단</h3>
                                <p className="contents">
                                    비 금융 대안 데이터와 AI 진단 기술을 접목하여 세부 영역 별<br />
                                    방대한 진단 및 평가 정보를 산출하고 금융 활동의 전략적 의사 결정을 지원합니다.
                                </p>
                            </TitleTextBox>

                            <IntroduceWrapper>
                                <div className="text-box">
                                    <h3 className="title">AI 진단 영역</h3>
                                    <p className="contents">
                                        기업의 핵심 평가를 10대 영역으로<br />
                                        구분하여 맞춤화 진단
                                    </p>
                                </div>

                                <div className="text-box">
                                    <h3 className="title">AI 산출 지표</h3>
                                    <p className="contents">
                                        기계 학습을 통하여 약 2,000개 이상의<br />
                                        진단 정보를 기업 별로 제공
                                    </p>
                                </div>

                                <div className="text-box">
                                    <h3 className="title">AI 응용 기능</h3>
                                    <p className="contents">
                                        기업 가치, 기술 혁신, 사회적 기여 등<br />
                                        추상적 영역에 대한 정량화 예측
                                    </p>
                                </div>
                            </IntroduceWrapper>
                        </CustomWidth60>
                    </CustomWidth100>
                </CustomWrapper>
            </PageWrapper>
        </CustomSectionWrapper>
    );
};

export default FirstSection;
