import React from "react";
import styled from "styled-components";

import { PageWrapper, Wrapper, Width100, Width45, Width55 } from "style/layout";

import Info from "components/Info";
import Contents from "./third/Contents";

import "../../layout.scss";

const CustomSectionWrapper = styled.div`
	position: relative;
	&::after {
		content: "";
		width: 100%;
		position: absolute;
		background-position: center center;
		background-image: url(/img/landing03.png);
		background-size: cover;
		background-repeat: no-repeat;
		z-index: 1;
		height: 18.5%;
		bottom: 0;
		right: 6%;
	}
`;

const CustomWidth100 = styled(Width100)`
	height: 100%;
`;

const infoTextObj = {
	title:`Powerful<br />Analytics Service`,
	content:`빅 데이터 AI 진단을 통해<br />
			기업 분석의 차별성, 정교성, 효율성을<br />    
			모두 큰 폭으로 개선합니다.`
}

const ThirdSection = () => {
	return (
		<>
			<CustomSectionWrapper>
				<PageWrapper>
					<Wrapper>
                        <CustomWidth100>
                            <Width45 className="page-info">
								<Info primary={false} texts = {infoTextObj}/>
							</Width45>
                            <Width55 className="contents-wrapper">
								<Contents/>
							</Width55>
                        </CustomWidth100>
                    </Wrapper>
				</PageWrapper>
			</CustomSectionWrapper>
		</>
	);
};

export default ThirdSection;
