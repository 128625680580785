const SET_CURRENT_PAGE = "page/SET_CURRENT_PAGE";
const SET_CURRENT_SECTION = "page/SET_CURRENT_SECTION";
const SET_ORIGIN_SECTION = "page/SET_ORIGIN_SECTION";

export const setCurrentPage = (currentPage) => ({
	type: SET_CURRENT_PAGE,
	currentPage,
});
export const setCurrentSection = (currentSection) => ({
	type: SET_CURRENT_SECTION,
	currentSection,
});
export const setOriginSection = (originSection) => ({
	type: SET_ORIGIN_SECTION,
	originSection,
});

const initialState = {
	// page : landing, hubble, mri, pr, prdetail, dvai, dvdata
	currentPage: "landing",
	currentSection: 1,
	originSection: "",
};

export default function page(state = initialState, action) {
	switch (action.type) {
		case SET_CURRENT_PAGE:
			return {
				...state,
				currentPage: action.currentPage,
			};
		case SET_CURRENT_SECTION:
			return {
				...state,
				currentSection: action.currentSection,
			};
		case SET_ORIGIN_SECTION:
			return {
				...state,
				originSection: action.originSection,
			};

		default:
			return state;
	}
}
