import React from "react";
import styled, {css} from "styled-components";

import {
	Height100,
	Height50,
	PageLogo,
	PageWrapper, textBoxContents,
	textBoxTitle,
	Width100,
	Width33,
	Width45,
	Width55,
	Wrapper
} from "style/layout";
import Info from "../Info";

const CustomSectionWrapper = styled.div``;

const CustomWidth100 = styled(Width100)`
	height: 100%;
`;
const PageInfoWidth45 = styled(Width45)`
	height: 100%;
	box-sizing: border-box;
	flex-wrap: nowrap;
	align-items: flex-start;
	justify-content:center;
	margin-top: -#{${(props) => props.theme.sizes.pageMarginTop}};
    flex-direction: column;
    border-right:1px solid ${props => props.theme.colors.lbgBorderColor};
`;

const CustomHeight50 = styled(Height50)`
	box-sizing: border-box;
	padding-top:${props => props.theme.Rem(50)};
	padding-bottom:${props => props.theme.Rem(50)};
	padding-right:${props => props.theme.Rem(15)};
	//padding-left:${props => props.theme.Rem(22)};
	padding-left:0;
	&:first-of-type{
		justify-content: flex-end;
		color:$point-color;
		padding:0;	
	}
	&:last-of-type{
		justify-content: flex-start;
		color:${props => props.theme.colors.pointColor};
	}
	.text-box{
		.title{
			${textBoxTitle}
			margin-bottom:${(props) => props.theme.Rem(30)};
			font-size:${(props) => props.theme.Rem(30)};
			word-break: keep-all;
		}
		.contents{
			${textBoxContents}
			word-break: keep-all;
			line-height:${(props) => props.theme.Rem(24)};
			font-size:${(props) => props.theme.Rem(16)};
		}
	}
`;

const CustomWidth33 = styled(Width33)`
	${(props) => {
	let styles=``;
	for (let i = 1; i <= 4 ; i += 1){
		styles +=`
		> div{
			width:100%;
		}
		&:first-of-type{
			border:none;
		}
		&:nth-of-type(${i}){
			border-left:1px solid ${props.theme.colors.lbgBorderColor};
			box-sizing:border-box;
		}
	`;
	}
	return css`${styles}`;
}
}
`;

const ImageBox = styled.div`
    height: 100%;
    display: flex;
    overflow: hidden;
    margin-bottom: 2.5rem;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    img{
        max-width: 100%;
		box-shadow: 1px 1px 1px rgb(255 255 255 / 40%);
		flex-shrink: 0;
		width: 100%;
    }
`;

const IconList = styled.ul`
        display:flex;
        flex-direction:row;
        align-items: center;
        width:100%;
        height:${props => props.theme.Rem(80)};
        margin-bottom:${props => props.theme.Rem(40)};
        li{
            display:flex;
            flex-direction:column;
            justify-content: space-between;
            height:100%;
            margin-right:${props => props.theme.Rem(30)};
            img{
                height:${props => props.theme.Rem(50)};
            }
            p{
                font-size:${props => props.theme.Rem(14)};
                font-weight:400;
                text-align: center;
                line-height:${props => props.theme.Rem(18)};
                //margin-bottom:${props => props.theme.Rem(14)};
                color:${props => props.theme.colors.dFontColor};
            }
        }
`;
const infoTextObj = {
	title:`Customized AI Data<br />Accommodation`,
	content:`고객의 AI 분석 환경 및 활용 방법을 고려한<br />
			다양한 형태의 데이터 공급을 진행하고 있습니다.`
}
const FifthSection = () => {
	return (
		<>
			<CustomSectionWrapper>
				<PageWrapper>
					<Wrapper>
						<CustomWidth100>
							<PageInfoWidth45>
								<IconList>
									<li>
										<p>투자기관</p>
										<img src="/img/build_1.png" alt="투자기관" />
									</li>
									<li>
										<p>연구기관</p>
										<img src="/img/build_2.png" alt="연구기관" />
									</li>
									<li>
										<p>금융기관</p>
										<img src="/img/build_3.png" alt="금융기관" />
									</li>
									<li>
										<p>공공기관</p>
										<img src="/img/build_4.png" alt="공공기관" />
									</li>
								</IconList>
								<Info texts = {infoTextObj}/>
								<PageLogo src="/img/hubble_h_b.png" alt="Hubble Database"/>
							</PageInfoWidth45>
							<Width55>
								<Width100>
									<CustomWidth33>
										<Height100>
											<CustomHeight50>
												<ImageBox>
													<img src="/img/source/browser-source-hubble.png" alt="web"/>
												</ImageBox>
											</CustomHeight50>
											<CustomHeight50>
												<div className="text-box">
													<h3 className="title">Web</h3>
													<p className="contents">
														개별 계정 정보로 로그인하여
														웹페이지에서 기업 조회, 발굴,
														관심기업 등록 등 활동 지원
													</p>
												</div>
											</CustomHeight50>
										</Height100>
									</CustomWidth33>
									<CustomWidth33>
										<Height100>
											<CustomHeight50>
												<ImageBox>
													<img src="/img/source/browser-source-code.png" alt="curation"/>
												</ImageBox>
											</CustomHeight50>
											<CustomHeight50>
												<div className="text-box">
													<h3 className="title">Curation</h3>
													<p className="contents">
														고객의 니즈에 맞춤화된 형태로
														가공 및 분석하여 제공하는
														데이터 큐레이션 서비스
													</p>
												</div>
											</CustomHeight50>
										</Height100>
									</CustomWidth33>
									<CustomWidth33>
										<Height100>
											<CustomHeight50>
												<ImageBox>
													<img src="/img/source/browser-source-API.png" alt="api"/>
												</ImageBox>
											</CustomHeight50>
											<CustomHeight50>
												<div className="text-box">
													<h3 className="title">API</h3>
													<p className="contents">
														기업별 7대 영역에서
														최대 300가지 항목의 데이터를
														API로 제공 받는 서비스
													</p>
												</div>
											</CustomHeight50>
										</Height100>
									</CustomWidth33>
								</Width100>
							</Width55>
						</CustomWidth100>
					</Wrapper>
				</PageWrapper>
			</CustomSectionWrapper>
		</>
	);
};

export default FifthSection;
