import React from "react";
import styled from "styled-components";

import {
	MriPageLogo,
	PageWrapper,
	Width100,
	Width40,
	Width60,
	Wrapper,
} from "style/layout";
import Info from "../Info";

import Carousel from "./second/Carousel";

const CustomSectionWrapper = styled.div``;
const CustomWidth100 = styled(Width100)`
	height: 100%;
`;
const PageInfoWidth40 = styled(Width40)`
	height: 100%;
	box-sizing: border-box;
	flex-wrap: nowrap;
	align-items: flex-start;
	justify-content: center;
	margin-top: -#{${(props) => props.theme.sizes.pageMarginTop}};
	flex-direction: column;
	border-right: 1px solid ${(props) => props.theme.colors.lbgBorderColor};
	position: relative;
`;

const CustomWidth60 = styled(Width60)`
	height: 100%;
`;
const infoTextObj = {
	title:`Antock Original<br />Perspectives`,
	content:`당사 고유 AI 분석 알고리즘을 확립하여<br />
			시장 어디에서도 얻을 수 없는 부가가치 창출을 지원합니다.`
}
const SecondSection = () => {
	return (
		<>
			<CustomSectionWrapper>
				<PageWrapper>
					<Wrapper>
						<CustomWidth100>
							<PageInfoWidth40>
								<Info texts = {infoTextObj}/>
								<MriPageLogo
									src="/img/antock_mri_b.png"
									alt="antock mri"
								/>
							</PageInfoWidth40>
							<CustomWidth60>
								<Carousel />
							</CustomWidth60>
						</CustomWidth100>
					</Wrapper>
				</PageWrapper>
			</CustomSectionWrapper>
		</>
	);
};

export default SecondSection;
