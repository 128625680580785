// const fontSize = 16;
export const Rem = (size) => {
    let res = (size / 16) ;
    res = res+"rem"
    return res;
}

const deviceSizes = {
    mobile:"767px",
    tablet: "768px",
    tabletL: "1024px",
    notebook: "1300px",
    desktopS: "1400px",
    desktopM: "1600px",
    desktopL: "2100px",
};

const colors = {
    landingBackgroundColor: "#00002D",
    pointColor: "#1210C9",
    bgColor: "rgba(250,251,255,1)",
    dFontColor: "#B8B8C6",
    fontColor: "#5A5A77",
    borderColor: "rgba(255,255,255,0.1)",
    lbgBorderColor: "rgba(90,90,119,0.08)",
    footerUntilColor: "rgba(184,184,198, 1)",
    contentBg: "#F5F5F5"
};

const sizes = {
    headerHeight: "75px",
    pageMarginTop: "50px",
    mHeaderHeight:"50px"
}

const device = {
    mobile: `@media (max-width: ${deviceSizes.mobile})`,
    tablet: `@media (max-width: ${deviceSizes.tablet})`,
    tabletL: `@media (max-width: ${deviceSizes.tabletL}) and (orientation:portrait)`,
    desktopS: `@media (max-width: ${deviceSizes.desktopS})`,
    desktopM: `@media (max-width: ${deviceSizes.desktopM})`,
    desktopL: `@media (max-width: ${deviceSizes.desktopL})`,
    notebook: `@media (max-width: ${deviceSizes.notebook})`,
};


export const theme = {
    colors,
    sizes,
    deviceSizes,
    device,
    Rem
};
