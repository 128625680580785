import React  from "react";
import { animated, useSpring } from "react-spring";

const info = [ 
    
    {
        x: "48",
        transform: "rotate(90 48.5518 0)", 
    }, 

    {
        x: "136",
        transform: "rotate(90 136.517 0)",
    },

    {
        x: "226",
        transform: "rotate(90 226 0)", 
    },

    {
        x: "315",
        transform: "rotate(90 315 0)",
    },

];

const Rectangle = ({ index, init }) => {

    const animationFillStyle = useSpring({
        from: { fillColor: "#b8b8c6" },
        to: { fillColor: "#1816c9" },
        config: { duration: 1000 },
        delay: 0,
        reset: init,
    });


    return (
        <>
            <animated.rect x={info[index].x} width="38" height="38" transform={info[index].transform} fill={animationFillStyle.fillColor} />
        </>
    );
};

export default Rectangle;