import React, { useRef } from "react";
import { animated, useSpring } from "react-spring";

const Circle = ({ color, init, data }) => {
	const shapeRef = useRef(null);

	const animationFillStyle = useSpring({
		from: { fillColor: "#b8b8c6" },
		to: { fillColor: color },
		config: { duration: 1000 },
		delay: 3500,
		reset: init,
	});

	return (
		<>
			<animated.rect
				ref={shapeRef}
				fill={animationFillStyle.fillColor}
				id={data.id}
				data-name={data.id}
				width="25"
				height="25"
				rx="12.5"
				transform={data.transform}
			/>
		</>
	);
};

export default React.memo(Circle);
