import React from "react";
import styled from "styled-components";
import {PageWrapper, Width100, Wrapper,} from "style/layout";

const CustomSectionWrapper = styled.div`
  position: relative;
  padding-top: calc(${(props) => props.theme.sizes.headerHeight} + 40px);
`;

const CustomPageWrapper = styled(PageWrapper)`
    height:auto;
`;

const CustomWrapper = styled(Wrapper)`
  border-color: ${(props) => props.theme.colors.lbgBorderColor};
  padding-top:${(props) => props.theme.sizes.headerHeight};
  height:auto;
`;

const CustomWidth100 = styled(Width100)`
  height: 100%;
`;

const DetailContentWrapper = styled.div`
    width:100%;
    align-items:flex-start;
    display:flex;
    flex-direction:column;
`;

const DetailTitle = styled.div`
        box-sizing:border-box;
        padding-bottom: ${props => props.theme.Rem(50)};
        margin-bottom: ${props => props.theme.Rem(50)};
        width:100%;
        //border-bottom:1px solid ${props => props.theme.colors.lbgBorderColor};        
    h3{
        height:${props => props.theme.Rem(70)};
        font-weight:800;
        width:70%;
        margin-bottom:${props => props.theme.Rem(28)};     
    }
    span{
        opacity:0.8;
        height:1rem;
        font-weight:300;
        width:5rem;
        display:inline-block;
    }
    b{
        display:inline-block;
        width:8rem;
        margin-left:0.5rem;
        height:1rem;
        font-weight:800;
    }
`;

const DetailContent = styled.div`
    &&{
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
        width: 60%;
        padding-bottom: ${props => props.theme.Rem(150)};
        box-sizing:border-box;
        .se2_quote1{
            margin:0 0 1rem 0 !important;
            p{
                margin:0 !important;
            }
        }
        p,span,b{
            text-align:left !important;
            font-family: 'Pretendard', "Malgun Gothic", "맑은 고딕", "dotum", "돋움", sans-serif !important;
            color:${props => props.theme.colors.fontColor} !important;
            height:1.1rem;
            overflow: hidden;
            position: relative;
            width:80%;
            background-color:#ddd;
            margin-bottom:0.3rem;
            &:nth-of-type(3n){
                margin-bottom:1rem;
            }
        }
        div{
            width:100%;
            height:50vh;
            margin-bottom:5vh;
        }
        ${props => props.theme.device.notebook}{
            width: 100%;
        }
    }
`;

const DetailLoading = () => {

    return (
        <CustomSectionWrapper>
            <CustomPageWrapper>
                <CustomWrapper>
                    <CustomWidth100>
                        <DetailContentWrapper>
                            <DetailTitle>
                                <h3 className="skeleton-item"></h3>
                                <span className="skeleton-item"></span>
                                <b className="skeleton-item"></b>
                            </DetailTitle>
                            <DetailContent>
                                <div className="skeleton-item" />
                                {
                                    new Array(10).fill(1).map(()=>{
                                     return(
                                         <p className="skeleton-item"></p>
                                     );
                                    })
                                }
                            </DetailContent>
                        </DetailContentWrapper>
                    </CustomWidth100>
                </CustomWrapper>
            </CustomPageWrapper>
        </CustomSectionWrapper>
    );
};

export default DetailLoading;
