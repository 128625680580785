import React, {useEffect} from 'react';
import styled from "styled-components";
import {
    ContentWrapper,
    MobileContentsList,
    PageInfoTitle,
    PageWrapper,
    SectionInfoWrapper, Width100,
    Wrapper
} from "../../style/layout";
import Title from "../../components/landing/main/Title";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentPage} from "../../modules/page";
import {useInView} from "react-intersection-observer";
import {setObserver} from "../../modules/style";
import {addNumberComma, percentValue} from "../../lib/function";

const CustomWrapper = styled.div`
	background-image: url("/img/landing-main.png");
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center center;
	display:flex;
	width:100%;
	min-height:100vh;
	height:100%;
	.border-light{
		align-items:center;
		justify-content:center;
		display: flex;
		h2{
			width:auto;
			height:100%;
			align-items:flex-start;
			justify-content:center;
			position:relative;
			div{
				width:100%;
				position:absolute;
				bottom:20vh;
			}
			img{
				height:6vw;
			}
			p{
				width:100%;
				text-align:center;
				&:first-of-type{
					margin-top:0;
				}
				${props => props.theme.device.tabletL}{
					font-size:2rem;
				}
				${props => props.theme.device.tablet}{
					font-size:1.8rem;
				}
				${props => props.theme.device.mobile}{
					font-size:1.1rem;
				}
			}
		}
	}
`;

const MobileMainWrapper = styled(Wrapper)`
    position:relative;
    flex-direction:column;
    &::before{
        content:"";
        display:block;
        width:88vw;
        height:88vw;
        position:absolute;
        top:0;
        right:0;
        background-image:url(/img/hubble_stars.png);
        background-size:cover;
        background-position:top center;
        background-repeat:no-repeat;
        @media (min-width: ${props => props.theme.deviceSizes.tablet}){
            width:70vw;
            height:70vw;
        }
    }
    img{
        width:50vw;
    }
    div{
        position:absolute;
        bottom:10vh;
        color:rgba(255,255,255);
        text-align:center;
        h2{
            margin-bottom:${props => props.theme.sizes.mHeaderHeight};
            span{
                font-weight:200;
            }
        }
        p{
            font-weight:300;
            line-height:1.3rem;
        }
        
    }
`;

const NonFinanceDataList = styled.ul`
    display:flex;
    width:100%;
    height:auto;
    flex-direction:row;
    flex-wrap:wrap;
    box-sizing:border-box;
    
    li{
        width:33.33333%;
        box-sizing:border-box;
        display:flex;
        flex-direction:column;
        color:#fff;
        padding:1.5rem;
        text-align:center;
        align-items:center;
        justify-content:center;
        border-right:1px solid ${props => props.theme.colors.borderColor};
        border-bottom:1px solid ${props => props.theme.colors.borderColor};
        &:nth-of-type(3n){
            border-right:none;
        }
        p{
            font-weight:200;
            margin-top:0.3rem;
        }
        img{
            width:60%;
            height:auto;
            min-height:1px;
            max-width: 80px;
            margin-bottom:1rem;
        }
        
    }
`;

const CustomizedList = styled(Width100)`
    flex-direction:column;
    >div{
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        align-items:center;
        margin-bottom:${props => props.theme.sizes.mHeaderHeight};
        img{
            width:45%;
        }
        h3{
            color:${props => props.theme.colors.pointColor};
            font-size:${props => props.theme.Rem(28)};
            font-weight:700;
        }
        p{
            font-size:1.15rem;
            word-break:keep-all;
            line-height:1.5rem;
            box-sizing:border-box;
            width:80%;
            margin-top:0.7rem;
        }
    }
`;

const NextLevelList = styled(CustomizedList)`
    > div{
        flex-direction:column;
        align-items:flex-start;
        width:100%;
        box-sizing:border-box;
        padding:${props => props.theme.sizes.mHeaderHeight} 1.5rem;
        margin:0;
        background-image:url(/img/hubble04_1.png);
        background-size:cover;
        background-position:bottom center;
        background-repeat:no-repeat;
        &:nth-of-type(2){
        background-image:url(/img/hubble04_2.png);
        background-color:${props => props.theme.colors.pointColor};
            h3{
                color:#fff;
            }
            p{
                color:#fff;
            }
        }
        &:nth-of-type(3){
        background-image:url(/img/hubble04_3.png);
        }
        h3{
            font-size:1.5rem;
            font-weight:900;
        }
        p{
            color:${props => props.theme.colors.pointColor};
            }
    }
`;

const HubbleContactWrapper = styled.div`
    position:relative;
    .bg-point-color{
        overflow: visible;
    }
    .logo{
        width:40%;
        max-width:200px;
        margin-top:${props=>props.theme.sizes.mHeaderHeight};
    }
    .notebook{
        width:100%;
        height:46vw;
        // position:absolute;
        // bottom:0;
        // left:0;
        // right:0;
        margin:0 auto;
        margin-bottom:-1vw;
        margin-top:${props=>props.theme.sizes.mHeaderHeight};
        background-image:url(/img/macbook1.png);
        background-size:cover;
        background-position:center bottom;
        background-repeat:no-repeat;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        video{
            width:80%;
            margin-left: 1vw;
            margin-top: -5vw;
        }
    }
    .info-wrapper{
        padding-bottom:0;
    }
    button{
        background-color:#FFE812;
        color:#000;
        margin-top:${props=>props.theme.sizes.mHeaderHeight};
        font-weight:600;
        font-size:1.2rem;
        box-sizing:border-box;
        padding:0 2rem 0 1rem;
        border-radius:2rem;
        img{
            width:3.5rem;
        }
    }
`;

const MobileHubble = () => {
    const dispatch = useDispatch();

    const { device,observer } = useSelector((state) => ({
        device: state.style.device,
        observer: state.style.observer,
    }));
    const { ref, inView } = useInView({});

    useEffect(()=>{
        dispatch(setObserver(inView));
    },[inView]);

    useEffect( ()=> {
        dispatch(setCurrentPage("hubble"));
    },[device]);

    useEffect(() => {
        const SDK_KEY = process.env.REACT_APP_KAKAO_SDK_KEY;
        
        if (!window.Kakao.isInitialized()){
            window.Kakao.init(SDK_KEY);
        }
    } ,[]);

    const handleKakaoButton = () => {
        window.Kakao.Channel.chat({
            channelPublicId: '_wxcprb'
        });
    };

    return (
        <>
            <CustomWrapper ref={ref}>
                <PageWrapper>
                    <MobileMainWrapper className="border-light">
                        <img src="/img/hubble_logo_600.png" alt="Hubble Database"/>
                        <div>
                            <h2><span>Hubble Database.</span><br/>대한민국의 모든 기업을 담다.</h2>
                            <p>
                                빅 데이터 기술로 국내 모든 기업을 자동<br />
                                추적하고, 개별 기업에 대한 다각적인<br />
                                대안 데이터를 수집합니다.
                            </p>
                        </div>
                    </MobileMainWrapper>
                </PageWrapper>
            </CustomWrapper>

            <PageWrapper>
                <ContentWrapper className="info-wrapper border-tb-dark" style={{borderWidth:"1px"}}>
                    <PageInfoTitle>
                        AI Database of<br />Holistic Coverage
                    </PageInfoTitle>
                </ContentWrapper>
                <ContentWrapper>
                    <MobileContentsList>
                        <li>
                            <span>전체 기업 표본</span>
                            <h3>{addNumberComma(Number(process.env.REACT_APP_COMPANY_COUNT))} 개</h3>
                            <p>
                                현재까지 {(Number(process.env.REACT_APP_COMPANY_COUNT) / 10000).toFixed(0)}만 개 이상의 기업 표본 확보하여
                                통합적인 기업 분석 인프라를 구축하였습니다.
                            </p>
                        </li>
                        <li>
                            <span>유효 기업 표본</span>
                            <h3>{addNumberComma(process.env.REACT_APP_COPERATION_COUNT)} 개</h3>
                            <p>
                                사업 활동을 영위하는 유효 기업 표본 또한 {(Number(process.env.REACT_APP_COPERATION_COUNT) / 10000).toFixed(0)}만 개로 적시적 표본 관리를 진행중입니다.
                            </p>
                        </li>
                        <li>
                            <span>기업 식별률</span>
                            <h3>{percentValue()}%</h3>
                            <p>
                                (법인등기현황 기준) 주식회사 중 {percentValue()}% 이상을
                                식별하며, 기업 생태계 전산화 성공하였습니다.
                            </p>
                        </li>
                    </MobileContentsList>
                </ContentWrapper>
            </PageWrapper>

            <PageWrapper className="bg-point-color">
                <ContentWrapper className="info-wrapper border-light" style={{borderWidth:"1px",Color:"#fff"}}>
                    <PageInfoTitle style={{color:"#fff"}}>
                        Non-Financial<br />Alternative Data
                    </PageInfoTitle>
                </ContentWrapper>
                <ContentWrapper className="border-light" style={{padding:"0"}}>
                    <NonFinanceDataList>
                        <li>
                            <img src="/img/pro_icon.png" alt="상품" />
                            <b>상품</b>
                            <p>Product</p>
                        </li>
                        <li>
                            <img src="/img/org_icon.png" alt="조직" />
                            <b>조직</b>
                            <p>Organization</p>
                        </li>
                        <li>
                            <img src="/img/news_icon.png" alt="언론" />
                            <b>언론</b>
                            <p>Press</p>
                        </li>
                        <li>
                            <img src="/img/ip_icon.png" alt="지적재산권" />
                            <b>지적재산권</b>
                            <p>IP</p>
                        </li>
                        <li>
                            <img src="/img/search_icon.png" alt="검색" />
                            <b>검색</b>
                            <p>Searching</p>
                        </li>
                        <li>
                            <img src="/img/geol_icon.png" alt="검색" />
                            <b>위치</b>
                            <p>Geographies</p>
                        </li>
                        <li>
                            <img src="/img/soci_icon.png" alt="가치" />
                            <b>가치</b>
                            <p>Value</p>
                        </li>
                        <li>
                            <img src="/img/reco_icon.png" alt="수상" />
                            <b>수상</b>
                            <p>Award</p>
                        </li>
                        <li>
                            <img src="/img/web_icon.png" alt="홈페이지" />
                            <b>홈페이지</b>
                            <p>Website</p>
                        </li>
                        <li>
                            <img src="/img/cert_icon.png" alt="인증" />
                            <b>인증</b>
                            <p>Certification</p>
                        </li>
                        <li>
                            <img src="/img/tran_icon.png" alt="계약" />
                            <b>계약</b>
                            <p>Contract</p>
                        </li>
                        <li>
                            <img src="/img/sns_icon.png" alt="소셜미디어" />
                            <b>소셜미디어</b>
                            <p>SNS</p>
                        </li>
                    </NonFinanceDataList>
                </ContentWrapper>
                <ContentWrapper className="border-light" >
                    <SectionInfoWrapper style={{color:"#fff"}}>
                        재무 정보를 넘어, 기업과 사업을 보다
                        정확하게 이해할 수 있도록, 약 300가지
                        비금융 대안 데이터를 제공합니다.
                    </SectionInfoWrapper>
                </ContentWrapper>
            </PageWrapper>

            <PageWrapper>
                <ContentWrapper className="info-wrapper">
                    <PageInfoTitle>
                        Customized AI Data<br />Accommodation
                    </PageInfoTitle>
                    <SectionInfoWrapper style={{paddingBottom:"0",paddingTop:"50px"}}>
                        고객의 분석 환경과 활용 목적을 고려한
                        다양한 형태의 데이터 공급을 진행합니다.
                    </SectionInfoWrapper>
                </ContentWrapper>
                <ContentWrapper>
                    <CustomizedList>
                        <div>
                            <div>
                                <h3>Web</h3>
                                <p>
                                    계정 발급을 통해 웹
                                    페이지에서 기업 조회,
                                    발굴 및 관심기업 등록
                                </p>
                            </div>
                            <img src="/img/source/browser-source-hubble.png" alt="web"/>
                        </div>
                        <div>
                            <div>
                                <h3>Curation</h3>
                                <p>
                                    고객 Needs 맞춤화된
                                    형태로 가공/ 분석하여
                                    큐레이션하는 서비스
                                </p>
                            </div>
                            <img src="/img/source/browser-source-code.png" alt="curation"/>
                        </div>
                        <div>
                            <div>
                                <h3>API</h3>
                                <p>
                                    기업 별 7대 영역에서,
                                    최대 300 가지 이상의
                                    데이터 자동 호출
                                </p>
                            </div>
                            <img src="/img/source/browser-source-API.png" alt="api"/>
                        </div>
                    </CustomizedList>
                </ContentWrapper>
            </PageWrapper>

            <PageWrapper>
                <ContentWrapper className="info-wrapper" style={{borderWidth:"1px"}}>
                    <PageInfoTitle>
                        Next Level<br />Financial AI Data
                    </PageInfoTitle>
                </ContentWrapper>
                <ContentWrapper style={{padding:"0"}}>
                    <NextLevelList>
                        <div>
                            <h3>ALL-IN-ONE 통합 시스템</h3>
                            <p>다양한 공공 / 개방 데이터를 유기적으로 연계하여 일원화된 AI 데이터 인프라 구축</p>
                        </div>
                        <div>
                            <h3>데이터 AI 전처리 시스템</h3>
                            <p>데이터 항목 (단위, 스타일, 양식 등)표준화 및 비정형 텍스트 데이터에 대한 정량화 변환</p>
                        </div>
                        <div>
                            <h3>자동 업데이트 알고리즘</h3>
                            <p>전체 기업 표본과 기업 별 세부 정보를 월 1회 갱신하여 추가 / 변동사항을 자동 반영</p>
                        </div>
                    </NextLevelList>
                </ContentWrapper>
            </PageWrapper>

            <HubbleContactWrapper>
                <PageWrapper className="bg-point-color">
                    <ContentWrapper className="info-wrapper border-light">
                        <PageInfoTitle style={{color:"#fff"}}>
                            Hubble Database
                        </PageInfoTitle>
                        <SectionInfoWrapper as={"div"} style={{paddingBottom:"0",paddingTop:"50px",color:"#fff",width:"100%"}}>
                            <p>망원경으로 별을 발견하듯<br/> 데이터로 기업을 발굴합니다.</p>
                            <img src="/img/hubble_h_w.png" alt="hubble" className="logo"/>
                            <div className="notebook">
                                <video data-autoplay="true" src="/img/all.mp4" muted autoPlay loop/>
                            </div>
                        </SectionInfoWrapper>
                    </ContentWrapper>
                </PageWrapper>
                <PageWrapper>
                    <ContentWrapper>
                        <SectionInfoWrapper>
                            Hubble Database 서비스에 대해 보다 자세히 알고 싶으시거나, 테스트 계정 신청을 원하시면 언제든지 알려주세요!<br />
                            <button onClick={handleKakaoButton}><img src="/img/kakao_logo.png" alt="hubble" className="kakao"/>문의하기</button>
                        </SectionInfoWrapper>
                    </ContentWrapper>
                </PageWrapper>
            </HubbleContactWrapper>
        </>
    );
};

export default MobileHubble;