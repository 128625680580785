import React from "react";
import styled from "styled-components";

import {
    Height33,
    Height100,
    textBoxTitle,
    textBoxExplain,
    textBoxContents,
} from "style/layout";

const CustomHeight100 = styled(Height100)`
	height: 80%;
	width:100%;
`;

const CustomHeight33 = styled(Height33)`
	justify-content: flex-start;
	flex-direction: row;
	box-sizing: border-box;
	padding: 0 ${(props) => props.theme.Rem(25)} 0
		${(props) => props.theme.Rem(25)};
	&:nth-of-type(1) {
		align-items: flex-end;
	}
	&:nth-of-type(2) {
		align-items: center;
	}
	&:nth-of-type(3) {
		align-items: flex-start;
	}
	b {
		font-size: 1rem;
		font-weight: 900;
		display: block;
		width: 100%;
		margin-bottom: ${(props) => props.theme.Rem(10)};
	}
`;

const CustomTitle = styled.h3`
	${textBoxTitle}
	display:inline;
	font-weight: 900;
	font-size: 1.2rem;
	margin-bottom: ${(props) => props.theme.Rem(16)};
`;

const CustomExplain = styled.span`
	${textBoxExplain}
	font-weight:200;
	font-size: 0.95rem;
	padding-left:0.4rem;
`;

const CustomContents = styled.p`
	${textBoxContents}
	font-weight:400;
	line-height: ${(props) => props.theme.Rem(26)};
	margin-top: ${(props) => props.theme.Rem(20)};
`;

const RightTextBox = () => {
    return (
        <CustomHeight100>
            <CustomHeight33>
                <div>
                    <b>Step 01</b>
                    <CustomTitle>대안 데이터 수집</CustomTitle>
                    <CustomExplain>Alternative Data</CustomExplain>
                    <CustomContents>
                        모든 기업에 대한 사업, 성과, 조직, 인증, 기술 등 기업 별
                        비 재무 (Non-Financial) 정보 자동 유입
                    </CustomContents>
                </div>
            </CustomHeight33>

            <CustomHeight33>
                <div>
                    <b>Step 02</b>
                    <CustomTitle>빅 데이터 전처리 통합</CustomTitle>
                    <CustomExplain>AI Processing</CustomExplain>
                    <CustomContents>
                        AI 진단 및 통계분석을 위한 정형 / 비정형 데이터 자동
                        전처리 및 표준화 수행
                    </CustomContents>
                </div>
            </CustomHeight33>

            <CustomHeight33>
                <div>
                    <b>Step 03</b>
                    <CustomTitle>인공지능 분석 평가</CustomTitle>
                    <CustomExplain>AI Robo-Advisor</CustomExplain>
                    <CustomContents>
                        고객 활용 목적에 따른 기업 별 AI 분석 모형 맞춤화 및
                        자동화 진단 수행
                    </CustomContents>
                </div>
            </CustomHeight33>
        </CustomHeight100>
    );
};

export default RightTextBox;
