import Landing from "page/Landing";
import Hubble from "page/Hubble";
import Mri from "page/Mri";
import Company from "./page/Company";
import Pr from "./page/Pr";
import PrDetail from "./page/PrDetail";
import Ai from "page/Ai";
import Data from "page/Data";

const routes = [
    {
        path: '/',
        element: <Landing />,
    },
    {
        path: '/hubble',
        element: <Hubble />
    },
    {
        path: '/mri',
        element: <Mri />
    },
    {
        path: '/company',
        element: <Company />
    },
    {
        path: '/pr',
        element: <Pr />
    },
    {
        path: '/pr/:id',
        element: <PrDetail />
    },
    {
        path: '/dv/ai',
        element: <Ai />
    },
    {
        path: '/dv/data',
        element: <Data />
    },
];

export default routes;
