import React from "react";
import styled, {css} from "styled-components";
import { PageWrapper, Wrapper, Width100 } from "style/layout";


import '../layout.scss';
import {useDispatch, useSelector} from "react-redux";

const CustomSectionWrapper = styled.div`
	background-image: url("/img/landing-main.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background-attachment: auto;
	height: 100%;
	${props => {
        if(props.page === "mri"){
            return css`
                background-image:url(${props.bgUrl});
                background-attachment: auto;
            `;
        }else{
            return null;
        }
    }}
`;

const CustomWrapper = styled(Wrapper)`
	// border:0.5px solid $border-color;
	// border-width:0 0.5px;
	border-width: 0 0.5px;
    position: relative;
    &::before{
        position:absolute;
        right:0;
        top:0;
        display:block;
        content:"";
        width:52vw;
        height:52vw;
        background-image:url("/img/hubble_stars.png");
        background-size:cover;
        background-position:top right;
    }
    ${props => {
        if(props.page === "mri"){
            return css`
                    &::before{
                        display:none;
                    }
                `;
        }else{
            return null;
        }
}}
`;

const CustomWidth100 = styled(Width100)`
	height: 100%;
`;

const MainContent = styled.div`
    color: #fff;
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: flex-start;
    position:relative;
    h2{
        font-size:${props =>  props.theme.Rem(48)};
        font-weight:100;
        margin-top: -11%;
        &+span{
            font-size:${props => props.theme.Rem(48)};
            font-weight:100;
            color: #B8B8C6;
            margin-bottom:${props => props.theme.Rem(50)};
        }
    }
    
    .contents{
            font-size:${props => props.theme.Rem(16)};
            font-weight:400;
            line-height:${props => props.theme.Rem(26)};
            color:${props => props.theme.colors.dFontColor};
            &:first-of-type{
                margin-bottom:${props => props.theme.Rem(34)};
            }
        }
`;

const Status = styled.div`
        width:${props => props.theme.Rem(580)};
        height:30.9%;
        position: absolute;
        bottom: 0;
        left: 0;
        ul{
            display:flex;
            width:100%;
            height:100%;
            flex-direction:row;
            flex-wrap: nowrap;
            align-items: flex-end;
        }
        li{
            display:flex;
            width: 25%;
            height: 100%;
            flex-direction:column;
            border-left:1px solid ${props => props.theme.colors.borderColor};
            .title{
                font-size:1rem;
                font-weight:900;
                color:#B8B8C6;
            }
            .explain{
                font-size:1.25rem;
                font-weight:400;
                color:#B8B8C6;
                margin-top: 1.1rem;
                b{
                    color:#fff;
                    font-weight:900;
                }
            }
            &:first-of-type{
                align-items: flex-start;
                justify-content: flex-end;
                display:flex;
                border:none;
                img{
                    width:${props => props.theme.Rem(120)};
                    margin-bottom:${props => props.theme.Rem(62)};
                    ${props => {
                        if(props.page === "mri"){
                            return css`
                                width:${props.theme.Rem(100)};
                            `;
                        }    
                    }}
                }
            }
            &:nth-of-type(3){
                height:calc(100% - (${props => props.theme.Rem(45)})) ;
            }
            &:nth-of-type(4){
                height:calc(100% - ((${props => props.theme.Rem(45)}) * 2));
            }
        }
`;

const subMainTxtObj = {
    mri:{
        title:"Antock MRI",
        subTitle:"기업 분석의 새로운 방향성",
        subTxt:`대안 데이터에 대한 AI 기계 학습 알고리즘 토대로<br />
            기업의 건강 상태와 가치를 과학적으로 측정하고 평가합니다.`,
        mainImgUrl:"/img/mri_bg.png",
        contents:{
            0:"대안 데이터에 대한 AI 기계 학습 알고리즘 토대로<br />기업의 건강 상태와 가치를 과학적으로 측정하고 평가합니다.",
            1:"기업 성장 잠재성, 리스크 수준, 그리고 사회적 가치 등을 예측하고<br />새로운 관점의 인사이트 창출을 지원합니다.",
        },
        status:{
            0:{
                title:"진단 영역",
                content:"10대 영역"
            },
            1:{
                title:"분석 지표",
                content:"약 2,000개"
            },
            2:{
                title:"활용 모형",
                content:"30개"
            }
        }
    },
    hubble:{
        title:"Hubble Database",
        subTitle:"대한민국 모든 기업을 담다.",
        mainImgUrl:"/img/mri_bg.png",
        contents:{
            0:"국내 모든 기업을 빅 데이터 기술로 추적하고,<br />사업, 성과, 기술, 조직, 인증 등의 대안 데이터를 자동으로 수집합니다.",
            1:"파편화되고 분절적인 기업 정보를 가공하고 통합함으로써,<br />기업 및 금융 분석을 위한 AI 학습용 데이터의 토대를 마련합니다.",
        },
        status:{
            0:{
                title:"추적기업",
                content:`약 ${(Number(process.env.REACT_APP_COMPANY_COUNT) / 10000).toFixed(0)}만개 / 기업`
            },
            1:{
                title:"제공 항목",
                content:"약 300개 / 항목"
            },
            2:{
                title:"분석관점",
                content:"7대 / 영역"
            }
        }
    }
}


const MainSection = () => {
    

    const { currentPage } = useSelector((state) => ({
        currentPage: state.page.currentPage,
    }));

    const textToDom = (text,tag) =>{
        let stringDom = text.toString().split(tag);
        return stringDom.map( (line,idx,arr) => {
            if(tag === "<br />"){
                return <>{line}<br /></> //br일 때
            }else{// b일 때
                if(idx === 0){
                    return <b>{line}</b>;
                }else{
                    return <>{line}</>;
                }
            }
        });
    }
    return (
        <>
            <CustomSectionWrapper page={currentPage} bgUrl={ currentPage === "hubble" ?
                subMainTxtObj.hubble.mainImgUrl
                : subMainTxtObj.mri.mainImgUrl
            }>
                <PageWrapper>
                    <CustomWrapper page={currentPage} bgUrl={ currentPage === "hubble" ?
                        subMainTxtObj.hubble.mainImgUrl
                        : subMainTxtObj.mri.mainImgUrl
                    }>
                        <CustomWidth100>
                            <MainContent>
                                <h2>
                                    {
                                        currentPage === "hubble" ?
                                            subMainTxtObj.hubble.title
                                            : subMainTxtObj.mri.title
                                    }
                                </h2>
                                <span>
                                    {
                                        currentPage === "hubble" ?
                                            subMainTxtObj.hubble.subTitle
                                            : subMainTxtObj.mri.subTitle
                                    }
                                </span>

                                <div>
                                    <div className="contents">
                                        {
                                            currentPage === "hubble" ?
                                                textToDom(subMainTxtObj.hubble.contents[0],"<br />")
                                                : textToDom(subMainTxtObj.mri.contents[0],"<br />")
                                        }
                                    </div>
                                    <div className="contents">
                                        {
                                            currentPage === "hubble" ?
                                                textToDom(subMainTxtObj.hubble.contents[1],"<br />")
                                                : textToDom(subMainTxtObj.mri.contents[1],"<br />")
                                        }
                                    </div>
                                </div>

                                <Status page={currentPage}>
                                    <ul>
                                        <li>
                                            {
                                                currentPage === "hubble" ?
                                                    <img src="/img/hubble_h_w.png" alt="Hubble Database"/>
                                                    : <img src="/img/antock_mri_w.png" alt="antock mri"/>
                                            }

                                        </li>
                                        <li className="border-left">
                                            <h3 className="title">
                                                {
                                                    currentPage === "hubble" ?
                                                        textToDom(subMainTxtObj.hubble.status[0].title)
                                                        : textToDom(subMainTxtObj.mri.status[0].title)
                                                }
                                            </h3>
                                            <p className="explain">
                                                {
                                                    currentPage === "hubble" ?
                                                        textToDom(subMainTxtObj.hubble.status[0].content,"/")
                                                        : textToDom(subMainTxtObj.mri.status[0].content,"/")
                                                }
                                            </p>
                                        </li>
                                        <li className="border-left">
                                            <h3 className="title">
                                                {
                                                    currentPage === "hubble" ?
                                                        textToDom(subMainTxtObj.hubble.status[1].title)
                                                        : textToDom(subMainTxtObj.mri.status[1].title)
                                                }
                                            </h3>
                                            <p className="explain">
                                                {
                                                    currentPage === "hubble" ?
                                                        textToDom(subMainTxtObj.hubble.status[1].content,"/")
                                                        : textToDom(subMainTxtObj.mri.status[1].content,"/")
                                                }
                                            </p>
                                        </li>
                                        <li className="border-left">
                                            <h3 className="title">
                                                {
                                                    currentPage === "hubble" ?
                                                        textToDom(subMainTxtObj.hubble.status[2].title)
                                                        : textToDom(subMainTxtObj.mri.status[2].title)
                                                }
                                            </h3>
                                            <p className="explain">
                                                {
                                                    currentPage === "hubble" ?
                                                        textToDom(subMainTxtObj.hubble.status[2].content,"/")
                                                        : textToDom(subMainTxtObj.mri.status[2].content,"/")
                                                }
                                            </p>
                                        </li>
                                    </ul>
                                </Status>
                            </MainContent>
                        </CustomWidth100>
                    </CustomWrapper>
                </PageWrapper>
            </CustomSectionWrapper>
        </>
    );
};

export default MainSection;
