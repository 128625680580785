import React from "react";
import styled from "styled-components";

import {
	PageWrapper,
	Wrapper,
	Width100,
	Width50,
} from "style/layout";

import Info from "components/Info";

import "../../layout.scss";

const CustomSectionWrapper = styled.div`
	height: 100%;
`;

const CustomWrapper = styled(Wrapper)`
    border: 0.5px solid ${(props) => props.theme.colors.lbgBorderColor};
	border-width: 0 0.5px;
	
	.contents-wrapper{
		border-left: 0.5px solid ${(props) => props.theme.colors.lbgBorderColor};
	}
`;

const CustomWidth100 = styled(Width100)`
	height: 100%;
	
`;

const ServiceWrapper = styled.div`
	width:100%;
	display:flex;
	flex-direction:column;
	align-items:center;
	box-sizing:border-box;
	padding:0 1rem;
	img{
		width:60%;
		flex-shrink:0;		
	}
	& + p{
		margin-top:2rem;
	}
	
	${(props) => props.theme.device.desktopS} {
		img{
			width:65%;
		}
		& + p{
			margin-top:1rem;
		}
	}
`;

const ServiceContent = styled(Width100)`
	width:100%;
	display:flex;
	flex-direction:column;
	align-items:center;
	padding-top:${props => props.theme.sizes.headerHeight};
	box-sizing:border-box;
	justify-content:center;
	
	b{
		color:${props => props.theme.colors.pointColor};
		font-size:${props => props.theme.Rem(20)};
		text-align:center;
		width:100%;
		margin-bottom:${props => props.theme.Rem(30)};
	}
	
	${(props) => props.theme.device.desktopS} {
		b{
			font-size:${props => props.theme.Rem(16)};
			margin-bottom:${props => props.theme.Rem(20)};
		}	
	}
`;

const ServiceInfo = styled(Width50)`
	flex-direction:column;
	align-items:center;
	margin-top:${props => props.theme.Rem(22)};
	padding:0 1rem;
	&:first-of-type{
		padding-left:0;
	}
	&:last-of-type{
		padding-right:0;
	}
	.price{
		display:flex;
		flex-direction:row;
		margin-bottom:1rem;
			b{
				width:auto;
				display:flex;
				flex-direction:column;
				height:100%;
				justify-content:center;
			}
		div{
			display:flex;
			flex-direction:column;
			flex-wrap:nowrap;
			word-break:keep-all;
			align-items: center;
			.point{
				background-color:${props => props.theme.colors.pointColor};
				color:#fff;
				text-align:center;
				box-sizing:border-box;
				padding: 0.5rem 1rem;
				border-radius:1rem;
				line-height:1;
				font-size:${props => props.theme.Rem(18)};
			}
			span{
				font-weight:600;
				font-size:${props => props.theme.Rem(18)};
				margin-top:0.4rem;
			}
		}
		b{
			margin-bottom:0;
			font-size:${props => props.theme.Rem(32)};
			margin-left:${props => props.theme.Rem(28)};
		}
	}
	
	ul{
		background-color:${props => props.theme.colors.contentBg};
		display:flex;
		flex-direction:column;
		width:100%;
		padding:${props => props.theme.Rem(22)} ${props => props.theme.Rem(18)} ${props => props.theme.Rem(22)} ${props => props.theme.Rem(28)};
		box-sizing:border-box;
		border-radius:${props => props.theme.Rem(32)};
		li{
			margin-bottom:${props => props.theme.Rem(18)};
			width:auto;
			display:flex;
			flex-direction:row;
			align-items: baseline;
			word-break:keep-all;
			flex-wrap: wrap;
			line-height: 1.6;
			&:last-of-type{
				margin-bottom:0;
			}
			b{
				color:${props => props.theme.colors.fontColor};
				font-size:1rem;
				width:auto;
				margin:0;
				height:auto;
				display:inline;
			}
			img{
				width:${props => props.theme.Rem(20)};
				height:auto;
				margin-right:0.2rem;
				flex-shrink:0;
			}
		}
	}
	
	${(props) => props.theme.device.desktopS} {
		ul{
			li{
				margin-bottom:${props => props.theme.Rem(10)};
				font-size:${props => props.theme.Rem(12)};
				b{
					font-size:${props => props.theme.Rem(12)};
				}
				img{
					width:${props => props.theme.Rem(16)};
				}
			}
		}
		.price{
			div{
				.point{
					font-size:${props => props.theme.Rem(14)};
					padding:0.3rem 0.7rem;
				}
				span{
					font-weight:600;
					font-size:${props => props.theme.Rem(14)};
					margin-top:0.4rem;
				}
			}
			b{
				margin-bottom:0;
				font-size:${props => props.theme.Rem(20)};
				margin-left:${props => props.theme.Rem(28)};
			}
		}
	}
`;

const infoTextObj = {
	title:`데이터바우처<br />가공 서비스란?`,
	content:`데이터 가공 및 분석, AI 모델링 및 솔루션 개발, 제품 고도화 등<br /> 
    다양한 데이터 가공(AI/일반) 서비스 비용을 <br />
    정부가 지원해주는 프로그램으로 무료 혹은 저렴한 비용으로<br />
    데이터 가공 서비스를 제공 받을 수 있습니다.`
};

const FirstSection = () => {
    return (
        <>
			<CustomSectionWrapper>
				<PageWrapper>
					<CustomWrapper>
						<CustomWidth100>

							<Width50 className="page-info">
                                <Info primary={false} texts = {infoTextObj}/>
							</Width50>

							<Width50 className="contents-wrapper border-left">
								<ServiceContent>
									<Width100>
										<b>
											수요기업은 2% 이하의 현금 부담으로,<br />
											바우처 금액에 해당하는 가공 서비스를 제공 받을 수 있습니다.
										</b>
									</Width100>
									<ServiceWrapper>
										<img src="/img/tri.png" alt="서비스 흐름"/>
										<Width100>
											<ServiceInfo>
												<div className="price">
													<div>
														<span className="point" >일반</span>
														<span>가공 바우처</span>
													</div>
													<b>4,500만 원</b>
												</div>

												<ul>
													<li><span><img src="/img/service_info_01.png" alt="중소기업"/>중소기업 :</span> 현금 2%<b>(113만원)</b> + *현물 18%	</li>
													<li><span><img src="/img/service_info_02.png" alt="소상공인"/>소상공인</span> <span><img src="/img/service_info_03.png" alt="1인기업"/>1인 창업자 :</span> 현금 0% + *현물 20%</li>
													<li><span><img src="/img/service_info_04.png" alt="예비창업자"/>예비 창업자 :</span> 민간부담금 없음</li>
												</ul>
											</ServiceInfo>

											<ServiceInfo>
												<div className="price">
													<div>
														<span className="point" >AI</span>
														<span>가공 바우처</span>
													</div>
													<b>7,000만 원</b>
												</div>

												<ul>
													<li><span><img src="/img/service_info_01.png" alt="중소기업"/>중소기업 :</span> 현금 2%<b>(175만원)</b> + *현물 18%	</li>
													<li><span><img src="/img/service_info_02.png" alt="소상공인"/>소상공인</span> <span><img src="/img/service_info_03.png" alt="1인기업"/>1인 창업자 :</span> 현금 0% + *현물 20%</li>
													<li><span><img src="/img/service_info_04.png" alt="예비창업자"/>예비 창업자 :</span> 민간부담금 없음</li>
												</ul>
											</ServiceInfo>
										</Width100>
									</ServiceWrapper>
									<p>*현물은 인건비 등으로 구성이 가능합니다.</p>
								</ServiceContent>
							</Width50>
						</CustomWidth100>
					</CustomWrapper>
				</PageWrapper>
			</CustomSectionWrapper>
		</>
    );
};

export default FirstSection;