import React from "react";
import { useEffect, useState, useRef } from "react";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";
import { useSimpleBarScroll } from "hooks/CustomHooks";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

const HistoryYears = styled.div`
	width: 14%;
	height: 100%;
	// height: 70%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	border-right: 1px solid ${(props) => props.theme.colors.lbgBorderColor};
	box-sizing: border-box;
	overflow: auto;
	margin-top: ${(props) => props.theme.sizes.headerHeight};
	// margin-top: 200px;
	${(props) => props.theme.device.tabletL} {
		width: 20%;
	}
`;

const YearsList = styled.ul`
	width: 100%;
	height: 100%;
	text-align: center;
	padding: 10% 0;
	box-sizing: border-box;
`;

const YearsItem = styled.li`
	font-weight: 600;
	cursor: pointer;
	font-size: ${(props) => props.theme.Rem(24)};
	color: ${(props) => props.theme.colors.dFontColor};
	margin-bottom: 63%;
    margin-left: 3%;
	width: 100%;
	text-align: center;
	position: relative;
	${(props) => props.theme.device.notebook} {
		margin-bottom: 55%;
        margin-left: 5%;
	}
	${(props) => props.theme.device.tabletL} {
		margin-bottom: 40%;
	}
	${(props) => {
		if (
			props.index === props.selectIndex ||
			(props.index === 0 && !props.selectIndex)
		) {
			return css`
				color: ${props.theme.colors.pointColor};
				&::after {
					content: "";
					position: absolute;
					left: -4px;
					top: 0;
					bottom: 0;
					margin: auto;
					width: 8px;
					height: 8px;
					border-radius: 8px;
					background-color: ${props.theme.colors.pointColor};
				}
			`;
		}
	}};
`;

const HistoryContents = styled.div`
	width: 86%;
	display: flex;
	flex-direction: row;
	${(props) => props.theme.device.tabletL} {
		width: 80%;
	}
`;

const HistoryList = styled.ul`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow: auto;
	// padding: 10% 0;
	padding-top: 10%;
	box-sizing: border-box;
`;

const HistoryItem = styled.li`
	display: flex;
	flex-direction: row;
	flex-wrap: no-wrap;
	width: 100%;
	box-sizing: border-box;
	padding: 0 ${(props) => props.theme.Rem(35)}
		${(props) => props.theme.Rem(30)};
	b {
		font-size: ${(props) => props.theme.Rem(16)};
		padding-right: ${(props) => props.theme.Rem(48)};
		box-sizing: border-box;
		text-align: right;
		width: calc(5rem + 3rem);
	}
	span {
		width: calc(100% - (5rem + 3rem));
		word-break: keep-all;
	}
	${(props) => props.theme.device.tabletL} {
		span {
			line-height: 1.4rem;
		}
	}
`;

const PrevOffsetHistoryItem = styled.li`
    min-height: 50vh;
    ${(props) => props.theme.device.notebook}{

    }
    ${(props) => props.theme.device.tabletL}{
        min-height: 15vh;
    }
`;

const NextOffsetHistoryItem = styled.li`
    min-height: 55vh;
    ${(props) => props.theme.device.notebook}{

    }
    ${(props) => props.theme.device.tabletL}{
        min-height: 20vh;
    }
`;

const MyScrollContainer = styled.div`
	// height: calc(100vh - 300px);
    height: 100%;
	width: 100%;
	// margin-top: ${(props) => props.theme.Rem(200)};
	position:relative;
	&::before{
		content:"";
		display:block;
		width:1px;
		height:100%;
		background-color:${(props) => props.theme.colors.lbgBorderColor};
		position:absolute;
		top:0;
		left:3px;	
	}
	${(props) => props.theme.device.notebook} {
		// height: calc(100vh - 250px);
        height: 100%;
        width: 100%;
		// margin-top: ${(props) => props.theme.Rem(150)};
	}
	${(props) => props.theme.device.tabletL} {
        height: 100%;
		margin-top: ${(props) => props.theme.Rem(10)};
	}
`;

const HistoryItems = styled.div`
	padding: 30px 0;
	// border-top: 1px solid ${(props) => props.theme.colors.lbgBorderColor};
	// border-bottom: 1px solid ${(props) => props.theme.colors.lbgBorderColor};
	${(props) => {
		if (props.index !== props.selectIndex) {
            if(props.isExplorer){
                return css`
                opacity: 0.35;
			`;
            } else {
                return css`
				filter: blur(0.17rem);
				opacity: 0.85;
			`;
            }
		}
	}};
`;

const PrevSpaceHistoryItems = styled.div`
	min-height: ${(props) => props.theme.Rem(200)};
	${(props) => props.theme.device.notebook} {
		min-height: ${(props) => props.theme.Rem(170)};
	}
`;


const NextSpaceHistoryItems = styled.div`
	min-height: 580px;
	${(props) => props.theme.device.notebook} {
		min-height: ${(props) => props.theme.Rem(500)};
	}
	${(props) => props.theme.device.tabletL} {
		min-height: ${(props) => props.theme.Rem(500)};
	}
`;

const History = ({ historyData }) => {

    const { device, isExplorer } = useSelector((state) => ({
        device: state.style.device,
        isExplorer: state.style.isExplorer,
    }));
	
	const yearsListRef = useRef(null);
	const historyListRef = useRef(null);
    
	const { scrollY } = useSimpleBarScroll(historyListRef);

    const [scrollOffset, setScrollOffset] = useState();
    const [yearHeightOffset, setYearHeightOffset] = useState();

	const [selectIndex, setSelectIndex] = useState(0);
    
	const [years, setYears] = useState();
	const [totalHeight, setTotalHeight] = useState();

	const [historyArry, setHistoryArry] = useState();
	
    const [offsetArry, setOffsetArry] = useState();
    const [yearOffsetArry, setYearOffsetArry] = useState();

    useEffect(() => {

		switch (device) {
			case "desktopM":
				setScrollOffset(250);
                setYearHeightOffset(45);
				break;
			case "desktop":
				setScrollOffset(150);
                setYearHeightOffset(25);
				break;
			case "mobile":
				setScrollOffset(100);
                setYearHeightOffset(20);
				break;
		};

    }, [device]);
    
	const handleItemClick = (e, index) => {
        
        let offSet = offsetArry[index].start - scrollOffset;
        
        // 맨 처음
		if(index === 0){
            offSet = 0;
		};
        
        const yearOffset = yearOffsetArry[index].start - scrollOffset + (yearHeightOffset * index) + (yearHeightOffset * (index - 1));
        
        if(isExplorer){
            historyListRef.current.getScrollElement().scrollTop = offSet;
			yearsListRef.current.getScrollElement().scrollTop = yearOffset;
        } else {
            historyListRef.current.getScrollElement().scrollTo({
                left: 0, 
                top: offSet,
                behavior: 'smooth'
            });
			yearsListRef.current.getScrollElement().scrollTo({
                left: 0, 
                top: yearOffset,
                behavior: 'smooth'
            });
        };
		
		setSelectIndex(index);
	};

	useEffect(() => {
		if(historyData){
			
			const years = [];
			const historyArry = [];
			
			Object.keys(historyData).reverse().map((obj) => {
				years.push(obj);
				historyArry.push(historyData[obj]);
			});
			
			setYears(years);
			setHistoryArry(historyArry);
		}
		
	}, [historyData]);


    useEffect(() => {

        const yearsListLength = yearsListRef.current.getContentElement().children.length;

        let totalHeight = 0;
		let offsetArry = [];

        for (let i = 0; i < yearsListLength; i++) {

			const offsetInfo = {};
			const elementHeight = yearsListRef.current.getContentElement().children[i].offsetHeight;

			offsetInfo.start = totalHeight;
			offsetInfo.end = totalHeight + elementHeight;
			if( i !== 0 && i !== yearsListLength - 1){
                offsetArry.push(offsetInfo);
            }
			
			totalHeight += elementHeight;
		}

        setYearOffsetArry(offsetArry);

    }, [years]);


	useEffect(() => {

		const historyListLength = historyListRef.current.getContentElement().children.length;

		let totalHeight = 0;
		let offsetArry = [];

		for (let i = 0; i < historyListLength; i++) {

			const offsetInfo = {};
			const elementHeight = historyListRef.current.getContentElement().children[i].offsetHeight;

			offsetInfo.start = totalHeight;
			offsetInfo.end = totalHeight + elementHeight;
			if( i !== 0 && i !== historyListLength - 1){
                offsetArry.push(offsetInfo);
            }
			
			totalHeight += elementHeight;
		}

		setTotalHeight(totalHeight);
		setOffsetArry(offsetArry);
		
	}, [historyArry]);

	useEffect(() => {	

		let targetIndex;
		const length = historyListRef.current.getContentElement().children.length;

		if (offsetArry) {

			offsetArry.map((offset, index) => {
				if ( offset.start <= scrollY + scrollOffset + 1 && offset.end > scrollY + scrollOffset + 2 ) {
					targetIndex = index;
				} 
			});

			// 맨 처음
			if(scrollY === 0) targetIndex = 0;
			// 맨 끝
			if(scrollY === totalHeight) targetIndex = length - 1;

			setSelectIndex(targetIndex);
		};

	}, [scrollY]);

	useEffect(() => {
        
        if(yearOffsetArry){

            let index;

            if(selectIndex) {
                index = selectIndex;
            } else {
                index = 0;
            };

            const yearOffset = yearOffsetArry[index].start - scrollOffset + (yearHeightOffset * index) + (yearHeightOffset * (index - 1));

            if(isExplorer){
                yearsListRef.current.getScrollElement().scrollTop = yearOffset;
            } else {
                yearsListRef.current.getScrollElement().scrollTo({
                    left: 0, 
                    top: yearOffset,
                    behavior: 'smooth'
                });
            };

        }
		
	}, [selectIndex, yearOffsetArry]);


	return (
		<>
			<HistoryYears>
				<MyScrollContainer>
					<YearsList className="scrollable-content">
					<SimpleBar
						style={{
							overflow: "auto",
							overscrollBehavior: "contain",
							width: "100%",
							height: "100%",
						}}
						ref={yearsListRef}
					>
                        <PrevOffsetHistoryItem/>
							{historyData
								? Object.keys(historyData)
										.reverse()
										.map((item, index) => {
											return (
												<YearsItem
													onClick={(e) => handleItemClick(e, index)}
													key={item}
													index={index}
													selectIndex={selectIndex}
												>
													{item}
												</YearsItem>
											);
										})
								: null}
                        <NextOffsetHistoryItem/>
						</SimpleBar>
					</YearsList>
				</MyScrollContainer>
			</HistoryYears>

			<HistoryContents>
				<HistoryList className="scrollable-content">
					<SimpleBar
						style={{
							overflow: "auto",
							overscrollBehavior: "contain",
							width: "100%",
							height: "100%",
						}}
						ref={historyListRef}
					>
						<PrevSpaceHistoryItems />
						{years &&
							years.map((year, index) => {
								return (
									<HistoryItems
										key={index}
										index={index}
										selectIndex={selectIndex}
										length={years.length}
                                        isExplorer={isExplorer}
									>
										{historyData &&
											historyData[year].map(
												(item, idx) => {
													const month = item.historyDate.split("-")[1];
													const day = item.historyDate.split("-")[2];

													let filterMonth;
													let filterDay;

													if (parseInt(month) < 10) {
														filterMonth = month.split("")[1];
													} else {
														filterMonth = month;
													}

													if (parseInt(day) < 10) {
														filterDay = day.split("")[1];
													} else {
														filterDay = day;
													}

													let contents;

													if(filterDay < 10) {
														contents = <p>{`${filterMonth}월`} {`${filterDay}일`}</p>;
													} else {
														contents = <p>{`${filterMonth}월`} {`${filterDay}일`}</p>;
													}


													return (
														<HistoryItem key={idx}>
															<b>
																{contents}
															</b>
															<span>
																{item.title}
															</span>
														</HistoryItem>
													);
												}
											)}
									</HistoryItems>
								);
							})}
						<NextSpaceHistoryItems />
					</SimpleBar>
				</HistoryList>
			</HistoryContents>
		</>
	);
};

export default History;
