import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {PageWrapper, Width100, Wrapper,} from "style/layout";
import parse from 'html-react-parser';

const CustomSectionWrapper = styled.div`
  position: relative;
  padding-top: calc(${(props) => props.theme.sizes.headerHeight} + 40px);
  ${props=>props.theme.device.tabletL}{
    padding-top: calc(${(props) => props.theme.sizes.mHeaderHeight} + 40px);  
  }
`;

const CustomPageWrapper = styled(PageWrapper)`
    height:auto;
`;

const CustomWrapper = styled(Wrapper)`
  border-color: ${(props) => props.theme.colors.lbgBorderColor};
  padding-top:${(props) => props.theme.sizes.headerHeight};
  height:auto;
  ${props => props.theme.device.tablet}{
    padding-top:${(props) => props.theme.sizes.mHeaderHeight};
  }
`;

const CustomWidth100 = styled(Width100)`
  height: 100%;
`;

const DetailContentWrapper = styled.div`
    width:100%;
    align-items:flex-start;
    display:flex;
    flex-direction:column;
`;

const DetailTitle = styled.div`
        box-sizing:border-box;
        padding-bottom: ${props => props.theme.Rem(50)};
        margin-bottom: ${props => props.theme.Rem(50)};
        width:100%;
        //border-bottom:1px solid ${props => props.theme.colors.lbgBorderColor};        
    h3{
        font-size:${props => props.theme.Rem(36)};
        font-weight:800;
        padding-bottom:${props => props.theme.Rem(28)};
        word-break:keep-all;
        ${props => props.theme.device.tablet}{
            font-size:${props => props.theme.Rem(26)};
        }
    }
    span{
        opacity:0.8;
        font-size:0.95rem;
        font-weight:300;
        span{
            margin-left:0.5rem;    
        }
    }
    b{
        margin-right:0.5rem;
        font-size:1rem;
        font-weight:800;
    }
`;

const DetailContent = styled.div`
    &&{
        // display:flex;
        // flex-direction:column;
        justify-content:flex-start;
        width: 60%;
        padding-bottom: ${props => props.theme.Rem(150)};
        box-sizing:border-box;
        .se2_quote1{
            margin:0 0 1rem 0 !important;
            p{
                margin:0 !important;
            }
        }
        p,span,b{
            text-align:left !important;
            background-color:transparent !important;
            font-family: 'Pretendard', "Malgun Gothic", "맑은 고딕", "dotum", "돋움", sans-serif !important;
            color:${props => props.theme.colors.fontColor} !important;
            font-size:1rem !important;
            height:auto;
        }
        a{
            // background-color:${props => props.theme.colors.pointColor};
            // color:#fff;
            // padding:1rem;
            // box-sizing:border-box;
            // border-radius:2px; 
            // border:1px solid transparent;
            // box-sizing:border-box;
            // transition:all 0.3s ease-in-out;
            //     &:hover{
            //         background-color:transparent;
            //         border:1px solid ${props => props.theme.colors.pointColor};
            //         color:${props => props.theme.colors.pointColor};
            //     }          
        }
        blockquote{
            border-left:2px solid ${props => props.theme.colors.pointColor} !important;
            b{
                font-weight:800 !important;
            }
        }
        span{
            line-height:1.7 !important;
            font-size:1rem;
            // word-break:keep-all;
            
        }
        p{
            margin-bottom:1rem !important;
            box-sizing:border-box;
        }
        img{
            width:70%;
        }
        ${props => props.theme.device.tablet}{
            width: 100%;
            img{
                width:100%;
            }
        }
    }
`;

const Detail = ({ detailData }) => {
    let date = "";
    const [content, setContent] = useState();

    useEffect(() => {

        const contents = detailData.contents;
        
        const normalTags = ["u", "div", "blockquote", "font", "b", "span", "p", "img", "br", "html", "head", "body", "title", "meta", "a", "link", "li", "ul", "style", "h1", "h2", "h3", "h4", "h5", "h6", "input", "form", "iframe", "nav", "strong", "footer", "header", "button", "article"];
        const filterTags = ["figure", "o:p"];

        const tags = [...normalTags, ...filterTags];

        const parseContent = parse(contents, {
            replace: domNode => {
                if(domNode.type === "tag"){
                    let flag = false;
                    tags.map((tag) => {
                        if(tag === domNode.name){
                           flag = true;
                        };
                    });
                    if(!flag){
                        return <p>{domNode.name}</p>;
                    }
                };
            }
        });
        setContent(parseContent);
    } ,[detailData]);

    const CategoryFiltering = (string) => {
        switch(string){
            case "MEDIA":
                return "언론보도";

            case "FEED":
                return "회사소식";

            case "NOTICE":
                return "공지사항";

            default:
                return "전체";
        }
    }
    return (
        <CustomSectionWrapper>
            <CustomPageWrapper>
                <CustomWrapper>
                    <CustomWidth100>
                        <DetailContentWrapper>
                            <DetailTitle>
                                <h3>{detailData && detailData.title}</h3>
                                <span>
                                    <b>{CategoryFiltering(detailData && detailData.category)}</b> |
                                    {
                                        detailData && detailData.displayDate.split("-").map(( item, idx )=>{
                                            switch( idx ){
                                                case 0:
                                                    date = item + "년 ";
                                                    break;
                                                case 1:
                                                    date += item + "월 ";
                                                    break;
                                                case 2:
                                                    date += item + "일 ";
                                                    return <span key={date}>{date}</span>;
                                            }
                                        })
                                    }
                                </span>
                            </DetailTitle>
                            <DetailContent>
                                {content}
                            </DetailContent>
                        </DetailContentWrapper>
                    </CustomWidth100>
                </CustomWrapper>
            </CustomPageWrapper>
        </CustomSectionWrapper>
    );
};

export default Detail;
