import React, {useEffect} from 'react';
import styled from "styled-components";
import {
    ContentWrapper, InfoGraphicImg,
    MobileContentsList,
    PageInfoTitle,
    PageWrapper,
    SectionInfoWrapper, Width100,
    Wrapper
} from "../../style/layout";
import Title from "../../components/landing/main/Title";
import AnimationBox from 'components/mri/third/AnimationBox';
import {useDispatch, useSelector} from "react-redux";
import {setCurrentPage} from "../../modules/page";

import {EffectCreative, EffectFade, Navigation, Scrollbar} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import {useInView} from "react-intersection-observer";
import {setObserver} from "../../modules/style";

const CustomWrapper = styled.div`
	background-image: url("/img/landing-main.png");
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center center;
	display:flex;
	width:100%;
	min-height:100vh;
	height:100%;
	.border-light{
		align-items:center;
		justify-content:center;
		display: flex;
		h2{
			width:auto;
			height:100%;
			align-items:flex-start;
			justify-content:center;
			position:relative;
			div{
				width:100%;
				position:absolute;
				bottom:20vh;
			}
			img{
				height:6vw;
			}
			p{
				width:100%;
				text-align:center;
				&:first-of-type{
					margin-top:0;
				}
				${props => props.theme.device.tabletL}{
					font-size:2rem;
				}
				${props => props.theme.device.tablet}{
					font-size:1.8rem;
				}
				${props => props.theme.device.mobile}{
					font-size:1.1rem;
				}
			}
		}
	}
`;

const MobileMainWrapper = styled(Wrapper)`
    position:relative;
    flex-direction:column;
    &::before{
        content:"";
        display:block;
        width:88vw;
        height:88vw;
        position:absolute;
        top:0;
        right:0;
        background-image:url(/img/mri_main_bg.png);
        background-size:cover;
        background-position:top center;
        background-repeat:no-repeat;
        @media (min-width: ${props => props.theme.deviceSizes.tablet}){
            width:70vw;
            height:70vw;
        }
    }
    img{
        width:30vw;
    }
    div{
        position:absolute;
        bottom:10vh;
        color:rgba(255,255,255);
        text-align:center;
        h2{
            margin-bottom:${props => props.theme.sizes.mHeaderHeight};
            span{
                font-weight:200;
            }
        }
        p{
            font-weight:300;
            line-height:1.3rem;
        }
    }
    ${props => props.theme.device.tabletL}{
        
    }
`;

const SwiperStyle = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:flex-start;
    box-sizing:border-box;
    padding:0 1rem ${props => props.theme.sizes.mHeaderHeight};
    .icon-wrapper{
        background-color:${props => props.theme.colors.pointColor};
        width:10rem;
        height:10rem;
        border-radius:10rem;
        margin-bottom:${props => props.theme.sizes.mHeaderHeight};
    }
    .text-wrapper{
        color:#fff;
        background-color:${props => props.theme.colors.pointColor};
        word-break:keep-all;
        box-sizing:border-box;
        padding:2rem 1.5rem;
        border-radius:1rem;
        h4{
            font-size: 1.4rem;
            margin-bottom:1rem;
        }
        p{
            font-size: 1.15rem;
            line-height:1.5rem;
            font-weight:300;
        }
    }
`;

const SwiperAIStyle = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    box-sizing:border-box;
    background-color:#fff;
    border-radius:1rem;
    justify-content: flex-start;
    margin-bottom:${props => props.theme.sizes.mHeaderHeight};
    .text-wrapper{
        color:${props => props.theme.colors.pointColor};
        word-break:keep-all;
        box-sizing:border-box;
        padding:2rem 1.5rem;
        h4{
            font-size: 1.4rem;
            font-weight:900;
            margin-bottom:0.5rem;
        }
        span{
            margin-bottom:1.5rem;
            display:block;
        }
        p{
            font-size: 1.15rem;
            line-height:1.5rem;
            font-weight:300;
            word-break:keep-all;
        }
    }
`;

export const CaseSlideStyle = styled.div`
    background-color:#fff;
    .case-style{
        color:${props => props.theme.colors.pointColor};
        display:flex;
        flex-direction:column;
        margin-bottom:2.5rem;
        span{
            font-size:1.7rem;
        }
        b{
            font-weight:800;
            font-size:2.9rem;
        }
    }
    
    .logo{
        width:50%;
        display:block;
        max-width:250px;
        margin-bottom:2.5rem;
    }
    
    h4{
        font-size:1.4rem;
        word-break:keep-all;
        color:${props => props.theme.colors.pointColor};
        & + p{
            font-size:1.15rem;
            word-break:keep-all;
            font-weight:600;
            margin-top:0.8rem;
            margin-bottom:2.5rem;
        }
    }
    ol{
        margin-bottom:${props => props.theme.sizes.mHeaderHeight};
        li{
            counter-increment: circle-counter 1;
            font-weight: 400;
            font-size: 1rem;
            margin-bottom: 1.125rem;
            display: flex;
            flex-direction: row;
            width: 100%;
            height: auto;
            word-break: keep-all;
            padding-right: 10%;
            box-sizing: border-box;
            &::before{
                content: counter(circle-counter);
                background-color: transparent;
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                display: flex;
                flex-direction: row;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                font-size: 0.8rem;
                border: 1px solid rgb(90, 90, 119);
                color: rgb(90, 90, 119);
                margin-right: 0.5rem;
                box-sizing: border-box;
                text-indent: 0rem;
                margin-top: 0rem;
                padding: 0.3rem;
            }
        }
    }
    .sub-list{
        display:block;
        width:100%;
        margin-bottom:${props => props.theme.sizes.mHeaderHeight};
        ul{
            width:100%;
            display:flex;
            flex-direction:row;
            flex-wrap:no-wrap;
            li{
                width:calc(100% / 3);
                font-size:0.8rem;
                > b{
                    display:block;
                    margin-bottom:0.5rem;
                }
                p{
                    font-size:0.9rem;
                    font-weight:300;
                    b{
                        font-weight:800;
                    }
                }
            }
        }
        
        & + img{
            width:100%;
            display:block;
            min-height:1px;
        }
    }
`;

const perspectivesObj = {
    "P4P":`대안 데이터에 대한 AI 분석을 통해 기업 경쟁력을 산업 맥락과 유사 기업군에 대한 백분율 관점에서 산출하는 알고리즘`,
    "Doppelganger":`AI Classification 기반으로 유사 기업군을 자동 도출하고, 가치 배수 (Multiple) 역 적용을 통해 기업 가치를 산정`,
    "Judgement Day":`과거 상장 신청 기업들에 대한 AI 기반의 속성 분석을 통해 진단 기업의 향후 상장 가능성을 정량화`,
    "Impact Count-Up":`기업의 온라인 활동 로그를 자동 조사하여 사회적 가치 (SV) 및 기술 혁신성 (TI) 부문을 점수화하여 도출`,
    "Reference Model":`산업 생태계에서 특정 기업의 활동 기록 (수상, 제휴, 선정, 보도, 투자 등) 정보를 데이터화하여 시장 내 영향력 및 관심을 정량화`,
    "Victory Road":`성공 기업을 (매출, 투자, 시가총액 등) 기준 별로 유형화하고, 해당 기업 과거 데이터에 대한 AI 분석 통해 현재 유사 초기기업 선별`,
    "Tech Scanner":`키워드 라이브러리 (Keyword Repository) 및 기업 텍스트 데이터 교차 연산을 통해 관련 기업 발굴하고 AI 기반 우선순위화`,
    "ESG Breakdown":`ESG (Environment, Social, Governance) 혹은 사회적 가치를 부문 별로 유형화하고 개별 영역에 대한 AI 관점의 정량적인 가치 책정`,
    "PULSE":`뉴스 데이터에 대한 AI RFM (Recency, Frequency, Magnitude) 모형을 적용하여 리스크 (부도 / 연체 / 자본잠식) 수준 측정`,
    "Screener Tech":`투자자 혹은 투자기관의 온라인 검색 기록을 활용하여 베타 계수 (β) 단위 기준으로 투자 위험 회피 (Risk Aversion) 성향 산출`
}

const superiorObj = {
    0:["데이터 자동 전처리","AI Machine Annotation",`비 금융 대안 데이터 자동 전처리 체계 기반 학습용 데이터 구축<br/ ><br/ > 자동 가공 및 표준화 통해 AI 분석 위한 데이터 준비 비용/공수를 획기적으로 절감`],
    1:["다각적 데이터 통합","Multilateral Data Integration",`다양한 출처와 상이한 속성의 기업 데이터를 단일 템플릿 (Master Template) 내로 통합 일원화된 데이터베이스를 마련하여 다채로운 분석 모형을 다각적으로 적용`],
    2:["맞춤화 분석 패키지","Curated Analytics Package",`기업 분석 목적과 속성을 고려하여 AI 기반 최적의 진단 모형과 활용 데이터를 제안<br/ ><br/ > 프로젝트 유형에 따른 분석 패키지 제공을 통해 고객 별 1:1 맞춤화 실현`],
    3:["기업 진단 특화 알고리즘","Targeted Algorithm Library",`기업 분석 목적을 위하여 설계된 전용 알고리즘 및 AI 모형 라이브러리 제공<br/ ><br/ > 투자 잠재성, 리스크 수준, 지속 가능성 등 금융 / 경영 목적에 특화된 분석 체계 확립`],
    4:["동시 다발적 분석","Simultaneous Diagnosis System",`다양한 영역의 기업 진단과 금융 분석을 동시 연산하여 산출<br/ ><br/ > 순차적인 분석 프로세스에서 탈피하여 분석 시간을 획기적으로 단축`],
    5:["투명한 설명 체계","White-Box Architecture",`기업 평가 및 진단 결과에 대한 논리적인 원인 구조를 투명하게 공개<br/ ><br/ > 예측 결과와 주요 변수와의 상관성을 파악하여 기업 분석의 설명력 강화`],
}

export const mriSlideObj = {
    "01":{
        "logoURL":"/img/customer/ibk_bank.png",
        "title":"IBK 기업은행",
        "subTitle":"공공데이터 RFM 분석 기반 중소기업 대안신용평가 AI 모형 구축",
        "list":[
            "공시 의무가 없어 전통적 금융 정보(재무)가 부족한 중소기업을 대상으로 비재무 공공데이터를 활용한 부도 리스크 AI 측정 모형 구축",
            "중소기업별 공공데이터의 발생 현상(시점-Recency, 빈도-Frequency, 규모-Magnitude)에 따라 평가 항목 유의성 검증하여 부도 예측 모형에 대입",
            "평가 모형의 결과치를 실제 기업은행의 부도 차주 정보와 비교하여 Confusion Matrix 및 Accuracy Ratio 등을 활용하여 모형의 성능 입증"
        ],
        "subList":["24만 개","1,169만 개","사업화 계약"],
        "thumbnail":"/img/landing04.png"
    },
    "02":{
        "logoURL":"/img/customer/moneytoday.png",
        "title":"머니투데이",
        "subTitle":"AI 기술을 활용한 유망 스타트업 발굴 및 분석 리포트 자동화 생성 시스템",
        "list":["언론사 자체 생산 뉴스(텍스트) 데이터 대상 AI 기계 독해를 통해 혁신적 기술을 보유한 벤처 및 각종 창업기업 자동화 발굴","발굴 기업에 대한 공공데이터를 추가적으로 수집하고 Auto ML 기반의 AI 경영 진단 결과를 자동화된 투자 리포트(IM) 형태로 산출","개발 시스템의 성능은 AI 기술분류 합리성, 기업정보 정합성, 기업평가 정교성, 투자제안 실효성 등 4대 기준으로 설정하여 각 기준별 목표치 달성 여부로 판단"],
        "subList":["5만 개","1,500만 개","사업화"],
        "thumbnail":"/img/landing04.png"
    },
    "03":{
        "logoURL":"/img/customer/seoul.png",
        "title":"서울대학교",
        "subTitle":"빅데이터 및 AI 활용한 사회적 경제 기업 가치 자동화 측정 알고리즘 개발",
        "list":["사회적 가치 인센티브 제공 프로그램에 참여한 사회적 경제 기업에 대한 과거 5개년에 대한 공공데이터 자동화 수집","대안 데이터에 대한 AI 판별 및 분석을 통해 개별 기업의 세부적 고용, 서비스, 환경 성과를 정량화된 방식으로 예측","기존 인센티브 평가 결과와 AI 알고리즘 진단 결과를 대조 비교하여 자동화 알고리즘에 대한 설명력 도출"],
        "subList":["800개","2만 개","사업화 계약"],
        "thumbnail":"/img/landing04.png"
    },
    "04":{
        "logoURL":"/img/customer/seempower.png",
        "title":"SE임파워 사회적협동조합",
        "subTitle":"사회적 경제 데이터 활용 중소기업 특화 ESG 진단 AI 컨설턴트 시스템",
        "list":[
            "중소기업 대안 데이터에 대한 AutoML 기반 AI 학습을 통해 ESG 예측 및 진단 모형을 개발하여 개별 중소기업의 ESG 측명의 성과 평가",
            "평가 결과 시각화하여 중소기업의 객관적이며 과학적인 ESG 자가진단 가능하게 하여, 자발적 형태의 지속가능경영 도입 지원",
            "환경 (E), 사회 (S), 지배구조 (G) 개별 영역 별 통계적 설명력 관점의 Cut-Off 기준 R²을 설정하여 시스템 성능 검증 수행"
        ],
        "subList":["1,300개","5만 개","사업화"],
        "thumbnail":"/img/landing04.png"
    }
}
const MobileMri = () => {
    const dispatch = useDispatch();

    const { device,observer } = useSelector((state) => ({
        device: state.style.device,
        observer: state.style.observer,
    }));
    const { ref, inView } = useInView({});

    useEffect(()=>{
        dispatch(setObserver(inView));
    },[inView]);

    useEffect( ()=> {
        dispatch(setCurrentPage("mri"));
    },[device])

    return (
        <>
            <CustomWrapper ref={ref}>
                <PageWrapper>
                    <MobileMainWrapper className="border-light">
                        <img src="/img/antock_mri.png" alt="Hubble Database"/>
                        <div>
                            <h2><span>Antock MRI</span><br/>기업 분석의 새로운 방향</h2>
                            <p>
                                AI 학습 알고리즘 토대로 기업의 상태와<br />
                                가치를 과학적으로 측정하고<br />
                                평가, 예측, 인사이트 창출을 지원합니다.
                            </p>
                        </div>
                    </MobileMainWrapper>
                </PageWrapper>
            </CustomWrapper>

            <PageWrapper>
                <ContentWrapper className="info-wrapper border-tb-dark" style={{borderWidth:"1px"}}>
                    <PageInfoTitle>
                        Versatile AI<br />Evaluation
                    </PageInfoTitle>
                </ContentWrapper>
                <ContentWrapper>
                    <MobileContentsList>
                        <li>
                            <span>진단 영역</span>
                            <h3>10대 영역</h3>
                            <p>
                                기업의 핵심 평가를 10대 영역으로
                                구분하여 맞춤화 진단
                            </p>
                        </li>
                        <li>
                            <span>분석 지표</span>
                            <h3>약 2,000개</h3>
                            <p>
                                기계 학습을 통하여 약 2,000개 이상의
                                진단 정보를 기업 별로 제공
                            </p>
                        </li>
                        <li>
                            <span>활동 모형</span>
                            <h3>30개</h3>
                            <p>
                                기업 가치, 기술 혁신, 사회적 기여 등
                                추상적 영역에 대한 정량화 예측
                            </p>
                        </li>
                    </MobileContentsList>
                </ContentWrapper>
            </PageWrapper>

            <PageWrapper>
                <ContentWrapper className="info-wrapper border-tb-dark" style={{borderWidth:"1px"}}>
                    <PageInfoTitle>
                        Antock Original<br />
                        Perspectives
                    </PageInfoTitle>
                </ContentWrapper>
                <ContentWrapper className="border-dark">
                    <Swiper
                        modules={[Scrollbar]}
                        spaceBetween={30}
                        slidesPerView={"auto"}
                        scrollbar={{ draggable: true }}
                        // onSlideChange={() => console.log('slide change')}
                        // onSwiper={(swiper) => console.log(swiper)}
                    >
                        { perspectivesObj ? Object.keys(perspectivesObj).map((key)=>{
                            return <SwiperSlide style={{display:"flex",maxWidth:"320px"}}>
                                        <SwiperStyle>
                                            <div className="icon-wrapper"/>
                                            <div className="text-wrapper">
                                                <h4>{key}</h4>
                                                <p>{perspectivesObj[key]}</p>
                                            </div>
                                        </SwiperStyle>
                                    </SwiperSlide>
                        })
                        : null }
                    </Swiper>
                </ContentWrapper>
                <ContentWrapper className="border-dark" />
            </PageWrapper>

            <PageWrapper>
                <ContentWrapper className="info-wrapper border-tb-dark" style={{borderWidth:"1px"}}>
                    <PageInfoTitle>
                        AI Driven<br />
                        Customized Analytics
                    </PageInfoTitle>
                </ContentWrapper>
                    <ContentWrapper>
                        {
                            device === "tablet" ?
                                // <InfoGraphicImg src="/img/mri03.png" alt="목적과 특성에 따라 AI 큐레이션을 통해 맞춤화된 형태로 구성"/>
                                <Width100>
                                    <AnimationBox />
                                </Width100>

                                : <InfoGraphicImg src="/img/mri_m_02.png" alt="AI Driven Customized Analytics 인포그래픽"/>
                        }
                    </ContentWrapper>
                <ContentWrapper>
                    <SectionInfoWrapper>
                        목적과 특성에 따라 AI 큐레이션을 통해
                        진단 영역, 활용 데이터, 분석 알고리즘을
                        맞춤화된 형태로 구성합니다.
                    </SectionInfoWrapper>
                </ContentWrapper>
            </PageWrapper>

            <PageWrapper style={{background:"radial-gradient(#1210c9,#1513bd,#1b1aa3)"}}>
                <ContentWrapper className="info-wrapper border-light" style={{borderWidth:"1px"}}>
                    <PageInfoTitle style={{color:"#fff"}}>
                        Superior<br />AI Technology
                    </PageInfoTitle>
                </ContentWrapper>
                <ContentWrapper className="border-light swiper-bg-point" style={{padding:"0",paddingBottom:"50px"}}>
                    <Swiper
                        modules={[Scrollbar]}
                        spaceBetween={30}
                        slidesPerView={"auto"}
                        scrollbar={{ draggable: true }}
                        // onSlideChange={() => console.log('slide change')}
                        // onSwiper={(swiper) => console.log(swiper)}
                    >
                        {superiorObj ? Object.keys(superiorObj).map((contents)=>{
                            return <SwiperSlide style={{display:"flex",height:"auto",maxWidth:"320px"}}>
                                <SwiperAIStyle>
                                    <div className="text-wrapper">
                                        <h4>{superiorObj[contents][0]}</h4>
                                        <span>{superiorObj[contents][1]}</span>
                                        <p dangerouslySetInnerHTML={{ __html: superiorObj[contents][2] }} />
                                    </div>
                                </SwiperAIStyle>
                            </SwiperSlide>
                        })
                        :null}
                    </Swiper>
                </ContentWrapper>
                <ContentWrapper className="border-light" style={{borderWidth:"1px"}}>
                    <SectionInfoWrapper style={{color:"#fff"}}>
                        AI 기반의 기업 데이터 수집, 분석,
                        검증을 수행하여 전 프로세스 상에서
                        차별적 경쟁 우위를 달성합니다.
                    </SectionInfoWrapper>
                </ContentWrapper>
            </PageWrapper>

            <PageWrapper>
                <ContentWrapper className="info-wrapper border-t-dark" style={{borderWidth:"1px 1px 0 1px"}}>
                    <PageInfoTitle> Success Case </PageInfoTitle>
                </ContentWrapper>

                <ContentWrapper style={{padding:"0",paddingBottom:"50px"}} className="case-swiper">
                    <Swiper
                        modules={[Navigation,EffectCreative]}
                        navigation={true}
                        spaceBetween={0}
                        slidesPerView={1}
                        // onSlideChange={() => console.log('slide change')}
                        // onSwiper={(swiper) => console.log(swiper)}
                        effect={"creative"}
                        creativeEffect={{
                            prev: {
                                translate: [0, 0, -400],
                                opacity:0,
                            },
                            next: {
                                translate: ["100%", 0, 0],
                                opacity:1,
                            },
                        }}
                    >
                        {mriSlideObj ? Object.keys(mriSlideObj).map((caseNum,idx)=>{
                                return <SwiperSlide>
                                    <CaseSlideStyle>
                                        <div className="case-style">
                                            <span>case</span>
                                            <b>{caseNum}</b>
                                        </div>
                                        <div className="text-wrapper">
                                            <img src={mriSlideObj[caseNum]["logoURL"]} alt="logo" className="logo"/>
                                            <h4>{mriSlideObj[caseNum]["title"]}</h4>
                                            <p>{mriSlideObj[caseNum]["subTitle"]}</p>
                                           <div>
                                               <ol>
                                                   {
                                                       mriSlideObj[caseNum]["list"].map((listItem)=>{
                                                           return <li>{listItem}</li>
                                                       })
                                                   }
                                               </ol>
                                           </div>
                                            <div className="sub-list">
                                                <ul>
                                                    <li>
                                                        <b>가공 표본</b>
                                                        <p><b>{mriSlideObj[caseNum]["subList"][0]}</b> {idx === 0 ? " 차주" : "기업"}</p>
                                                    </li>
                                                    <li>
                                                        <b>가공 객체</b>
                                                        <p><b>{mriSlideObj[caseNum]["subList"][1]}</b> 항목</p>
                                                    </li>
                                                    <li>
                                                        <b>개발실적</b>
                                                        <p><b>{mriSlideObj[caseNum]["subList"][2]}</b> {idx === 3 || idx === 1 ? " / 시험인증 통과" : null}</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <img src={mriSlideObj[caseNum]["thumbnail"]} alt="thumbnail"/>
                                        </div>
                                    </CaseSlideStyle>
                                </SwiperSlide>
                            })
                            :null}
                    </Swiper>
                </ContentWrapper>
            </PageWrapper>
        </>
    );
};

export default MobileMri;