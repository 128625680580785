import React from "react";
import styled from "styled-components";
import { Width33 } from "style/layout";

import "../../../layout.scss";

const CustomCustomerList = styled.ul`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	//width:75%;
	
	li {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: ${(props) => props.theme.Rem(30)};
		
		&:first-child {
			margin-bottom: ${(props) => props.theme.Rem(30)};
		}
		img {
			//transform: scale(0.7);
			transform-origin: left;
			max-width:${(props) => props.theme.Rem(160)};
			// max-height:${(props) => props.theme.Rem(30)};
			margin-bottom:2rem;
			&.h-logo{
				max-width:70%;	
			}
			&.fixed-height{
				max-width:100%;
				height:${(props) => props.theme.Rem(28)};
			}
		}
	}
	${props => props.theme.device.desktopS}{
		li {
			margin-bottom:${props => props.theme.Rem(25)};
			&:first-child {
				margin-bottom: ${(props) => props.theme.Rem(20)};
			}
			img {
				// transform: scale(0.6);
				margin-bottom:2rem;
				max-width: ${(props) => props.theme.Rem(130)};
				transform-origin: left;
			}
		}	
	}
	${props => props.theme.device.tabletL}{
		li {
			margin-bottom:${props => props.theme.Rem(20)};
			&:first-child {
				margin-bottom: ${(props) => props.theme.Rem(15)};
			}
			img {
				// transform: scale(0.5);
				// transform-origin: left;
			}
		}
`;

const CustomTitle = styled.li`
	//display: flex;
	width: ${(props) => props.theme.Rem(160)};
	//flex-direction:column;
	//justify-content: flex-start;
	//align-items: flex-start;
	b {
		font-size: 1rem;
		margin-bottom: 1rem;
		font-weight: 700;
	}
	p {
		font-size: rem(18px);
		font-weight: 300;
	}
`;

const CustomWidth33 = styled(Width33)`
	flex-direction:column;
	justify-content:center;
	&:nth-of-type(2) {
		margin-top:10%;
	}
	&:nth-of-type(3) {
		margin-top:20%;
	}
`;

const Contents = () => {
	return (
		<>
			<CustomWidth33 className="border-left">
				<CustomCustomerList>
					<CustomTitle><b>핵심 고객</b></CustomTitle>
					<li>
						<img src="/img/customer/kbfg.png" alt="kb금융그룹" />
					</li>
					<li>
						<img src="/img/customer/woorigroup.png" alt="우리금융그룹"/>
					</li>
					<li>
						<img src="/img/customer/ibk_bank2.jpeg" alt="기업은행" />
					</li>
					<li>
						<img src="/img/customer/kised.png" alt="창업진흥원"/>
					</li>
					<li>
						<img src="/img/customer/moneytoday.png" alt="머니투데이" />
					</li>
					<li>
						<img src="/img/customer/cses.png" alt="사회적가치연구원"/>
					</li>
					{/*<li>*/}
					{/*	<img src="/img/customer/kyobo.png" alt="교보생명"/>*/}
					{/*</li>*/}
					{/*<li>*/}
					{/*	<img src="/img/customer/fnguide.jpg" alt="에프엔가이드"/>*/}
					{/*</li>*/}
					{/*<li>*/}
					{/*	<img src="/img/customer/seempower.png" alt="에스이임파워 협동조합"/>*/}
					{/*</li>*/}
					{/*<li>*/}
					{/*	<img src="/img/customer/kt.jpg" alt="KT 커머스"/>*/}
					{/*</li>*/}
				</CustomCustomerList>
			</CustomWidth33>
			<CustomWidth33 className="border-left">
				<CustomCustomerList>
					<CustomTitle>
						<b>후원 기관</b>
					</CustomTitle>
					<li>
						<img src="/img/customer/dinnolab2.jpeg" alt="dinnolab" />
					</li>
					<li>
						<img src="/img/customer/kb_innovation_hub.png" alt="kb innovation hub" />
					</li>
					<li>
						<img src="/img/customer/ibank_lab.jpeg" alt="ibk 퍼스트 랩"/>
					</li>
					<li>
						<img src="/img/customer/kosme.png" alt="중소벤처기업진흥공단" />
					</li>
					<li>
						<img src="/img/customer/koreafintech.png" alt="한국핀테크지원센터"/>
					</li>
					<li>
						<img src="/img/customer/nia.png" alt="한국지능정보사회진흥원 nia"/>
					</li>
				</CustomCustomerList>
			</CustomWidth33>
			<CustomWidth33 className="border-left">
				<CustomCustomerList>
					<CustomTitle>
						<b>수상 기관</b>
					</CustomTitle>
					<li>
						<img src="/img/customer/finece.png" className={"fixed-height"} alt="금융위원회"/>
					</li>
					<li>
						<img src="/img/customer/tech.png" className={"fixed-height"} alt="과학기술정보통신부" />
					</li>
					<li>
						<img src="/img/customer/mois.png" className={"fixed-height"} alt="행정안전부"/>
					</li>
					<li>
						<img src="/img/customer/kipris.png" className={"fixed-height"} alt="특허청"/>
					</li>
					<li>
						<img src="/img/customer/kosme.png" alt="중소벤처기업진흥공단" />
					</li>
					<li>
						<img src="/img/customer/nia.png" alt="한국지능정보사회진흥원 nia"/>
					</li>
				</CustomCustomerList>
			</CustomWidth33>
		</>
	);
};

export default Contents;
