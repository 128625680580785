import React, { useEffect, useState } from "react";
import styled, {css} from "styled-components";

import { ScrollButton } from "style/layout";
import { useSelector } from "react-redux";

import NavBar from "components/NavBar";

const TopButton = styled(ScrollButton)`
  transform: rotate(-180deg);
  border: none;
  bottom: ${(props) => props.theme.Rem(66)};
    
`;

const PrevButton = styled(ScrollButton)`
  transform: rotate(-180deg);
  ${props => {
    if(props.page === "company"){
        return css`
                bottom: ${(props) => props.theme.Rem(210)};
            `;
    }else if(props.page === "dvdata" ||props.page === "dvai"){
        return css`
                bottom: ${(props) => props.theme.Rem(240)};
            `;
    }else{
        return css`
                  bottom: ${(props) => props.theme.Rem(280)};
                  `;
    }
}}
`;

const NextButton = styled(ScrollButton)``;

const MenuBar = (props) => {
    const { currentPage,currentSection } = useSelector((state) => ({
        currentSection: state.page.currentSection,
        currentPage: state.page.currentPage
    }));

    const handleTopClick = () => {
        props.onHandleTop();
    };

    const handlePrevClick = () => {
        props.onHandlePrev();
    };

    const handleNextClick = () => {
        props.onHandleNext();
    };

    const Top = () => {
        return (
            <>
                <TopButton onClick={handleTopClick}>
                    <img src="/img/nav_arrow.png" alt="아래" />
                </TopButton>
            </>
        );
    };

    const Prev = () => {
        return (
            <>
                <PrevButton onClick={handlePrevClick} page={currentPage}>
                    <img src="/img/nav_arrow.png" alt="아래" />
                </PrevButton>
            </>
        );
    };

    const Next = () => {
        return (
            <>
                <NextButton onClick={handleNextClick}>
                    <img src="/img/nav_arrow.png" alt="아래" />
                </NextButton>
            </>
        );
    };

    const Content = () => {
        switch (currentSection) {
            case 1:
                return (
                    <>
                        <NavBar /><Next />
                    </>
                );
            case 4:
                return (
                    currentPage === "company" ?
                        <>
                            <Top />
                        </>
                        :
                        <>
                            <Prev /><NavBar /><Next />
                        </>
                );
            case 5:
                return (
                    currentPage === "dvdata" || currentPage === "dvai" ?
                        <>
                            <Top />
                        </>
                        :
                        <>
                            <Prev /><NavBar /><Next />
                        </>
                );
            case 7:
                return (
                    <>
                        <Top />
                    </>
                );

            default:
                return (
                    <>
                        <Prev /><NavBar /><Next />
                    </>
                );
        }
    };

    return Content();
};

export default MenuBar;
