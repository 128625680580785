import React, { useState, useRef, useEffect } from "react";
import { animated, useSpring } from "react-spring";

const pathArr = [
    "M688, 750 c88,194 324,0 385,162",
    "M498, 750 c88,194 324,0 385,162",
    "M1082, 750 c88,194 324,0 385,162",
];

const ThirdLine = ({ init, data }) => {

    const lineRef = useRef(null);
	const [length, setLength] = useState(null);

	useEffect(() => {
		setLength(lineRef.current.getTotalLength());
	}, [length, data]);

	const animatedStyle = useSpring({
		from: { length },
		to: { length: 0 },
		delay: 5500,
		config: { duration: 500 },
		reset: init,
	});

	return (
		<>
			<animated.path
				ref={lineRef}
                id={data.id}
				data-name={data.id}
				d={data.d}
				transform={data.transform}
				fill="transparent"
				stroke="#1210c7"
				strokeDashoffset={animatedStyle.length}
				strokeDasharray={length}
				strokeWidth={3}
			/>
		</>
	);
};

export default React.memo(ThirdLine);
