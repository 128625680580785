import React from "react";
import styled from "styled-components";

import {useRef} from "react";

import {useCaseSlider} from "hooks/CustomHooks";

const CaseSliderLogoWrapper = styled.div`
  width: 100%;
  height: 80px;
  margin-top: ${(props) => props.theme.Rem(24)};
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 78%;
    max-width: 55%;
    max-height: 100%;

    transition: all 0.3s ease-in-out;
    transform: translate(${(props) => props.theme.Rem(-100)}, 0);
    opacity: 0;

    &.active {
      opacity: 1;
      transform: translate(0, 0);
    }
    &.active-out {
      opacity: 0;
      transform: translate(${(props) => props.theme.Rem(100)}, 0);
    }
  }
`;

const CaseSliderLogo = ({sliderIndex}) => {
	const sliderRef = useRef(null);
	useCaseSlider(sliderIndex, sliderRef);

	return (
		<CaseSliderLogoWrapper ref={sliderRef}>
			<img src="/img/customer/ibk_bank.png"/>
			<img src="/img/customer/moneytoday.png"/>
			<img src="/img/customer/seoul.png"/>
			<img src="/img/customer/seempower.png"/>
		</CaseSliderLogoWrapper>
	);
};

export default CaseSliderLogo;
