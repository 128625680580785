import React from "react";
import styled from "styled-components";

import { PageLogo, PageWrapper, Width100, Wrapper } from "style/layout";


const CustomSectionWrapper = styled.div``;

const CustomWidth100 = styled(Width100)`
	height: 100%;
	position:relative;
`;
const VideoWrapper = styled.div`
	width: 70%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-image: url(/img/macbook1.png);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	margin:0 auto;
	video{
		width: 49.3vw;
		margin-top: -4%;
		margin-left: 1%;
	}
  `;
const ThirdSection = () => {
	return (
		<>
			<CustomSectionWrapper>
				<PageWrapper>
					<Wrapper>
						<CustomWidth100>
							<PageLogo src="/img/hubble_h_b.png" alt="Hubble Database"/>
							<VideoWrapper>
								<video data-autoplay="true" src="/img/all.mp4" muted autoPlay loop/>
							</VideoWrapper>
							<PageLogo src="/img/hubble_h_b.png" alt="Hubble Database"/>
						</CustomWidth100>
                    </Wrapper>
				</PageWrapper>
			</CustomSectionWrapper>
		</>
	);
};

export default ThirdSection;
