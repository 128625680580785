import React, {  useRef } from "react";
import { animated, useSpring } from "react-spring";

const QuadCricle = ({ color, init, data }) => {
	

    const shapeRef = useRef(null);

	const animationFillStyle = useSpring({
        from: { fillColor: "#b8b8c6" },
        to: { fillColor: color }, 
		config: { duration: 1000 },
		delay: 6500,
        reset: init,
	});

	return (
		<>
			<animated.path
                ref={shapeRef}
                fill={animationFillStyle.fillColor}
                id={data.id}
				data-name={data.id}
				d={data.d}
				transform={data.transform}
			></animated.path>
		</>
	);
};

export default React.memo(QuadCricle);
