import React from 'react';
import { animated, useSpring } from "react-spring";

const transform = [
	"translate(30 415)",
	"translate(120 415)",
	"translate(208 415)",
	"translate(298 415)"
]

const FourthText = ({ index, textData, init, onHandleRest }) => {

	const animationFillStyle = useSpring({
		from: { fillColor: "#b8b8c6" },
		to: { fillColor: "#1816c9" },
		config: { duration: 1000 },
		delay: 5000,
		reset: init,
		onRest: onHandleRest,
	});

	return (
		<>

			<animated.text
				id="Data_Set_06"
				data-name="Data Set 06"
				transform={transform[index]}
				font-size="14"
				font-family="SegoeUI, Segoe UI"
				fill={animationFillStyle.fillColor}
			>
				<tspan x="-26.072" y="0">
					Data Set
				</tspan>

				<tspan x="-8.547" y="16">
					{textData}
				</tspan>
			</animated.text>

		</>
	);
};

export default FourthText;