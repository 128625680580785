import React from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";

const TitleWrapper = styled.h2`
	height: 51vh;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width:100%;
	align-items: flex-start;
    justify-content: flex-end;
	img {
		width:auto;
		height:10%;
		margin-bottom:3%;
		flex-shrink:0;
	}
	p {
		font-size: 1.35rem;
		text-indent: 0;
		line-height: 1.5;
		color: #b8b8c6;
		font-weight: 400;
		&:first-of-type{
			margin-top:3%;
		}
	}
	// ${props => props.theme.device.tabletL}{
	// 	height:auto;
	// 	width:auto;
	// 	justify-content: center;
	// 	max-width: none;
	// 	img {
	// 		height:8%;
	// 	}
	// }
	// ${props => props.theme.device.tablet}{
	// 	width:100%;
	// 	height:auto;
	// 	img {
	// 		min-height: 0;
	// 		max-width:100%;
	// 		height:2rem;
	// 	}
	// 	p {
	// 		font-size: 1.1rem;
	// 	}
	// }
`;

const Title = (props) => {
	const {device} = useSelector((state) => ({
		device: state.style.device,
	}));

	return (
		<TitleWrapper>
			<img
				src="/img/main-title_01.png"
				alt="No.1"
			/>
			<img
				src="/img/main-title_02.png"
				alt="Financial Big Data"
			/>
			<img
				src="/img/main-title_03.png"
				alt="Ai Analytics"
			/>
			{
				props && props.mobile ?
					<div>
 						<p>빅 데이터를 활용하여 국내 기업 생태계를</p>
						<p>전산화 하고 AI 분석을 통해</p>
						<p>우량 기업과 부실 기업을 분별합니다.</p>
					</div>
				:
					<>
						<p>빅 데이터를 활용하여 국내 기업 생태계를 전산화 하고</p>
						<p>AI 분석을 통해 우량 기업과 부실 기업을 분별합니다.</p>
					</>
			}

		</TitleWrapper>
	);
};

export default Title;
