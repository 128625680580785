import { combineReducers } from 'redux';
import page from './page';
import style from './style';
import news from './news';

const rootReducer = combineReducers({
    page,
    style,
    news,
});

export default rootReducer;