import React from "react";
import styled from "styled-components";

import {
	Height100,
	Height50, MriPageLogo,
	PageLogo,
	PageWrapper, textBoxContents, textBoxSub, textBoxTitle,
	Width100,
	Width33,
	Width45,
	Width55,
	Wrapper
} from "style/layout";
import Info from "../Info";

const CustomWrapper = styled(Wrapper)`
	border-color:${props => props.theme.colors.borderColor};
`;

const CustomSectionWrapper = styled.div`
	background: radial-gradient(#1210C9,#1513BD,#1B1AA3);
`;

const CustomWidth100 = styled(Width100)`
	height: 100%;
	position:relative;
`;

const PageInfoWidth45 = styled(Width45)`
	height: 100%;
	box-sizing: border-box;
	flex-wrap: nowrap;
	align-items: flex-start;
	justify-content:center;
	margin-top: -#{${(props) => props.theme.sizes.pageMarginTop}};
    flex-direction: column;
    border-right:1px solid ${props => props.theme.colors.lbgBorderColor};
    position:relative;
    margin-top:0;
`;

const PageWidth55 = styled(Width55)`
	padding: 1.875rem;
    box-sizing: border-box;
    flex-direction: column;
    border-color: rgba(90,90,119,0.08);
    justify-content: center;
    flex-shrink: 0;
    color: white;
    padding:0;
`;

const CustomHeight50 = styled(Height50)`
	box-sizing: border-box;
	padding-top:10%;
	padding-bottom:13%;
	padding-right:${props => props.theme.Rem(15)};
	padding-left:${props => props.theme.Rem(25)};

	&:first-of-type{
		justify-content: flex-end;
		border-bottom:1px solid ${props => props.theme.colors.borderColor};
		box-sizing:border-box;
	}
	&:last-of-type{
		padding-top:13%;
		justify-content: flex-start;
	}
	&:hover{
		background-image:url("/img/superior_hover.png");
		background-repeat:no-repeat;
		background-size:cover;
		background-position:top left;
		color:#fff;
		.text-box {
			.sub-title {
				color:#fff;
			}
			.contents {
				color:#fff;
			}
		}
	}
	
	.text-box{
		z-index:1;
		.title{
			${textBoxTitle}
			font-size:${props => props.theme.Rem(24)};
			margin-bottom:${props => props.theme.Rem(50)};
		}
		.sub-title {
			${textBoxSub}
			font-size:${props => props.theme.Rem(16)};
			font-weight: 400;
			color: ${props => props.theme.colors.dFontColor};
			margin-bottom:${props => props.theme.Rem(12)};
		}
		.contents{
			${textBoxContents}
			word-break: keep-all;
			line-height:${props => props.theme.Rem(24)};
			font-size:0.8rem;
			color: ${props => props.theme.colors.dFontColor};

			&:last-of-type{
				margin-top:${props => props.theme.Rem(24)};
			}
		}
	}
	${props => props.theme.device.desktopS}{
		.text-box{
			.title{
				font-size:${props => props.theme.Rem(20)};
				margin-bottom:${props => props.theme.Rem(22)};
			}
			.sub-title {
			}
			.contents{
				font-size:${props => props.theme.Rem(14)};
				&:last-of-type{
					margin-top:${props => props.theme.Rem(16)};
				}
			}
			
		}
	${props => props.theme.device.tabletL}{
		.text-box{
			.title{
				font-size:${props => props.theme.Rem(18)};
			}
			.contents{
				font-size:${props => props.theme.Rem(12)};
			}
			
		}
`;

const CustomHeight100 = styled(Height100)`
	width:100%;
`;

const CustomWidth33 = styled(Width33)`
	border-left:1px solid ${props => props.theme.colors.borderColor};
`;
const infoTextObj = {
	title:`Superior<br />AI Technology`,
	content:`AI 기반의 기업 데이터 수집, 분석, 검증을<br />
			수행하여 전 프로세스 상에서 차별적 경쟁 우위를<br />
			달성합니다.`
}
const FourthSection = () => {
	return (
		<CustomSectionWrapper>
			<PageWrapper>
				<CustomWrapper>
					<CustomWidth100>
						<PageInfoWidth45 className="page-info">
							<Info primary={true} texts = {infoTextObj}/>
							<MriPageLogo src="/img/antock_mri_w.png" alt="antock MRI"/>
						</PageInfoWidth45>
						<PageWidth55>
							<CustomWidth100>
								<CustomWidth33>
									<CustomHeight100>
										<CustomHeight50>
											<div className="text-box">
												<h5 className="sub-title">AI Machine<br/>Annotation</h5>
												<h3 className="title">데이터 자동 전처리</h3>
												<p className="contents">
													비 금융 대안 데이터<br/>
													자동 전처리 체계 기반<br/>
													학습용 데이터 구축
												</p>
												<p className="contents">
													자동 가공 및 표준화 통해<br/>
													AI 분석 위한 데이터 준비<br/>
													비용/공수를 획기적으로 절감
												</p>
											</div>
										</CustomHeight50>
										<CustomHeight50>
											<div className="text-box">
												<h5 className="sub-title">Targeted<br/>Algorithm Library</h5>
												<h3 className="title">기업 진단 특화 알고리즘</h3>
												<p className="contents">
													기업 분석 목적을 위하여 설계된<br/>
													전용 알고리즘 및 AI 모형<br/>
													라이브러리 제공
												</p>
												<p className="contents">
													투자 잠재성, 리스크 수준,<br/>
													지속 가능성 등 금융 / 경영 목적에<br/>
													특화된 분석 체계 확립
												</p>
											</div>
										</CustomHeight50>
									</CustomHeight100>
								</CustomWidth33>
								<CustomWidth33>
									<CustomHeight100>
										<CustomHeight50>
											<div className="text-box">
												<h5 className="sub-title">Multilateral<br/>Data Integration</h5>
												<h3 className="title">다각적 데이터 통합</h3>
												<p className="contents">
													다양한 출처와<br/>
													상이한 속성의 기업 데이터를<br/>
													단일 템플릿 내로 통합
												</p>
												<p className="contents">
													데이터베이스를 일원화하여<br/>
													다채로운 분석 모형을<br/>
													다각적으로 적용
												</p>
											</div>
										</CustomHeight50>
										<CustomHeight50>
											<div className="text-box">
												<h5 className="sub-title">Simultaneous<br/>Diagnosis System</h5>
												<h3 className="title">동시 다발적 분석</h3>
												<p className="contents">
													다양한 영역의<br/>
													기업 진단과 금융 분석을<br/>
													동시 연산하여 산출
												</p>
												<p className="contents">
													순차적인 분석 프로세스에서<br/>
													탈피하여 분석 시간을<br/>
													획기적으로 단축
												</p>
											</div>
										</CustomHeight50>
									</CustomHeight100>
								</CustomWidth33>
								<CustomWidth33>
									<CustomHeight100>
										<CustomHeight50>
											<div className="text-box">
												<h5 className="sub-title">Curated<br/>Analytics Package</h5>
												<h3 className="title">맞춤화 분석 패키지</h3>
												<p className="contents">
													기업 분석 목적과 속성을 고려하여<br/>
													AI 기반 최적의 진단 모형과<br/>
													활용 데이터를 제안
												</p>
												<p className="contents">
													프로젝트 유형에 따른<br/>
													분석 패키지 제공을 통해<br/>
													고객 별 1:1 맞춤화 실현
												</p>
											</div>
										</CustomHeight50>
										<CustomHeight50>
											<div className="text-box">
												<h5 className="sub-title">White-Box<br/>Architecture</h5>
												<h3 className="title">투명한 설계 체계</h3>
												<p className="contents">
													기업 평가 및 진단 결과에 대한<br/>
													논리적인 원인 구조를<br/>
													투명하게 공개
												</p>
												<p className="contents">
													예측 결과와 주요 변수와의<br/>
													상관성을 파악하여<br/>
													기업 분석의 설명력 강화
												</p>
											</div>
										</CustomHeight50>
									</CustomHeight100>
								</CustomWidth33>
							</CustomWidth100>
						</PageWidth55>
					</CustomWidth100>
				</CustomWrapper>
			</PageWrapper>
		</CustomSectionWrapper>
	);
};

export default FourthSection;
