import React, { useState, useRef, useEffect } from "react";
import { animated, useSpring } from "react-spring";

const SecondLine = ({ init, data }) => {
    

    const lineRef = useRef(null);
	const [length, setLength] = useState(null);

	useEffect(() => {
		setLength(lineRef.current.getTotalLength());
	}, [length, data]);

	const animatedStyle = useSpring({
		from: { length },
		to: { length: 0 },
		delay: 4000,
		config: { duration: 500 },
		reset: init,
	});

	return (
		<>
        
			<animated.path
                ref={lineRef}
                id={data.id}
				data-name={data.id}
				d={data.d}
				transform={data.transform}
				fill="transparent"
				stroke="#1210c7"
				strokeDashoffset={animatedStyle.length}
				strokeDasharray={length}
				strokeWidth={3}
			/>
		</>
	);
};

export default React.memo(SecondLine);
