import React from 'react';
import { animated, useSpring } from "react-spring";

const transform = [
	"translate(30 295)",
	"translate(120 295)",
	"translate(208 295)",
	"translate(298 295)"
]

const ThirdText = ({ index, textData, init }) => {


	const animationFillStyle = useSpring({
		from: { fillColor: "#b8b8c6" },
		to: { fillColor: "#1816c9" },
		config: { duration: 1000 },
		delay: 3500,
		reset: init,
	});


    return (
        <>

			<animated.text			
				id="AI_모형_03"
				data-name="AI 모형03"
				transform={transform[index]}
				font-size="14"
				font-family="SegoeUI, Segoe UI"
                fill={animationFillStyle.fillColor}
			>
				<tspan x="-22.295" y="0">
					AI
				</tspan>
				{/* x="-6" 해줘야함 */}
				<tspan x="-6" y="0" font-family="MalgunGothic, Malgun Gothic">
					모형
				</tspan>
				<tspan y="0"></tspan>
				<tspan x="-7.547" y="14">
					{textData}
				</tspan>
			</animated.text>
        </>
    );
};

export default ThirdText;