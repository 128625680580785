import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import Info from "../Info";

import {
	PageWrapper,
	Wrapper,
	Width100,
	Width60,
	Width40,
	PageLogo
} from "style/layout";

import '../../layout.scss';
import {addNumberComma, percentValue} from "../../lib/function";

const CustomSectionWrapper = styled.div`
	position: relative;
`;

const CustomWrapper = styled(Wrapper)`
	border-color: ${props => props.theme.colors.lbgBorderColor};
`;

const CustomWidth100 = styled(Width100)`
	height: 100%;
`;

const PageInfoWidth40 = styled(Width40)`
	height: 100%;
	box-sizing: border-box;
	flex-wrap: nowrap;
	align-items: flex-start;
	justify-content:center;
	margin-top: -#{${(props) => props.theme.sizes.pageMarginTop}};
    flex-direction: column;
    border-right:1px solid ${props => props.theme.colors.lbgBorderColor};
    position:relative;
    margin-top:0;
`;

const BaseCircle = styled.div`
	position: absolute;
	display: flex;
	right: ${props => props.theme.Rem(-145)};
	bottom: ${props => props.theme.Rem(-262)};
	justify-content: center;
	align-items: center;
	width: 42vw;
	height: 42vw;
	border: dotted 0.125rem #a9a9a9;
	border-radius: 50%;
	.text-box {
		> img {
			position: absolute;
			left: ${props => props.theme.Rem(-10)};
			top: ${props => props.theme.Rem(-32)};
		}
		.title {
			font-size: ${props => props.theme.Rem(16)};
			font-weight: 800;
			margin-bottom: 0.1rem;
		}

		&.kostat-box {
			color: #B8B8C6;
			position: absolute;
			top: ${props => props.theme.Rem(-110)};
			right: 37%;
			
			img{
				width:${props => props.theme.Rem(80)};
				min-height:1px;
			}
			.title {
				font-weight: 400;
			}

			&::after {
				transition: all 1s ease-in-out;
                // transition: all 20s ease-in-out;
				position: absolute;
				content: '';
				width: 2px;
				height:${props => props.theme.Rem(106)};
				left: ${props => props.theme.Rem(-10)};
				top:0;
				background-image: linear-gradient(#B8B8C6 33%, rgba(255,255,255,0) 0%);
				background-position: right;
				background-size: 1.8px 5px;
				background-repeat: repeat-y;
			}
		}
		&.hubble-box {
			position: absolute;
			top: ${props => props.theme.Rem(-34)};
			left: 19%;
			color: ${props => props.theme.colors.pointColor};

			img{
				width:${props => props.theme.Rem(80)};
				min-height:1px;
			}
			.title small {
				font-weight: 400;
				font-size:0.6rem;
			}
			.contents {
				font-size: ${props => props.theme.Rem(19)};
			}

			&::after {
				transition: all 0.3s ease-in-out;
				position: absolute;
				content: '';
				width: 1px;
				height:0;
				left: ${props => props.theme.Rem(-10)};
				top:0;
				background-color: ${props => props.theme.colors.pointColor};
			}
		}
		&.hubble-rate-box {
			position: absolute;
			bottom: ${props => props.theme.Rem(300)};
			right: ${props => props.theme.Rem(176)};
			color: #ffffff;
			.title {
				font-size: ${props => props.theme.Rem(93)};
				line-height: 0.8;
				margin-bottom:1rem;
				font-weight:900;
			}
			.contents {
				font-size: ${props => props.theme.Rem(14)};
				text-align: right;
			}
		}
	}

	.hubble-circle {
		border-radius: 50%;
		border: 1px solid ${props => props.theme.colors.pointColor};
		background: ${props => props.theme.colors.pointColor};
		transition: all 0.3s ease-in-out;
		transition-delay: 0.15s;
		width:0;
		height:0;
		opacity: 0;
		z-index:0;
	}

    ${props => {
		if(props.isCircleAppear || props.device === "mobile"){
			return css`
				&{
					.text-box.hubble-box::after {
						height:${props => props.theme.Rem(200)};
					}
					.hubble-circle {
						opacity: 1;
						width:92%;
						height:92%;
					}
				}
			`;
	}}}
`;

const CustomWidth60 = styled(Width60)`
	padding-top:8.7%;
	box-sizing:border-box;
	flex-direction:column;
	position: relative;
	overflow: hidden;
`;

const TitleTextBox = styled.div`
	.title{
		font-size:${(props) => props.theme.Rem(42)};
		font-weight:800;
		margin-bottom:${(props) => props.theme.Rem(36)};
	}
	.contents{
		font-size:${(props) => props.theme.Rem(16)};
		font-weight:400;
		line-height:${(props) => props.theme.Rem(30)};
	}
`;

const IntroduceWrapper = styled.div`
	margin-top:${(props) => props.theme.Rem(84)};
        .text-box{
            &:nth-of-type(2),&:nth-of-type(3){
                box-sizing:border-box;
                padding-top:${(props) => props.theme.Rem(46)};
            }
            .title{
                font-size:${(props) => props.theme.Rem(20)};
                font-weight:800;
                line-height:${(props) => props.theme.Rem(24)};
                margin-bottom:${(props) => props.theme.Rem(25)};
            }
            .contents{
                font-size:${(props) => props.theme.Rem(14)};
                font-weight:400;
                line-height:${(props) => props.theme.Rem(24)};
            }
        }
    ${props => props.theme.device.desktopS}{
		margin-top:${(props) => props.theme.Rem(40)};
		.text-box{
			&:nth-of-type(2),&:nth-of-type(3){
				padding-top:${(props) => props.theme.Rem(26)};
			}
			.title{
				margin-bottom:${(props) => props.theme.Rem(15)};
			}
		}
	}
`;
const infoTextObj = {
	title:`AI Database of<br />Holistic Coverage`,
	content:`허블데이터베이스는 국내 법인 기업 전수를 포괄하는<br />
			약 ${(Number(process.env.REACT_APP_COMPANY_COUNT) / 10000).toFixed(0)}만 개 표본으로 구성된 AI 학습용 데이터 인프라`
}
const FirstSection = () => {

    const { currentSection, device } = useSelector((state) => ({
        currentSection: state.page.currentSection,
        device: state.style.device,
    }));

    const [isCircleAppear, setIsCircleAppear] = useState(false);

    useEffect(() => {
        if(currentSection === 2) {
            setTimeout(() => {
               setIsCircleAppear(true);
            }, 1000 );
        } else {
            setIsCircleAppear(false);
        };
    } ,[currentSection]);

	return (
		<CustomSectionWrapper>
			<PageWrapper>
				<CustomWrapper>
					<CustomWidth100>
						<PageInfoWidth40 className="page-info">
							<Info texts = {infoTextObj}/>
							<PageLogo src="/img/hubble_h_b.png" alt="Hubble Database"/>
						</PageInfoWidth40>

						<CustomWidth60>
							<BaseCircle device={device} isCircleAppear={isCircleAppear}>
								<div className="hubble-circle" />
								<div className="text-box kostat-box">
									<img src="/img/source/kostat-std.png"/>
									<h4 className="title">법인사업체</h4>
									<p className="contents">{addNumberComma(process.env.REACT_APP_COPERATION_COUNT)}개</p>
								</div>

								<div className="text-box hubble-box">
									<img src="/img/source/hubble-std.png"/>
									<h4 className="title">기업 표본 <small>* 폐업 제외</small></h4>
									<p className="contents">{addNumberComma(process.env.REACT_APP_COMPANY_COUNT)}개</p>
								</div>

								<div className="text-box hubble-rate-box">
									<h3 className="title">{percentValue()}%</h3>
									<p className="contents">'{process.env.REACT_APP_UPDATE_YEAR}년 {process.env.REACT_APP_UPDATE_MONTH}월 법인 사업체 기준</p>
								</div>
							</BaseCircle>

							<TitleTextBox>
								<h3 className="title">{addNumberComma(Number(process.env.REACT_APP_COMPANY_COUNT))}개 기업 표본</h3>
								<p className="contents">
									허블 데이터베이스의 빅 데이터 기술력을 토대로 국내 법인 전체를<br />
									AI 학습 데이터 표본으로 편입하였으며 대한민국 기업 생태계를 전산화하는데 성공하였습니다.
								</p>
							</TitleTextBox>

							<IntroduceWrapper>
								<div className="text-box">
									<h3 className="title">통합적 분석</h3>
									<p className="contents">
										경제 활동을 영위하는 (폐업 제외)<br />
										기업 대부분을 데이터 표본으로 포착
									</p>
								</div>

								<div className="text-box">
									<h3 className="title">포괄적 기업 표본</h3>
									<p className="contents">
										대기업, 중견 / 중소기업, 나아가<br />
										창업기업 / 스타트업까지 포괄
									</p>
								</div>

								<div className="text-box">
									<h3 className="title">적시적 표본 관리</h3>
									<p className="contents">
										기업 생멸 (창업 / 폐업) 현상에 따른<br />
										표본 추가 / 제거 유기적으로 진행
									</p>
								</div>
							</IntroduceWrapper>

						</CustomWidth60>
					</CustomWidth100>
				</CustomWrapper>
			</PageWrapper>
		</CustomSectionWrapper>
	);
};

export default FirstSection;
