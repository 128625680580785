import React from "react";
import styled from "styled-components";

import {
	PageWrapper,
	Wrapper,
	Width100,
	Width50,
} from "style/layout";

import Info from "components/Info";

import "../../layout.scss";

const CustomSectionWrapper = styled.div`
    height: 100%;
`;

const CustomWrapper = styled(Wrapper)`
    border: 0.5px solid ${(props) => props.theme.colors.lbgBorderColor};
	border-width: 0 0.5px;
	.contents-wrapper{
		flex-direction:column;
		align-items:center;
		justify-content:center;
		border-left: 0.5px solid rgba(90,90,119,0.08);
		img{
			width:90%;
			height:auto;
			margin:0 auto;
			flex-shrink:0;
		}
	}
`;

const CustomWidth100 = styled(Width100)`
	height: 100%;
`;

const CustomWidth50 = styled(Width50)`
	align-items: center;
	justify-content: center;
`;

const infoTextObj = {
	title:`활용 예시`,
	content:`당사 “Hubble Database” 의 Big Data 기술력과<br /> 
    Antock MRI 기반의 AI(인공지능) 기술력 기반으로<br />
    고객사의 프로젝트에 부합하는 가공 서비스를 제공합니다.`
};

const ThirdSection = () => {
    return (
        <>
			<CustomSectionWrapper>
				<PageWrapper>
					<CustomWrapper>
						<CustomWidth100>

							<Width50 className="page-info">
                                <Info primary={false} texts = {infoTextObj}/>
							</Width50>

							<Width50 className="contents-wrapper border-left">
								<img src="/img/dvai_bg.png" alt="활용예시"/>
								<div className="ir-content">
									<ul>
										<li>
											<strong>정책자금/투자대상 기업 발굴</strong>
											<span>정책자금 및 펀드/투자 요건에 정확히 부합하는 기업발굴 및 평가</span>
										</li>
										<li>
											<strong>스타트업/중소기업 대안신용평가</strong>
											<span>대안데이터 기반 신용평가 모델 개발, 통계 관점의 심화 분석 인사이트 제공</span>
										</li>
										<li>
											<strong>
												사회적 가치 측정 모델링
											</strong>
											<span>국내 최초 프로그램 기반 사회적 가치 측정 및 소셜벤처 판별을 위한 알고리즘 연구/개발</span>
										</li>
										<li>
											<strong>정책 연구 및 책자 발간</strong>
											<span>정책 개선/조례 제정을 위한 통계 관점의 인사이트 제공, 각종 보고서/책자 발간을 위한 기초 연구 데이터 분석</span>
										</li>
										<li>
											<strong>기업데이터 관리 및 사후추적</strong>
											<span>내부 보고서 작성을 위한 관리/졸업기업에 대한 사후 실적 데이터 자동화 추적 시스템 및 산출물</span>
										</li>
									</ul>
								</div>
							</Width50>

						</CustomWidth100>
					</CustomWrapper>
				</PageWrapper>
			</CustomSectionWrapper>
		</>
    );
};

export default ThirdSection;