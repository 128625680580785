import React from "react";
import styled from "styled-components";

import {
	PageWrapper,
	Wrapper,
	Width100,
	Width50,
	Width40, 
    Width60,
} from "style/layout";

import Info from "components/Info";

import "../../layout.scss";

const CustomSectionWrapper = styled.div`
    height: 100%;
	.contents-wrapper{
		border-left: 0.5px solid rgba(90,90,119,0.08);
	}
`;

const CustomWrapper = styled(Wrapper)`
    border: 0.5px solid ${(props) => props.theme.colors.lbgBorderColor};
	border-width: 0 0.5px;
`;

const CustomWidth100 = styled(Width100)`
	height: 100%;
	
	.img-wrapper{
		display:flex;
		width:100%;
		height:100%;
		flex-direction:column;
		align-items:center;
		justify-content:center;
		img{
			width:90%;
			flex-shrink:0;
		}
	}
`;

const CustomWidth50 = styled(Width50)`
	align-items: center;
`;

const infoTextObj = {
	title:`(주)앤톡 제공 데이터 항목`,
	content:`국내 100만 법인기업의 원천데이터를 자동화 시스템 기반<br /> 
    수집-가공-분석하여 표준화된 형태로 제공하며,<br />
    기업별 최대 7대 영역에서 300가지 이상의 항목을 제공합니다.`
};

const dataListObj = {
	"사업/활동" : ["회사소개","대표상품","상품소개","언론보도","영상보도","채용활용","입찰계약","입차일자","입찰금액","입찰형태","입찰URL","선정사업","정부지원","민간지원"],
	"조직" : ["월별현황","고용규모","입사자 수","퇴사자 수","증가율(%)","평균급여","산업평균","산업대비","업력대비","누적집계","고용규모","회전율(%)","평균연봉","업력평균"],
	"인증/수상" : ["벤처기업","인증여부","인증번호","인증유형","이노비즈","메인비즈","강소기업","사회/예비","인증여부","지정번호","수출입","연구소","통신판매","NEP/NET"],
	"식별/개황" : ["상호명","사업번호","사업영역","등기주소","법인번호","홈페이지","공시지가","법인구분","회사소개","대표이사","등기상태","대표상품","조직규모","산업코드","폐업일자","설립일자","산업명칭","체납현황","사업기간","과거상호","연락정보"],
	"분석/지표" : ["매출액","가치분석","석차평가","영업이익","매출증가","산업대비","순이익","영업증가","업력대비","자산총계","ROE","혁신성","부채총계","부채비율","사회성","자본총계","부채증가","자본증가","조직회전","조직효율","직원증가"],
	"기술/지배권" : [{"특허":["총 등록수","출원코드","등록번호","출원번호","등록일자","출원일자"]},{"실용신안":["총 등록수","등록번호","출원번호","등록일자","출원일자","명칭"]},{"디자인":["총 등록수","등록번호","출원번호","저작권","세부정보","명칭"]}],
	"자금/투자" : ["투자유치","R&D","수상상금","유치여부","선정여부","수상여부","최종일자","참여횟수","수상내용","투자기관","사업명","수여기관","최종단계","과제내용","추가여정","정부지원","클라우드","기술보증","수혜여부","펀딩여부","신용보증"],
}

const ThirdSection = () => {
    return (
        <>
			<CustomSectionWrapper>
				<PageWrapper>
					<CustomWrapper>
						<CustomWidth100>

							<Width40 className="page-info">
                                <Info primary={false} texts = {infoTextObj}/>
							</Width40>

							<Width60 className="contents-wrapper border-left">
								<div className="img-wrapper">
									<img src="/img/dvai_3.png" alt="앤톡 제공 데이터 항목 정리"/>
								</div>
								<div className="ir-content">
									{
										dataListObj ? Object.keys(dataListObj).map((index,num)=>{
											return (
												<div>
													<p>{index}</p>
													<ul>
														{
															dataListObj[index].map((list)=>{
																if(list.constructor === Object){
																	return (
																		<li>
																			<p>{Object.keys(list)}</p>
																			<ul>
																				{
																					list[Object.keys(list)].map((item)=>{
																						return (
																							<li>{item}</li>
																						);
																					})
																				}
																			</ul>
																		</li>
																	);
																}else{
																	return <li>{list}</li>
																}
															})
														}
													</ul>
												</div>
											);
										})
									: null }
								</div>
							</Width60>

						</CustomWidth100>
					</CustomWrapper>
				</PageWrapper>
			</CustomSectionWrapper>
		</>
    );
};

export default ThirdSection;