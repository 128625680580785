import React from "react";
import styled from "styled-components";

const ItemList = styled.div`
  display: flex;
  margin: 0 auto;
  width: 90%;
  height: auto;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const ItemStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 23%;
  margin-right: 2.6%;
  box-sizing: border-box;
  padding: ;
  cursor: pointer;
  margin-bottom: ${(props) => props.theme.Rem(85)};
  &:nth-of-type(4n) {
    margin-right: 0;
  }
  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: ${(props) => props.theme.Rem(22)} 0;
    font-weight: 700;
    color: #808091;
    span {
      background-color: #ddd;
      display: block;
      height: 1rem;
      width: 30%;
      &:last-of-type {
        font-weight: 400;
      }
      &::before {
        content: " ";
      }
    }
  }
  h4 {
    font-size: ${(props) => props.theme.Rem(22)};
    line-height: 1.5;
    word-break: keep-all;
    transition: color 0.3s ease-in-out;
    background-color: #ddd;
    display: block;
    height: 4.5rem;
    width: 100%;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  height: ${(props) => props.theme.Rem(216)};
  background-color: #ddd;

  ${(props) => props.theme.device.desktopM} {
    height: ${(props) => props.theme.Rem(170)};
  }
`;

const SkeletonItems = () => {
  return (
    <ItemStyle>
      {/* <ImgWrapper className="skeleton-item" />
      <div>
        <span className="skeleton-item" />
        <span className="skeleton-item" />
      </div>
      <h4 className="skeleton-item" /> */}
    </ItemStyle>
  );
};

const PrLoading = () => {
  return (
    <ItemList>
      {new Array(12).fill(1).map(() => {
        return <SkeletonItems/>;
      })}
    </ItemList>
  );
};

export default PrLoading;
