import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";


const CustomNav = styled.div`
  & #fp-nav,
  .fp-slidesNav {
    bottom: 9rem;
    top: auto;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    opacity: 1;
    width: 6%;
    right:0;
    &.hide {
      opacity: 0;
      z-index: 0;
    }
    ul{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    ul li {
      margin: 3px;
      width: ${(props) => props.theme.Rem(14)};
      height: ${(props) => props.theme.Rem(14)};
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        a {
          span {
            width: ${(props) => props.theme.Rem(10)};
            height: ${(props) => props.theme.Rem(10)};
            margin: 0;
          }
        }
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          background-color: #b9b9c7;
          width: ${(props) => props.theme.Rem(6)};
          height: ${(props) => props.theme.Rem(6)};
          position: relative;
          left: 0;
          top: 0;
          display: block;
          margin: 0;
          transition: all 0.3s ease-in-out;
        }

        &.active {
          span {
            background-color: #1210c9;
            width: ${(props) => props.theme.Rem(6)};
            height: ${(props) => props.theme.Rem(18)};
            border-radius: 32%;
            margin: 0;
          }
        }
      }
    }
  }
`;

const NavBar = () => {
    const { currentPage,currentSection, originSection } = useSelector((state) => ({
        currentSection: state.page.currentSection,
        originSection: state.page.originSection,
        currentPage: state.page.currentPage
    }));

    const [nav, setNav] = useState();

    const navRef = useRef(null);

    useEffect(() => {
        const navLink = navRef.current.querySelectorAll("#fp-nav ul li a");
        setNav(navLink);
    }, []);

    useEffect(() => {
        if(currentPage === "company"){
            if (nav && currentSection !== 4) {
                nav[currentSection - 1].classList.toggle("active");
            }
        } else if(currentPage === "dvai" || currentPage === "dvdata"){
            if (nav && currentSection !== 5) {
                nav[currentSection - 1].classList.toggle("active");
            }
        } else{
            if (nav && currentSection !== 7) {
                nav[currentSection - 1].classList.toggle("active");
            }
        }

    }, [nav, currentSection]);

    useEffect(() => {
        if(currentPage === "company"){
            if (nav && originSection !== 4) {
                nav[originSection - 1].classList.remove("active");
            }
        } else if(currentPage === "dvai" || currentPage === "dvdata"){
            if (nav && originSection !== 5) {
                nav[originSection - 1].classList.remove("active");
            }
        } else{
            if (nav && originSection !== 7) {
                nav[originSection - 1].classList.remove("active");
            }
        }

    }, [originSection]);

    const Contents = () => {
        switch(currentPage){
            case "company":
                return (
                    <>
                        <li><a><span></span></a></li>
                        <li><a><span></span></a></li>
                        <li><a><span></span></a></li>
                    </>
                );
            case "dvai" :
                return (
                    <>
                        <li><a><span></span></a></li>
                        <li><a><span></span></a></li>
                        <li><a><span></span></a></li>
                        <li><a><span></span></a></li>
                    </>
                );
            case "dvdata" :
                 return (
                    <>
                        <li><a><span></span></a></li>
                        <li><a><span></span></a></li>
                        <li><a><span></span></a></li>
                        <li><a><span></span></a></li>
                    </>
                );
            default:
                return (
                    <>
                        <li><a><span></span></a></li>
                        <li><a><span></span></a></li>
                        <li><a><span></span></a></li>
                        <li><a><span></span></a></li>
                        <li><a><span></span></a></li>
                        <li><a><span></span></a></li>
                    </>
                );
        }
    }
    return (
        <CustomNav>
            <div ref={navRef} id="fp-nav" className="right">
                <ul>
                    {Contents()}
                </ul>
            </div>
        </CustomNav>
    );
};

export default NavBar;
