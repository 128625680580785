import React from "react";
import styled from "styled-components";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import Slider from "react-slick";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useWindowSize } from "hooks/CustomHooks";
import { useDispatch, useSelector } from "react-redux";

const CustomDataList = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	box-sizing: border-box;

	position: relative;
	.slick-slider {
		width: 100%;
	}
`;

const CustomList = styled.div`
	display: flex !important;
	flex-direction: row;
	flex-wrap: nowrap;
	min-height: ${(props) => props.theme.Rem(80)};
	padding: ${(props) => props.theme.Rem(35)} ${(props) => props.theme.Rem(70)};
	margin-top: ${(props) => props.theme.Rem(3)};
	word-break: keep-all;
	box-sizing: border-box;
	img {
		width: ${(props) => props.theme.Rem(40)};
		margin-right: ${(props) => props.theme.Rem(24)};
	}
	${(props) => props.theme.device.notebook} {
		min-height: ${(props) => props.theme.Rem(145)};
		padding: ${(props) => props.theme.Rem(10)}
			${(props) => props.theme.Rem(70)};
		margin-top: ${(props) => props.theme.Rem(0)};
	}
	${(props) => props.theme.device.tabletL} {
		min-height: ${(props) => props.theme.Rem(170)};
		padding: ${(props) => props.theme.Rem(10)}
			${(props) => props.theme.Rem(70)};
		margin-top: ${(props) => props.theme.Rem(0)};
	}
	${(props) => props.theme.device.desktopS} {
		padding: ${(props) => props.theme.Rem(25)}
			${(props) => props.theme.Rem(40)};
	}
`;

const TextBox = styled.span`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	width: 100%;
`;

const TextBoxTitle = styled.b`
	br {
		display: none;
	} 
	font-size: ${(props) => props.theme.Rem(16)};
	font-weight: 900;
	margin-bottom: ${(props) => props.theme.Rem(12)};
	text-align: left;

	span {
		font-weight: 400;
		font-size: ${(props) => props.theme.Rem(14)};
		margin-left: ${(props) => props.theme.Rem(5)};
	}
`;

const TextBoxContents = styled.span`
	font-weight: 400;
	font-size: ${(props) => props.theme.Rem(14)};
	line-height: ${(props) => props.theme.Rem(25)};
`;

const SliderWrapper = styled.div`
	padding-top:${(props) => props.theme.sizes.headerHeight}};
	${(props) => props.theme.device.tabletL}{
		padding-top: 25px;
	}
	box-sizing: border-box;
	overflow: hidden;
	
}
`;
const NextButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

const SliderContents = () => {
	const { device } = useSelector((state) => ({
		device: state.style.device,
	}));

	const windowSize = useWindowSize();

	const hoverRef = useRef(null);
	const listRef = useRef(null);
	const sliderRef = useRef(null);

	const [currentIndex, setCurrentIndex] = useState(0);
	const [isAutoPlay, setIsAutoPlay] = useState(true);
	const [slideToShow, setSlideToShow] = useState();

	useEffect(() => {
		if (listRef.current) {
			const slickList = listRef.current.querySelector(".slick-list");
			slickList.style.overflow = "hidden";
		}
	}, []);
    
    useEffect(() => {
        if(device === "mobile"){
            sliderRef.current.slickGoTo(1);   
        }
    } , [device])

	useEffect(() => {
		if (hoverRef.current) {
			hoverRef.current.addEventListener("mouseover", handleMouseOver);
			hoverRef.current.addEventListener("mouseout", handleMouseOut);
			return () => {
				hoverRef.current.removeEventListener(
					"mouseover",
					handleMouseOver
				);
				hoverRef.current.removeEventListener(
					"mouseout",
					handleMouseOut
				);
			};
		}
	}, [hoverRef.current]);

	const settings = {
		dots: false,
		infinite: false,
		slidesToShow: 5,
		slidesToScroll: 1,
		autoplay: true,
		vertical: true,
		arrows: false,
		speed: 1000,
		waitForAnimate: false,
		pauseOnHover: true,
		afterChange: function (index) {
			setCurrentIndex(index);
		},
		responsive: [
			{
				breakpoint: 1300,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
		],
	};

	useEffect(() => {
		if (windowSize && sliderRef.current) {
			if (windowSize.width > 1300) {
				setSlideToShow(sliderRef.current.props.slidesToShow);
			} else if (windowSize.width > 1024) {
				setSlideToShow(
					sliderRef.current.props.responsive[0].settings.slidesToShow
				);
			} else {
				setSlideToShow(
					sliderRef.current.props.responsive[1].settings.slidesToShow
				);
			}
		}
	}, [windowSize]);

	useEffect(() => {
		if (slideToShow) {
			// 전체 totalIndex - slidetoshow == currentindex (12 - 5 = 7)
			const totalIndex = sliderRef.current.props.children.length;

            let targetIndex;

            if (device !== "mobile") {
                targetIndex = totalIndex - slideToShow - 1;
            } else {
                targetIndex = totalIndex - slideToShow;
            };

			if (targetIndex === currentIndex && isAutoPlay) {
				setTimeout(() => {
                    if (device !== "mobile") {
                        sliderRef.current.slickGoTo(0);
                    } else {
                        sliderRef.current.slickGoTo(1);
                    }
				}, 2000);
			}
		}
	}, [currentIndex]);

	const handleMouseOver = () => {
		setIsAutoPlay(false);
		sliderRef.current.slickPause();
	};

	const handleMouseOut = () => {
		setIsAutoPlay(true);
		sliderRef.current.slickPlay();
	};

	const handlePrev = () => {
		const totalIndex = sliderRef.current.props.children.length;

        // 모바일 아닐 경우엔 정상적으로
        if (device !== "mobile"){
            if (currentIndex === 0) {
                sliderRef.current.slickGoTo(totalIndex - slideToShow);
            } else {
                sliderRef.current.slickPrev();
            }
        } else {
            // 모바일일땐 하나 전 일때 끝으로 이동
            if (currentIndex === 0 || currentIndex === 1) {
                sliderRef.current.slickGoTo(totalIndex - slideToShow);
            } else {
                sliderRef.current.slickPrev();
            }
        }
	};

	const handleNext = () => {
		const totalIndex = sliderRef.current.props.children.length;

        // 모바일 아닐 경우엔 정상적으로
        if (device !== "mobile"){
            
            if (totalIndex - slideToShow - 1 === currentIndex) {
                sliderRef.current.slickGoTo(0);
            } else {
                sliderRef.current.slickNext();
            }
        } else {
            if (totalIndex - slideToShow === currentIndex) {
                sliderRef.current.slickGoTo(1);
            } else {
                sliderRef.current.slickNext();
            }
        }
		
	};

	return (
		<div ref={hoverRef}>
			<SliderWrapper ref={listRef}>
				<CustomDataList>
                    <button style={{width:"100%"}} onClick={handlePrev}>
                        {device === "mobile" ? <ExpandLessIcon fontSize="large"/> : <ExpandLessIcon fontSize="medium"/>  }
                    </button>
					<Slider ref={sliderRef} {...settings}>
                        {/* 모바일 일때 index 0인 값 선택 안되서 예외처리 -> 0 이전에 하나더 추가 */}
						{device === "mobile" ? (
							<CustomList>
								<span>
									<img src="/img/org_icon.png" alt="조직" />
								</span>
								<TextBox>
									<TextBoxTitle>
										조직
										<br />
										<span>Organization</span>
									</TextBoxTitle>
									<TextBoxContents>
										고용 규모, 임금 수준, 입사 및 퇴사자
										비율 등
									</TextBoxContents>
								</TextBox>
							</CustomList>
						) : null}

						<CustomList>
							<span>
								<img src="/img/org_icon.png" alt="조직" />
							</span>
							<TextBox>
								<TextBoxTitle>
									조직
									<br />
									<span>Organization</span>
								</TextBoxTitle>
								<TextBoxContents>
									고용 규모, 임금 수준, 입사 및 퇴사자 비율 등
								</TextBoxContents>
							</TextBox>
						</CustomList>
						<CustomList>
							<span>
								<img src="/img/web_icon.png" alt="홈페이지" />
							</span>
							<TextBox>
								<TextBoxTitle>
									홈페이지
									<br />
									<span>Web</span>
								</TextBoxTitle>
								<TextBoxContents>
									사이트 주소, 가동여부, 서버 반응속도,
									업데이트 이력 등
								</TextBoxContents>
							</TextBox>
						</CustomList>
						<CustomList>
							<span>
								<img src="/img/pro_icon.png" alt="상품" />
							</span>
							<TextBox>
								<TextBoxTitle>
									상품
									<br />
									<span>Product</span>
								</TextBoxTitle>
								<TextBoxContents>
									기업 소개, 사업 영역, 서비스 및 핵심 상품
									설명, 상품 이미지 등
								</TextBoxContents>
							</TextBox>
						</CustomList>
						<CustomList>
							<span>
								<img src="/img/ip_icon.png" alt="지적재산권" />
							</span>
							<TextBox>
								<TextBoxTitle>
									지적재산권
									<br />
									<span>IP</span>
								</TextBoxTitle>
								<TextBoxContents>
									특허, 실용신안, 상표, 저작권, 국가 R&D 추진
									내역
								</TextBoxContents>
							</TextBox>
						</CustomList>
						<CustomList>
							<span>
								<img src="/img/cert_icon.png" alt="인증" />
							</span>
							<TextBox>
								<TextBoxTitle>
									인증
									<br />
									<span>Certification</span>
								</TextBoxTitle>
								<TextBoxContents>
									정부 및 민간 기관에서 발급한 기업, 상품,
									기술 관련 인증
								</TextBoxContents>
							</TextBox>
						</CustomList>
						<CustomList>
							<span>
								<img
									src="/img/search_icon.png"
									alt="검색기록"
								/>
							</span>
							<TextBox>
								<TextBoxTitle>
									검색기록
									<br />
									<span>Search Log</span>
								</TextBoxTitle>
								<TextBoxContents>
									검색 규모, 검색 순위, 시장 관심도 등
								</TextBoxContents>
							</TextBox>
						</CustomList>
						<CustomList>
							<span>
								<img src="/img/reco_icon.png" alt="수상" />
							</span>
							<TextBox>
								<TextBoxTitle>
									수상
									<br />
									<span>Recognition</span>
								</TextBoxTitle>
								<TextBoxContents>
									정부 수상, 선정 지원 사업, 공공 및 민간
									경진대회 입상 내역 등
								</TextBoxContents>
							</TextBox>
						</CustomList>
						<CustomList>
							<span>
								<img src="/img/news_icon.png" alt="언론보도" />
							</span>
							<TextBox>
								<TextBoxTitle>
									언론보도
									<br />
									<span>News</span>
								</TextBoxTitle>
								<TextBoxContents>
									언론 보도 주기, 최신 보도 일자, 언론 노출
									규모 등
								</TextBoxContents>
							</TextBox>
						</CustomList>
						<CustomList>
							<span>
								<img src="/img/tran_icon.png" alt="계약" />
							</span>
							<TextBox>
								<TextBoxTitle>
									계약
									<br />
									<span>Transaction</span>
								</TextBoxTitle>
								<TextBoxContents>
									정부 / 공공 프로젝트 수주 현황, 입찰 상세
									내역 등
								</TextBoxContents>
							</TextBox>
						</CustomList>
						<CustomList>
							<span>
								<img
									src="/img/sns_icon.png"
									alt="사회적 관계망"
								/>
							</span>
							<TextBox>
								<TextBoxTitle>
									사회적 관계망
									<br />
									<span>SNS</span>
								</TextBoxTitle>
								<TextBoxContents>
									SNS 채널 별 주소, 활동 현황, 업데이트 빈도
									등
								</TextBoxContents>
							</TextBox>
						</CustomList>
						<CustomList>
							<span>
								<img src="/img/geol_icon.png" alt="지리" />
							</span>
							<TextBox>
								<TextBoxTitle>
									지리
									<br />
									<span>Geolocation</span>
								</TextBoxTitle>
								<TextBoxContents>
									회사 소재, 공시 지가, 주소 변경 이력 및 추이
									등
								</TextBoxContents>
							</TextBox>
						</CustomList>
						<CustomList>
							<span>
								<img src="/img/soci_icon.png" alt="사회 가치" />
							</span>
							<TextBox>
								<TextBoxTitle>
									사회 가치
									<br />
									<span>Social Value</span>
								</TextBoxTitle>
								<TextBoxContents>
									추구 가치, 소셜벤처 추정 점수, ESG 보유 기술
									등
								</TextBoxContents>
							</TextBox>
						</CustomList>
					</Slider>
				</CustomDataList>
			</SliderWrapper>
			<NextButtonWrapper>
                <button style={{width:"100%", transform: "rotate(180deg)" }} onClick={handleNext}>
                    {device === "mobile" ? <ExpandLessIcon fontSize="large"/> : <ExpandLessIcon fontSize="medium"/>  }
                </button>
			</NextButtonWrapper>
		</div>
	);
};

export default SliderContents;
