import React from 'react';
import styled from "styled-components";

import { PageWrapper, Wrapper, Width100, Width45, Width55 } from "style/layout";

import Info from "components/Info";
import Contents from './fourth/Contents';


const CustomSectionWrapper = styled.div`
`;

const CustomWidth100 = styled(Width100)`
	height: 100%;
`;
const infoTextObj = {
    title:`AI Application<br />Real Case Studies`,
    content:`다양한 산업에서 빅 데이터 및 AI 기술력을 접목하여<br />	
            부가가치를 창출하고 있습니다.`
}
const FourthSection = () => {
    return (
		<>
			<CustomSectionWrapper>
				<PageWrapper>
					<Wrapper>
                        <CustomWidth100>
                            <Width45 className="page-info">
                                <Info primary={false} texts = {infoTextObj}/>
                            </Width45>

                            <Width55 className="contents-wrapper">
                                <Contents/>
                            </Width55>
                        </CustomWidth100>
                    </Wrapper>
				</PageWrapper>
			</CustomSectionWrapper>
		</>
	);
};

export default FourthSection;