import { createGlobalStyle } from "styled-components";
import {theme} from "./theme";

// import reset from "styled-reset"; // style-reset 패키지

const GlobalStyles = createGlobalStyle`
    :root{
        --font-size:18;
        ${theme.device.desktopL}{
            --font-size:16;
        }
        ${theme.device.desktopM}{
            --font-size:16;
        }
        ${theme.device.desktopS}{
            --font-size:14;
            .page-info{
                margin-top:0;
            }
        }
        ${theme.device.tabletL}{
            --font-size:18;
            .page-info{
                margin-top:0;
            }
        }
        ${theme.device.tablet}{
            --font-size:16;
            .page-info{
                margin-top:0;
            }
        }
        ${theme.device.mobile}{
            --font-size:14;
            .page-info{
                margin-top:0;
            }
        }
    }
    
    html,body{
        user-select: none;
        font-size:18px;
        font-size:calc(var(--font-size) * 1px);
        ${theme.device.desktopL}{
            font-size:16px;
            font-size:calc(var(--font-size) * 1px);
        }
        ${theme.device.desktopM}{
            font-size:16px;
            font-size:calc(var(--font-size) * 1px);
        }
        ${theme.device.desktopS}{
            font-size:14px;
            font-size:calc(var(--font-size) * 1px);
            .page-info{
                margin-top:0;
            }
        }
        ${theme.device.tabletL}{
            font-size:18px;
            font-size:calc(var(--font-size) * 1px);
            .page-info{
                margin-top:0;
            }
        }
        ${theme.device.tablet}{
            font-size:16px;
            font-size:calc(var(--font-size) * 1px);
            .page-info{
                margin-top:0;
            }
        }
        ${theme.device.mobile}{
            font-size:14px;
            font-size:calc(var(--font-size) * 1px);
            .page-info{
                margin-top:0;
            }
        }
    }
    
    body{
        width:100%;
        overflow:hidden;
    }
    
    ${theme.device.desktopL}{
        img{
            transform: translateZ(0);
            backface-visibility: hidden;
        }
    }
    
    img{
        image-rendering: -webkit-optimize-contrast;
    }
    .skeleton-item{
      overflow: hidden;
      position: relative;
      background-color:#ddd;
    }
    .skeleton-item::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 6rem;
      height: 100%;
      background: linear-gradient(to right, #ddd, #ccc, #ddd);
      animation: loading 2s infinite linear;
    }
    
    .swiper-scrollbar-drag{
        background-color:${theme.colors.pointColor} !important;
    }
    .swiper-slide{
        width:85%;
    }
    
    .swiper-bg-point .swiper-horizontal>.swiper-scrollbar{
        background-color:#1B1A7B;
    }
    
    .swiper-bg-point .swiper-scrollbar-drag{
        background-color:#fff !important;
    }
    
    .case-swiper{
        .swiper-button-next, .swiper-button-prev{
           top:5%;
           color:${theme.colors.pointColor};
           font-weight: 900;           
        }
        .swiper-button-prev{
            left: auto;
            right:80px;
        }
    }
    
   .simplebar-scrollbar::before{
        background-color:${theme.colors.pointColor};        
   } 
   .simplebar-scrollbar.simplebar-visible::before{
        opacity:1;
   }
    
    @keyframes loading {
    0% {
         left: 0;
      }
      50%,100% {
         left: 100%;
      }
    }
    
`;


export default GlobalStyles;

