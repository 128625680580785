import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import PrNav from "components/pr/PrNav";
import NewsList from "./NewsList";

import PrLoading from "components/pr/PrLoading";
import Error from "components/Error";

import { getNewsList } from "api/api";
import { useLocation } from "react-router-dom";
import { setCategory } from "modules/news";


import {
  PageWrapper,
  Wrapper,
  Width100,
  Height100,
} from "style/layout";

import "../../layout.scss";

const CustomSectionWrapper = styled.div`
  position: relative;
`;
const CustomPageWrapper = styled(PageWrapper)`
  height: auto;
`;
const CustomWrapper = styled(Wrapper)`
  border-color: ${(props) => props.theme.colors.lbgBorderColor};
  padding-top: ${(props) => props.theme.sizes.headerHeight};
  box-sizing: border-box;
  padding-bottom: ${(props) => props.theme.Rem(170)};
`;

const CustomWidth100 = styled(Width100)`
  height: 100%;
`;

const CustomHeight100 = styled(Height100)`
  width: 100%;
`;

const FirstSection = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [params, setParams] = useState();
  const [newsListData, setNewsListData] = useState();

  const {
    isLoading,
    isError,
    data,
    isFetching,
    refetch: fetchNewsList,
  } = useQuery(["getNewsList", params], () => getNewsList(params), {
    enabled: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    // 카테고리, 서치 등의 상태값을 가지고 있다면 api call 및 category 상태값 업데이트
    if (location.state) {
      const newParams = location.state;
      dispatch(setCategory(newParams.category));
      setParams(newParams);
    } else {
      const defaultPrams = { category: "", search: null };
      setParams(defaultPrams);
    }
  }, [location]);

  useEffect(() => {
    
    if (params) {
      fetchNewsList();
    }
  }, [params]);

  useEffect(() => {
    setNewsListData(data);
  }, [data]);

  const Contents = () => {
    if (isError) return <Error/>;
    if (isLoading || isFetching) return <PrLoading/>;
    if (newsListData)
        return <NewsList itemsPerPage={12} newsListData={newsListData} />
  };

  return (
    <>
    {newsListData ? <PrNav/> : null}
    <CustomSectionWrapper>
      <CustomPageWrapper>
        <CustomWrapper>
          <CustomWidth100>
            <CustomHeight100>
    
             {Contents()}

            </CustomHeight100>
          </CustomWidth100>
        </CustomWrapper>
      </CustomPageWrapper>
    </CustomSectionWrapper>
    </>
  );
};

export default FirstSection;
