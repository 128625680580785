import React from "react";
import styled from "styled-components";

import {
	Height50,
	Height100,
	textBoxTitle,
	textBoxContents,
} from "style/layout";

const CustomHeight50 = styled(Height50)`
    box-sizing: border-box;
	border-top: 1px solid ${(props) => props.theme.colors.lbgBorderColor};
    padding: ${(props) => props.theme.Rem(50)} ${(props) => props.theme.Rem(25)}
		${(props) => props.theme.Rem(50)} ${(props) => props.theme.Rem(25)};
    &:first-of-type{
        justify-content: flex-end;
        border-bottom:1px solid ${(props) => props.theme.colors.borderColor};
        box-sizing:border-box;
    }
    &:last-of-type{
        justify-content: flex-start;
    }
    ${props => props.theme.device.tabletL}{
		padding: ${(props) => props.theme.Rem(25)} ${(props) => props.theme.Rem(10)}
		${(props) => props.theme.Rem(50)} ${(props) => props.theme.Rem(20)};
	}
`;

const CustomTitle = styled.h3`
	${textBoxTitle}
	color:${(props) => props.theme.colors.pointColor};
	font-size: ${(props) => props.theme.Rem(16)};
	margin-bottom: ${(props) => props.theme.Rem(40)};
`;

const CustomContents = styled.ul`
	${textBoxContents}
	font-size:1.1rem;
	font-weight: 400;
	line-height: ${(props) => props.theme.Rem(34)};
	padding-left: 1rem;
	box-sizing: border-box;
	list-style: disc;
	br {
		display: none;
	}
	li {
		line-height: 1.5;
		margin-bottom: ${(props) => props.theme.Rem(10)};
		list-style: disc;
	}
`;

const CustomHeight100 = styled(Height100)`
	height: 100%;
	width:100%;
	${props => props.theme.device.tablet}{
		border-left:1px solid rgba(90,90,119,0.08);
	}
`;


const LeftTextBox = () => {
	return (
		<CustomHeight100>
			<CustomHeight50>
				<div>
					<CustomTitle>Big Data</CustomTitle>
					<CustomContents>
						<li>
							국내 모든 법인 기업 (100만 개 이상) 자동으로 식별하고 표본 저장
						</li>
						<li>
							기업 별 최대 300가지 이상의 비 금융 대안 데이터 실시간 수집
						</li>
						<li>
							AI(인공지능) 분석을 위한 데이터 자동 전처리, 가공, 통합 실시
						</li>
					</CustomContents>
				</div>
			</CustomHeight50>

			<CustomHeight50>
				<div>
					<CustomTitle>Artificial Intelligence</CustomTitle>
					<CustomContents>
						<li>
							AI 기술력 접목된 약 100개 이상의 기업 분석 모형 라이브러리 구축
						</li>
						<li>
							기업경쟁력, 성장잠재성, 기술혁신성, 경영위험도, 사회적가치 등 예측
						</li>
						<li>
							투자 매력도, 리스크 수준 등에 따른 AI 큐레이션 / 포트폴리오 제안
						</li>
					</CustomContents>
				</div>
			</CustomHeight50>
		</CustomHeight100>
	);
};

export default LeftTextBox;
