import React from "react";
import { PageInfoTitle, PageInfoExplain } from "style/layout";

const Info = (props) => {

    const { primary } = props;
	const title = props.texts.title;
	const content = props.texts.content;

	const textToDom = (text,tag) =>{
		let stringDom = text.toString().split(tag);
		return stringDom.map( (line,idx) => {
			if(tag === "<br />"){
				return <>{line}<br /></> //br일 때
			}else{// b일 때
				if(idx === 0){
					return <b>{line}</b>;
				}else{
					return <>{line}</>;
				}
			}
		});
	}

	return (
		<div>
			<PageInfoTitle primary={primary} >
				{
					title ?
						<>
							{textToDom(title,"<br />")}
						</>
						:
						<>
							Integrated AI<br />
							Financial Analytics
						</>
				}
			</PageInfoTitle>
			<PageInfoExplain primary={primary}>
				{
					content ?
						<>
							{textToDom(content,"<br />")}
						</>
						:
						<>
							금융 인공지능 분석을 위한<br />
							기업 데이터 수집–가공-진단 기술이<br />
							통합적으로 구현되었습니다.
						</>
				}

			</PageInfoExplain>
		</div>
	);
};

export default Info;
