import React from "react";
import styled from "styled-components";

import { Wrapper, Width100 } from "style/layout";
import {useSelector} from "react-redux";

const CustomFooter = styled.footer`
	background-image: url("/img/landing-main.png");
	background-position: center 70%;
	background-size: cover;
	background-repeat: no-repeat;
	color: #ffffff;
	height: ${(props) => props.theme.Rem(300)};
	${props => props.theme.device.tablet}{
		height:auto;
	}
`;

const CustomWrapper = styled(Wrapper)`
	border-color: ${(props) => props.theme.colors.borderColor};
`;

const CustomWidth100 = styled(Width100)`
	height: 100%;
`;

const CustomFooterInfoList = styled.ul`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: ${(props) => props.theme.Rem(300)};
	li {
		padding: ${(props) => props.theme.Rem(55)} 0;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		width: calc((100% - 26.73%) / 4);
		height: 100%;
		word-break: keep-all;
		font-size: 0.9rem;
		//border-color:$lbg-border-color;
		img {
			width: ${(props) => props.theme.Rem(120)};
			padding-bottom: ${(props) => props.theme.Rem(10)};
			margin-right:0.8rem;
			& + p {
				font-size: ${(props) => props.theme.Rem(11)};
				color: ${(props) => props.theme.colors.footerUntilColor};
			}
		}
		p {
			line-height: ${(props) => props.theme.Rem(24)};
			color: rgba(255, 255, 255, 0.7);
			font-weight:800;
			b {
				display: block;
				font-weight:400;
				box-sizing: border-box;
				padding-bottom: ${(props) => props.theme.Rem(15)};
				color: rgba(255, 255, 255, 1);
			}
		}
	}
	${props => props.theme.device.tablet}{
		flex-direction:column;
		height:auto;
		box-sizing:border-box;
		padding-top:1rem;
		li{
			width:100%;
			padding: 1rem 0;
			flex-wrap:wrap;
			font-size:1rem;
			&:first-of-type {
				width: 100%;
			}
			&:not(:first-of-type){
				flex-direction:row;
				p{
					width:70%;
					&:nth-of-type(odd){
						width:30%;
					}
				}
			}
		}
	}
`;

const CustomSnsWrapper = styled.ul`
	display: flex;
	flex-direction: row;
	
	li {
		width: auto;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 0;
		&:first-of-type {
			// width: 20%;
			img {
				max-width: ${(props) => props.theme.Rem(28)};
			}
		}
		a{
			padding-right:1.125rem;
			box-sizing:border-box;
		}
		img {
			max-width: ${(props) => props.theme.Rem(20)};
			width:auto;
			margin:0;
		}
	}
`;
const Footer = () => {
	const { device } = useSelector(
		(state) => ({
			device: state.style.device
		})
	);
	return (
		<CustomFooter>
			<CustomWrapper>
				<CustomWidth100>
					<CustomFooterInfoList>
						<li>
							<img src="/img/all_menu_logo.png" alt="antock" />
							<p>© 2022 Antock Co., Ltd.</p>
						</li>
						<li className="border-left">
							<p>대표자</p>
							<p>
								<b>박재준</b>
							</p>
							<p>회사명</p>
							<p>
								<b>주식회사 앤톡 Antock Co., Ltd.</b>
							</p>
							<p>사업자등록번호</p>
							<p>
								<b>527-88-00181</b>
							</p>
						</li>
						<li className="border-left">
							<p>T</p>
							<p>
								<b>02-6263-1026</b>
							</p>
							<p>F</p>
							<p>
								<b>050-8090-1026</b>
							</p>
							<p>E</p>
							<p>
								<b>contact@antock.com</b>
							</p>
						</li>
						<li className="border-left">
							<span>
								<p>본사 주소</p>
								<p>
									<b>
										서울특별시 성동구 뚝섬로17가길 48, 성수에이원 지식산업센터 906호 (04785)
									</b>
								</p>
							</span>
							<span>
								<p>기술연구소 R&D 주소</p>
								<p>
									<b>
										서울특별시 영등포구 의사당대로83, 오투타워 서울핀테크랩 6층 108호 (07325)
									</b>
								</p>
							</span>
						</li>
						<li className="border-left">

								{
									device && ( device !== "tablet" || device !== "mobile") ?
										<CustomSnsWrapper>
											<li>
												<a
													href="https://www.youtube.com/channel/UCcXuO1UjoIdWv5nbIlVfwkQ"
													target="_blank"
												>
													<img
														src="/img/youtube.png"
														alt="youtube"
													/>
												</a>
											</li>
											<li>
												<a
													href="https://blog.naver.com/NBlogTop.naver?isHttpsRedirect=true&blogId=antock"
													target="_blank"
												>
													<img
														src="/img/naver.png"
														alt="naver blog"
													/>
												</a>
											</li>
											<li>
												<a
													href="https://www.instagram.com/antock_official/"
													target="_blank"
												>
													<img
														src="/img/instagram.png"
														alt="instagram"
													/>
												</a>
											</li>
										</CustomSnsWrapper>
									: null }

						</li>
					</CustomFooterInfoList>
				</CustomWidth100>
			</CustomWrapper>
		</CustomFooter>
	);
};

export default Footer;
