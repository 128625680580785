const SET_DEVICE = "style/SET_DEVICE";
const SET_SCROLL_OFFSET = "style/SET_SCROLL_OFFSET";
const SET_IS_EXPLORER = "style/SET_IS_EXPLORER";
const SET_HEADER_VISIABLE = "style/SET_HEADER_VISIABLE";
const SET_OBSERVER = "style/SET_OBSERVER"

export const setObserver = (observer) => ({type:SET_OBSERVER,observer});
export const setDevice = (device) => ({ type: SET_DEVICE, device });
export const setScrollOffset = (scrollOffset) => ({
	type: SET_SCROLL_OFFSET,
	scrollOffset,
});
export const setIsExplorer = (isExplorer) => ({
	type: SET_IS_EXPLORER,
	isExplorer,
});
export const setHeaderVisiable = (headerVisiable) => ({
	type: SET_HEADER_VISIABLE,
	headerVisiable,
});

const initialState = {
	device: "desktop",
	scrollOffset: 0,
	isExplorer: false,
	headerVisiable: true,
};

export default function style(state = initialState, action) {
	switch (action.type) {
		case SET_OBSERVER:
			return {
				...state,
				observer:action.observer
			};
		case SET_DEVICE:
			return {
				...state,
				device: action.device,
			};
		case SET_SCROLL_OFFSET:
			return {
				...state,
				scrollOffset: action.scrollOffset,
			};
		case SET_IS_EXPLORER:
			return {
				...state,
				isExplorer: action.isExplorer,
			};
		case SET_HEADER_VISIABLE:
			return {
				...state,
				headerVisiable: action.headerVisiable,
			};
		default:
			return state;
	}
}
