import React, {useEffect} from 'react';

import { useQuery } from "react-query";
import { getHistory } from "api/api";

import MobileHistory from 'mobile/MobileHistory';
import styled, {css} from "styled-components";
import {
    ContentWrapper, InfoGraphicImg,
    MobileContentsList,
    PageInfoTitle,
    PageWrapper,
    SectionInfoWrapper, Width100,
    Wrapper
} from "../../style/layout";

import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {setCurrentPage} from "../../modules/page";
import SimpleBar from "simplebar-react";
import {useInView} from "react-intersection-observer";
import {setObserver} from "../../modules/style";
import {awardListArr} from "../../components/company/SecondSection";

const CustomWrapper = styled.div`
	background-image: url("/img/landing-main.png");
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center center;
	display:flex;
	width:100%;
	min-height:100vh;
	height:100%;
	.border-light{
		align-items:center;
		justify-content:center;
		display: flex;
		h2{
			width:auto;
			height:100%;
			align-items:flex-start;
			justify-content:center;
			position:relative;
			div{
				width:100%;
				position:absolute;
				bottom:20vh;
			}
			img{
				height:6vw;
			}
			p{
				width:100%;
				text-align:center;
				&:first-of-type{
					margin-top:0;
				}
				${props => props.theme.device.tabletL}{
					font-size:2rem;
				}
				${props => props.theme.device.tablet}{
					font-size:1.8rem;
				}
				${props => props.theme.device.mobile}{
					font-size:1.1rem;
				}
			}
		}
	}
`;
const MobileMainWrapper = styled(Wrapper)`
    position:relative;
    flex-direction:column;
    
    img{
        width:90%;
        max-width:350px;
    }
    
    strong{
        font-weight:800;
        font-size:1.55rem;
        color:#fff;
        margin-bottom:1rem;
    }
    div{
        position:absolute;
        bottom:10vh;
        color:rgba(255,255,255);
        text-align:center;
        p{
            font-weight:300;
            line-height:1.3rem;
            margin-bottom:1rem;
        }
        b{
            font-size:1.15rem;
            line-height:1.5rem;
        }
    }
`;

const AwardItemWrapper = styled.div`
  display: flex;
  flex-directions: row;
  justify-content: space-between;
  padding: 0 4%;
`;


const AwardItem = styled.div`
  background-color: #fff;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  width: 88vw;
  max-width:300px;
  position: relative;
  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
    margin-top: auto;
  }

  ${(props) => {
    return css`
      padding: ${props.theme.Rem(42)} ${props.theme.Rem(48)};
      margin-bottom: ${props.theme.Rem(40)};
      margin-top: ${props.theme.Rem(10)};
      margin-right: ${props.theme.Rem(20)};
      line-height: 1.7rem;
      color: #393939;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        font-size: ${props.theme.Rem(20)};
        font-weight: 300;
        word-break: keep-all;
      }
      h4 {
        font-size: ${props.theme.Rem(20)};
        font-weight: 700;
        word-break: keep-all;
        margin-bottom: ${props.theme.Rem(50)};
      }
      .host-logo {
        max-height: ${props.theme.Rem(50)};
        width: auto;
        flex-shrink: 0;
        height: auto;
        max-width: 60%;
        margin-top: auto;
      }
    `;
}};
  ${(props) => props.theme.device.tabletL} {
    padding: ${(props) => props.theme.Rem(36)} ${(props) => props.theme.Rem(28)};
    p {
      font-size: ${(props) => props.theme.Rem(14)};
      font-weight: 300;
    }
    h4 {
        font-size: 1.45rem;
        font-weight: 900;
    }
  }
`;
const AwardList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  // height: calc(100% - (${(props) => props.theme.sizes.headerHeight} * 2));
  box-sizing: border-box;
  padding: 0 0 4% 0;
  overflow: auto;
`;
const AwardIcon = styled.img`
  flex-shrink: 0;

  ${(props) => {
    return css`
      width: ${props.theme.Rem(26)};
    `;
}}
`;

const AwardImg = styled.img`
  width: 200px;
  height: 200px;
`

const MobileCompany = () => {
    const dispatch = useDispatch();

    const { device,observer } = useSelector((state) => ({
        device: state.style.device,
        observer: state.style.observer,
    }));
    const { ref, inView } = useInView({});

    useEffect(()=>{
        dispatch(setObserver(inView));
    },[inView]);

    useEffect(() => {
        dispatch(setCurrentPage("company"));
    }, [device]);

    const {
        isError,
        isLoading,
        isFetching,
        data: historyData,
    } = useQuery("getHistory", getHistory);

    return (
        <>
            <CustomWrapper ref={ref}>
                <PageWrapper>
                    <MobileMainWrapper className="border-light">
                        <strong>금융을 새로운 관점으로</strong>
                        <img src="/img/company_main_img.png" alt="antock"/>
                        <div>
                            <p>(주)<b>antock</b>은 개인 투자자를 상징하는 <b>ant</b>와<br />
                                주식을 의미하는 <b>stock</b>의 합성어로,</p>
                            <b>
                                IT 기술력 기반 개인투자자 친화적인<br />
                                Fin-Tech 서비스를 제공합니다.
                            </b>
                        </div>
                    </MobileMainWrapper>
                </PageWrapper>
            </CustomWrapper>
            <PageWrapper>
                <ContentWrapper className="info-wrapper border-b-dark">
                    <PageInfoTitle>사업연혁</PageInfoTitle>
                </ContentWrapper>
                <Wrapper>
                    {
                        historyData ? <MobileHistory historyData={historyData} /> : null
                    }
                </Wrapper>
            </PageWrapper>
            <PageWrapper>
                <ContentWrapper className="info-wrapper border-tb-dark" style={{borderWidth:"1px 1px 0 1px"}}>
                    <PageInfoTitle>수상인증</PageInfoTitle>
                </ContentWrapper>
                <Wrapper>
                    <SectionInfoWrapper>
                        AI 분석 알고리즘을 토대로 전통적인 기업 분석과 미래전망에 대한 예측을 다각적으로 진행할 수 있습니다.
                    </SectionInfoWrapper>
                </Wrapper>
                <Wrapper>
                    <AwardList className="scrollable-content">
                            <AwardItemWrapper>
                                {awardListArr
                                    ? awardListArr.map((item, idx) => {
                                        return (
                                            <AwardItem key={item.title+idx}>
                                                <h4>{item.title}</h4>
                                                <div>
                                                    <AwardImg src={item.logoUrl} className="host-logo" alt={item.title}/>
                                                    <AwardIcon src={"/img/award_icon.png"} alt="수상내역"/>
                                                </div>
                                            </AwardItem>
                                        );
                                    })
                                    : null}
                            </AwardItemWrapper>
                    </AwardList>
                </Wrapper>
            </PageWrapper>
        </>
    );
};

export default MobileCompany;