import React, { useState, useRef, useEffect } from "react";
import { animated, useSpring } from "react-spring";

const FirstLine = ({ init, data }) => {
	const lineRef = useRef(null);
	const [length, setLength] = useState(null);

	useEffect(() => {
		setLength(lineRef.current.getTotalLength());
	}, [data]);

	const animatedStyle = useSpring({
		from: { length },
		to: { length: 0 },
		delay: 2000,
		config: { duration: 500 },
		reset: init,
	});

	return (
		<>
			{data ? (
				<animated.path
					ref={lineRef}
					id={data.id}
					data-name={data.id}
					d={data.d}
					transform={data.transform}
					fill="transparent"
					stroke="#1210c7"
					strokeDashoffset={animatedStyle.length}
					strokeDasharray={length}
					strokeWidth={3}
				/>
			) : null}
		</>
	);
};

export default React.memo(FirstLine);
