import { ThemeProvider } from "styled-components";
import GlobalStyles from "style/GlobalStyles";

import Header from "Header";
import MobileHeader from "mobile/MobileHeader";
import { theme } from "style/theme";

import { useRoutes } from "react-router-dom";
import routes from "routes";

import { useDispatch, useSelector } from "react-redux";
import { setDevice, setIsExplorer } from "modules/style";

import { useWindowSize, usePreventRightClick } from "hooks/CustomHooks";

import { useEffect } from "react";

import {isExplorer, isLandscape} from "lib/function";

import "reset.css";
import "style/font/pretendard/pretendard-subset.css";
import MobileFooter from "./mobile/MobileFooter";

function App() {
	const windowSize = useWindowSize();
	const content = useRoutes(routes);
	const dispatch = useDispatch();

	// usePreventRightClick();

	const { device } = useSelector((state) => ({
		device: state.style.device,
	}));

	// tablet: "768px",
	// tabletL: "1270px",
	// notebook: "1300px",
	// desktopS: "1400px",
	// desktopM: "1600px",
	// desktopL: "2100px",

	useEffect(() => {
		if (windowSize.width) {
			if (windowSize.width >= 2100) {
				dispatch(setDevice("desktopL"));
			} else if (windowSize.width <= 2099 && windowSize.width >= 1600) {
				dispatch(setDevice("desktopM"));
			} else if (windowSize.width <= 1599 && windowSize.width >= 950) {
				if(!isLandscape()){ // ipad 가로회전 때문에 조건 추가
					dispatch(setDevice("tablet"));
				}else{
					dispatch(setDevice("desktop"));
				}
			} else if (windowSize.width <= 1023 && windowSize.width > 768) {
				dispatch(setDevice("tablet"));
			} else {
				dispatch(setDevice("mobile"));
			}
		}
	}, [windowSize.width]);

	useEffect(() => {
		isExplorer()
			? dispatch(setIsExplorer(true))
			: dispatch(setIsExplorer(false));
	}, []);

	return (
		<>
			<GlobalStyles />
			<ThemeProvider theme={theme}>
				{/* Responsive Header */}
				{device === "mobile" || device === "tablet" ? (
					<MobileHeader />
				) : (
					<Header />
				)}
				{content}
				{device === "mobile" || device === "tablet" ?
					<MobileFooter />
					: null}
			</ThemeProvider>
		</>
	);
}

export default App;
