import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { Wrapper, Logo } from "style/layout";
import { useScroll } from "hooks/CustomHooks";

import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { setCategory, setSearchKeyword, setOffset } from "modules/news";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const CustomHeader = styled.header`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: ${props => props.theme.sizes.mHeaderHeight};
  z-index: 20;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  color: #fff;
    ${(props) => {
        if(props.observer){
            return`
                background-color:transparent;
                color:#fff;
            `;
        }else{
            return`  
              color: #fff;
              background-color:${props.theme.colors.pointColor};
            `;
        }
    }}
  
  `
;

const HeaderWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: row;
  border: none;
  position:relative;
  .ask-btn{
    position:absolute;
    height:${props => props.theme.sizes.headerHeight};
    right: calc((6% + 0.9rem) * -1);
    width: calc(6% + 0.95rem);
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    cursor: pointer;
    ${props => {
        if(props.page === "dvdata" || props.page === "dvai"){
            return `
                background:linear-gradient(90deg, rgba(32, 14, 208, 0.8), rgba(137, 0, 255, 0.8));
                background-position:0% 0%;
                background-size: 400% 400%;
                animation: AnimationName 7s ease infinite;
            `;
        }
    }}
    &::before{
        content:"문의하기";
        position:absolute;
        color:${props=>props.theme.colors.pointColor};
        background-image:url("/img/speach.png");
        background-size:100% 100%;
        background-position:center center;
        background-repeat:no-repeat;
        font-weight:600;
        top: 40%;
        left: -5rem;
        width:7rem;
        height:2.7rem;
        text-align:center;
        opacity:0;
        vertical-align: middle;
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        box-sizing: border-box;
        padding-right: 0.8rem;
        padding-bottom: 0.2rem;
        transition: all 0.3s ease-in-out ;
    }
    img{
        width:30px;
        ${props => props.theme.device.tabletL}{
            width: 20px;
            height: 20px;
        }
    }
    
        &:hover{
            &::before{
                opacity:1;
                display:flex;
            }
        }
  }
  
  @keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
`;

const HeaderNav = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:flex-end;
  height: 100%;
  width: 100%;
  position:relative;
  li {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    position: relative;
    a {
      display: flex;
      padding: 1.2rem ${(props) => props.theme.Rem("35")} 1.2rem 2rem;
      height: 100%;
      vertical-align: middle;
      align-items: center;
      font-size: 1.5rem;
      color:#fff;
      font-weight: 400;
    }
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    justify-content: flex-start;
  }
`;

const PrSubMenuWrapper = styled.div`
  display: block;
  height: 2.5rem;
  width: 100%;
  background-color: ${(props) => props.theme.colors.pointColor};
  transition: all 0.3s ease-in-out;
  ${(props) => {
    if (
        (props.page === "pr" && props.isOffset) ||
        props.page === "newsdetail"
    ) {
        return css`
        display: block;
      `;
    } else if (props.device !== "desktop") {
        return css`
        display: none;
      `;
    } else if (!props.isOffset) {
        return css`
        display: none;
      `;
    }
}}
`;

const HeaderNavItem = styled.li`
  ${(props) => {
    if (
        props.value === props.page ||
        (props.page === "dvdata" && props.value === "dvai") ||
        (props.page === "newsdetail" && props.value === "pr")
    ) {
        return css`
        a {
          font-weight: 600;
          position: relative;
          background-color:${(props) => props.theme.colors.pointColor};
          &::before {
            content: "";
            width: 0.7rem;
            height: 0.7rem;
            border-radius: 0.7rem;
            background-color: #fff;
            margin-right: 1rem;
          }
        }
      `;
    }
}}
  &.ask-btn{
      position:absolute;
      bottom:2rem;
      right:0;
      a{
        padding-right:0;
        img{
            width:2.5rem;
            margin-right:1rem;
        }
      }
  }
`;

const PrSubMenuList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width:100%;
  align-items: center;
  justify-content: space-evenly;
  height: 2.5rem;
  li {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    color: #fff;
    font-size:0.85rem;
    font-weight:300;
    &:first-of-type{
        padding-left:0;
    }
    &:last-of-type{
        padding-right:0;
    }
    .input-popup{
        position:absolute;
        background-color:#4d4cd7;
        width:100%;
        height:40px;
        right:0;
        top:calc(${props=> props.theme.sizes.mHeaderHeight} + 2.5rem );
        display:flex;
        flex-direction:row;
        flex-wrap:nowrap;
        justify-content:space-between;
        button{
            width:40px;
            height:40px;
        }
    }
    input {
      background-color: transparent;
      height: 40px;
      font-weight: 600;
      width:calc(100% - 40px);
      color:#fff;
    }
    button {
      // padding-left:1rem;
      height: 2.5rem;
      width: 2.5rem;
      // background-color: rgba(255, 255, 255, 0.25);
      box-sizing: border-box;
      svg {
        vertical-align: middle;
      }
    }
  }
  ${props => props.theme.device.notebook} {
    left: auto;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    justify-contents: flex-start;
    left:-100%;
  }
`;

const PrSubMenuItem = styled.li`
  ${(props) => {
    if (props.category === props.value) {
        return css`
        font-weight: 900 !important;
      `;
    }
}}
`;

const MenuBtn = styled.button`
    margin-left:auto;
    padding:0 2rem;
    svg{
        font-size:2rem;
        color:#fff;
    }
    margin-right:-2rem;
`;

const MobileMenuStyle = styled.div`
    position:fixed;
    display:flex;
    width:100vw;
    height:100vh;
    background-color:rgba(0,0,0,0.7);
    backdrop-filter: blur(0.2rem);
    top:0;
    right:0;
    z-index:10; 
    & > div{
        border-color:rgba(255,255,255,0.5);
    }      
`;


const MobileHeader = () => {

    const scroll = useScroll();

    const [modalIsOpen,setModalIsOpen] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const inputRef = useRef(null);

    const [isOffset, setIsOffset] = useState(false);

    const [search, setSearch] = useState();
    const [inputFocus, setInputFocus] = useState(false);
    const [isMSearchInput,setIsMSearchInput] = useState(false);

    const { observer } = useSelector((state) => ({
        observer: state.style.observer,
    }));

    useEffect(() => {
        const SDK_KEY = process.env.REACT_APP_KAKAO_SDK_KEY;

        if (!window.Kakao.isInitialized()){
            window.Kakao.init(SDK_KEY);
        }
    } ,[]);

    const handleKakaoButton = () => {
        window.Kakao.Channel.chat({
            channelPublicId: '_wxcprb'
        });
    };


    useEffect(() => {
        const offset = scroll.scrollY;
        offset > 0 ? setIsOffset(true) : setIsOffset(false);
    }, [scroll]);

    const { currentSection, currentPage, category, searchKeyword, device } = useSelector(
        (state) => ({
            currentSection: state.page.currentSection,
            currentPage: state.page.currentPage,
            category: state.news.category,
            searchKeyword: state.news.searchKeyword,
            device: state.style.device,
        })
    );

    const handleNavClick = (newCategory) => {
        if(inputRef && inputRef.current){
            inputRef.current.value = '';
        }
        setSearch(null);
        dispatch(setSearchKeyword(null));
        dispatch(setCategory(newCategory));
        dispatch(setOffset(0));
        moveToPrPageWithParams(newCategory, "");
    };

    const handleKeywordChange = (e) => {
        setSearch(e.target.value);
        setInputFocus(true);
        if (e.target.value.length > 0) setInputFocus(true);
    };

    const handleSearchSubmit = () => {
        if (search) {
            dispatch(setSearchKeyword(search));
            moveToPrPageWithParams(category, search);
        } else {
            alert("검색어를 입력해주세요");
        }
    };

    const callSearchInput = () =>{
        if(!isMSearchInput){
           setIsMSearchInput(true);
        }else{
            setIsMSearchInput(false);
        }
    }

    const handleKeyPress = (e) => {
        if (e.key == "Enter") {
            if (search) {
                dispatch(setSearchKeyword(search));
                moveToPrPageWithParams(category, search);
            } else {
                alert("검색어를 입력해주세요");
            }
        }
    };

    const handleOnBlur = () => {
        inputRef.current.value.length > 0
            ? setInputFocus(true)
            : setInputFocus(false);
    };

    const moveToPrPageWithParams = (category, search) => {
        navigate("/pr", {
            state: {
                category: category,
                search: search,
            },
        });
    };

    const handleMoveToPR = () => {
        dispatch(setOffset(0));
        navigate("/pr", {
            state: {
                category: '',
                search: null,
            },
        });
    }

    const PrNavContents = () => {
        return (
            <>
                <PrSubMenuList>
                    <PrSubMenuItem
                        category={category}
                        value={""}
                        onClick={() => handleNavClick("")}
                    >
                        전체보기
                    </PrSubMenuItem>
                    <PrSubMenuItem
                        category={category}
                        value={"NOTICE"}
                        onClick={() => handleNavClick("NOTICE")}
                    >
                        공지사항
                    </PrSubMenuItem>
                    <PrSubMenuItem
                        category={category}
                        value={"FEED"}
                        onClick={() => handleNavClick("FEED")}
                    >
                        회사소식
                    </PrSubMenuItem>
                    <PrSubMenuItem
                        category={category}
                        value={"MEDIA"}
                        onClick={() => handleNavClick("MEDIA")}
                    >
                        언론보도
                    </PrSubMenuItem>
                    <li>
                        <button onClick={callSearchInput}>
                            {
                                !isMSearchInput ? <SearchIcon /> : <ExpandLessIcon />
                            }
                        </button>
                        {
                            isMSearchInput ?
                                <span className="input-popup">
                                    <Wrapper>
                                        <input
                                            ref={inputRef}
                                            type="text"
                                            onChange={handleKeywordChange}
                                            onKeyPress={handleKeyPress}
                                            placeholder={"검색어를 입력해주세요."}
                                            onFocus={() => {
                                                setInputFocus(true);
                                            }}
                                            onBlur={handleOnBlur}
                                        />
                                      <button type={"submit"} onClick={handleSearchSubmit}>
                                        <SearchIcon />
                                      </button>
                                    </Wrapper>
                                </span>
                                : null }
                    </li>
                </PrSubMenuList>
            </>
        );
    };


    // const DesktopButtonContent = () => {
    //     let blueBtnDom = <a href="https://forms.gle/QbgWKviYSLZyVhwj8" className="ask-btn" target="_blank"><img src="/img/ask.png" alt="문의하기"/></a>;
    //     let whiteBtnDom = <a href="https://forms.gle/QbgWKviYSLZyVhwj8" className="ask-btn" target="_blank"><img src="/img/ask_w.png" alt="문의하기"/></a>;
    //     let kakaoBlueBtnDom = <a href onClick={handleKakaoButton} className="ask-btn" target="_blank"><img src="/img/ask.png" alt="문의하기"/></a>;
    //     let kakaoWhiteBtnDom = <a href onClick={handleKakaoButton} className="ask-btn" target="_blank"><img src="/img/ask_w.png" alt="문의하기"/></a>;
    //     if (currentPage === "dvdata" || currentPage === "dvai") {
    //         if (currentSection > 1) {
    //             //return blueBtnDom;
    //             return whiteBtnDom;
    //         }else{
    //             return whiteBtnDom;
    //         };
    //     } else if (currentPage === "pr"){
    //         return kakaoBlueBtnDom;
    //     }else if (currentPage === "landing"){
    //         if (currentSection <= 2) {
    //             return kakaoWhiteBtnDom;
    //         }else{
    //             return kakaoBlueBtnDom;
    //         }
    //     }else if (currentPage === "mri") {
    //         if (currentSection === 4 || currentSection === 1) {
    //             return kakaoWhiteBtnDom;
    //         }else{
    //             return kakaoBlueBtnDom;
    //         }
    //     }else {
    //         if (currentSection > 1) {
    //             return kakaoBlueBtnDom;
    //         }else{
    //             return kakaoWhiteBtnDom;
    //         };
    //     };
    // };


    const MobileMenuModal = () =>{
        return(
            <MobileMenuStyle>
                <Wrapper>
                    <HeaderNav>
                        <HeaderNavItem page={currentPage} value="hubble">
                            <a href="/hubble">Hubble Database</a>
                        </HeaderNavItem>
                        <HeaderNavItem page={currentPage} value="mri">
                            <a href="/mri">antock MRI</a>
                        </HeaderNavItem>
                        <HeaderNavItem page={currentPage} value="company">
                            <a href="/company">Company</a>
                        </HeaderNavItem>
                        <HeaderNavItem page={currentPage} value="pr">
                            <a href="/pr" onClick={handleMoveToPR}>PR</a>
                        </HeaderNavItem>
                        <HeaderNavItem value="ask" className="ask-btn">
                            <a onClick={handleKakaoButton} href="#"><img src="/img/ask_w.png" alt="문의하기"/>문의하기</a>
                        </HeaderNavItem>
                    </HeaderNav>
                </Wrapper>
            </MobileMenuStyle>
        );
    }

    // const MobileButtonContent = () => {
    //     if (currentPage === "dvdata" || currentPage === "dvai") {
    //         return <a href="https://forms.gle/QbgWKviYSLZyVhwj8" className="ask-btn" target="_blank"><img src="/img/ask.png" alt="문의하기"/></a>;
    //     } else {
    //         return <a href onClick={handleKakaoButton} className="ask-btn" target="_blank"><img src="/img/ask.png" alt="문의하기"/></a>;
    //     };
    // };

    const handleMenuBtnClick = () => {
        !modalIsOpen ? setModalIsOpen(true) : setModalIsOpen(false)
    }

    return (
            <>
                <CustomHeader page={currentPage} section={currentSection} isOffset={isOffset} device={device} observer={observer}>
                    <HeaderWrapper page={currentPage}>
                        <Logo>
                            <a href="/">Antock</a>
                        </Logo>
                        <MenuBtn onClick = {handleMenuBtnClick}>
                            {
                                modalIsOpen ? <CloseIcon /> : <MenuIcon />
                            }
                        </MenuBtn>
                    </HeaderWrapper>

                <PrSubMenuWrapper
                    isOffset={true}
                    page={currentPage}
                    device={device}
                >
                    {(currentPage === "pr") || currentPage === "newsdetail"
                        ? PrNavContents() : null}
                </PrSubMenuWrapper>
            </CustomHeader>

            { modalIsOpen ? <MobileMenuModal /> : null }

        </>
    );
};

export default MobileHeader;
