import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getNewsList } from 'api/api';
import styled from "styled-components";
import {
    ContentWrapper, InfoGraphicImg,
    MobileContentsList,
    PageInfoTitle,
    PageWrapper,
    SectionInfoWrapper, Width100,
    Wrapper
} from "../../style/layout";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage } from "../../modules/page";

import MobileNewsList from 'mobile/components/MobileNewsList';


import PrLoading from "components/pr/PrLoading";
import Error from "components/Error";
import { useLocation } from 'react-router-dom';
import { setCategory } from "modules/news";
import {useInView} from "react-intersection-observer";
import {setObserver} from "../../modules/style";

const MobilePr = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const { device,observer } = useSelector((state) => ({
        device: state.style.device,
        observer: state.style.observer,
    }));
    const { ref, inView } = useInView({});

    useEffect(()=>{
        dispatch(setObserver(inView));
    },[inView]);

    useEffect(() => {
        dispatch(setCurrentPage("pr"));
    }, [device]);

    const [params, setParams] = useState();
    const [newsListData, setNewsListData] = useState();

    const {
        isLoading,
        isError,
        data,
        isFetching,
        refetch: fetchNewsList,
    } = useQuery(["getNewsList", params], () => getNewsList(params), {
        enabled: false,
    });


    useEffect(() => {
        window.scrollTo(0, 0);

        // 카테고리, 서치 등의 상태값을 가지고 있다면 api call 및 category 상태값 업데이트
        if (location.state) {
            const newParams = location.state;
            dispatch(setCategory(newParams.category));
            setParams(newParams);
        } else {
            const defaultPrams = { category: "", search: null };
            setParams(defaultPrams);
        }
    }, [location]);


    useEffect(() => {
        if (params) fetchNewsList();
    }, [params]);

    useEffect(() => {
        if (data) setNewsListData(data);
    }, [data]);

    const Contents = () => {
        if (isError) return <Error />;
        if (isLoading || isFetching) return <PrLoading />;
        if (newsListData)
            return <MobileNewsList itemsPerPage={6} newsListData={newsListData} />
    };


    return (
        <PageWrapper style={{ backgroundColor: "rgb(235, 235, 235)" }}>
            <Wrapper style={{ padding: "calc(50px + 10vw) 0" }}>
                {Contents()}
            </Wrapper>
        </PageWrapper>

    );
};

export default MobilePr;