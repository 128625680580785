import React from "react";
import styled, {css} from "styled-components";

import {
	Height50, 
	Width100,
	Width45,
	Width55,
	Wrapper,
    MriPageLogo,
	PageWrapper, 
    textBoxContents,
	textBoxTitle
} from "style/layout";
import Info from "../Info";
import Contents from '../landing/fourth/Contents';

import { useSelector } from "react-redux";
import AnimationBox from "./third/AnimationBox";

const CustomSectionWrapper = styled.div``;

const CustomWidth100 = styled(Width100)`
	height: 100%;
`;
const PageInfoWidth45 = styled(Width45)`
	height: 100%;
	box-sizing: border-box;
	flex-wrap: nowrap;
	align-items: flex-start;
	justify-content:center;
	margin-top: -#{${(props) => props.theme.sizes.pageMarginTop}};
    flex-direction: column;
    border-right:1px solid ${props => props.theme.colors.lbgBorderColor};
`;

const CustomWidth55 = styled(Width55)`
		flex-shrink:0;
		display:flex;
		align-items:center;
		justify-content:center;
	img{
		width:95%;
		height:auto;
		display:block;
		margin:0 auto;
	}
`;

const infoTextObj = {
	title:`AI Driven<br />Customized Analytics`,
	content:`목적과 특성에 따라 AI 큐레이션을 통해<br />
			진단 영역, 활용 데이터, 분석 알고리즘<br />    
			맞춤화된 형태로 구성합니다.`
}



const ThirdSection = () => {

    const { currentSection, isExplorer, device } = useSelector((state) => ({
		currentSection: state.page.currentSection,
        isExplorer: state.style.isExplorer,
        device: state.style.device,
	}));


    const AnimationBoxContents = () => {
        if (isExplorer){
            return <img src="/img/mri03.png" alt="목적과 특성에 따라 AI 큐레이션을 통해 맞춤화된 형태로 구성"/>;
        } else {
    
            if(device === "mobile"){
    
                return <AnimationBox />;
    
            } else {
    
                if (currentSection === 4){
                    return <AnimationBox />;
                } else {
                    return null;
                }
            }
    
        }
    };

	return (
		<>
			<CustomSectionWrapper>
				<PageWrapper>
					<Wrapper>
						<CustomWidth100>
							<PageInfoWidth45>
								<Info texts = {infoTextObj}/>
								<MriPageLogo src="/img/antock_mri_b.png" alt="antock mri"/>
							</PageInfoWidth45>
							<CustomWidth55>
								<Width100>
									{/* <img src="/img/mri03.png" alt="목적과 특성에 따라 AI 큐레이션을 통해 맞춤화된 형태로 구성"/> */}
                                    {/* <AnimationBox /> */}
                                    { AnimationBoxContents() }
								</Width100>
							</CustomWidth55>
						</CustomWidth100>
					</Wrapper>
				</PageWrapper>
			</CustomSectionWrapper>
		</>
	);
};

export default ThirdSection;
