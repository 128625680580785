import React from "react";

import { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { Width100, Wrapper } from "style/layout";
import { useScroll } from "hooks/CustomHooks";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { setCategory, setSearchKeyword, setOffset } from "modules/news";

import { useNavigate } from "react-router-dom";

const CustomWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-top: calc(${(props) => props.theme.sizes.headerHeight} + 6vh);
  opacity:1;
  transition:all 0.3s ease-in-out;
  
  ${(props) => {

    const isOffset = props.isOffset;
    const currentPage = props.currentPage;

    if ( currentPage === "pr" && isOffset ) {
        return css`
        height:0;
        padding:0;
        opacity:0;
      `;
    }
    if (currentPage === "newsdetail") {
        return css`
      display: none;
    `;
    }
}};
`;

const PrNavList = styled.ul`
  display: flex;
  flex-direction: row;
  width: auto;
  font-size: ${(props) => props.theme.Rem(28)};
  font-weight: 600;
`;
const PrNavListItem = styled.li`
  a {
    padding: ${(props) => props.theme.Rem(15)} ${(props) => props.theme.Rem(23)};
    box-sizing: border-box;
    display: block;
    transition: all 0.3s ease-in-out;
    border-bottom: 3px solid transparent;
    text-align: center;
    position: relative;
    cursor:pointer;
    color: ${(props) => props.theme.colors.pointColor};
    &:hover,
    &.now {
      font-weight: 900;
      border-bottom: 3px solid ${(props) => props.theme.colors.pointColor};
    }
    ${(props) => {
    if (props.new === true) {
        return css`
          &::before {
            content: "";
            width: 0.5rem;
            height: 0.5rem;
            background-color: #fd4e98;
            border-radius: 0.5rem;
            position: absolute;
            top: ${props.theme.Rem(10)};
            right: ${props.theme.Rem(15)};
          }
        `;
    }
    if(props.category === props.value){
        return css`
            font-weight: 900;
            border-bottom: 3px solid ${(props) => props.theme.colors.pointColor};
        `;
    }
    if(props.isFocus){
        return css`
            color: ${props.theme.colors.dFontColor};
        `;
    }
}}
  }
`;
const SearchWrapperStyle = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  position:relative;
  &::before{
        content:"";
        display:block;
        width:0;
        height:2px;
        background-color:${props => props.theme.colors.pointColor};
        position:absolute;
        bottom:0;
        left:0;
        transition: all 0.3s ease-in-out;
    }
  input {
    width:0;
    padding:0;
    color:${props => props.theme.colors.pointColor};
    font-size: ${(props) => props.theme.Rem(28)};
    transition: all 0.3s ease-in-out;
    padding:0 10px;
    font-weight:600;
    &:focus,&:focus-within{
        width:300px;
    }
    
  }
  button {
    color: ${(props) => props.theme.colors.pointColor};
    box-sizing: border-box;
    padding: ${(props) => props.theme.Rem(15)} ${(props) => props.theme.Rem(10)};
    transition: all 0.3s ease-in-out;
    svg{
        width: ${(props) => props.theme.Rem(28)};
        height: ${(props) => props.theme.Rem(28)};
        font-weight:bold;    
    }
  }
  &:focus{
    margin-left:2rem;
  }
  &:hover{
    width:auto;
    margin-left:2rem;
    &::before{
        width:100%;
    }
      input{
        width:300px;
        &:focus{
            width:300px;
        }
      }
      button{
        padding: ${(props) => props.theme.Rem(15)} ${(props) => props.theme.Rem(23)};
      }
  } 
  ${props => {
    if (props.isFocus) {
        return css`
        margin-left:2rem;
          &::before{
                width:100%;
            }  
            input{
                width:300px;
            }
        `;
    }
}}
`;



const navList = [
    {
        title: "전체보기",
        category: "",
    },
    {
        title: "공지사항",
        category: "NOTICE",
    },
    {
        title: "회사소식",
        category: "FEED",
    },
    {
        title: "언론보도",
        category: "MEDIA",
    },
];

const PrNav = () => {

    const scroll = useScroll();
    const [isOffset, setIsOffset] = useState(false);
    const [inputFocus,setInputFocus] = useState(false);
    const [search, setSearch] = useState();

    useEffect(() => {
        const offset = scroll.scrollY;
        offset > 0 ? setIsOffset(true) : setIsOffset(false);
    }, [scroll]);

    const inputRef = useRef(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { category, searchKeyword, currentPage } = useSelector((state) => ({
        category: state.news.category,
        searchKeyword: state.news.searchKeyword,
        currentPage: state.page.currentPage,
    }));

    useEffect(() => {
        if(searchKeyword){
          inputRef.current.value = searchKeyword;
          setInputFocus(true);
        }
    } ,[searchKeyword]);

    const handleNavClick = (e, newCategory) => {
        inputRef.current.value = '';

        setSearch(null);

        dispatch(setSearchKeyword(null));
        dispatch(setCategory(newCategory));
        dispatch(setOffset(0));
        
        moveToPrPageWithParams(newCategory, "");
    };

    const handleKeywordChange = (e) => {
        setSearch(e.target.value);
        setInputFocus(true)
         if (e.target.value.length > 0) setInputFocus(true);
    };

    const handleSearchSubmit = () => {
        if (search) {
            dispatch(setSearchKeyword(search));
            moveToPrPageWithParams(category, search);
        } else {
            alert("검색어를 입력해주세요"); 
        }
    };

    const handleKeyPress = (e) => {
        if (e.key == "Enter") {
            if (search) {
                dispatch(setSearchKeyword(search));
                moveToPrPageWithParams(category, search);
            } else {
                alert("검색어를 입력해주세요");
            }
        }
    };

    const handleOnBlur = () =>{
        inputRef.current.value.length > 0 ? setInputFocus(true) : setInputFocus(false)
    };

    const moveToPrPageWithParams = (category, search) => {
        navigate("/pr", {
            state: {
                category: category,
                search: search,
            },
        });
    };


    return (
        <Width100>
            <CustomWrapper currentPage={currentPage} isOffset={isOffset} >

                <PrNavList >
                    {navList.map((list, index) => (
                        <PrNavListItem
                            key={index}
                            value={list.category}
                            category={category}
                            onClick={(e) => { handleNavClick(e, list.category); }}
                            isFocus={inputFocus}
                            // new={true} 확인하지 않은 최신 소식이 있으면 표시
                        >
                            <a>{list.title}</a>
                        </PrNavListItem>
                    ))}

                </PrNavList>

                <SearchWrapperStyle isFocus={inputFocus}>
                    <input
                        ref={inputRef}
                        type="text"
                        onChange={handleKeywordChange}
                        onKeyPress={handleKeyPress}
                        placeholder={"검색어를 입력해주세요."}
                        onFocus={ () => {setInputFocus(true)}}
                        onBlur={handleOnBlur}
                    />
                    <button type={"submit"} onClick={handleSearchSubmit}>
                        <SearchIcon />
                    </button>
                </SearchWrapperStyle>


            </CustomWrapper>
        </Width100>
    );
};

export default PrNav;
