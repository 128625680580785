import React from "react";
import styled from "styled-components";
import {Width100, Width33, Width67} from "style/layout";

import CaseSliderItem from "./CaseSliderItem";
import CaseSliderLogo from "./CaseSliderLogo";

import "../../../layout.scss";
import {useState, useRef} from "react";
import {useEffect} from "react";

import {Transition} from "react-transition-group";

const CaseSliderNavigationWrapper = styled.div`
  padding-top: ${(props) => props.theme.Rem(84)};
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  position: relative;
  overflow: hidden;

  ${props => props.theme.device.desktopS} {
    padding-top: ${(props) => props.theme.Rem(94)};
  }
`;

const CaseSliderNavigation = styled.ol`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 1rem;

  > li {
    display: inline-block;
    background-color: ${(props) => props.theme.colors.dFontColor};
    padding: ${(props) => props.theme.Rem(4)} ${(props) => props.theme.Rem(9)};
    box-sizing: border-box;
    border-radius: 0.6rem;
    font-size: ${(props) => props.theme.Rem(11)};
    font-weight: bold;
    color: white;
    white-space: nowrap;

    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &.active,
    &:hover {
      background-color: ${(props) => props.theme.colors.pointColor};
    }
  }

  ${props => props.theme.device.tabletL} {
    > li {
      padding: ${(props) => props.theme.Rem(4)} ${(props) => props.theme.Rem(5)};
    }
  }

`;

const CaseSliderContentWrapper = styled.div`
  position: relative;
  display: block;
  flex-direction: row;
  align-items: center;
  min-height: ${(props) => props.theme.Rem(300)};
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const buttonList = [
    {name: "CASE 01"},
    {name: "CASE 02"},
    {name: "CASE 03"},
    {name: "CASE 04"},
];

const Contents = () => {
    const buttonSelect = useRef(null);

    const [sliderIndex, setSliderIndex] = useState(0);


    useEffect(() => {
        const buttons = buttonSelect.current;
        buttons.children[sliderIndex].classList.toggle("active");
    }, []);

    const handleButtonClick = (e, targetIndex) => {


        setSliderIndex(targetIndex);

        const buttons = buttonSelect.current;
        const length = buttons.children.length;

        for (let i = 0; i < length; i++) {
            if (i === targetIndex) {
                buttons.children[i].classList.toggle("active");
            } else {
                buttons.children[i].classList.remove("active");
            }
        }
    };

    return (
        <Width100>
            <Width33 className="border-left">
                <CaseSliderNavigationWrapper>
                    <CaseSliderNavigation ref={buttonSelect}>
                        {buttonList.map((button, index) => (
                            <li
                                key={index}
                                onClick={(e) => handleButtonClick(e, index)}
                            >
                                {button.name}
                            </li>
                        ))}
                    </CaseSliderNavigation>

                    <CaseSliderLogo sliderIndex={sliderIndex}/>
                </CaseSliderNavigationWrapper>
            </Width33>

            <Width67 className="border-left">
                <CaseSliderContentWrapper>
                    <CaseSliderItem sliderIndex={sliderIndex}/>
                </CaseSliderContentWrapper>
            </Width67>
        </Width100>
    );
};

export default Contents;
