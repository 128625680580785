import React, { useState, useRef, useEffect } from "react";
import { animated, useSpring } from "react-spring";

const TripleRectangle = ({ color, init, data }) => {
	
    const shapeRef = useRef(null);

	const animationFillStyle = useSpring({
        from: { fillColor: "#b8b8c6" },
        to: { fillColor: color }, 
		config: { duration: 1000 },
		delay: 5000,
        reset: init,
	});

	return (
		<>
			<animated.path
                ref={shapeRef}
                fill={animationFillStyle.fillColor}
                id={data.id}
				data-name={data.id}
				transform={data.transform}
				d={data.d}
			/>
		</>
	);
};

export default React.memo(TripleRectangle);
