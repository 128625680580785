import React, { useEffect, useState, useRef } from 'react';
import styled from "styled-components";

import { useSwipeable } from 'react-swipeable';
import SwipeRightAltOutlinedIcon from '@mui/icons-material/SwipeRightAltOutlined';
import SwipeLeftAltOutlinedIcon from '@mui/icons-material/SwipeLeftAltOutlined';
import SwipeIcon from '@mui/icons-material/Swipe';

const HistoryWrapper = styled.div`
    border-bottom:1px solid ${props => props.theme.colors.borderColor};
`;
const YearWrapper = styled.div`
    display:flex;
    box-sizing:border-box;
    width:100%;
    overflow:hidden;
    overflow-x:auto;
    align-items:center;
    position:relative;
    border-bottom:1px solid ${props => props.theme.colors.lbgBorderColor};
    &::before{
        content:"";
        display:block;
        position:absolute;
        top:0.3rem;
        height:1px;
        width:340%;
        background-color:${props => props.theme.colors.lbgBorderColor};
        
    }
    .slider-wrapper{
        width:auto;
        flex-direction:row;
        flex-wrap:nowrap;
        display:flex;
        box-sizing:border-box;
        padding:0 40vw;
    }
    button{
        font-size:1.4rem;
        padding: calc(1.8rem + 0.3rem) 1.5rem 1.8rem ;
        color:#B8B8C6;
        position:relative;
    }
`;

const YearBtn = styled.button`
    ${(props) => {
        if (props.children === props.selectIdx) {
            return `
                font-weight:900;
                color:${props.theme.colors.pointColor} !important;
                &::before{
                    content:"";
                    width:0.6rem;
                    height:0.6rem;
                    background-color:${props.theme.colors.pointColor};
                    border-radius:0.6rem;
                    position:absolute;
                    top:1px;
                    left:0;
                    right:0;
                    margin:0 auto;
                }  
            `;
        }

    }}    
`;
const SelectYearsListWrapper = styled.div`
    width:100%;
    min-height:40vh;
    height:auto;
    display:flex;
    flex-direction:column;
    padding-top:1rem;
    box-sizing:border-box;
`;
const SelectYearsItem = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    margin-bottom:1rem;
    span{
        display:block;
        width:6.5rem;
        font-weight:800;
        line-height:1.5rem;
        
    }
    p{
        width:calc(100% - 6.5rem);
        word-break:keep-all;
        line-height:1.5rem;
    }
`;

const SwipeWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
`

const MobileHistory = ({ historyData }) => {

    const containerRef = useRef(null);
    const itemRef = useRef(null);

    const [years, setYears] = useState();
    const [selectedYears, setSelectedYears] = useState();

    useEffect(() => {

        const yearsArr = [];

        Object.keys(historyData).map((year) => {
            yearsArr.push(year);
        });

        setYears(yearsArr);

    }, [historyData]);


    useEffect(() => {
        if (years) setSelectedYears(years[0]);
    }, [years]);


    useEffect(() => {

        if (containerRef && containerRef.current) {
            // Init scroll
            containerRef.current.scrollTo({
                left: 27,
                top: 0,
                behavior: 'smooth'
            });
        }

    }, [containerRef]);

    const handleYearClick = (year) => {
        setSelectedYears(year);
    };

    useEffect(() => {

        if (selectedYears) {
            let currentIndex;
            years.map((year, index) => {
                if (year === selectedYears) currentIndex = index;
            });

            if (containerRef && containerRef.current && itemRef && itemRef.current) {

                const itemWidth = itemRef.current.offsetWidth * 0.975;

                let offset = 27;
                if (currentIndex !== 0) offset = 27 + (itemWidth * currentIndex);
                if (currentIndex === years.length - 1) offset = 27 + (itemWidth * currentIndex) - 35;

                containerRef.current.scrollTo({
                    left: offset,
                    top: 0,
                    behavior: 'smooth'
                });
            }
        }

    }, [selectedYears]);

    
    const handleSwipe = (event) => {

        let currentIndex;
        years.map((year, index) => {
            if (year === selectedYears) currentIndex = index;
        });

        // Right swipe
        if (event.dir === 'Left' && event.deltaX < -100) {
            // 오른쪽 맨 끝 -> 처음으로
            if (currentIndex === years.length - 1) {
                setSelectedYears(years[0]);
            } else {
                setSelectedYears(years[currentIndex + 1]);
            }
        }

        // Left swipe
        if (event.dir === 'Right' && event.deltaX > 100) {
            // 왼쪽 맨 끝 -> 마지막으로
            if (currentIndex === 0) {
                setSelectedYears(years[years.length - 1]);
            } else {
                setSelectedYears(years[currentIndex - 1]);
            }
        }
    };

    const swipeHandlers = useSwipeable({
        onSwiped: handleSwipe,
    });
    
    return (
        <HistoryWrapper>
            <YearWrapper ref={containerRef}>
                <div className="slider-wrapper">
                    {/* class now 를 붙이면 현재 이펙트가 나타납니다.*/}
                    {years && years.map((year, index) => (
                        <YearBtn ref={itemRef} onClick={() => handleYearClick(year, index)} key={year} selectIdx={selectedYears}>{year}</YearBtn>
                    ))}
                </div>
            </YearWrapper>
            <SelectYearsListWrapper {...swipeHandlers}>
                <SwipeWrapper><SwipeIcon/></SwipeWrapper>
                {selectedYears && historyData[selectedYears].map((info, index) => {            

                    const month = info.historyDate.split("-")[1];
                    const day = info.historyDate.split("-")[2];

                    let filterMonth, filterDay;

                    parseInt(month) < 10 ? filterMonth = "0" + month.split("")[1] : filterMonth = month;
                    parseInt(day) < 10 ? filterDay = "0" + day.split("")[1] : filterDay = day;

                    return (
                        <SelectYearsItem key={index}>
                            <span>{filterMonth}월 {filterDay}일</span>
                            <p>{info.title} </p>
                        </SelectYearsItem>
                    );
                })}
            </SelectYearsListWrapper>
        </HistoryWrapper>
    );
};

export default MobileHistory;