import React from "react";
import styled from "styled-components";

import {
  Width100,
  textBoxTitle,
  textBoxExplain,
  textBoxContents,
} from "style/layout";

import "../../../layout.scss";
import { useRef } from "react";

import { useCaseSlider } from "hooks/CustomHooks";

const CaseSliderItemWrapper = styled.div`
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  transform: translate(${(props) => props.theme.Rem(-100)}, 0);
  transition: all 0.3s ease-in-out;
  opacity: 0;

  &.active {
    opacity: 1;
    transform: translate(0, 0);
  }
  &.active-out {
    opacity: 0;
    transform: translate(${(props) => props.theme.Rem(100)}, 0);
  }
`;

const ItemImgWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  background-image: url("/img/landing04.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;

const CustomTextBox = styled.div`
  padding-top: ${(props) => props.theme.Rem(134)};
  box-sizing: border-box;
`;

const CustomTitle = styled.h3`
  ${textBoxTitle}
  font-size:${(props) => props.theme.Rem(20)};
  color: ${(props) => props.theme.colors.pointColor};
  margin-bottom: ${(props) => props.theme.Rem(28)};
  font-weight: 900;
`;

const CustomExplain = styled.p`
  ${textBoxExplain}
  font-size:${(props) => props.theme.Rem(16)};
  font-weight: 900;
  margin-bottom: ${(props) => props.theme.Rem(28)};
`;

const CustomContents = styled.div`
  ${textBoxContents}
  ol {
    margin-bottom: ${(props) => props.theme.Rem(40)};
    counter-reset: circle-counter;
    display:flex;
    flex-direction:column;
    li {
      counter-increment: circle-counter;
      font-weight: 400;
      font-size: ${(props) => props.theme.Rem(14)};
//      line-height: ${(props) => props.theme.Rem(24)};
      margin-bottom: ${(props) => props.theme.Rem(18)};
      display: flex;
      flex-direction: row;
      width:100%;
      height:auto;
      word-break:keep-all;
      padding-right:20%;
      box-sizing:border-box;
      &::before {
        content: counter(circle-counter);
        background-color: transparent;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 0.7rem;
        border: 1px solid ${(props) => props.theme.colors.fontColor};
        color: ${(props) => props.theme.colors.fontColor};
        margin-right: 0.5rem;
        box-sizing: border-box;
        text-indent: -0.1rem;
        margin-top: 0.0rem;
        padding: 0.3rem;
      }
    }
  }
`;

const CustomSumList = styled(Width100)`
  padding-bottom: ${(props) => props.theme.Rem(36)};
  height: auto;
  > div {
    min-width: ${(props) => props.theme.Rem(106)};
    max-width: ${(props) => props.theme.Rem(150)};
    margin-right: ${(props) => props.theme.Rem(36)};
    &:first-of-type {
      margin-left: ${(props) => props.theme.Rem(24)};
    }
    &:last-of-type {
      margin-right: 0;
    }
    b {
      font-size: ${(props) => props.theme.Rem(11)};
      font-weight: 900;
    }
    p {
      font-size: ${(props) => props.theme.Rem(16)};
      font-weight: 400;
      margin-top: ${(props) => props.theme.Rem(10)};
      b {
        font-size: ${(props) => props.theme.Rem(16)};
        font-weight: 900;
      }
    }
  }
`;

const contentsList = [
  {
    title: "IBK 기업은행",
    explain: "공공데이터 RFM 분석 기반 중소기업 대안신용평가 AI 모형 구축",
    contents: [
      {
        0: "공시 의무가 없어 전통적 금융 정보(재무)가 부족한 중소기업을 대상으로 비재무 공공데이터를 활용한 부도 리스크 AI 측정 모형 구축",
      },
      {
        1: "중소기업별 공공데이터의 발생 현상(시점-Recency, 빈도-Frequency, 규모-Magnitude)에 따라 평가 항목 유의성 검증하여 부도 예측 모형에 대입",
      },
      {
        2: "평가 모형의 결과치를 실제 기업은행의 부도 차주 정보와 비교하여 Confusion Matrix 및 Accuracy Ratio 등을 활용하여 모형의 성능 입증",
      },
    ],
    sumList: [
      {
        title: "가공 표본",
        number: "24만 개",
        unit: "차주",
      },
      {
        title: "가공 객체",
        number: "1,169만 개",
        unit: "항목",
      },
      {
        title: "개발실적",
        number: "사업화 계약",
        unit: "",
      },
    ],
  },
  {
    title: "머니투데이",
    explain: "AI 기술을 활용한 유망 스타트업 발굴 및 분석 리포트 자동화 생성 시스템",
    contents: [
      {
        0: "언론사 자체 생산 뉴스(텍스트) 데이터 대상 AI 기계 독해를 통해 혁신적 기술을 보유한 벤처 및 각종 창업기업 자동화 발굴",
      },
      {
        1: "발굴 기업에 대한 공공데이터를 추가적으로 수집하고 Auto ML 기반의 AI 경영 진단 결과를 자동화된 투자 리포트(IM) 형태로 산출",
      },
      {
        2: "개발 시스템의 성능은 AI 기술분류 합리성, 기업정보 정합성, 기업평가 정교성, 투자제안 실효성 등 4대 기준으로 설정하여 각 기준별 목표치 달성 여부로 판단",
      },
    ],
    sumList: [
      {
        title: "가공 표본",
        number: "5만 개",
        unit: "기업",
      },
      {
        title: "가공 객체",
        number: "1,500만 개",
        unit: "항목",
      },
      {
        title: "개발실적",
        number: "사업화",
        unit: " / 시험인증 통과",
      },
    ],
  },
  {
    title: "서울대학교",
    explain: "빅데이터 및 AI 활용한 사회적 경제 기업 가치 자동화 측정 알고리즘 개발",
    contents: [
      {
        0: "사회적 가치 인센티브 제공 프로그램에 참여한 사회적 경제 기업에 대한 과거 5개년에 대한 공공데이터 자동화 수집",
      },
      {
        1: "대안 데이터에 대한 AI 판별 및 분석을 통해 개별 기업의 세부적 고용, 서비스, 환경 성과를 정량화된 방식으로 예측",
      },
      {
        2: "기존 인센티브 평가 결과와 AI 알고리즘 진단 결과를 대조 비교하여 자동화 알고리즘에 대한 설명력 도출",
      },
    ],
    sumList: [
      {
        title: "가공 표본",
        number: "800개",
        unit: "기업",
      },
      {
        title: "가공 객체",
        number: "2만",
        unit: "개 항목",
      },
      {
        title: "개발실적",
        number: "사업화 계약",
        unit: "",
      },
    ],
  },
  {
    title: "SE임파워 사회적협동조합",
    explain: "사회적 경제 데이터 활용 중소기업 특화 ESG 진단 AI 컨설턴트 시스템",
    contents: [
      {
        0: "중소기업 대안 데이터에 대한 AutoML 기반 AI 학습을 통해 ESG 예측 및 진단 모형을 개발하여 개별 중소기업의 ESG 측명의 성과 평가",
      },
      {
        1: "평가 결과 시각화하여 중소기업의 객관적이며 과학적인 ESG 자가진단 가능하게 하여, 자발적 형태의 지속가능경영 도입 지원",
      },
      {
        2: "환경 (E), 사회 (S), 지배구조 (G) 개별 영역 별 통계적 설명력 관점의 Cut-Off 기준 R²을 설정하여 시스템 성능 검증 수행",
      },
    ],
    sumList: [
      {
        title: "가공 표본",
        number: "1,300개",
        unit: "기업",
      },
      {
        title: "가공 객체",
        number: "5만",
        unit: "개 항목",
      },
      {
        title: "개발실적",
        number: "사업화",
        unit: " / 시험인증 통과",
      },
    ],
  },
];

const CaseSliderItem = ({ sliderIndex }) => {
  const sliderRef = useRef(null);
  useCaseSlider(sliderIndex, sliderRef);

  return (
    <div ref={sliderRef}>
      {contentsList.map((content, index) => (
        <CaseSliderItemWrapper key={index}>
          <CustomTextBox>
            <CustomTitle>{content.title}</CustomTitle>
            <CustomExplain>{content.explain}</CustomExplain>
            <CustomContents>
              <ol>
                {content.contents.map((contentDetail, index) => (
                  <li key={index}>{contentDetail[index]}</li>
                ))}
              </ol>
            </CustomContents>

            <CustomSumList>
              {content.sumList.map((sum, index) => (
                <div key={index} className="border-left">
                  <b>{sum.title}</b>
                  <p>
                    <b>{sum.number}</b> {sum.unit}
                  </p>
                </div>
              ))}
            </CustomSumList>
          </CustomTextBox>
          <ItemImgWrapper />
        </CaseSliderItemWrapper>
      ))}
    </div>
  );
};

export default CaseSliderItem;
